
@import "../../../../../css/variables/devices/_device_sizes";

@mixin mobileScreen{

    /* @media only screen and (min-width:$mobile_min) and (max-width:$mobile_max){ */
    
    /**** COMBINE SMALL MOBILE AND MOBILE BY SETTING ONLY MAX-WIDTH ****/
    
    @media only screen and (min-width:$mobile_min) and (max-width:$mobile_max){

        --smetio-customer-website-teamMembers-items-max-width:100%;
        // --smetio-customer-website-teamMembers-item-width:95%;
        --smetio-customer-website-teamMembers-items-justify-content:center;

        --smetio-photo-and-description-photo-width:100%;

        // .smetio-photo-and-description { 
        //     // flex-wrap: wrap !important;
        //     &[data-smetio-max-height="1950px"]{ 
        //         max-height: 1950px;
        //     }
        // }

        .smetio-photo-and-description {
            max-width: 95% !important;
            width: 95% !important;

            img {
                // display: none;
                width: 120% !important;
            }
        }

        @media (orientation: landscape){

            
        }
        

        @media (orientation: portrait){

            
        }
        
        
    }

}    