
@import "../../../../../css/variables/devices/_device_sizes";

@mixin tabletScreen{

    @media only screen and (min-width:$tablet_min) and (max-width:$tablet_max){

        

        /**** THE CSS FOR THE TABLET LOOK OF THE WIDGET SHOULD BE PLACED BELOW ****/
        --smetio-3ack-blogs-page-controller-item-width:50%;
        --smetio-3ack-blogs-page-controller-items-holder-width:95%;

        @media (orientation: landscape){

            
        }
        

        @media (orientation: portrait){

            
        }
        
        
    } 

}
