@import "../../variables/smetio_brands/smetio/colors/colors";


@mixin general_smetio_classes{

    *{
    
        margin:0;
        padding:0;  
              
    }

    i {
        font-size:
            var(--smetio-app-ui-menu-bar-icon-size);
        display:block;
        
    }

    .ajaxErrorMsg {
    
        width:100%;
        text-align:center;
    
    }
    
    .smetio-blue-icon {
    
       color:$smetio_blue; 
    
    }

    .smetio-red-icon {
    
       color:$smetio_red; 
    
    }

    .smetio-yellow-icon {
    
       color:$smetio_yellow; 
    
    }

    .smetio-green-icon {
    
       color:$smetio_green; 
    
    }
    
    .smetio-icon-primary:hover {
    
        color:var(--smetio-icon-primary-color-hover);
        cursor:pointer; 
        
    }

    .smetio-icon-primary:active {
    
        color:var(--smetio-icon-primary-color-active);
        cursor:pointer; 
        
    }
    
    .smetio-icon-secondary {
    
        color:var(--smetio-icon-secondary-color);
    
    }

    .smetio-icon-secondary:hover {
    
        color:var(--smetio-icon-secondary-color-hover);
        cursor:pointer;
    
    }

    .smetio-icon-secondary:active {
    
        color:var(--smetio-icon-secondary-color-active);
    
    }
    
    .smetio-icon-tetiary {
    
        color:var(--smetio-icon-tetiary-color);
    
    }

    .smetio-icon-tetiary:hover {
    
        color:var(--smetio-icon-tetiary-color-hover);
        cursor:pointer;
    
    }
    
    .smetio-icon-tetiary:active {
    
        color:var(--smetio-icon-tetiary-color-active);
    
    }    
    
    .smetio-button {
    
        background-color:var(--smetio-button-color);
                                                                                                
        color:var(--smetio-text-primary-color);

        &[data-active="yes"]{
            
            background-color:var(--smetio-button-color-active);
            color:var(--smetio-text-secondary-color);
            
                *{
                
                    background-color:var(--smetio-button-color-active);
                    color:var(--smetio-text-secondary-color);                
                }
                        
        }   
                     
    }
            
    .smetio-button:hover{
    
        background-color:var(--smetio-button-color-hover);
                                                                                     
        color:var(--smetio-text-secondary-color);
        cursor:pointer; 

            *{
            
                background-color:var(--smetio-button-color-hover);
                                                                                             
                color:var(--smetio-text-secondary-color);
                cursor:pointer;                
            }                
    }

    .smetio-button:active{
    
        background-color:var(--smetio-button-color-active);
        
            *{
            
                background-color:var(--smetio-button-color-active);                
            }                
    }        


    .smetio-accent-btn {
    
        background-color:var(--smetio-accent-color);
    
    }

    .smetio-accent-btn:hover {

        background-color:var(--smetio-accent-color-hover);
        cursor:pointer;
    
    }
    
    .smetio-accent-btn:active {
        
        background-color:var(--smetio-accent-color-active);
    
    }         

    .smetio-link-btn {
    
        background-color:var(--smetio-link-color);
    
    }

    .smetio-link-btn:hover {

        background-color:var(--smetio-link-color-hover);
        cursor:pointer;
    
    }
    
    .smetio-link-btn:active {
        
        background-color:var(--smetio-link-color-active);
    
    }  

    .smetio-link-btn-text {
    
        color:var(--smetio-link-color);
    
    }

    .smetio-link-btn-text:hover {

        color:var(--smetio-link-color-hover);
        cursor:pointer;
    
    }
    
    .smetio-link-btn-text:active {
        
        color:var(--smetio-link-color-active);
    
    }



    .smetio-tab-content {
    
        //display:none;
        
            &[data-active="no"]{
            
                display:none;
            
            }
        
    }

    .smetio-clickable-pointer:hover {

        cursor: pointer;
        
    }

    .testing {

        color: green;
        
    }

 

}












