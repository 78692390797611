:root {
    /* accentColor */
    --smetio-accent-color: #DDCFF2;

    --smetio-accent-color-shade-20: #C1A6E7;
    --smetio-accent-color-tint-20: #F9F7FD;

    --smetio-accent-color-shade-40: #A67EDD;
    --smetio-accent-color-tint-40: #FFFFFF;

    --smetio-accent-color-shade-60: #8A56D2;
    --smetio-accent-color-tint-60: #FFFFFF;

    --smetio-accent-color-shade-80: #6F33C1;
    --smetio-accent-color-tint-80: #FFFFFF;

    --smetio-accent-color-shade-100: #582999;
    --smetio-accent-color-tint-100: #FFFFFF;

    /* dominantColor */
    --smetio-dominant-color: #150962;

    --smetio-dominant-color-shade-20: #0B0533;
    --smetio-dominant-color-tint-20: #1F0D91;

    --smetio-dominant-color-shade-40: #010005;
    --smetio-dominant-color-tint-40: #2912BF;

    --smetio-dominant-color-shade-60: #000000;
    --smetio-dominant-color-tint-60: #361AEA;

    --smetio-dominant-color-shade-80: #000000;
    --smetio-dominant-color-tint-80: #5F49EE;

    --smetio-dominant-color-shade-100: #000000;
    --smetio-dominant-color-tint-100: #8878F2;

    /* neutralColor */
    --smetio-neutral-color: #FFFFFF;

    --smetio-neutral-color-rgb: rgb(255, 255, 255);

    --smetio-neutral-color-rgba-10: rgba(255, 255, 255, 0.1);

    --smetio-neutral-color-rgba-20: rgba(255, 255, 255, 0.2);

    --smetio-neutral-color-rgba-30: rgba(255, 255, 255, 0.3);

    --smetio-neutral-color-rgba-40: rgba(255, 255, 255, 0.4);

    --smetio-neutral-color-rgba-50: rgba(255, 255, 255, 0.5);

    --smetio-neutral-color-rgba-60: rgba(255, 255, 255, 0.6);

    --smetio-neutral-color-rgba-70: rgba(255, 255, 255, 0.7);

    --smetio-neutral-color-rgba-80: rgba(255, 255, 255, 0.8);

    --smetio-neutral-color-rgba-90: rgba(255, 255, 255, 0.9);

    --smetio-neutral-color-shade-20: #E6E6E6;
    --smetio-neutral-color-tint-20: #FFFFFF;

    --smetio-neutral-color-shade-40: #CCCCCC;
    --smetio-neutral-color-tint-40: #FFFFFF;

    --smetio-neutral-color-shade-60: #B3B3B3;
    --smetio-neutral-color-tint-60: #FFFFFF;

    --smetio-neutral-color-shade-80: #999999;
    --smetio-neutral-color-tint-80: #FFFFFF;

    --smetio-neutral-color-shade-100: #808080;
    --smetio-neutral-color-tint-100: #FFFFFF;

    /* greyColor */
    --smetio-grey-color: #808080;

    --smetio-grey-color-rgb: rgb(128, 128, 128);

    --smetio-grey-color-rgba-10: rgba(128, 128, 128, 0.1);

    --smetio-grey-color-rgba-20: rgba(128, 128, 128, 0.2);

    --smetio-grey-color-rgba-30: rgba(128, 128, 128, 0.3);

    --smetio-grey-color-rgba-40: rgba(128, 128, 128, 0.4);

    --smetio-grey-color-rgba-50: rgba(128, 128, 128, 0.5);

    --smetio-grey-color-rgba-60: rgba(128, 128, 128, 0.6);

    --smetio-grey-color-rgba-70: rgba(128, 128, 128, 0.7);

    --smetio-grey-color-rgba-80: rgba(128, 128, 128, 0.8);

    --smetio-grey-color-rgba-90: rgba(128, 128, 128, 0.9);

    --smetio-grey-color-shade-20: #666666;
    --smetio-grey-color-tint-20: #999999;

    --smetio-grey-color-shade-40: #4D4D4D;
    --smetio-grey-color-tint-40: #B3B3B3;

    --smetio-grey-color-shade-60: #333333;
    --smetio-grey-color-tint-60: #CCCCCC;

    --smetio-grey-color-shade-80: #1A1A1A;
    --smetio-grey-color-tint-80: #E6E6E6;

    --smetio-grey-color-shade-100: #000000;
    --smetio-grey-color-tint-100: #FFFFFF;

    /* warningColor */
    --smetio-warning-color: #F59C61;

    --smetio-warning-color-shade-20: #F27E31;
    --smetio-warning-color-tint-20: #F8BA91;

    --smetio-warning-color-shade-40: #E1630E;
    --smetio-warning-color-tint-40: #FBD8C1;

    --smetio-warning-color-shade-60: #B14E0B;
    --smetio-warning-color-tint-60: #FEF6F1;

    --smetio-warning-color-shade-80: #813908;
    --smetio-warning-color-tint-80: #FFFFFF;

    --smetio-warning-color-shade-100: #512405;
    --smetio-warning-color-tint-100: #FFFFFF;

    /* successColor */
    --smetio-success-color: #CFF2E2;

    --smetio-success-color-shade-20: #A6E7CA;
    --smetio-success-color-tint-20: #F7FDFA;

    --smetio-success-color-shade-40: #7EDDB2;
    --smetio-success-color-tint-40: #FFFFFF;

    --smetio-success-color-shade-60: #56D29A;
    --smetio-success-color-tint-60: #FFFFFF;

    --smetio-success-color-shade-80: #33C181;
    --smetio-success-color-tint-80: #FFFFFF;

    --smetio-success-color-shade-100: #299967;
    --smetio-success-color-tint-100: #FFFFFF;

    /* informationColor */
    --smetio-information-color: #230BC1;

    --smetio-information-color-shade-20: #1B0891;
    --smetio-information-color-tint-20: #2C0EF1;

    --smetio-information-color-shade-40: #120660;
    --smetio-information-color-tint-40: #563EF4;

    --smetio-information-color-shade-60: #090330;
    --smetio-information-color-tint-60: #816EF7;

    --smetio-information-color-shade-80: #000000;
    --smetio-information-color-tint-80: #AB9FF9;

    --smetio-information-color-shade-100: #000000;
    --smetio-information-color-tint-100: #D5CFFC;

    /* dangerColor */
    --smetio-danger-color: #F25F5F;

    --smetio-danger-color-shade-20: #EE2F2F;
    --smetio-danger-color-tint-20: #F68E8E;

    --smetio-danger-color-shade-40: #D91212;
    --smetio-danger-color-tint-40: #FABDBD;

    --smetio-danger-color-shade-60: #AA0E0E;
    --smetio-danger-color-tint-60: #FDECEC;

    --smetio-danger-color-shade-80: #7B0A0A;
    --smetio-danger-color-tint-80: #FFFFFF;

    --smetio-danger-color-shade-100: #4B0606;
    --smetio-danger-color-tint-100: #FFFFFF;
}

.smetio-neutral-background-trans-10 {
    background-color: var(--smetio-neutral-color-rgba-10);
    fill: var(--smetio-neutral-color-rgba-10);
}

.smetio-neutral-background-trans-20 {
    background-color: var(--smetio-neutral-color-rgba-20);
    fill: var(--smetio-neutral-color-rgba-20);
}

.smetio-neutral-background-trans-30 {
    background-color: var(--smetio-neutral-color-rgba-30);
    fill: var(--smetio-neutral-color-rgba-30);
}

.smetio-neutral-background-trans-40 {
    background-color: var(--smetio-neutral-color-rgba-40);
    fill: var(--smetio-neutral-color-rgba-40);
}

.smetio-neutral-background-trans-50 {
    background-color: var(--smetio-neutral-color-rgba-50);
    fill: var(--smetio-neutral-color-rgba-50);
}

.smetio-neutral-background-trans-60 {
    background-color: var(--smetio-neutral-color-rgba-60);
    fill: var(--smetio-neutral-color-rgba-60);
}

.smetio-neutral-background-trans-70 {
    background-color: var(--smetio-neutral-color-rgba-70);
    fill: var(--smetio-neutral-color-rgba-70);
}

.smetio-neutral-background-trans-80 {
    background-color: var(--smetio-neutral-color-rgba-80);
    fill: var(--smetio-neutral-color-rgba-80);
}

.smetio-neutral-background-trans-90 {
    background-color: var(--smetio-neutral-color-rgba-90);
    fill: var(--smetio-neutral-color-rgba-90);
}

.smetio-grey-background-trans-10 {
    background-color: var(--smetio-grey-color-rgba-10);
    fill: var(--smetio-grey-color-rgba-10);
}

.smetio-grey-background-trans-20 {
    background-color: var(--smetio-grey-color-rgba-20);
    fill: var(--smetio-grey-color-rgba-20);
}

.smetio-grey-background-trans-30 {
    background-color: var(--smetio-grey-color-rgba-30);
    fill: var(--smetio-grey-color-rgba-30);
}

.smetio-grey-background-trans-40 {
    background-color: var(--smetio-grey-color-rgba-40);
    fill: var(--smetio-grey-color-rgba-40);
}

.smetio-grey-background-trans-50 {
    background-color: var(--smetio-grey-color-rgba-50);
    fill: var(--smetio-grey-color-rgba-50);
}

.smetio-grey-background-trans-60 {
    background-color: var(--smetio-grey-color-rgba-60);
    fill: var(--smetio-grey-color-rgba-60);
}

.smetio-grey-background-trans-70 {
    background-color: var(--smetio-grey-color-rgba-70);
    fill: var(--smetio-grey-color-rgba-70);
}

.smetio-grey-background-trans-80 {
    background-color: var(--smetio-grey-color-rgba-80);
    fill: var(--smetio-grey-color-rgba-80);
}

.smetio-grey-background-trans-90 {
    background-color: var(--smetio-grey-color-rgba-90);
    fill: var(--smetio-grey-color-rgba-90);
}

.smetio-neutral-shadow-around-trans-10 {
    box-shadow: 0px 0px 10px 5px var(--smetio-neutral-color-rgba-10);
    -webkit-box-shadow: 0px 0px 10px 5px var(--smetio-neutral-color-rgba-10);
}

.smetio-neutral-shadow-bottom-trans-10 {
    box-shadow: 0px 5px 10px 5px var(--smetio-neutral-color-rgba-10);
    -webkit-box-shadow: 0px 5px 10px 5px var(--smetio-neutral-color-rgba-10);
}

.smetio-neutral-shadow-around-trans-20 {
    box-shadow: 0px 0px 10px 5px var(--smetio-neutral-color-rgba-20);
    -webkit-box-shadow: 0px 0px 10px 5px var(--smetio-neutral-color-rgba-20);
}

.smetio-neutral-shadow-bottom-trans-20 {
    box-shadow: 0px 5px 10px 5px var(--smetio-neutral-color-rgba-20);
    -webkit-box-shadow: 0px 5px 10px 5px var(--smetio-neutral-color-rgba-20);
}

.smetio-neutral-shadow-around-trans-30 {
    box-shadow: 0px 0px 10px 5px var(--smetio-neutral-color-rgba-30);
    -webkit-box-shadow: 0px 0px 10px 5px var(--smetio-neutral-color-rgba-30);
}

.smetio-neutral-shadow-bottom-trans-30 {
    box-shadow: 0px 5px 10px 5px var(--smetio-neutral-color-rgba-30);
    -webkit-box-shadow: 0px 5px 10px 5px var(--smetio-neutral-color-rgba-30);
}

.smetio-neutral-shadow-around-trans-40 {
    box-shadow: 0px 0px 10px 5px var(--smetio-neutral-color-rgba-40);
    -webkit-box-shadow: 0px 0px 10px 5px var(--smetio-neutral-color-rgba-40);
}

.smetio-neutral-shadow-bottom-trans-40 {
    box-shadow: 0px 5px 10px 5px var(--smetio-neutral-color-rgba-40);
    -webkit-box-shadow: 0px 5px 10px 5px var(--smetio-neutral-color-rgba-40);
}

.smetio-neutral-shadow-around-trans-50 {
    box-shadow: 0px 0px 10px 5px var(--smetio-neutral-color-rgba-50);
    -webkit-box-shadow: 0px 0px 10px 5px var(--smetio-neutral-color-rgba-50);
}

.smetio-neutral-shadow-bottom-trans-50 {
    box-shadow: 0px 5px 10px 5px var(--smetio-neutral-color-rgba-50);
    -webkit-box-shadow: 0px 5px 10px 5px var(--smetio-neutral-color-rgba-50);
}

.smetio-neutral-shadow-around-trans-60 {
    box-shadow: 0px 0px 10px 5px var(--smetio-neutral-color-rgba-60);
    -webkit-box-shadow: 0px 0px 10px 5px var(--smetio-neutral-color-rgba-60);
}

.smetio-neutral-shadow-bottom-trans-60 {
    box-shadow: 0px 5px 10px 5px var(--smetio-neutral-color-rgba-60);
    -webkit-box-shadow: 0px 5px 10px 5px var(--smetio-neutral-color-rgba-60);
}

.smetio-neutral-shadow-around-trans-70 {
    box-shadow: 0px 0px 10px 5px var(--smetio-neutral-color-rgba-70);
    -webkit-box-shadow: 0px 0px 10px 5px var(--smetio-neutral-color-rgba-70);
}

.smetio-neutral-shadow-bottom-trans-70 {
    box-shadow: 0px 5px 10px 5px var(--smetio-neutral-color-rgba-70);
    -webkit-box-shadow: 0px 5px 10px 5px var(--smetio-neutral-color-rgba-70);
}

.smetio-neutral-shadow-around-trans-80 {
    box-shadow: 0px 0px 10px 5px var(--smetio-neutral-color-rgba-80);
    -webkit-box-shadow: 0px 0px 10px 5px var(--smetio-neutral-color-rgba-80);
}

.smetio-neutral-shadow-bottom-trans-80 {
    box-shadow: 0px 5px 10px 5px var(--smetio-neutral-color-rgba-80);
    -webkit-box-shadow: 0px 5px 10px 5px var(--smetio-neutral-color-rgba-80);
}

.smetio-neutral-shadow-around-trans-90 {
    box-shadow: 0px 0px 10px 5px var(--smetio-neutral-color-rgba-90);
    -webkit-box-shadow: 0px 0px 10px 5px var(--smetio-neutral-color-rgba-90);
}

.smetio-neutral-shadow-bottom-trans-90 {
    box-shadow: 0px 5px 10px 5px var(--smetio-neutral-color-rgba-90);
    -webkit-box-shadow: 0px 5px 10px 5px var(--smetio-neutral-color-rgba-90);
}

.smetio-grey-shadow-around-trans-10 {
    box-shadow: 0px 0px 10px 5px var(--smetio-grey-color-rgba-10);
    -webkit-box-shadow: 0px 0px 10px 5px var(--smetio-grey-color-rgba-10);
}

.smetio-grey-shadow-bottom-trans-10 {
    box-shadow: 0px 5px 10px 5px var(--smetio-grey-color-rgba-10);
    -webkit-box-shadow: 0px 5px 10px 5px var(--smetio-grey-color-rgba-10);
}

.smetio-grey-shadow-around-trans-20 {
    box-shadow: 0px 0px 10px 5px var(--smetio-grey-color-rgba-20);
    -webkit-box-shadow: 0px 0px 10px 5px var(--smetio-grey-color-rgba-20);
}

.smetio-grey-shadow-bottom-trans-20 {
    box-shadow: 0px 5px 10px 5px var(--smetio-grey-color-rgba-20);
    -webkit-box-shadow: 0px 5px 10px 5px var(--smetio-grey-color-rgba-20);
}

.smetio-grey-shadow-around-trans-30 {
    box-shadow: 0px 0px 10px 5px var(--smetio-grey-color-rgba-30);
    -webkit-box-shadow: 0px 0px 10px 5px var(--smetio-grey-color-rgba-30);
}

.smetio-grey-shadow-bottom-trans-30 {
    box-shadow: 0px 5px 10px 5px var(--smetio-grey-color-rgba-30);
    -webkit-box-shadow: 0px 5px 10px 5px var(--smetio-grey-color-rgba-30);
}

.smetio-grey-shadow-around-trans-40 {
    box-shadow: 0px 0px 10px 5px var(--smetio-grey-color-rgba-40);
    -webkit-box-shadow: 0px 0px 10px 5px var(--smetio-grey-color-rgba-40);
}

.smetio-grey-shadow-bottom-trans-40 {
    box-shadow: 0px 5px 10px 5px var(--smetio-grey-color-rgba-40);
    -webkit-box-shadow: 0px 5px 10px 5px var(--smetio-grey-color-rgba-40);
}

.smetio-grey-shadow-around-trans-50 {
    box-shadow: 0px 0px 10px 5px var(--smetio-grey-color-rgba-50);
    -webkit-box-shadow: 0px 0px 10px 5px var(--smetio-grey-color-rgba-50);
}

.smetio-grey-shadow-bottom-trans-50 {
    box-shadow: 0px 5px 10px 5px var(--smetio-grey-color-rgba-50);
    -webkit-box-shadow: 0px 5px 10px 5px var(--smetio-grey-color-rgba-50);
}

.smetio-grey-shadow-around-trans-60 {
    box-shadow: 0px 0px 10px 5px var(--smetio-grey-color-rgba-60);
    -webkit-box-shadow: 0px 0px 10px 5px var(--smetio-grey-color-rgba-60);
}

.smetio-grey-shadow-bottom-trans-60 {
    box-shadow: 0px 5px 10px 5px var(--smetio-grey-color-rgba-60);
    -webkit-box-shadow: 0px 5px 10px 5px var(--smetio-grey-color-rgba-60);
}

.smetio-grey-shadow-around-trans-70 {
    box-shadow: 0px 0px 10px 5px var(--smetio-grey-color-rgba-70);
    -webkit-box-shadow: 0px 0px 10px 5px var(--smetio-grey-color-rgba-70);
}

.smetio-grey-shadow-bottom-trans-70 {
    box-shadow: 0px 5px 10px 5px var(--smetio-grey-color-rgba-70);
    -webkit-box-shadow: 0px 5px 10px 5px var(--smetio-grey-color-rgba-70);
}

.smetio-grey-shadow-around-trans-80 {
    box-shadow: 0px 0px 10px 5px var(--smetio-grey-color-rgba-80);
    -webkit-box-shadow: 0px 0px 10px 5px var(--smetio-grey-color-rgba-80);
}

.smetio-grey-shadow-bottom-trans-80 {
    box-shadow: 0px 5px 10px 5px var(--smetio-grey-color-rgba-80);
    -webkit-box-shadow: 0px 5px 10px 5px var(--smetio-grey-color-rgba-80);
}

.smetio-grey-shadow-around-trans-90 {
    box-shadow: 0px 0px 10px 5px var(--smetio-grey-color-rgba-90);
    -webkit-box-shadow: 0px 0px 10px 5px var(--smetio-grey-color-rgba-90);
}

.smetio-grey-shadow-bottom-trans-90 {
    box-shadow: 0px 5px 10px 5px var(--smetio-grey-color-rgba-90);
    -webkit-box-shadow: 0px 5px 10px 5px var(--smetio-grey-color-rgba-90);
}

html[data-theme="light-20"] {

    /* accentColor */
    .smetio-accent-background {
        background-color: var(--smetio-accent-color-shade-20);
        fill: var(--smetio-accent-color-shade-20);
    }

    .smetio-accent-background-reverse {
        background-color: var(--smetio-accent-color-tint-20);
        fill: var(--smetio-accent-color-tint-20);
    }

    .smetio-accent-text {
        color: var(--smetio-accent-color-tint-20);
    }

    .smetio-accent-text-reverse {
        color: var(--smetio-accent-color-shade-20);
    }

    .smetio-accent-button {
        background-color: var(--smetio-accent-color-shade-20);
        fill: var(--smetio-accent-color-shade-20);
        color: var(--smetio-dominant-color-tint-20);
    }

    .smetio-accent-button:hover {
        background-color: var(--smetio-accent-color-tint-20);
        fill: var(--smetio-accent-color-tint-20);
        color: var(--smetio-dominant-color-shade-20);
    }

    .smetio-accent-button:active {
        background-color: var(--smetio-accent-color-shade-20);
        fill: var(--smetio-accent-color-shade-20);
        color: var(--smetio-dominant-color-tint-20);
    }

    .smetio-accent-button[data-smetio-status='active'] {
        background-color: var(--smetio-dominant-color-tint-20);
        fill: var(--smetio-dominant-color-tint-20);
        color: var(--smetio-accent-color-shade-20);
    }

    .smetio-accent-link {
        color: var(--smetio-accent-color-shade-20);
    }

    .smetio-accent-link-interactive {
        color: var(--smetio-accent-color-tint-20);
    }

    .smetio-accent-link-interactive:hover {
        color: var(--smetio-accent-color-shade-20);
    }

    .smetio-accent-link-interactive:active {
        color: var(--smetio-accent-color);
    }

    .smetio-accent-icon {
        color: var(--smetio-accent-color-shade-20);
    }

    .smetio-accent-icon-interactive {
        color: var(--smetio-accent-color-tint-20);
    }

    .smetio-accent-icon-interactive:hover {
        color: var(--smetio-accent-color-shade-20);
    }

    .smetio-accent-icon-interactive:active {
        color: var(--smetio-accent-color);
    }

    .smetio-accent-border {
        border: solid 1px var(--smetio-accent-color-shade-20);
    }

    .smetio-accent-border-interactive {
        border-color: var(--smetio-accent-color-tint-20);
        stroke: var(--smetio-accent-color-tint-20);
    }

    .smetio-accent-border-interactive:hover {
        border-color: solid 1px var(--smetio-accent-color-shade-20);
        stroke: solid 1px var(--smetio-accent-color-shade-20);
    }

    .smetio-accent-border-interactive:active {
        border-color: solid 1px var(--smetio-accent-color);
        stroke: solid 1px var(--smetio-accent-color);
    }

    .smetio-accent-border-interactive[data-smetio-status='active'] {
        border-color: solid 1px var(--smetio-accent-color);
        stroke: solid 1px var(--smetio-accent-color);
    }

    /* dominantColor */
    .smetio-dominant-background {
        background-color: var(--smetio-dominant-color-tint-20);
        fill: var(--smetio-dominant-color-tint-20);
    }

    .smetio-dominant-background-reverse {
        background-color: var(--smetio-dominant-color-shade-20);
        fill: var(--smetio-dominant-color-shade-20);
    }

    .smetio-dominant-text {
        color: var(--smetio-dominant-color-shade-20);
    }

    .smetio-dominant-text-reverse {
        color: var(--smetio-dominant-color-tint-20);
    }

    .smetio-dominant-border {
        border: solid 1px var(--smetio-dominant-color);
    }

    .smetio-dominant-border-interactive {
        border-color: var(--smetio-dominant-color);
        stroke: var(--smetio-dominant-color);
    }

    .smetio-dominant-border-interactive:hover {
        border-color: solid 1px var(--smetio-dominant-color-shade-20);
        stroke: solid 1px var(--smetio-dominant-color-shade-20);
    }

    .smetio-dominant-border-interactive:active {
        border-color: solid 1px var(--smetio-dominant-color);
        stroke: solid 1px var(--smetio-dominant-color);
    }

    .smetio-dominant-border-interactive[data-smetio-status='active'] {
        border-color: solid 1px var(--smetio-dominant-color);
        stroke: solid 1px var(--smetio-dominant-color);
    }

    /* neutralColor */
    .smetio-neutral-background {
        background-color: var(--smetio-neutral-color-tint-20);
        fill: var(--smetio-neutral-color-tint-20);
    }

    .smetio-neutral-background-reverse {
        background-color: var(--smetio-neutral-color-shade-20);
        fill: var(--smetio-neutral-color-shade-20);
    }

    .smetio-neutral-text {
        color: var(--smetio-neutral-color-shade-100);
    }

    .smetio-neutral-text-reverse {
        color: var(--smetio-neutral-color-tint-100);
    }

    .smetio-neutral-border {
        border: solid 1px var(--smetio-neutral-color);
        border-color: var(--smetio-neutral-color-shade-100);
        stroke: var(--smetio-neutral-color-shade-100);
    }

    /* greyColor */
    .smetio-grey-background {
        background-color: var(--smetio-grey-color);
        fill: var(--smetio-grey-color);
    }

    .smetio-grey-background-reverse {
        background-color: var(--smetio-grey-color-tint-100);
        fill: var(--smetio-grey-color-tint-100);
    }

    .smetio-grey-text {
        color: var(--smetio-grey-color-shade-100);
    }

    .smetio-grey-text-reverse {
        color: var(--smetio-grey-color-tint-100);
    }

    .smetio-grey-border {
        border: solid 1px var(--smetio-grey-color);
        border-color: var(--smetio-grey-color-shade-100);
        stroke: var(--smetio-grey-color-shade-100);
    }

    /* warningColor */
    .smetio-warning-background {
        background-color: var(--smetio-warning-color-tint-20);
        fill: var(--smetio-warning-color-tint-20);
    }

    .smetio-warning-background-reverse {
        background-color: var(--smetio-warning-color-shade-20);
        fill: var(--smetio-warning-color-shade-20);
    }

    .smetio-warning-text {
        color: var(--smetio-warning-color-shade-20);
    }

    .smetio-warning-text-reverse {
        color: var(--smetio-warning-color-tint-20);
    }

    .smetio-warning-icon {
        color: var(--smetio-warning-color-shade-20);
    }

    .smetio-warning-icon-interactive {
        color: var(--smetio-warning-color-shade-20);
    }

    .smetio-warning-icon-interactive:hover {
        color: var(--smetio-warning-color-tint-20);
    }

    .smetio-warning-icon-interactive:active {
        color: var(--smetio-warning-color);
    }

    .smetio-warning-border {
        border: solid 1px var(--smetio-warning-color-shade-20);
    }

    /* successColor */
    .smetio-success-background {
        background-color: var(--smetio-success-color-tint-20);
        fill: var(--smetio-success-color-tint-20);
    }

    .smetio-success-background-reverse {
        background-color: var(--smetio-success-color-shade-20);
        fill: var(--smetio-success-color-shade-20);
    }

    .smetio-success-text {
        color: var(--smetio-success-color-shade-20);
    }

    .smetio-success-text-reverse {
        color: var(--smetio-success-color-tint-20);
    }

    .smetio-success-icon {
        color: var(--smetio-success-color-shade-20);
    }

    .smetio-success-icon-interactive {
        color: var(--smetio-success-color-shade-20);
    }

    .smetio-success-icon-interactive:hover {
        color: var(--smetio-success-color-tint-20);
    }

    .smetio-success-icon-interactive:active {
        color: var(--smetio-success-color);
    }

    .smetio-success-border {
        border: solid 1px var(--smetio-success-color-shade-20);
    }

    /* informationColor */
    .smetio-information-background {
        background-color: var(--smetio-information-color-tint-20);
        fill: var(--smetio-information-color-tint-20);
    }

    .smetio-information-background-reverse {
        background-color: var(--smetio-information-color-shade-20);
        fill: var(--smetio-information-color-shade-20);
    }

    .smetio-information-text {
        color: var(--smetio-information-color-shade-20);
    }

    .smetio-information-text-reverse {
        color: var(--smetio-information-color-tint-20);
    }

    .smetio-information-icon {
        color: var(--smetio-information-color-shade-20);
    }

    .smetio-information-icon-interactive {
        color: var(--smetio-information-color-shade-20);
    }

    .smetio-information-icon-interactive:hover {
        color: var(--smetio-information-color-tint-20);
    }

    .smetio-information-icon-interactive:active {
        color: var(--smetio-information-color);
    }

    .smetio-information-border {
        border: solid 1px var(--smetio-information-color-shade-20);
    }

    /* dangerColor */
    .smetio-danger-background {
        background-color: var(--smetio-danger-color-tint-20);
        fill: var(--smetio-danger-color-tint-20);
    }

    .smetio-danger-background-reverse {
        background-color: var(--smetio-danger-color-shade-20);
        fill: var(--smetio-danger-color-shade-20);
    }

    .smetio-danger-text {
        color: var(--smetio-danger-color-shade-20);
    }

    .smetio-danger-text-reverse {
        color: var(--smetio-danger-color-tint-20);
    }

    .smetio-danger-icon {
        color: var(--smetio-danger-color-shade-20);
    }

    .smetio-danger-icon-interactive {
        color: var(--smetio-danger-color-shade-20);
    }

    .smetio-danger-icon-interactive:hover {
        color: var(--smetio-danger-color-tint-20);
    }

    .smetio-danger-icon-interactive:active {
        color: var(--smetio-danger-color);
    }

    .smetio-danger-border {
        border: solid 1px var(--smetio-danger-color-shade-20);
    }
}

html[data-theme="dark-20"] {

    /* accentColor */
    .smetio-accent-background {
        background-color: var(--smetio-accent-color-tint-20);
        fill: var(--smetio-accent-color-tint-20);
    }

    .smetio-accent-background-reverse {
        background-color: var(--smetio-accent-color-shade-20);
        fill: var(--smetio-accent-color-shade-20);
    }

    .smetio-accent-text {
        color: var(--smetio-accent-color-shade-20);
    }

    .smetio-accent-text-reverse {
        color: var(--smetio-accent-color-tint-20);
    }

    .smetio-accent-button {
        background-color: var(--smetio-accent-color-tint-20);
        fill: var(--smetio-accent-color-tint-20);
        color: var(--smetio-dominant-color-shade-20);
    }

    .smetio-accent-button:hover {
        background-color: var(--smetio-accent-color-shade-20);
        fill: var(--smetio-accent-color-shade-20);
        color: var(--smetio-dominant-color-tint-20);
    }

    .smetio-accent-button:active {
        background-color: var(--smetio-accent-color-tint-20);
        fill: var(--smetio-accent-color-tint-20);
        color: var(--smetio-dominant-color-shade-20);
    }

    .smetio-accent-button[data-smetio-status='active'] {
        background-color: var(--smetio-dominant-color-shade-20);
        fill: var(--smetio-dominant-color-shade-20);
        color: var(--smetio-accent-color-tint-20);
    }

    .smetio-accent-link {
        color: var(--smetio-accent-color-tint-20);
    }

    .smetio-accent-link-interactive {
        color: var(--smetio-accent-color-shade-20);
    }

    .smetio-accent-link-interactive:hover {
        color: var(--smetio-accent-color-tint-20);
    }

    .smetio-accent-link-interactive:active {
        color: var(--smetio-accent-color);
    }

    .smetio-accent-icon {
        color: var(--smetio-accent-color-tint-20);
    }

    .smetio-accent-icon-interactive {
        color: var(--smetio-accent-color-shade-20);
    }

    .smetio-accent-icon-interactive:hover {
        color: var(--smetio-accent-color-tint-20);
    }

    .smetio-accent-icon-interactive:active {
        color: var(--smetio-accent-color);
    }

    .smetio-accent-border {
        border: solid 1px var(--smetio-accent-color-tint-20);
    }

    .smetio-accent-border-interactive {
        border-color: var(--smetio-accent-color-shade-20);
        stroke: var(--smetio-accent-color-shade-20);
    }

    .smetio-accent-border-interactive:hover {
        border-color: solid 1px var(--smetio-accent-color-tint-20);
        stroke: solid 1px var(--smetio-accent-color-tint-20);
    }

    .smetio-accent-border-interactive:active {
        border-color: solid 1px var(--smetio-accent-color);
        stroke: solid 1px var(--smetio-accent-color);
    }

    .smetio-accent-border-interactive[data-smetio-status='active'] {
        border-color: solid 1px var(--smetio-accent-color);
        stroke: solid 1px var(--smetio-accent-color);
    }

    /* dominantColor */
    .smetio-dominant-background {
        background-color: var(--smetio-dominant-color-shade-20);
        fill: var(--smetio-dominant-color-shade-20);
    }

    .smetio-dominant-background-reverse {
        background-color: var(--smetio-dominant-color-tint-20);
        fill: var(--smetio-dominant-color-tint-20);
    }

    .smetio-dominant-text {
        color: var(--smetio-dominant-color-tint-20);
    }

    .smetio-dominant-text-reverse {
        color: var(--smetio-dominant-color-shade-20);
    }

    .smetio-dominant-border {
        border: solid 1px var(--smetio-dominant-color);
    }

    .smetio-dominant-border-interactive {
        border-color: var(--smetio-dominant-color);
        stroke: var(--smetio-dominant-color);
    }

    .smetio-dominant-border-interactive:hover {
        border-color: solid 1px var(--smetio-dominant-color-tint-20);
        stroke: solid 1px var(--smetio-dominant-color-tint-20);
    }

    .smetio-dominant-border-interactive:active {
        border-color: solid 1px var(--smetio-dominant-color);
        stroke: solid 1px var(--smetio-dominant-color);
    }

    .smetio-dominant-border-interactive[data-smetio-status='active'] {
        border-color: solid 1px var(--smetio-dominant-color);
        stroke: solid 1px var(--smetio-dominant-color);
    }

    /* neutralColor */
    .smetio-neutral-background {
        background-color: var(--smetio-neutral-color-shade-20);
        fill: var(--smetio-neutral-color-shade-20);
    }

    .smetio-neutral-background-reverse {
        background-color: var(--smetio-neutral-color-tint-20);
        fill: var(--smetio-neutral-color-tint-20);
    }

    .smetio-neutral-text {
        color: var(--smetio-neutral-color-tint-100);
    }

    .smetio-neutral-text-reverse {
        color: var(--smetio-neutral-color-shade-100);
    }

    .smetio-neutral-border {
        border: solid 1px var(--smetio-neutral-color);
        border-color: var(--smetio-neutral-color-tint-100);
        stroke: var(--smetio-neutral-color-tint-100);
    }

    /* greyColor */
    .smetio-grey-background {
        background-color: var(--smetio-grey-color);
        fill: var(--smetio-grey-color);
    }

    .smetio-grey-background-reverse {
        background-color: var(--smetio-grey-color-shade-100);
        fill: var(--smetio-grey-color-shade-100);
    }

    .smetio-grey-text {
        color: var(--smetio-grey-color-tint-100);
    }

    .smetio-grey-text-reverse {
        color: var(--smetio-grey-color-shade-100);
    }

    .smetio-grey-border {
        border: solid 1px var(--smetio-grey-color);
        border-color: var(--smetio-grey-color-tint-100);
        stroke: var(--smetio-grey-color-tint-100);
    }

    /* warningColor */
    .smetio-warning-background {
        background-color: var(--smetio-warning-color-shade-20);
        fill: var(--smetio-warning-color-shade-20);
    }

    .smetio-warning-background-reverse {
        background-color: var(--smetio-warning-color-tint-20);
        fill: var(--smetio-warning-color-tint-20);
    }

    .smetio-warning-text {
        color: var(--smetio-warning-color-tint-20);
    }

    .smetio-warning-text-reverse {
        color: var(--smetio-warning-color-shade-20);
    }

    .smetio-warning-icon {
        color: var(--smetio-warning-color-tint-20);
    }

    .smetio-warning-icon-interactive {
        color: var(--smetio-warning-color-tint-20);
    }

    .smetio-warning-icon-interactive:hover {
        color: var(--smetio-warning-color-shade-20);
    }

    .smetio-warning-icon-interactive:active {
        color: var(--smetio-warning-color);
    }

    .smetio-warning-border {
        border: solid 1px var(--smetio-warning-color-tint-20);
    }

    /* successColor */
    .smetio-success-background {
        background-color: var(--smetio-success-color-shade-20);
        fill: var(--smetio-success-color-shade-20);
    }

    .smetio-success-background-reverse {
        background-color: var(--smetio-success-color-tint-20);
        fill: var(--smetio-success-color-tint-20);
    }

    .smetio-success-text {
        color: var(--smetio-success-color-tint-20);
    }

    .smetio-success-text-reverse {
        color: var(--smetio-success-color-shade-20);
    }

    .smetio-success-icon {
        color: var(--smetio-success-color-tint-20);
    }

    .smetio-success-icon-interactive {
        color: var(--smetio-success-color-tint-20);
    }

    .smetio-success-icon-interactive:hover {
        color: var(--smetio-success-color-shade-20);
    }

    .smetio-success-icon-interactive:active {
        color: var(--smetio-success-color);
    }

    .smetio-success-border {
        border: solid 1px var(--smetio-success-color-tint-20);
    }

    /* informationColor */
    .smetio-information-background {
        background-color: var(--smetio-information-color-shade-20);
        fill: var(--smetio-information-color-shade-20);
    }

    .smetio-information-background-reverse {
        background-color: var(--smetio-information-color-tint-20);
        fill: var(--smetio-information-color-tint-20);
    }

    .smetio-information-text {
        color: var(--smetio-information-color-tint-20);
    }

    .smetio-information-text-reverse {
        color: var(--smetio-information-color-shade-20);
    }

    .smetio-information-icon {
        color: var(--smetio-information-color-tint-20);
    }

    .smetio-information-icon-interactive {
        color: var(--smetio-information-color-tint-20);
    }

    .smetio-information-icon-interactive:hover {
        color: var(--smetio-information-color-shade-20);
    }

    .smetio-information-icon-interactive:active {
        color: var(--smetio-information-color);
    }

    .smetio-information-border {
        border: solid 1px var(--smetio-information-color-tint-20);
    }

    /* dangerColor */
    .smetio-danger-background {
        background-color: var(--smetio-danger-color-shade-20);
        fill: var(--smetio-danger-color-shade-20);
    }

    .smetio-danger-background-reverse {
        background-color: var(--smetio-danger-color-tint-20);
        fill: var(--smetio-danger-color-tint-20);
    }

    .smetio-danger-text {
        color: var(--smetio-danger-color-tint-20);
    }

    .smetio-danger-text-reverse {
        color: var(--smetio-danger-color-shade-20);
    }

    .smetio-danger-icon {
        color: var(--smetio-danger-color-tint-20);
    }

    .smetio-danger-icon-interactive {
        color: var(--smetio-danger-color-tint-20);
    }

    .smetio-danger-icon-interactive:hover {
        color: var(--smetio-danger-color-shade-20);
    }

    .smetio-danger-icon-interactive:active {
        color: var(--smetio-danger-color);
    }

    .smetio-danger-border {
        border: solid 1px var(--smetio-danger-color-tint-20);
    }
}

html[data-theme="light-40"] {

    /* accentColor */
    .smetio-accent-background {
        background-color: var(--smetio-accent-color-shade-40);
        fill: var(--smetio-accent-color-shade-40);
    }

    .smetio-accent-background-reverse {
        background-color: var(--smetio-accent-color-tint-40);
        fill: var(--smetio-accent-color-tint-40);
    }

    .smetio-accent-text {
        color: var(--smetio-accent-color-tint-40);
    }

    .smetio-accent-text-reverse {
        color: var(--smetio-accent-color-shade-40);
    }

    .smetio-accent-button {
        background-color: var(--smetio-accent-color-shade-40);
        fill: var(--smetio-accent-color-shade-40);
        color: var(--smetio-dominant-color-tint-40);
    }

    .smetio-accent-button:hover {
        background-color: var(--smetio-accent-color-tint-40);
        fill: var(--smetio-accent-color-tint-40);
        color: var(--smetio-dominant-color-shade-40);
    }

    .smetio-accent-button:active {
        background-color: var(--smetio-accent-color-shade-40);
        fill: var(--smetio-accent-color-shade-40);
        color: var(--smetio-dominant-color-tint-40);
    }

    .smetio-accent-button[data-smetio-status='active'] {
        background-color: var(--smetio-dominant-color-tint-40);
        fill: var(--smetio-dominant-color-tint-40);
        color: var(--smetio-accent-color-shade-40);
    }

    .smetio-accent-link {
        color: var(--smetio-accent-color-shade-40);
    }

    .smetio-accent-link-interactive {
        color: var(--smetio-accent-color-tint-40);
    }

    .smetio-accent-link-interactive:hover {
        color: var(--smetio-accent-color-shade-40);
    }

    .smetio-accent-link-interactive:active {
        color: var(--smetio-accent-color);
    }

    .smetio-accent-icon {
        color: var(--smetio-accent-color-shade-40);
    }

    .smetio-accent-icon-interactive {
        color: var(--smetio-accent-color-tint-40);
    }

    .smetio-accent-icon-interactive:hover {
        color: var(--smetio-accent-color-shade-40);
    }

    .smetio-accent-icon-interactive:active {
        color: var(--smetio-accent-color);
    }

    .smetio-accent-border {
        border: solid 1px var(--smetio-accent-color-shade-40);
    }

    .smetio-accent-border-interactive {
        border-color: var(--smetio-accent-color-tint-40);
        stroke: var(--smetio-accent-color-tint-40);
    }

    .smetio-accent-border-interactive:hover {
        border-color: solid 1px var(--smetio-accent-color-shade-40);
        stroke: solid 1px var(--smetio-accent-color-shade-40);
    }

    .smetio-accent-border-interactive:active {
        border-color: solid 1px var(--smetio-accent-color);
        stroke: solid 1px var(--smetio-accent-color);
    }

    .smetio-accent-border-interactive[data-smetio-status='active'] {
        border-color: solid 1px var(--smetio-accent-color);
        stroke: solid 1px var(--smetio-accent-color);
    }

    /* dominantColor */
    .smetio-dominant-background {
        background-color: var(--smetio-dominant-color-tint-40);
        fill: var(--smetio-dominant-color-tint-40);
    }

    .smetio-dominant-background-reverse {
        background-color: var(--smetio-dominant-color-shade-40);
        fill: var(--smetio-dominant-color-shade-40);
    }

    .smetio-dominant-text {
        color: var(--smetio-dominant-color-shade-40);
    }

    .smetio-dominant-text-reverse {
        color: var(--smetio-dominant-color-tint-40);
    }

    .smetio-dominant-border {
        border: solid 1px var(--smetio-dominant-color);
    }

    .smetio-dominant-border-interactive {
        border-color: var(--smetio-dominant-color);
        stroke: var(--smetio-dominant-color);
    }

    .smetio-dominant-border-interactive:hover {
        border-color: solid 1px var(--smetio-dominant-color-shade-40);
        stroke: solid 1px var(--smetio-dominant-color-shade-40);
    }

    .smetio-dominant-border-interactive:active {
        border-color: solid 1px var(--smetio-dominant-color);
        stroke: solid 1px var(--smetio-dominant-color);
    }

    .smetio-dominant-border-interactive[data-smetio-status='active'] {
        border-color: solid 1px var(--smetio-dominant-color);
        stroke: solid 1px var(--smetio-dominant-color);
    }

    /* neutralColor */
    .smetio-neutral-background {
        background-color: var(--smetio-neutral-color-tint-40);
        fill: var(--smetio-neutral-color-tint-40);
    }

    .smetio-neutral-background-reverse {
        background-color: var(--smetio-neutral-color-shade-40);
        fill: var(--smetio-neutral-color-shade-40);
    }

    .smetio-neutral-text {
        color: var(--smetio-neutral-color-shade-100);
    }

    .smetio-neutral-text-reverse {
        color: var(--smetio-neutral-color-tint-100);
    }

    .smetio-neutral-border {
        border: solid 1px var(--smetio-neutral-color);
        border-color: var(--smetio-neutral-color-shade-100);
        stroke: var(--smetio-neutral-color-shade-100);
    }

    /* greyColor */
    .smetio-grey-background {
        background-color: var(--smetio-grey-color);
        fill: var(--smetio-grey-color);
    }

    .smetio-grey-background-reverse {
        background-color: var(--smetio-grey-color-tint-100);
        fill: var(--smetio-grey-color-tint-100);
    }

    .smetio-grey-text {
        color: var(--smetio-grey-color-shade-100);
    }

    .smetio-grey-text-reverse {
        color: var(--smetio-grey-color-tint-100);
    }

    .smetio-grey-border {
        border: solid 1px var(--smetio-grey-color);
        border-color: var(--smetio-grey-color-shade-100);
        stroke: var(--smetio-grey-color-shade-100);
    }

    /* warningColor */
    .smetio-warning-background {
        background-color: var(--smetio-warning-color-tint-40);
        fill: var(--smetio-warning-color-tint-40);
    }

    .smetio-warning-background-reverse {
        background-color: var(--smetio-warning-color-shade-40);
        fill: var(--smetio-warning-color-shade-40);
    }

    .smetio-warning-text {
        color: var(--smetio-warning-color-shade-40);
    }

    .smetio-warning-text-reverse {
        color: var(--smetio-warning-color-tint-40);
    }

    .smetio-warning-icon {
        color: var(--smetio-warning-color-shade-40);
    }

    .smetio-warning-icon-interactive {
        color: var(--smetio-warning-color-shade-40);
    }

    .smetio-warning-icon-interactive:hover {
        color: var(--smetio-warning-color-tint-40);
    }

    .smetio-warning-icon-interactive:active {
        color: var(--smetio-warning-color);
    }

    .smetio-warning-border {
        border: solid 1px var(--smetio-warning-color-shade-40);
    }

    /* successColor */
    .smetio-success-background {
        background-color: var(--smetio-success-color-tint-40);
        fill: var(--smetio-success-color-tint-40);
    }

    .smetio-success-background-reverse {
        background-color: var(--smetio-success-color-shade-40);
        fill: var(--smetio-success-color-shade-40);
    }

    .smetio-success-text {
        color: var(--smetio-success-color-shade-40);
    }

    .smetio-success-text-reverse {
        color: var(--smetio-success-color-tint-40);
    }

    .smetio-success-icon {
        color: var(--smetio-success-color-shade-40);
    }

    .smetio-success-icon-interactive {
        color: var(--smetio-success-color-shade-40);
    }

    .smetio-success-icon-interactive:hover {
        color: var(--smetio-success-color-tint-40);
    }

    .smetio-success-icon-interactive:active {
        color: var(--smetio-success-color);
    }

    .smetio-success-border {
        border: solid 1px var(--smetio-success-color-shade-40);
    }

    /* informationColor */
    .smetio-information-background {
        background-color: var(--smetio-information-color-tint-40);
        fill: var(--smetio-information-color-tint-40);
    }

    .smetio-information-background-reverse {
        background-color: var(--smetio-information-color-shade-40);
        fill: var(--smetio-information-color-shade-40);
    }

    .smetio-information-text {
        color: var(--smetio-information-color-shade-40);
    }

    .smetio-information-text-reverse {
        color: var(--smetio-information-color-tint-40);
    }

    .smetio-information-icon {
        color: var(--smetio-information-color-shade-40);
    }

    .smetio-information-icon-interactive {
        color: var(--smetio-information-color-shade-40);
    }

    .smetio-information-icon-interactive:hover {
        color: var(--smetio-information-color-tint-40);
    }

    .smetio-information-icon-interactive:active {
        color: var(--smetio-information-color);
    }

    .smetio-information-border {
        border: solid 1px var(--smetio-information-color-shade-40);
    }

    /* dangerColor */
    .smetio-danger-background {
        background-color: var(--smetio-danger-color-tint-40);
        fill: var(--smetio-danger-color-tint-40);
    }

    .smetio-danger-background-reverse {
        background-color: var(--smetio-danger-color-shade-40);
        fill: var(--smetio-danger-color-shade-40);
    }

    .smetio-danger-text {
        color: var(--smetio-danger-color-shade-40);
    }

    .smetio-danger-text-reverse {
        color: var(--smetio-danger-color-tint-40);
    }

    .smetio-danger-icon {
        color: var(--smetio-danger-color-shade-40);
    }

    .smetio-danger-icon-interactive {
        color: var(--smetio-danger-color-shade-40);
    }

    .smetio-danger-icon-interactive:hover {
        color: var(--smetio-danger-color-tint-40);
    }

    .smetio-danger-icon-interactive:active {
        color: var(--smetio-danger-color);
    }

    .smetio-danger-border {
        border: solid 1px var(--smetio-danger-color-shade-40);
    }
}

html[data-theme="dark-40"] {

    /* accentColor */
    .smetio-accent-background {
        background-color: var(--smetio-accent-color-tint-40);
        fill: var(--smetio-accent-color-tint-40);
    }

    .smetio-accent-background-reverse {
        background-color: var(--smetio-accent-color-shade-40);
        fill: var(--smetio-accent-color-shade-40);
    }

    .smetio-accent-text {
        color: var(--smetio-accent-color-shade-40);
    }

    .smetio-accent-text-reverse {
        color: var(--smetio-accent-color-tint-40);
    }

    .smetio-accent-button {
        background-color: var(--smetio-accent-color-tint-40);
        fill: var(--smetio-accent-color-tint-40);
        color: var(--smetio-dominant-color-shade-40);
    }

    .smetio-accent-button:hover {
        background-color: var(--smetio-accent-color-shade-40);
        fill: var(--smetio-accent-color-shade-40);
        color: var(--smetio-dominant-color-tint-40);
    }

    .smetio-accent-button:active {
        background-color: var(--smetio-accent-color-tint-40);
        fill: var(--smetio-accent-color-tint-40);
        color: var(--smetio-dominant-color-shade-40);
    }

    .smetio-accent-button[data-smetio-status='active'] {
        background-color: var(--smetio-dominant-color-shade-40);
        fill: var(--smetio-dominant-color-shade-40);
        color: var(--smetio-accent-color-tint-40);
    }

    .smetio-accent-link {
        color: var(--smetio-accent-color-tint-40);
    }

    .smetio-accent-link-interactive {
        color: var(--smetio-accent-color-shade-40);
    }

    .smetio-accent-link-interactive:hover {
        color: var(--smetio-accent-color-tint-40);
    }

    .smetio-accent-link-interactive:active {
        color: var(--smetio-accent-color);
    }

    .smetio-accent-icon {
        color: var(--smetio-accent-color-tint-40);
    }

    .smetio-accent-icon-interactive {
        color: var(--smetio-accent-color-shade-40);
    }

    .smetio-accent-icon-interactive:hover {
        color: var(--smetio-accent-color-tint-40);
    }

    .smetio-accent-icon-interactive:active {
        color: var(--smetio-accent-color);
    }

    .smetio-accent-border {
        border: solid 1px var(--smetio-accent-color-tint-40);
    }

    .smetio-accent-border-interactive {
        border-color: var(--smetio-accent-color-shade-40);
        stroke: var(--smetio-accent-color-shade-40);
    }

    .smetio-accent-border-interactive:hover {
        border-color: solid 1px var(--smetio-accent-color-tint-40);
        stroke: solid 1px var(--smetio-accent-color-tint-40);
    }

    .smetio-accent-border-interactive:active {
        border-color: solid 1px var(--smetio-accent-color);
        stroke: solid 1px var(--smetio-accent-color);
    }

    .smetio-accent-border-interactive[data-smetio-status='active'] {
        border-color: solid 1px var(--smetio-accent-color);
        stroke: solid 1px var(--smetio-accent-color);
    }

    /* dominantColor */
    .smetio-dominant-background {
        background-color: var(--smetio-dominant-color-shade-40);
        fill: var(--smetio-dominant-color-shade-40);
    }

    .smetio-dominant-background-reverse {
        background-color: var(--smetio-dominant-color-tint-40);
        fill: var(--smetio-dominant-color-tint-40);
    }

    .smetio-dominant-text {
        color: var(--smetio-dominant-color-tint-40);
    }

    .smetio-dominant-text-reverse {
        color: var(--smetio-dominant-color-shade-40);
    }

    .smetio-dominant-border {
        border: solid 1px var(--smetio-dominant-color);
    }

    .smetio-dominant-border-interactive {
        border-color: var(--smetio-dominant-color);
        stroke: var(--smetio-dominant-color);
    }

    .smetio-dominant-border-interactive:hover {
        border-color: solid 1px var(--smetio-dominant-color-tint-40);
        stroke: solid 1px var(--smetio-dominant-color-tint-40);
    }

    .smetio-dominant-border-interactive:active {
        border-color: solid 1px var(--smetio-dominant-color);
        stroke: solid 1px var(--smetio-dominant-color);
    }

    .smetio-dominant-border-interactive[data-smetio-status='active'] {
        border-color: solid 1px var(--smetio-dominant-color);
        stroke: solid 1px var(--smetio-dominant-color);
    }

    /* neutralColor */
    .smetio-neutral-background {
        background-color: var(--smetio-neutral-color-shade-40);
        fill: var(--smetio-neutral-color-shade-40);
    }

    .smetio-neutral-background-reverse {
        background-color: var(--smetio-neutral-color-tint-40);
        fill: var(--smetio-neutral-color-tint-40);
    }

    .smetio-neutral-text {
        color: var(--smetio-neutral-color-tint-100);
    }

    .smetio-neutral-text-reverse {
        color: var(--smetio-neutral-color-shade-100);
    }

    .smetio-neutral-border {
        border: solid 1px var(--smetio-neutral-color);
        border-color: var(--smetio-neutral-color-tint-100);
        stroke: var(--smetio-neutral-color-tint-100);
    }

    /* greyColor */
    .smetio-grey-background {
        background-color: var(--smetio-grey-color);
        fill: var(--smetio-grey-color);
    }

    .smetio-grey-background-reverse {
        background-color: var(--smetio-grey-color-shade-100);
        fill: var(--smetio-grey-color-shade-100);
    }

    .smetio-grey-text {
        color: var(--smetio-grey-color-tint-100);
    }

    .smetio-grey-text-reverse {
        color: var(--smetio-grey-color-shade-100);
    }

    .smetio-grey-border {
        border: solid 1px var(--smetio-grey-color);
        border-color: var(--smetio-grey-color-tint-100);
        stroke: var(--smetio-grey-color-tint-100);
    }

    /* warningColor */
    .smetio-warning-background {
        background-color: var(--smetio-warning-color-shade-40);
        fill: var(--smetio-warning-color-shade-40);
    }

    .smetio-warning-background-reverse {
        background-color: var(--smetio-warning-color-tint-40);
        fill: var(--smetio-warning-color-tint-40);
    }

    .smetio-warning-text {
        color: var(--smetio-warning-color-tint-40);
    }

    .smetio-warning-text-reverse {
        color: var(--smetio-warning-color-shade-40);
    }

    .smetio-warning-icon {
        color: var(--smetio-warning-color-tint-40);
    }

    .smetio-warning-icon-interactive {
        color: var(--smetio-warning-color-tint-40);
    }

    .smetio-warning-icon-interactive:hover {
        color: var(--smetio-warning-color-shade-40);
    }

    .smetio-warning-icon-interactive:active {
        color: var(--smetio-warning-color);
    }

    .smetio-warning-border {
        border: solid 1px var(--smetio-warning-color-tint-40);
    }

    /* successColor */
    .smetio-success-background {
        background-color: var(--smetio-success-color-shade-40);
        fill: var(--smetio-success-color-shade-40);
    }

    .smetio-success-background-reverse {
        background-color: var(--smetio-success-color-tint-40);
        fill: var(--smetio-success-color-tint-40);
    }

    .smetio-success-text {
        color: var(--smetio-success-color-tint-40);
    }

    .smetio-success-text-reverse {
        color: var(--smetio-success-color-shade-40);
    }

    .smetio-success-icon {
        color: var(--smetio-success-color-tint-40);
    }

    .smetio-success-icon-interactive {
        color: var(--smetio-success-color-tint-40);
    }

    .smetio-success-icon-interactive:hover {
        color: var(--smetio-success-color-shade-40);
    }

    .smetio-success-icon-interactive:active {
        color: var(--smetio-success-color);
    }

    .smetio-success-border {
        border: solid 1px var(--smetio-success-color-tint-40);
    }

    /* informationColor */
    .smetio-information-background {
        background-color: var(--smetio-information-color-shade-40);
        fill: var(--smetio-information-color-shade-40);
    }

    .smetio-information-background-reverse {
        background-color: var(--smetio-information-color-tint-40);
        fill: var(--smetio-information-color-tint-40);
    }

    .smetio-information-text {
        color: var(--smetio-information-color-tint-40);
    }

    .smetio-information-text-reverse {
        color: var(--smetio-information-color-shade-40);
    }

    .smetio-information-icon {
        color: var(--smetio-information-color-tint-40);
    }

    .smetio-information-icon-interactive {
        color: var(--smetio-information-color-tint-40);
    }

    .smetio-information-icon-interactive:hover {
        color: var(--smetio-information-color-shade-40);
    }

    .smetio-information-icon-interactive:active {
        color: var(--smetio-information-color);
    }

    .smetio-information-border {
        border: solid 1px var(--smetio-information-color-tint-40);
    }

    /* dangerColor */
    .smetio-danger-background {
        background-color: var(--smetio-danger-color-shade-40);
        fill: var(--smetio-danger-color-shade-40);
    }

    .smetio-danger-background-reverse {
        background-color: var(--smetio-danger-color-tint-40);
        fill: var(--smetio-danger-color-tint-40);
    }

    .smetio-danger-text {
        color: var(--smetio-danger-color-tint-40);
    }

    .smetio-danger-text-reverse {
        color: var(--smetio-danger-color-shade-40);
    }

    .smetio-danger-icon {
        color: var(--smetio-danger-color-tint-40);
    }

    .smetio-danger-icon-interactive {
        color: var(--smetio-danger-color-tint-40);
    }

    .smetio-danger-icon-interactive:hover {
        color: var(--smetio-danger-color-shade-40);
    }

    .smetio-danger-icon-interactive:active {
        color: var(--smetio-danger-color);
    }

    .smetio-danger-border {
        border: solid 1px var(--smetio-danger-color-tint-40);
    }
}

html[data-theme="light-60"] {

    /* accentColor */
    .smetio-accent-background {
        background-color: var(--smetio-accent-color-shade-60);
        fill: var(--smetio-accent-color-shade-60);
    }

    .smetio-accent-background-reverse {
        background-color: var(--smetio-accent-color-tint-60);
        fill: var(--smetio-accent-color-tint-60);
    }

    .smetio-accent-text {
        color: var(--smetio-accent-color-tint-60);
    }

    .smetio-accent-text-reverse {
        color: var(--smetio-accent-color-shade-60);
    }

    .smetio-accent-button {
        background-color: var(--smetio-accent-color-shade-60);
        fill: var(--smetio-accent-color-shade-60);
        color: var(--smetio-dominant-color-tint-60);
    }

    .smetio-accent-button:hover {
        background-color: var(--smetio-accent-color-tint-60);
        fill: var(--smetio-accent-color-tint-60);
        color: var(--smetio-dominant-color-shade-60);
    }

    .smetio-accent-button:active {
        background-color: var(--smetio-accent-color-shade-60);
        fill: var(--smetio-accent-color-shade-60);
        color: var(--smetio-dominant-color-tint-60);
    }

    .smetio-accent-button[data-smetio-status='active'] {
        background-color: var(--smetio-dominant-color-tint-60);
        fill: var(--smetio-dominant-color-tint-60);
        color: var(--smetio-accent-color-shade-60);
    }

    .smetio-accent-link {
        color: var(--smetio-accent-color-shade-60);
    }

    .smetio-accent-link-interactive {
        color: var(--smetio-accent-color-tint-60);
    }

    .smetio-accent-link-interactive:hover {
        color: var(--smetio-accent-color-shade-60);
    }

    .smetio-accent-link-interactive:active {
        color: var(--smetio-accent-color);
    }

    .smetio-accent-icon {
        color: var(--smetio-accent-color-shade-60);
    }

    .smetio-accent-icon-interactive {
        color: var(--smetio-accent-color-tint-60);
    }

    .smetio-accent-icon-interactive:hover {
        color: var(--smetio-accent-color-shade-60);
    }

    .smetio-accent-icon-interactive:active {
        color: var(--smetio-accent-color);
    }

    .smetio-accent-border {
        border: solid 1px var(--smetio-accent-color-shade-60);
    }

    .smetio-accent-border-interactive {
        border-color: var(--smetio-accent-color-tint-60);
        stroke: var(--smetio-accent-color-tint-60);
    }

    .smetio-accent-border-interactive:hover {
        border-color: solid 1px var(--smetio-accent-color-shade-60);
        stroke: solid 1px var(--smetio-accent-color-shade-60);
    }

    .smetio-accent-border-interactive:active {
        border-color: solid 1px var(--smetio-accent-color);
        stroke: solid 1px var(--smetio-accent-color);
    }

    .smetio-accent-border-interactive[data-smetio-status='active'] {
        border-color: solid 1px var(--smetio-accent-color);
        stroke: solid 1px var(--smetio-accent-color);
    }

    /* dominantColor */
    .smetio-dominant-background {
        background-color: var(--smetio-dominant-color-tint-60);
        fill: var(--smetio-dominant-color-tint-60);
    }

    .smetio-dominant-background-reverse {
        background-color: var(--smetio-dominant-color-shade-60);
        fill: var(--smetio-dominant-color-shade-60);
    }

    .smetio-dominant-text {
        color: var(--smetio-dominant-color-shade-60);
    }

    .smetio-dominant-text-reverse {
        color: var(--smetio-dominant-color-tint-60);
    }

    .smetio-dominant-border {
        border: solid 1px var(--smetio-dominant-color);
    }

    .smetio-dominant-border-interactive {
        border-color: var(--smetio-dominant-color);
        stroke: var(--smetio-dominant-color);
    }

    .smetio-dominant-border-interactive:hover {
        border-color: solid 1px var(--smetio-dominant-color-shade-60);
        stroke: solid 1px var(--smetio-dominant-color-shade-60);
    }

    .smetio-dominant-border-interactive:active {
        border-color: solid 1px var(--smetio-dominant-color);
        stroke: solid 1px var(--smetio-dominant-color);
    }

    .smetio-dominant-border-interactive[data-smetio-status='active'] {
        border-color: solid 1px var(--smetio-dominant-color);
        stroke: solid 1px var(--smetio-dominant-color);
    }

    /* neutralColor */
    .smetio-neutral-background {
        background-color: var(--smetio-neutral-color-tint-60);
        fill: var(--smetio-neutral-color-tint-60);
    }

    .smetio-neutral-background-reverse {
        background-color: var(--smetio-neutral-color-shade-60);
        fill: var(--smetio-neutral-color-shade-60);
    }

    .smetio-neutral-text {
        color: var(--smetio-neutral-color-shade-100);
    }

    .smetio-neutral-text-reverse {
        color: var(--smetio-neutral-color-tint-100);
    }

    .smetio-neutral-border {
        border: solid 1px var(--smetio-neutral-color);
        border-color: var(--smetio-neutral-color-shade-100);
        stroke: var(--smetio-neutral-color-shade-100);
    }

    /* greyColor */
    .smetio-grey-background {
        background-color: var(--smetio-grey-color);
        fill: var(--smetio-grey-color);
    }

    .smetio-grey-background-reverse {
        background-color: var(--smetio-grey-color-tint-100);
        fill: var(--smetio-grey-color-tint-100);
    }

    .smetio-grey-text {
        color: var(--smetio-grey-color-shade-100);
    }

    .smetio-grey-text-reverse {
        color: var(--smetio-grey-color-tint-100);
    }

    .smetio-grey-border {
        border: solid 1px var(--smetio-grey-color);
        border-color: var(--smetio-grey-color-shade-100);
        stroke: var(--smetio-grey-color-shade-100);
    }

    /* warningColor */
    .smetio-warning-background {
        background-color: var(--smetio-warning-color-tint-60);
        fill: var(--smetio-warning-color-tint-60);
    }

    .smetio-warning-background-reverse {
        background-color: var(--smetio-warning-color-shade-60);
        fill: var(--smetio-warning-color-shade-60);
    }

    .smetio-warning-text {
        color: var(--smetio-warning-color-shade-60);
    }

    .smetio-warning-text-reverse {
        color: var(--smetio-warning-color-tint-60);
    }

    .smetio-warning-icon {
        color: var(--smetio-warning-color-shade-60);
    }

    .smetio-warning-icon-interactive {
        color: var(--smetio-warning-color-shade-60);
    }

    .smetio-warning-icon-interactive:hover {
        color: var(--smetio-warning-color-tint-60);
    }

    .smetio-warning-icon-interactive:active {
        color: var(--smetio-warning-color);
    }

    .smetio-warning-border {
        border: solid 1px var(--smetio-warning-color-shade-60);
    }

    /* successColor */
    .smetio-success-background {
        background-color: var(--smetio-success-color-tint-60);
        fill: var(--smetio-success-color-tint-60);
    }

    .smetio-success-background-reverse {
        background-color: var(--smetio-success-color-shade-60);
        fill: var(--smetio-success-color-shade-60);
    }

    .smetio-success-text {
        color: var(--smetio-success-color-shade-60);
    }

    .smetio-success-text-reverse {
        color: var(--smetio-success-color-tint-60);
    }

    .smetio-success-icon {
        color: var(--smetio-success-color-shade-60);
    }

    .smetio-success-icon-interactive {
        color: var(--smetio-success-color-shade-60);
    }

    .smetio-success-icon-interactive:hover {
        color: var(--smetio-success-color-tint-60);
    }

    .smetio-success-icon-interactive:active {
        color: var(--smetio-success-color);
    }

    .smetio-success-border {
        border: solid 1px var(--smetio-success-color-shade-60);
    }

    /* informationColor */
    .smetio-information-background {
        background-color: var(--smetio-information-color-tint-60);
        fill: var(--smetio-information-color-tint-60);
    }

    .smetio-information-background-reverse {
        background-color: var(--smetio-information-color-shade-60);
        fill: var(--smetio-information-color-shade-60);
    }

    .smetio-information-text {
        color: var(--smetio-information-color-shade-60);
    }

    .smetio-information-text-reverse {
        color: var(--smetio-information-color-tint-60);
    }

    .smetio-information-icon {
        color: var(--smetio-information-color-shade-60);
    }

    .smetio-information-icon-interactive {
        color: var(--smetio-information-color-shade-60);
    }

    .smetio-information-icon-interactive:hover {
        color: var(--smetio-information-color-tint-60);
    }

    .smetio-information-icon-interactive:active {
        color: var(--smetio-information-color);
    }

    .smetio-information-border {
        border: solid 1px var(--smetio-information-color-shade-60);
    }

    /* dangerColor */
    .smetio-danger-background {
        background-color: var(--smetio-danger-color-tint-60);
        fill: var(--smetio-danger-color-tint-60);
    }

    .smetio-danger-background-reverse {
        background-color: var(--smetio-danger-color-shade-60);
        fill: var(--smetio-danger-color-shade-60);
    }

    .smetio-danger-text {
        color: var(--smetio-danger-color-shade-60);
    }

    .smetio-danger-text-reverse {
        color: var(--smetio-danger-color-tint-60);
    }

    .smetio-danger-icon {
        color: var(--smetio-danger-color-shade-60);
    }

    .smetio-danger-icon-interactive {
        color: var(--smetio-danger-color-shade-60);
    }

    .smetio-danger-icon-interactive:hover {
        color: var(--smetio-danger-color-tint-60);
    }

    .smetio-danger-icon-interactive:active {
        color: var(--smetio-danger-color);
    }

    .smetio-danger-border {
        border: solid 1px var(--smetio-danger-color-shade-60);
    }
}

html[data-theme="dark-60"] {

    /* accentColor */
    .smetio-accent-background {
        background-color: var(--smetio-accent-color-tint-60);
        fill: var(--smetio-accent-color-tint-60);
    }

    .smetio-accent-background-reverse {
        background-color: var(--smetio-accent-color-shade-60);
        fill: var(--smetio-accent-color-shade-60);
    }

    .smetio-accent-text {
        color: var(--smetio-accent-color-shade-60);
    }

    .smetio-accent-text-reverse {
        color: var(--smetio-accent-color-tint-60);
    }

    .smetio-accent-button {
        background-color: var(--smetio-accent-color-tint-60);
        fill: var(--smetio-accent-color-tint-60);
        color: var(--smetio-dominant-color-shade-60);
    }

    .smetio-accent-button:hover {
        background-color: var(--smetio-accent-color-shade-60);
        fill: var(--smetio-accent-color-shade-60);
        color: var(--smetio-dominant-color-tint-60);
    }

    .smetio-accent-button:active {
        background-color: var(--smetio-accent-color-tint-60);
        fill: var(--smetio-accent-color-tint-60);
        color: var(--smetio-dominant-color-shade-60);
    }

    .smetio-accent-button[data-smetio-status='active'] {
        background-color: var(--smetio-dominant-color-shade-60);
        fill: var(--smetio-dominant-color-shade-60);
        color: var(--smetio-accent-color-tint-60);
    }

    .smetio-accent-link {
        color: var(--smetio-accent-color-tint-60);
    }

    .smetio-accent-link-interactive {
        color: var(--smetio-accent-color-shade-60);
    }

    .smetio-accent-link-interactive:hover {
        color: var(--smetio-accent-color-tint-60);
    }

    .smetio-accent-link-interactive:active {
        color: var(--smetio-accent-color);
    }

    .smetio-accent-icon {
        color: var(--smetio-accent-color-tint-60);
    }

    .smetio-accent-icon-interactive {
        color: var(--smetio-accent-color-shade-60);
    }

    .smetio-accent-icon-interactive:hover {
        color: var(--smetio-accent-color-tint-60);
    }

    .smetio-accent-icon-interactive:active {
        color: var(--smetio-accent-color);
    }

    .smetio-accent-border {
        border: solid 1px var(--smetio-accent-color-tint-60);
    }

    .smetio-accent-border-interactive {
        border-color: var(--smetio-accent-color-shade-60);
        stroke: var(--smetio-accent-color-shade-60);
    }

    .smetio-accent-border-interactive:hover {
        border-color: solid 1px var(--smetio-accent-color-tint-60);
        stroke: solid 1px var(--smetio-accent-color-tint-60);
    }

    .smetio-accent-border-interactive:active {
        border-color: solid 1px var(--smetio-accent-color);
        stroke: solid 1px var(--smetio-accent-color);
    }

    .smetio-accent-border-interactive[data-smetio-status='active'] {
        border-color: solid 1px var(--smetio-accent-color);
        stroke: solid 1px var(--smetio-accent-color);
    }

    /* dominantColor */
    .smetio-dominant-background {
        background-color: var(--smetio-dominant-color-shade-60);
        fill: var(--smetio-dominant-color-shade-60);
    }

    .smetio-dominant-background-reverse {
        background-color: var(--smetio-dominant-color-tint-60);
        fill: var(--smetio-dominant-color-tint-60);
    }

    .smetio-dominant-text {
        color: var(--smetio-dominant-color-tint-60);
    }

    .smetio-dominant-text-reverse {
        color: var(--smetio-dominant-color-shade-60);
    }

    .smetio-dominant-border {
        border: solid 1px var(--smetio-dominant-color);
    }

    .smetio-dominant-border-interactive {
        border-color: var(--smetio-dominant-color);
        stroke: var(--smetio-dominant-color);
    }

    .smetio-dominant-border-interactive:hover {
        border-color: solid 1px var(--smetio-dominant-color-tint-60);
        stroke: solid 1px var(--smetio-dominant-color-tint-60);
    }

    .smetio-dominant-border-interactive:active {
        border-color: solid 1px var(--smetio-dominant-color);
        stroke: solid 1px var(--smetio-dominant-color);
    }

    .smetio-dominant-border-interactive[data-smetio-status='active'] {
        border-color: solid 1px var(--smetio-dominant-color);
        stroke: solid 1px var(--smetio-dominant-color);
    }

    /* neutralColor */
    .smetio-neutral-background {
        background-color: var(--smetio-neutral-color-shade-60);
        fill: var(--smetio-neutral-color-shade-60);
    }

    .smetio-neutral-background-reverse {
        background-color: var(--smetio-neutral-color-tint-60);
        fill: var(--smetio-neutral-color-tint-60);
    }

    .smetio-neutral-text {
        color: var(--smetio-neutral-color-tint-100);
    }

    .smetio-neutral-text-reverse {
        color: var(--smetio-neutral-color-shade-100);
    }

    .smetio-neutral-border {
        border: solid 1px var(--smetio-neutral-color);
        border-color: var(--smetio-neutral-color-tint-100);
        stroke: var(--smetio-neutral-color-tint-100);
    }

    /* greyColor */
    .smetio-grey-background {
        background-color: var(--smetio-grey-color);
        fill: var(--smetio-grey-color);
    }

    .smetio-grey-background-reverse {
        background-color: var(--smetio-grey-color-shade-100);
        fill: var(--smetio-grey-color-shade-100);
    }

    .smetio-grey-text {
        color: var(--smetio-grey-color-tint-100);
    }

    .smetio-grey-text-reverse {
        color: var(--smetio-grey-color-shade-100);
    }

    .smetio-grey-border {
        border: solid 1px var(--smetio-grey-color);
        border-color: var(--smetio-grey-color-tint-100);
        stroke: var(--smetio-grey-color-tint-100);
    }

    /* warningColor */
    .smetio-warning-background {
        background-color: var(--smetio-warning-color-shade-60);
        fill: var(--smetio-warning-color-shade-60);
    }

    .smetio-warning-background-reverse {
        background-color: var(--smetio-warning-color-tint-60);
        fill: var(--smetio-warning-color-tint-60);
    }

    .smetio-warning-text {
        color: var(--smetio-warning-color-tint-60);
    }

    .smetio-warning-text-reverse {
        color: var(--smetio-warning-color-shade-60);
    }

    .smetio-warning-icon {
        color: var(--smetio-warning-color-tint-60);
    }

    .smetio-warning-icon-interactive {
        color: var(--smetio-warning-color-tint-60);
    }

    .smetio-warning-icon-interactive:hover {
        color: var(--smetio-warning-color-shade-60);
    }

    .smetio-warning-icon-interactive:active {
        color: var(--smetio-warning-color);
    }

    .smetio-warning-border {
        border: solid 1px var(--smetio-warning-color-tint-60);
    }

    /* successColor */
    .smetio-success-background {
        background-color: var(--smetio-success-color-shade-60);
        fill: var(--smetio-success-color-shade-60);
    }

    .smetio-success-background-reverse {
        background-color: var(--smetio-success-color-tint-60);
        fill: var(--smetio-success-color-tint-60);
    }

    .smetio-success-text {
        color: var(--smetio-success-color-tint-60);
    }

    .smetio-success-text-reverse {
        color: var(--smetio-success-color-shade-60);
    }

    .smetio-success-icon {
        color: var(--smetio-success-color-tint-60);
    }

    .smetio-success-icon-interactive {
        color: var(--smetio-success-color-tint-60);
    }

    .smetio-success-icon-interactive:hover {
        color: var(--smetio-success-color-shade-60);
    }

    .smetio-success-icon-interactive:active {
        color: var(--smetio-success-color);
    }

    .smetio-success-border {
        border: solid 1px var(--smetio-success-color-tint-60);
    }

    /* informationColor */
    .smetio-information-background {
        background-color: var(--smetio-information-color-shade-60);
        fill: var(--smetio-information-color-shade-60);
    }

    .smetio-information-background-reverse {
        background-color: var(--smetio-information-color-tint-60);
        fill: var(--smetio-information-color-tint-60);
    }

    .smetio-information-text {
        color: var(--smetio-information-color-tint-60);
    }

    .smetio-information-text-reverse {
        color: var(--smetio-information-color-shade-60);
    }

    .smetio-information-icon {
        color: var(--smetio-information-color-tint-60);
    }

    .smetio-information-icon-interactive {
        color: var(--smetio-information-color-tint-60);
    }

    .smetio-information-icon-interactive:hover {
        color: var(--smetio-information-color-shade-60);
    }

    .smetio-information-icon-interactive:active {
        color: var(--smetio-information-color);
    }

    .smetio-information-border {
        border: solid 1px var(--smetio-information-color-tint-60);
    }

    /* dangerColor */
    .smetio-danger-background {
        background-color: var(--smetio-danger-color-shade-60);
        fill: var(--smetio-danger-color-shade-60);
    }

    .smetio-danger-background-reverse {
        background-color: var(--smetio-danger-color-tint-60);
        fill: var(--smetio-danger-color-tint-60);
    }

    .smetio-danger-text {
        color: var(--smetio-danger-color-tint-60);
    }

    .smetio-danger-text-reverse {
        color: var(--smetio-danger-color-shade-60);
    }

    .smetio-danger-icon {
        color: var(--smetio-danger-color-tint-60);
    }

    .smetio-danger-icon-interactive {
        color: var(--smetio-danger-color-tint-60);
    }

    .smetio-danger-icon-interactive:hover {
        color: var(--smetio-danger-color-shade-60);
    }

    .smetio-danger-icon-interactive:active {
        color: var(--smetio-danger-color);
    }

    .smetio-danger-border {
        border: solid 1px var(--smetio-danger-color-tint-60);
    }
}

html[data-theme="light-80"] {

    /* accentColor */
    .smetio-accent-background {
        background-color: var(--smetio-accent-color-shade-80);
        fill: var(--smetio-accent-color-shade-80);
    }

    .smetio-accent-background-reverse {
        background-color: var(--smetio-accent-color-tint-80);
        fill: var(--smetio-accent-color-tint-80);
    }

    .smetio-accent-text {
        color: var(--smetio-accent-color-tint-80);
    }

    .smetio-accent-text-reverse {
        color: var(--smetio-accent-color-shade-80);
    }

    .smetio-accent-button {
        background-color: var(--smetio-accent-color-shade-80);
        fill: var(--smetio-accent-color-shade-80);
        color: var(--smetio-dominant-color-tint-80);
    }

    .smetio-accent-button:hover {
        background-color: var(--smetio-accent-color-tint-80);
        fill: var(--smetio-accent-color-tint-80);
        color: var(--smetio-dominant-color-shade-80);
    }

    .smetio-accent-button:active {
        background-color: var(--smetio-accent-color-shade-80);
        fill: var(--smetio-accent-color-shade-80);
        color: var(--smetio-dominant-color-tint-80);
    }

    .smetio-accent-button[data-smetio-status='active'] {
        background-color: var(--smetio-dominant-color-tint-80);
        fill: var(--smetio-dominant-color-tint-80);
        color: var(--smetio-accent-color-shade-80);
    }

    .smetio-accent-link {
        color: var(--smetio-accent-color-shade-80);
    }

    .smetio-accent-link-interactive {
        color: var(--smetio-accent-color-tint-80);
    }

    .smetio-accent-link-interactive:hover {
        color: var(--smetio-accent-color-shade-80);
    }

    .smetio-accent-link-interactive:active {
        color: var(--smetio-accent-color);
    }

    .smetio-accent-icon {
        color: var(--smetio-accent-color-shade-80);
    }

    .smetio-accent-icon-interactive {
        color: var(--smetio-accent-color-tint-80);
    }

    .smetio-accent-icon-interactive:hover {
        color: var(--smetio-accent-color-shade-80);
    }

    .smetio-accent-icon-interactive:active {
        color: var(--smetio-accent-color);
    }

    .smetio-accent-border {
        border: solid 1px var(--smetio-accent-color-shade-80);
    }

    .smetio-accent-border-interactive {
        border-color: var(--smetio-accent-color-tint-80);
        stroke: var(--smetio-accent-color-tint-80);
    }

    .smetio-accent-border-interactive:hover {
        border-color: solid 1px var(--smetio-accent-color-shade-80);
        stroke: solid 1px var(--smetio-accent-color-shade-80);
    }

    .smetio-accent-border-interactive:active {
        border-color: solid 1px var(--smetio-accent-color);
        stroke: solid 1px var(--smetio-accent-color);
    }

    .smetio-accent-border-interactive[data-smetio-status='active'] {
        border-color: solid 1px var(--smetio-accent-color);
        stroke: solid 1px var(--smetio-accent-color);
    }

    /* dominantColor */
    .smetio-dominant-background {
        background-color: var(--smetio-dominant-color-tint-80);
        fill: var(--smetio-dominant-color-tint-80);
    }

    .smetio-dominant-background-reverse {
        background-color: var(--smetio-dominant-color-shade-80);
        fill: var(--smetio-dominant-color-shade-80);
    }

    .smetio-dominant-text {
        color: var(--smetio-dominant-color-shade-80);
    }

    .smetio-dominant-text-reverse {
        color: var(--smetio-dominant-color-tint-80);
    }

    .smetio-dominant-border {
        border: solid 1px var(--smetio-dominant-color);
    }

    .smetio-dominant-border-interactive {
        border-color: var(--smetio-dominant-color);
        stroke: var(--smetio-dominant-color);
    }

    .smetio-dominant-border-interactive:hover {
        border-color: solid 1px var(--smetio-dominant-color-shade-80);
        stroke: solid 1px var(--smetio-dominant-color-shade-80);
    }

    .smetio-dominant-border-interactive:active {
        border-color: solid 1px var(--smetio-dominant-color);
        stroke: solid 1px var(--smetio-dominant-color);
    }

    .smetio-dominant-border-interactive[data-smetio-status='active'] {
        border-color: solid 1px var(--smetio-dominant-color);
        stroke: solid 1px var(--smetio-dominant-color);
    }

    /* neutralColor */
    .smetio-neutral-background {
        background-color: var(--smetio-neutral-color-tint-80);
        fill: var(--smetio-neutral-color-tint-80);
    }

    .smetio-neutral-background-reverse {
        background-color: var(--smetio-neutral-color-shade-80);
        fill: var(--smetio-neutral-color-shade-80);
    }

    .smetio-neutral-text {
        color: var(--smetio-neutral-color-shade-100);
    }

    .smetio-neutral-text-reverse {
        color: var(--smetio-neutral-color-tint-100);
    }

    .smetio-neutral-border {
        border: solid 1px var(--smetio-neutral-color);
        border-color: var(--smetio-neutral-color-shade-100);
        stroke: var(--smetio-neutral-color-shade-100);
    }

    /* greyColor */
    .smetio-grey-background {
        background-color: var(--smetio-grey-color);
        fill: var(--smetio-grey-color);
    }

    .smetio-grey-background-reverse {
        background-color: var(--smetio-grey-color-tint-100);
        fill: var(--smetio-grey-color-tint-100);
    }

    .smetio-grey-text {
        color: var(--smetio-grey-color-shade-100);
    }

    .smetio-grey-text-reverse {
        color: var(--smetio-grey-color-tint-100);
    }

    .smetio-grey-border {
        border: solid 1px var(--smetio-grey-color);
        border-color: var(--smetio-grey-color-shade-100);
        stroke: var(--smetio-grey-color-shade-100);
    }

    /* warningColor */
    .smetio-warning-background {
        background-color: var(--smetio-warning-color-tint-80);
        fill: var(--smetio-warning-color-tint-80);
    }

    .smetio-warning-background-reverse {
        background-color: var(--smetio-warning-color-shade-80);
        fill: var(--smetio-warning-color-shade-80);
    }

    .smetio-warning-text {
        color: var(--smetio-warning-color-shade-80);
    }

    .smetio-warning-text-reverse {
        color: var(--smetio-warning-color-tint-80);
    }

    .smetio-warning-icon {
        color: var(--smetio-warning-color-shade-80);
    }

    .smetio-warning-icon-interactive {
        color: var(--smetio-warning-color-shade-80);
    }

    .smetio-warning-icon-interactive:hover {
        color: var(--smetio-warning-color-tint-80);
    }

    .smetio-warning-icon-interactive:active {
        color: var(--smetio-warning-color);
    }

    .smetio-warning-border {
        border: solid 1px var(--smetio-warning-color-shade-80);
    }

    /* successColor */
    .smetio-success-background {
        background-color: var(--smetio-success-color-tint-80);
        fill: var(--smetio-success-color-tint-80);
    }

    .smetio-success-background-reverse {
        background-color: var(--smetio-success-color-shade-80);
        fill: var(--smetio-success-color-shade-80);
    }

    .smetio-success-text {
        color: var(--smetio-success-color-shade-80);
    }

    .smetio-success-text-reverse {
        color: var(--smetio-success-color-tint-80);
    }

    .smetio-success-icon {
        color: var(--smetio-success-color-shade-80);
    }

    .smetio-success-icon-interactive {
        color: var(--smetio-success-color-shade-80);
    }

    .smetio-success-icon-interactive:hover {
        color: var(--smetio-success-color-tint-80);
    }

    .smetio-success-icon-interactive:active {
        color: var(--smetio-success-color);
    }

    .smetio-success-border {
        border: solid 1px var(--smetio-success-color-shade-80);
    }

    /* informationColor */
    .smetio-information-background {
        background-color: var(--smetio-information-color-tint-80);
        fill: var(--smetio-information-color-tint-80);
    }

    .smetio-information-background-reverse {
        background-color: var(--smetio-information-color-shade-80);
        fill: var(--smetio-information-color-shade-80);
    }

    .smetio-information-text {
        color: var(--smetio-information-color-shade-80);
    }

    .smetio-information-text-reverse {
        color: var(--smetio-information-color-tint-80);
    }

    .smetio-information-icon {
        color: var(--smetio-information-color-shade-80);
    }

    .smetio-information-icon-interactive {
        color: var(--smetio-information-color-shade-80);
    }

    .smetio-information-icon-interactive:hover {
        color: var(--smetio-information-color-tint-80);
    }

    .smetio-information-icon-interactive:active {
        color: var(--smetio-information-color);
    }

    .smetio-information-border {
        border: solid 1px var(--smetio-information-color-shade-80);
    }

    /* dangerColor */
    .smetio-danger-background {
        background-color: var(--smetio-danger-color-tint-80);
        fill: var(--smetio-danger-color-tint-80);
    }

    .smetio-danger-background-reverse {
        background-color: var(--smetio-danger-color-shade-80);
        fill: var(--smetio-danger-color-shade-80);
    }

    .smetio-danger-text {
        color: var(--smetio-danger-color-shade-80);
    }

    .smetio-danger-text-reverse {
        color: var(--smetio-danger-color-tint-80);
    }

    .smetio-danger-icon {
        color: var(--smetio-danger-color-shade-80);
    }

    .smetio-danger-icon-interactive {
        color: var(--smetio-danger-color-shade-80);
    }

    .smetio-danger-icon-interactive:hover {
        color: var(--smetio-danger-color-tint-80);
    }

    .smetio-danger-icon-interactive:active {
        color: var(--smetio-danger-color);
    }

    .smetio-danger-border {
        border: solid 1px var(--smetio-danger-color-shade-80);
    }
}

html[data-theme="dark-80"] {

    /* accentColor */
    .smetio-accent-background {
        background-color: var(--smetio-accent-color-tint-80);
        fill: var(--smetio-accent-color-tint-80);
    }

    .smetio-accent-background-reverse {
        background-color: var(--smetio-accent-color-shade-80);
        fill: var(--smetio-accent-color-shade-80);
    }

    .smetio-accent-text {
        color: var(--smetio-accent-color-shade-80);
    }

    .smetio-accent-text-reverse {
        color: var(--smetio-accent-color-tint-80);
    }

    .smetio-accent-button {
        background-color: var(--smetio-accent-color-tint-80);
        fill: var(--smetio-accent-color-tint-80);
        color: var(--smetio-dominant-color-shade-80);
    }

    .smetio-accent-button:hover {
        background-color: var(--smetio-accent-color-shade-80);
        fill: var(--smetio-accent-color-shade-80);
        color: var(--smetio-dominant-color-tint-80);
    }

    .smetio-accent-button:active {
        background-color: var(--smetio-accent-color-tint-80);
        fill: var(--smetio-accent-color-tint-80);
        color: var(--smetio-dominant-color-shade-80);
    }

    .smetio-accent-button[data-smetio-status='active'] {
        background-color: var(--smetio-dominant-color-shade-80);
        fill: var(--smetio-dominant-color-shade-80);
        color: var(--smetio-accent-color-tint-80);
    }

    .smetio-accent-link {
        color: var(--smetio-accent-color-tint-80);
    }

    .smetio-accent-link-interactive {
        color: var(--smetio-accent-color-shade-80);
    }

    .smetio-accent-link-interactive:hover {
        color: var(--smetio-accent-color-tint-80);
    }

    .smetio-accent-link-interactive:active {
        color: var(--smetio-accent-color);
    }

    .smetio-accent-icon {
        color: var(--smetio-accent-color-tint-80);
    }

    .smetio-accent-icon-interactive {
        color: var(--smetio-accent-color-shade-80);
    }

    .smetio-accent-icon-interactive:hover {
        color: var(--smetio-accent-color-tint-80);
    }

    .smetio-accent-icon-interactive:active {
        color: var(--smetio-accent-color);
    }

    .smetio-accent-border {
        border: solid 1px var(--smetio-accent-color-tint-80);
    }

    .smetio-accent-border-interactive {
        border-color: var(--smetio-accent-color-shade-80);
        stroke: var(--smetio-accent-color-shade-80);
    }

    .smetio-accent-border-interactive:hover {
        border-color: solid 1px var(--smetio-accent-color-tint-80);
        stroke: solid 1px var(--smetio-accent-color-tint-80);
    }

    .smetio-accent-border-interactive:active {
        border-color: solid 1px var(--smetio-accent-color);
        stroke: solid 1px var(--smetio-accent-color);
    }

    .smetio-accent-border-interactive[data-smetio-status='active'] {
        border-color: solid 1px var(--smetio-accent-color);
        stroke: solid 1px var(--smetio-accent-color);
    }

    /* dominantColor */
    .smetio-dominant-background {
        background-color: var(--smetio-dominant-color-shade-80);
        fill: var(--smetio-dominant-color-shade-80);
    }

    .smetio-dominant-background-reverse {
        background-color: var(--smetio-dominant-color-tint-80);
        fill: var(--smetio-dominant-color-tint-80);
    }

    .smetio-dominant-text {
        color: var(--smetio-dominant-color-tint-80);
    }

    .smetio-dominant-text-reverse {
        color: var(--smetio-dominant-color-shade-80);
    }

    .smetio-dominant-border {
        border: solid 1px var(--smetio-dominant-color);
    }

    .smetio-dominant-border-interactive {
        border-color: var(--smetio-dominant-color);
        stroke: var(--smetio-dominant-color);
    }

    .smetio-dominant-border-interactive:hover {
        border-color: solid 1px var(--smetio-dominant-color-tint-80);
        stroke: solid 1px var(--smetio-dominant-color-tint-80);
    }

    .smetio-dominant-border-interactive:active {
        border-color: solid 1px var(--smetio-dominant-color);
        stroke: solid 1px var(--smetio-dominant-color);
    }

    .smetio-dominant-border-interactive[data-smetio-status='active'] {
        border-color: solid 1px var(--smetio-dominant-color);
        stroke: solid 1px var(--smetio-dominant-color);
    }

    /* neutralColor */
    .smetio-neutral-background {
        background-color: var(--smetio-neutral-color-shade-80);
        fill: var(--smetio-neutral-color-shade-80);
    }

    .smetio-neutral-background-reverse {
        background-color: var(--smetio-neutral-color-tint-80);
        fill: var(--smetio-neutral-color-tint-80);
    }

    .smetio-neutral-text {
        color: var(--smetio-neutral-color-tint-100);
    }

    .smetio-neutral-text-reverse {
        color: var(--smetio-neutral-color-shade-100);
    }

    .smetio-neutral-border {
        border: solid 1px var(--smetio-neutral-color);
        border-color: var(--smetio-neutral-color-tint-100);
        stroke: var(--smetio-neutral-color-tint-100);
    }

    /* greyColor */
    .smetio-grey-background {
        background-color: var(--smetio-grey-color);
        fill: var(--smetio-grey-color);
    }

    .smetio-grey-background-reverse {
        background-color: var(--smetio-grey-color-shade-100);
        fill: var(--smetio-grey-color-shade-100);
    }

    .smetio-grey-text {
        color: var(--smetio-grey-color-tint-100);
    }

    .smetio-grey-text-reverse {
        color: var(--smetio-grey-color-shade-100);
    }

    .smetio-grey-border {
        border: solid 1px var(--smetio-grey-color);
        border-color: var(--smetio-grey-color-tint-100);
        stroke: var(--smetio-grey-color-tint-100);
    }

    /* warningColor */
    .smetio-warning-background {
        background-color: var(--smetio-warning-color-shade-80);
        fill: var(--smetio-warning-color-shade-80);
    }

    .smetio-warning-background-reverse {
        background-color: var(--smetio-warning-color-tint-80);
        fill: var(--smetio-warning-color-tint-80);
    }

    .smetio-warning-text {
        color: var(--smetio-warning-color-tint-80);
    }

    .smetio-warning-text-reverse {
        color: var(--smetio-warning-color-shade-80);
    }

    .smetio-warning-icon {
        color: var(--smetio-warning-color-tint-80);
    }

    .smetio-warning-icon-interactive {
        color: var(--smetio-warning-color-tint-80);
    }

    .smetio-warning-icon-interactive:hover {
        color: var(--smetio-warning-color-shade-80);
    }

    .smetio-warning-icon-interactive:active {
        color: var(--smetio-warning-color);
    }

    .smetio-warning-border {
        border: solid 1px var(--smetio-warning-color-tint-80);
    }

    /* successColor */
    .smetio-success-background {
        background-color: var(--smetio-success-color-shade-80);
        fill: var(--smetio-success-color-shade-80);
    }

    .smetio-success-background-reverse {
        background-color: var(--smetio-success-color-tint-80);
        fill: var(--smetio-success-color-tint-80);
    }

    .smetio-success-text {
        color: var(--smetio-success-color-tint-80);
    }

    .smetio-success-text-reverse {
        color: var(--smetio-success-color-shade-80);
    }

    .smetio-success-icon {
        color: var(--smetio-success-color-tint-80);
    }

    .smetio-success-icon-interactive {
        color: var(--smetio-success-color-tint-80);
    }

    .smetio-success-icon-interactive:hover {
        color: var(--smetio-success-color-shade-80);
    }

    .smetio-success-icon-interactive:active {
        color: var(--smetio-success-color);
    }

    .smetio-success-border {
        border: solid 1px var(--smetio-success-color-tint-80);
    }

    /* informationColor */
    .smetio-information-background {
        background-color: var(--smetio-information-color-shade-80);
        fill: var(--smetio-information-color-shade-80);
    }

    .smetio-information-background-reverse {
        background-color: var(--smetio-information-color-tint-80);
        fill: var(--smetio-information-color-tint-80);
    }

    .smetio-information-text {
        color: var(--smetio-information-color-tint-80);
    }

    .smetio-information-text-reverse {
        color: var(--smetio-information-color-shade-80);
    }

    .smetio-information-icon {
        color: var(--smetio-information-color-tint-80);
    }

    .smetio-information-icon-interactive {
        color: var(--smetio-information-color-tint-80);
    }

    .smetio-information-icon-interactive:hover {
        color: var(--smetio-information-color-shade-80);
    }

    .smetio-information-icon-interactive:active {
        color: var(--smetio-information-color);
    }

    .smetio-information-border {
        border: solid 1px var(--smetio-information-color-tint-80);
    }

    /* dangerColor */
    .smetio-danger-background {
        background-color: var(--smetio-danger-color-shade-80);
        fill: var(--smetio-danger-color-shade-80);
    }

    .smetio-danger-background-reverse {
        background-color: var(--smetio-danger-color-tint-80);
        fill: var(--smetio-danger-color-tint-80);
    }

    .smetio-danger-text {
        color: var(--smetio-danger-color-tint-80);
    }

    .smetio-danger-text-reverse {
        color: var(--smetio-danger-color-shade-80);
    }

    .smetio-danger-icon {
        color: var(--smetio-danger-color-tint-80);
    }

    .smetio-danger-icon-interactive {
        color: var(--smetio-danger-color-tint-80);
    }

    .smetio-danger-icon-interactive:hover {
        color: var(--smetio-danger-color-shade-80);
    }

    .smetio-danger-icon-interactive:active {
        color: var(--smetio-danger-color);
    }

    .smetio-danger-border {
        border: solid 1px var(--smetio-danger-color-tint-80);
    }
}

html[data-theme="light-100"] {

    /* accentColor */
    .smetio-accent-background {
        background-color: var(--smetio-accent-color-shade-100);
        fill: var(--smetio-accent-color-shade-100);
    }

    .smetio-accent-background-reverse {
        background-color: var(--smetio-accent-color-tint-100);
        fill: var(--smetio-accent-color-tint-100);
    }

    .smetio-accent-text {
        color: var(--smetio-accent-color-tint-100);
    }

    .smetio-accent-text-reverse {
        color: var(--smetio-accent-color-shade-100);
    }

    .smetio-accent-button {
        background-color: var(--smetio-accent-color-shade-100);
        fill: var(--smetio-accent-color-shade-100);
        color: var(--smetio-dominant-color-tint-100);
    }

    .smetio-accent-button:hover {
        background-color: var(--smetio-accent-color-tint-100);
        fill: var(--smetio-accent-color-tint-100);
        color: var(--smetio-dominant-color-shade-100);
    }

    .smetio-accent-button:active {
        background-color: var(--smetio-accent-color-shade-100);
        fill: var(--smetio-accent-color-shade-100);
        color: var(--smetio-dominant-color-tint-100);
    }

    .smetio-accent-button[data-smetio-status='active'] {
        background-color: var(--smetio-dominant-color-tint-100);
        fill: var(--smetio-dominant-color-tint-100);
        color: var(--smetio-accent-color-shade-100);
    }

    .smetio-accent-link {
        color: var(--smetio-accent-color-shade-100);
    }

    .smetio-accent-link-interactive {
        color: var(--smetio-accent-color-tint-100);
    }

    .smetio-accent-link-interactive:hover {
        color: var(--smetio-accent-color-shade-100);
    }

    .smetio-accent-link-interactive:active {
        color: var(--smetio-accent-color);
    }

    .smetio-accent-icon {
        color: var(--smetio-accent-color-shade-100);
    }

    .smetio-accent-icon-interactive {
        color: var(--smetio-accent-color-tint-100);
    }

    .smetio-accent-icon-interactive:hover {
        color: var(--smetio-accent-color-shade-100);
    }

    .smetio-accent-icon-interactive:active {
        color: var(--smetio-accent-color);
    }

    .smetio-accent-border {
        border: solid 1px var(--smetio-accent-color-shade-100);
    }

    .smetio-accent-border-interactive {
        border-color: var(--smetio-accent-color-tint-100);
        stroke: var(--smetio-accent-color-tint-100);
    }

    .smetio-accent-border-interactive:hover {
        border-color: solid 1px var(--smetio-accent-color-shade-100);
        stroke: solid 1px var(--smetio-accent-color-shade-100);
    }

    .smetio-accent-border-interactive:active {
        border-color: solid 1px var(--smetio-accent-color);
        stroke: solid 1px var(--smetio-accent-color);
    }

    .smetio-accent-border-interactive[data-smetio-status='active'] {
        border-color: solid 1px var(--smetio-accent-color);
        stroke: solid 1px var(--smetio-accent-color);
    }

    /* dominantColor */
    .smetio-dominant-background {
        background-color: var(--smetio-dominant-color-tint-100);
        fill: var(--smetio-dominant-color-tint-100);
    }

    .smetio-dominant-background-reverse {
        background-color: var(--smetio-dominant-color-shade-100);
        fill: var(--smetio-dominant-color-shade-100);
    }

    .smetio-dominant-text {
        color: var(--smetio-dominant-color-shade-100);
    }

    .smetio-dominant-text-reverse {
        color: var(--smetio-dominant-color-tint-100);
    }

    .smetio-dominant-border {
        border: solid 1px var(--smetio-dominant-color);
    }

    .smetio-dominant-border-interactive {
        border-color: var(--smetio-dominant-color);
        stroke: var(--smetio-dominant-color);
    }

    .smetio-dominant-border-interactive:hover {
        border-color: solid 1px var(--smetio-dominant-color-shade-100);
        stroke: solid 1px var(--smetio-dominant-color-shade-100);
    }

    .smetio-dominant-border-interactive:active {
        border-color: solid 1px var(--smetio-dominant-color);
        stroke: solid 1px var(--smetio-dominant-color);
    }

    .smetio-dominant-border-interactive[data-smetio-status='active'] {
        border-color: solid 1px var(--smetio-dominant-color);
        stroke: solid 1px var(--smetio-dominant-color);
    }

    /* neutralColor */
    .smetio-neutral-background {
        background-color: var(--smetio-neutral-color-tint-100);
        fill: var(--smetio-neutral-color-tint-100);
    }

    .smetio-neutral-background-reverse {
        background-color: var(--smetio-neutral-color-shade-100);
        fill: var(--smetio-neutral-color-shade-100);
    }

    .smetio-neutral-text {
        color: var(--smetio-neutral-color-shade-100);
    }

    .smetio-neutral-text-reverse {
        color: var(--smetio-neutral-color-tint-100);
    }

    .smetio-neutral-border {
        border: solid 1px var(--smetio-neutral-color);
        border-color: var(--smetio-neutral-color-shade-100);
        stroke: var(--smetio-neutral-color-shade-100);
    }

    /* greyColor */
    .smetio-grey-background {
        background-color: var(--smetio-grey-color);
        fill: var(--smetio-grey-color);
    }

    .smetio-grey-background-reverse {
        background-color: var(--smetio-grey-color-tint-100);
        fill: var(--smetio-grey-color-tint-100);
    }

    .smetio-grey-text {
        color: var(--smetio-grey-color-shade-100);
    }

    .smetio-grey-text-reverse {
        color: var(--smetio-grey-color-tint-100);
    }

    .smetio-grey-border {
        border: solid 1px var(--smetio-grey-color);
        border-color: var(--smetio-grey-color-shade-100);
        stroke: var(--smetio-grey-color-shade-100);
    }

    /* warningColor */
    .smetio-warning-background {
        background-color: var(--smetio-warning-color-tint-100);
        fill: var(--smetio-warning-color-tint-100);
    }

    .smetio-warning-background-reverse {
        background-color: var(--smetio-warning-color-shade-100);
        fill: var(--smetio-warning-color-shade-100);
    }

    .smetio-warning-text {
        color: var(--smetio-warning-color-shade-100);
    }

    .smetio-warning-text-reverse {
        color: var(--smetio-warning-color-tint-100);
    }

    .smetio-warning-icon {
        color: var(--smetio-warning-color-shade-100);
    }

    .smetio-warning-icon-interactive {
        color: var(--smetio-warning-color-shade-100);
    }

    .smetio-warning-icon-interactive:hover {
        color: var(--smetio-warning-color-tint-100);
    }

    .smetio-warning-icon-interactive:active {
        color: var(--smetio-warning-color);
    }

    .smetio-warning-border {
        border: solid 1px var(--smetio-warning-color-shade-100);
    }

    /* successColor */
    .smetio-success-background {
        background-color: var(--smetio-success-color-tint-100);
        fill: var(--smetio-success-color-tint-100);
    }

    .smetio-success-background-reverse {
        background-color: var(--smetio-success-color-shade-100);
        fill: var(--smetio-success-color-shade-100);
    }

    .smetio-success-text {
        color: var(--smetio-success-color-shade-100);
    }

    .smetio-success-text-reverse {
        color: var(--smetio-success-color-tint-100);
    }

    .smetio-success-icon {
        color: var(--smetio-success-color-shade-100);
    }

    .smetio-success-icon-interactive {
        color: var(--smetio-success-color-shade-100);
    }

    .smetio-success-icon-interactive:hover {
        color: var(--smetio-success-color-tint-100);
    }

    .smetio-success-icon-interactive:active {
        color: var(--smetio-success-color);
    }

    .smetio-success-border {
        border: solid 1px var(--smetio-success-color-shade-100);
    }

    /* informationColor */
    .smetio-information-background {
        background-color: var(--smetio-information-color-tint-100);
        fill: var(--smetio-information-color-tint-100);
    }

    .smetio-information-background-reverse {
        background-color: var(--smetio-information-color-shade-100);
        fill: var(--smetio-information-color-shade-100);
    }

    .smetio-information-text {
        color: var(--smetio-information-color-shade-100);
    }

    .smetio-information-text-reverse {
        color: var(--smetio-information-color-tint-100);
    }

    .smetio-information-icon {
        color: var(--smetio-information-color-shade-100);
    }

    .smetio-information-icon-interactive {
        color: var(--smetio-information-color-shade-100);
    }

    .smetio-information-icon-interactive:hover {
        color: var(--smetio-information-color-tint-100);
    }

    .smetio-information-icon-interactive:active {
        color: var(--smetio-information-color);
    }

    .smetio-information-border {
        border: solid 1px var(--smetio-information-color-shade-100);
    }

    /* dangerColor */
    .smetio-danger-background {
        background-color: var(--smetio-danger-color-tint-100);
        fill: var(--smetio-danger-color-tint-100);
    }

    .smetio-danger-background-reverse {
        background-color: var(--smetio-danger-color-shade-100);
        fill: var(--smetio-danger-color-shade-100);
    }

    .smetio-danger-text {
        color: var(--smetio-danger-color-shade-100);
    }

    .smetio-danger-text-reverse {
        color: var(--smetio-danger-color-tint-100);
    }

    .smetio-danger-icon {
        color: var(--smetio-danger-color-shade-100);
    }

    .smetio-danger-icon-interactive {
        color: var(--smetio-danger-color-shade-100);
    }

    .smetio-danger-icon-interactive:hover {
        color: var(--smetio-danger-color-tint-100);
    }

    .smetio-danger-icon-interactive:active {
        color: var(--smetio-danger-color);
    }

    .smetio-danger-border {
        border: solid 1px var(--smetio-danger-color-shade-100);
    }
}

html[data-theme="dark-100"] {

    /* accentColor */
    .smetio-accent-background {
        background-color: var(--smetio-accent-color-tint-100);
        fill: var(--smetio-accent-color-tint-100);
    }

    .smetio-accent-background-reverse {
        background-color: var(--smetio-accent-color-shade-100);
        fill: var(--smetio-accent-color-shade-100);
    }

    .smetio-accent-text {
        color: var(--smetio-accent-color-shade-100);
    }

    .smetio-accent-text-reverse {
        color: var(--smetio-accent-color-tint-100);
    }

    .smetio-accent-button {
        background-color: var(--smetio-accent-color-tint-100);
        fill: var(--smetio-accent-color-tint-100);
        color: var(--smetio-dominant-color-shade-100);
    }

    .smetio-accent-button:hover {
        background-color: var(--smetio-accent-color-shade-100);
        fill: var(--smetio-accent-color-shade-100);
        color: var(--smetio-dominant-color-tint-100);
    }

    .smetio-accent-button:active {
        background-color: var(--smetio-accent-color-tint-100);
        fill: var(--smetio-accent-color-tint-100);
        color: var(--smetio-dominant-color-shade-100);
    }

    .smetio-accent-button[data-smetio-status='active'] {
        background-color: var(--smetio-dominant-color-shade-100);
        fill: var(--smetio-dominant-color-shade-100);
        color: var(--smetio-accent-color-tint-100);
    }

    .smetio-accent-link {
        color: var(--smetio-accent-color-tint-100);
    }

    .smetio-accent-link-interactive {
        color: var(--smetio-accent-color-shade-100);
    }

    .smetio-accent-link-interactive:hover {
        color: var(--smetio-accent-color-tint-100);
    }

    .smetio-accent-link-interactive:active {
        color: var(--smetio-accent-color);
    }

    .smetio-accent-icon {
        color: var(--smetio-accent-color-tint-100);
    }

    .smetio-accent-icon-interactive {
        color: var(--smetio-accent-color-shade-100);
    }

    .smetio-accent-icon-interactive:hover {
        color: var(--smetio-accent-color-tint-100);
    }

    .smetio-accent-icon-interactive:active {
        color: var(--smetio-accent-color);
    }

    .smetio-accent-border {
        border: solid 1px var(--smetio-accent-color-tint-100);
    }

    .smetio-accent-border-interactive {
        border-color: var(--smetio-accent-color-shade-100);
        stroke: var(--smetio-accent-color-shade-100);
    }

    .smetio-accent-border-interactive:hover {
        border-color: solid 1px var(--smetio-accent-color-tint-100);
        stroke: solid 1px var(--smetio-accent-color-tint-100);
    }

    .smetio-accent-border-interactive:active {
        border-color: solid 1px var(--smetio-accent-color);
        stroke: solid 1px var(--smetio-accent-color);
    }

    .smetio-accent-border-interactive[data-smetio-status='active'] {
        border-color: solid 1px var(--smetio-accent-color);
        stroke: solid 1px var(--smetio-accent-color);
    }

    /* dominantColor */
    .smetio-dominant-background {
        background-color: var(--smetio-dominant-color-shade-100);
        fill: var(--smetio-dominant-color-shade-100);
    }

    .smetio-dominant-background-reverse {
        background-color: var(--smetio-dominant-color-tint-100);
        fill: var(--smetio-dominant-color-tint-100);
    }

    .smetio-dominant-text {
        color: var(--smetio-dominant-color-tint-100);
    }

    .smetio-dominant-text-reverse {
        color: var(--smetio-dominant-color-shade-100);
    }

    .smetio-dominant-border {
        border: solid 1px var(--smetio-dominant-color);
    }

    .smetio-dominant-border-interactive {
        border-color: var(--smetio-dominant-color);
        stroke: var(--smetio-dominant-color);
    }

    .smetio-dominant-border-interactive:hover {
        border-color: solid 1px var(--smetio-dominant-color-tint-100);
        stroke: solid 1px var(--smetio-dominant-color-tint-100);
    }

    .smetio-dominant-border-interactive:active {
        border-color: solid 1px var(--smetio-dominant-color);
        stroke: solid 1px var(--smetio-dominant-color);
    }

    .smetio-dominant-border-interactive[data-smetio-status='active'] {
        border-color: solid 1px var(--smetio-dominant-color);
        stroke: solid 1px var(--smetio-dominant-color);
    }

    /* neutralColor */
    .smetio-neutral-background {
        background-color: var(--smetio-neutral-color-shade-100);
        fill: var(--smetio-neutral-color-shade-100);
    }

    .smetio-neutral-background-reverse {
        background-color: var(--smetio-neutral-color-tint-100);
        fill: var(--smetio-neutral-color-tint-100);
    }

    .smetio-neutral-text {
        color: var(--smetio-neutral-color-tint-100);
    }

    .smetio-neutral-text-reverse {
        color: var(--smetio-neutral-color-shade-100);
    }

    .smetio-neutral-border {
        border: solid 1px var(--smetio-neutral-color);
        border-color: var(--smetio-neutral-color-tint-100);
        stroke: var(--smetio-neutral-color-tint-100);
    }

    /* greyColor */
    .smetio-grey-background {
        background-color: var(--smetio-grey-color);
        fill: var(--smetio-grey-color);
    }

    .smetio-grey-background-reverse {
        background-color: var(--smetio-grey-color-shade-100);
        fill: var(--smetio-grey-color-shade-100);
    }

    .smetio-grey-text {
        color: var(--smetio-grey-color-tint-100);
    }

    .smetio-grey-text-reverse {
        color: var(--smetio-grey-color-shade-100);
    }

    .smetio-grey-border {
        border: solid 1px var(--smetio-grey-color);
        border-color: var(--smetio-grey-color-tint-100);
        stroke: var(--smetio-grey-color-tint-100);
    }

    /* warningColor */
    .smetio-warning-background {
        background-color: var(--smetio-warning-color-shade-100);
        fill: var(--smetio-warning-color-shade-100);
    }

    .smetio-warning-background-reverse {
        background-color: var(--smetio-warning-color-tint-100);
        fill: var(--smetio-warning-color-tint-100);
    }

    .smetio-warning-text {
        color: var(--smetio-warning-color-tint-100);
    }

    .smetio-warning-text-reverse {
        color: var(--smetio-warning-color-shade-100);
    }

    .smetio-warning-icon {
        color: var(--smetio-warning-color-tint-100);
    }

    .smetio-warning-icon-interactive {
        color: var(--smetio-warning-color-tint-100);
    }

    .smetio-warning-icon-interactive:hover {
        color: var(--smetio-warning-color-shade-100);
    }

    .smetio-warning-icon-interactive:active {
        color: var(--smetio-warning-color);
    }

    .smetio-warning-border {
        border: solid 1px var(--smetio-warning-color-tint-100);
    }

    /* successColor */
    .smetio-success-background {
        background-color: var(--smetio-success-color-shade-100);
        fill: var(--smetio-success-color-shade-100);
    }

    .smetio-success-background-reverse {
        background-color: var(--smetio-success-color-tint-100);
        fill: var(--smetio-success-color-tint-100);
    }

    .smetio-success-text {
        color: var(--smetio-success-color-tint-100);
    }

    .smetio-success-text-reverse {
        color: var(--smetio-success-color-shade-100);
    }

    .smetio-success-icon {
        color: var(--smetio-success-color-tint-100);
    }

    .smetio-success-icon-interactive {
        color: var(--smetio-success-color-tint-100);
    }

    .smetio-success-icon-interactive:hover {
        color: var(--smetio-success-color-shade-100);
    }

    .smetio-success-icon-interactive:active {
        color: var(--smetio-success-color);
    }

    .smetio-success-border {
        border: solid 1px var(--smetio-success-color-tint-100);
    }

    /* informationColor */
    .smetio-information-background {
        background-color: var(--smetio-information-color-shade-100);
        fill: var(--smetio-information-color-shade-100);
    }

    .smetio-information-background-reverse {
        background-color: var(--smetio-information-color-tint-100);
        fill: var(--smetio-information-color-tint-100);
    }

    .smetio-information-text {
        color: var(--smetio-information-color-tint-100);
    }

    .smetio-information-text-reverse {
        color: var(--smetio-information-color-shade-100);
    }

    .smetio-information-icon {
        color: var(--smetio-information-color-tint-100);
    }

    .smetio-information-icon-interactive {
        color: var(--smetio-information-color-tint-100);
    }

    .smetio-information-icon-interactive:hover {
        color: var(--smetio-information-color-shade-100);
    }

    .smetio-information-icon-interactive:active {
        color: var(--smetio-information-color);
    }

    .smetio-information-border {
        border: solid 1px var(--smetio-information-color-tint-100);
    }

    /* dangerColor */
    .smetio-danger-background {
        background-color: var(--smetio-danger-color-shade-100);
        fill: var(--smetio-danger-color-shade-100);
    }

    .smetio-danger-background-reverse {
        background-color: var(--smetio-danger-color-tint-100);
        fill: var(--smetio-danger-color-tint-100);
    }

    .smetio-danger-text {
        color: var(--smetio-danger-color-tint-100);
    }

    .smetio-danger-text-reverse {
        color: var(--smetio-danger-color-shade-100);
    }

    .smetio-danger-icon {
        color: var(--smetio-danger-color-tint-100);
    }

    .smetio-danger-icon-interactive {
        color: var(--smetio-danger-color-tint-100);
    }

    .smetio-danger-icon-interactive:hover {
        color: var(--smetio-danger-color-shade-100);
    }

    .smetio-danger-icon-interactive:active {
        color: var(--smetio-danger-color);
    }

    .smetio-danger-border {
        border: solid 1px var(--smetio-danger-color-tint-100);
    }
}

html[data-theme="plain-light-100"] {

    /* accentColor */
    .smetio-accent-background {
        background-color: var(--smetio-dominant-color-rgba-10);
        fill: var(--smetio-dominant-color-rgba-10);
    }

    .smetio-accent-background-reverse {
        background-color: var(--smetio-dominant-color-rgba-10);
        fill: var(--smetio-dominant-color-rgba-10);
    }

    .smetio-accent-text {
        color: var(--smetio-accent-color-tint-100);
    }

    .smetio-accent-text-reverse {
        color: var(--smetio-accent-color-tint-100);
    }

    .smetio-accent-button {
        background-color: var(--smetio-accent-color-tint-100);
        fill: var(--smetio-accent-color-tint-100);
        color: var(--smetio-dominant-color-shade-100);
    }

    .smetio-accent-button:hover {
        color: var(--smetio-dominant-color-tint-100);
    }

    .smetio-accent-button:active {
        background-color: var(--smetio-accent-color-tint-100);
        fill: var(--smetio-accent-color-tint-100);
        color: var(--smetio-dominant-color-shade-100);
    }

    .smetio-accent-button[data-smetio-status='active'] {
        background-color: var(--smetio-dominant-color-tint-100);
        fill: var(--smetio-dominant-color-tint-100);
        color: var(--smetio-dominant-color-shade-100);
    }

    .smetio-accent-link {
        color: var(--smetio-accent-color-tint-100);
    }

    .smetio-accent-link-interactive {
        color: var(--smetio-accent-color-tint-100);
    }

    .smetio-accent-link-interactive:hover {
        color: var(--smetio-accent-color-shade-100);
    }

    .smetio-accent-link-interactive:active {
        color: var(--smetio-accent-color);
    }

    .smetio-accent-icon {
        color: var(--smetio-accent-color-tint-100);
    }

    .smetio-accent-icon-interactive {
        color: var(--smetio-accent-color-tint-100);
    }

    .smetio-accent-icon-interactive:hover {
        color: var(--smetio-accent-color-shade-100);
    }

    .smetio-accent-icon-interactive:active {
        color: var(--smetio-accent-color);
    }

    .smetio-accent-border {
        border: solid 1px var(--smetio-accent-color-tint-100);
    }

    .smetio-accent-border-interactive {
        border-color: var(--smetio-accent-color-tint-100);
        stroke: var(--smetio-accent-color-tint-100);
    }

    .smetio-accent-border-interactive:hover {
        border-color: solid 1px var(--smetio-accent-color-shade-100);
        stroke: solid 1px var(--smetio-accent-color-shade-100);
    }

    .smetio-accent-border-interactive:active {
        border-color: solid 1px var(--smetio-accent-color);
        stroke: solid 1px var(--smetio-accent-color);
    }

    .smetio-accent-border-interactive[data-smetio-status='active'] {
        border-color: solid 1px var(--smetio-accent-color);
        stroke: solid 1px var(--smetio-accent-color);
    }

    /* dominantColor */
    .smetio-dominant-background {
        background-color: var(--smetio-dominant-color-tint-100);
        fill: var(--smetio-dominant-color-tint-100);
    }

    .smetio-dominant-background-reverse {
        background-color: var(--smetio-dominant-color-shade-100);
        fill: var(--smetio-dominant-color-shade-100);
    }

    .smetio-dominant-text {
        color: var(--smetio-dominant-color-shade-100);
    }

    .smetio-dominant-text-reverse {
        color: var(--smetio-dominant-color-tint-100);
    }

    .smetio-dominant-border {
        border: solid 1px var(--smetio-dominant-color);
    }

    .smetio-dominant-border-interactive {
        border-color: var(--smetio-dominant-color);
        stroke: var(--smetio-dominant-color);
    }

    .smetio-dominant-border-interactive:hover {
        border-color: solid 1px var(--smetio-dominant-color-shade-100);
        stroke: solid 1px var(--smetio-dominant-color-shade-100);
    }

    .smetio-dominant-border-interactive:active {
        border-color: solid 1px var(--smetio-dominant-color);
        stroke: solid 1px var(--smetio-dominant-color);
    }

    .smetio-dominant-border-interactive[data-smetio-status='active'] {
        border-color: solid 1px var(--smetio-dominant-color);
        stroke: solid 1px var(--smetio-dominant-color);
    }

    /* neutralColor */
    .smetio-neutral-background {
        background-color: var(--smetio-neutral-color-tint-100);
        fill: var(--smetio-neutral-color-tint-100);
    }

    .smetio-neutral-background-reverse {
        background-color: var(--smetio-neutral-color-shade-100);
        fill: var(--smetio-neutral-color-shade-100);
    }

    .smetio-neutral-text {
        color: var(--smetio-neutral-color-shade-100);
    }

    .smetio-neutral-text-reverse {
        color: var(--smetio-neutral-color-tint-100);
    }

    /* greyColor */
    .smetio-grey-background {
        background-color: var(--smetio-grey-color);
        fill: var(--smetio-grey-color);
    }

    .smetio-grey-background-reverse {
        background-color: var(--smetio-grey-color-tint-100);
        fill: var(--smetio-grey-color-tint-100);
    }

    .smetio-grey-text {
        color: var(--smetio-grey-color-shade-100);
    }

    .smetio-grey-text-reverse {
        color: var(--smetio-grey-color-tint-100);
    }

    .smetio-grey-border {
        border: solid 1px var(--smetio-grey-color);
        border-color: var(--smetio-grey-color-shade-100);
        stroke: var(--smetio-grey-color-shade-100);
    }

    /* warningColor */
    .smetio-warning-background {
        background-color: var(--smetio-warning-color-tint-100);
        fill: var(--smetio-warning-color-tint-100);
    }

    .smetio-warning-background-reverse {
        background-color: var(--smetio-warning-color-shade-100);
        fill: var(--smetio-warning-color-shade-100);
    }

    .smetio-warning-text {
        color: var(--smetio-warning-color);
    }

    .smetio-warning-text-reverse {
        color: var(--smetio-warning-color-tint-100);
    }

    .smetio-warning-icon {
        color: var(--smetio-warning-color-shade-100);
    }

    .smetio-warning-icon-interactive {
        color: var(--smetio-warning-color-shade-100);
    }

    .smetio-warning-icon-interactive:hover {
        color: var(--smetio-warning-color-tint-100);
    }

    .smetio-warning-icon-interactive:active {
        color: var(--smetio-warning-color);
    }

    .smetio-warning-border {
        border: solid 1px var(--smetio-warning-color);
    }

    /* successColor */
    .smetio-success-background {
        background-color: var(--smetio-success-color-tint-100);
        fill: var(--smetio-success-color-tint-100);
    }

    .smetio-success-background-reverse {
        background-color: var(--smetio-success-color-shade-100);
        fill: var(--smetio-success-color-shade-100);
    }

    .smetio-success-text {
        color: var(--smetio-success-color);
    }

    .smetio-success-text-reverse {
        color: var(--smetio-success-color-tint-100);
    }

    .smetio-success-icon {
        color: var(--smetio-success-color-shade-100);
    }

    .smetio-success-icon-interactive {
        color: var(--smetio-success-color-shade-100);
    }

    .smetio-success-icon-interactive:hover {
        color: var(--smetio-success-color-tint-100);
    }

    .smetio-success-icon-interactive:active {
        color: var(--smetio-success-color);
    }

    .smetio-success-border {
        border: solid 1px var(--smetio-success-color);
    }

    /* informationColor */
    .smetio-information-background {
        background-color: var(--smetio-information-color-tint-100);
        fill: var(--smetio-information-color-tint-100);
    }

    .smetio-information-background-reverse {
        background-color: var(--smetio-information-color-shade-100);
        fill: var(--smetio-information-color-shade-100);
    }

    .smetio-information-text {
        color: var(--smetio-information-color);
    }

    .smetio-information-text-reverse {
        color: var(--smetio-information-color-tint-100);
    }

    .smetio-information-icon {
        color: var(--smetio-information-color-shade-100);
    }

    .smetio-information-icon-interactive {
        color: var(--smetio-information-color-shade-100);
    }

    .smetio-information-icon-interactive:hover {
        color: var(--smetio-information-color-tint-100);
    }

    .smetio-information-icon-interactive:active {
        color: var(--smetio-information-color);
    }

    .smetio-information-border {
        border: solid 1px var(--smetio-information-color);
    }

    /* dangerColor */
    .smetio-danger-background {
        background-color: var(--smetio-danger-color-tint-100);
        fill: var(--smetio-danger-color-tint-100);
    }

    .smetio-danger-background-reverse {
        background-color: var(--smetio-danger-color-shade-100);
        fill: var(--smetio-danger-color-shade-100);
    }

    .smetio-danger-text {
        color: var(--smetio-danger-color);
    }

    .smetio-danger-text-reverse {
        color: var(--smetio-danger-color-tint-100);
    }

    .smetio-danger-icon {
        color: var(--smetio-danger-color-shade-100);
    }

    .smetio-danger-icon-interactive {
        color: var(--smetio-danger-color-shade-100);
    }

    .smetio-danger-icon-interactive:hover {
        color: var(--smetio-danger-color-tint-100);
    }

    .smetio-danger-icon-interactive:active {
        color: var(--smetio-danger-color);
    }

    .smetio-danger-border {
        border: solid 1px var(--smetio-danger-color);
    }
}

html[data-theme="plain-dark-100"] {

    /* accentColor */
    .smetio-accent-background {
        background-color: var(--smetio-dominant-color-rgba-10);
        fill: var(--smetio-dominant-color-rgba-10);
    }

    .smetio-accent-background-reverse {
        background-color: var(--smetio-dominant-color-rgba-10);
        fill: var(--smetio-dominant-color-rgba-10);
    }

    .smetio-accent-text {
        color: var(--smetio-accent-color-tint-100);
    }

    .smetio-accent-text-reverse {
        color: var(--smetio-accent-color-tint-100);
    }

    .smetio-accent-button {
        background-color: var(--smetio-accent-color-tint-100);
        fill: var(--smetio-accent-color-tint-100);
        color: var(--smetio-dominant-color-shade-100);
    }

    .smetio-accent-button:hover {
        color: var(--smetio-dominant-color-tint-100);
    }

    .smetio-accent-button:active {
        background-color: var(--smetio-accent-color-tint-100);
        fill: var(--smetio-accent-color-tint-100);
        color: var(--smetio-dominant-color-shade-100);
    }

    .smetio-accent-button[data-smetio-status='active'] {
        background-color: var(--smetio-dominant-color-shade-100);
        fill: var(--smetio-dominant-color-shade-100);
        color: var(--smetio-dominant-color-tint-100);
    }

    .smetio-accent-link {
        color: var(--smetio-accent-color-tint-100);
    }

    .smetio-accent-link-interactive {
        color: var(--smetio-accent-color-tint-100);
    }

    .smetio-accent-link-interactive:hover {
        color: var(--smetio-accent-color-shade-100);
    }

    .smetio-accent-link-interactive:active {
        color: var(--smetio-accent-color);
    }

    .smetio-accent-icon {
        color: var(--smetio-accent-color-tint-100);
    }

    .smetio-accent-icon-interactive {
        color: var(--smetio-accent-color-tint-100);
    }

    .smetio-accent-icon-interactive:hover {
        color: var(--smetio-accent-color-shade-100);
    }

    .smetio-accent-icon-interactive:active {
        color: var(--smetio-accent-color);
    }

    .smetio-accent-border {
        border: solid 1px var(--smetio-accent-color-tint-100);
    }

    .smetio-accent-border-interactive {
        border-color: var(--smetio-accent-color-tint-100);
        stroke: var(--smetio-accent-color-tint-100);
    }

    .smetio-accent-border-interactive:hover {
        border-color: solid 1px var(--smetio-accent-color-shade-100);
        stroke: solid 1px var(--smetio-accent-color-shade-100);
    }

    .smetio-accent-border-interactive:active {
        border-color: solid 1px var(--smetio-accent-color);
        stroke: solid 1px var(--smetio-accent-color);
    }

    .smetio-accent-border-interactive[data-smetio-status='active'] {
        border-color: solid 1px var(--smetio-accent-color);
        stroke: solid 1px var(--smetio-accent-color);
    }

    /* dominantColor */
    .smetio-dominant-background {
        background-color: var(--smetio-dominant-color-shade-100);
        fill: var(--smetio-dominant-color-shade-100);
    }

    .smetio-dominant-background-reverse {
        background-color: var(--smetio-dominant-color-tint-100);
        fill: var(--smetio-dominant-color-tint-100);
    }

    .smetio-dominant-text {
        color: var(--smetio-dominant-color-tint-100);
    }

    .smetio-dominant-text-reverse {
        color: var(--smetio-dominant-color-shade-100);
    }

    .smetio-dominant-border {
        border: solid 1px var(--smetio-dominant-color);
    }

    .smetio-dominant-border-interactive {
        border-color: var(--smetio-dominant-color);
        stroke: var(--smetio-dominant-color);
    }

    .smetio-dominant-border-interactive:hover {
        border-color: solid 1px var(--smetio-dominant-color-tint-100);
        stroke: solid 1px var(--smetio-dominant-color-tint-100);
    }

    .smetio-dominant-border-interactive:active {
        border-color: solid 1px var(--smetio-dominant-color);
        stroke: solid 1px var(--smetio-dominant-color);
    }

    .smetio-dominant-border-interactive[data-smetio-status='active'] {
        border-color: solid 1px var(--smetio-dominant-color);
        stroke: solid 1px var(--smetio-dominant-color);
    }

    /* neutralColor */
    .smetio-neutral-background {
        background-color: var(--smetio-neutral-color-shade-100);
        fill: var(--smetio-neutral-color-shade-100);
    }

    .smetio-neutral-background-reverse {
        background-color: var(--smetio-neutral-color-tint-100);
        fill: var(--smetio-neutral-color-tint-100);
    }

    .smetio-neutral-text {
        color: var(--smetio-neutral-color-tint-100);
    }

    .smetio-neutral-text-reverse {
        color: var(--smetio-neutral-color-shade-100);
    }

    /* greyColor */
    .smetio-grey-background {
        background-color: var(--smetio-grey-color);
        fill: var(--smetio-grey-color);
    }

    .smetio-grey-background-reverse {
        background-color: var(--smetio-grey-color-shade-100);
        fill: var(--smetio-grey-color-shade-100);
    }

    .smetio-grey-text {
        color: var(--smetio-grey-color-tint-100);
    }

    .smetio-grey-text-reverse {
        color: var(--smetio-grey-color-shade-100);
    }

    .smetio-grey-border {
        border: solid 1px var(--smetio-grey-color);
        border-color: var(--smetio-grey-color-tint-100);
        stroke: var(--smetio-grey-color-tint-100);
    }

    /* warningColor */
    .smetio-warning-background {
        background-color: var(--smetio-warning-color-shade-100);
        fill: var(--smetio-warning-color-shade-100);
    }

    .smetio-warning-background-reverse {
        background-color: var(--smetio-warning-color-tint-100);
        fill: var(--smetio-warning-color-tint-100);
    }

    .smetio-warning-text {
        color: var(--smetio-warning-color);
    }

    .smetio-warning-text-reverse {
        color: var(--smetio-warning-color-shade-100);
    }

    .smetio-warning-icon {
        color: var(--smetio-warning-color-tint-100);
    }

    .smetio-warning-icon-interactive {
        color: var(--smetio-warning-color-tint-100);
    }

    .smetio-warning-icon-interactive:hover {
        color: var(--smetio-warning-color-shade-100);
    }

    .smetio-warning-icon-interactive:active {
        color: var(--smetio-warning-color);
    }

    .smetio-warning-border {
        border: solid 1px var(--smetio-warning-color);
    }

    /* successColor */
    .smetio-success-background {
        background-color: var(--smetio-success-color-shade-100);
        fill: var(--smetio-success-color-shade-100);
    }

    .smetio-success-background-reverse {
        background-color: var(--smetio-success-color-tint-100);
        fill: var(--smetio-success-color-tint-100);
    }

    .smetio-success-text {
        color: var(--smetio-success-color);
    }

    .smetio-success-text-reverse {
        color: var(--smetio-success-color-shade-100);
    }

    .smetio-success-icon {
        color: var(--smetio-success-color-tint-100);
    }

    .smetio-success-icon-interactive {
        color: var(--smetio-success-color-tint-100);
    }

    .smetio-success-icon-interactive:hover {
        color: var(--smetio-success-color-shade-100);
    }

    .smetio-success-icon-interactive:active {
        color: var(--smetio-success-color);
    }

    .smetio-success-border {
        border: solid 1px var(--smetio-success-color);
    }

    /* informationColor */
    .smetio-information-background {
        background-color: var(--smetio-information-color-shade-100);
        fill: var(--smetio-information-color-shade-100);
    }

    .smetio-information-background-reverse {
        background-color: var(--smetio-information-color-tint-100);
        fill: var(--smetio-information-color-tint-100);
    }

    .smetio-information-text {
        color: var(--smetio-information-color);
    }

    .smetio-information-text-reverse {
        color: var(--smetio-information-color-shade-100);
    }

    .smetio-information-icon {
        color: var(--smetio-information-color-tint-100);
    }

    .smetio-information-icon-interactive {
        color: var(--smetio-information-color-tint-100);
    }

    .smetio-information-icon-interactive:hover {
        color: var(--smetio-information-color-shade-100);
    }

    .smetio-information-icon-interactive:active {
        color: var(--smetio-information-color);
    }

    .smetio-information-border {
        border: solid 1px var(--smetio-information-color);
    }

    /* dangerColor */
    .smetio-danger-background {
        background-color: var(--smetio-danger-color-shade-100);
        fill: var(--smetio-danger-color-shade-100);
    }

    .smetio-danger-background-reverse {
        background-color: var(--smetio-danger-color-tint-100);
        fill: var(--smetio-danger-color-tint-100);
    }

    .smetio-danger-text {
        color: var(--smetio-danger-color);
    }

    .smetio-danger-text-reverse {
        color: var(--smetio-danger-color-shade-100);
    }

    .smetio-danger-icon {
        color: var(--smetio-danger-color-tint-100);
    }

    .smetio-danger-icon-interactive {
        color: var(--smetio-danger-color-tint-100);
    }

    .smetio-danger-icon-interactive:hover {
        color: var(--smetio-danger-color-shade-100);
    }

    .smetio-danger-icon-interactive:active {
        color: var(--smetio-danger-color);
    }

    .smetio-danger-border {
        border: solid 1px var(--smetio-danger-color);
    }
}

html[data-theme="true-brand-light-100"] {

    /* accentColor */
    .smetio-accent-background {
        background-color: var(--smetio-accent-color);
        fill: var(--smetio-accent-color);
    }

    .smetio-accent-background-reverse {
        background-color: var(--smetio-accent-color-shade-100);
        fill: var(--smetio-accent-color-shade-100);
    }

    .smetio-accent-text {
        color: var(--smetio-accent-color);
    }

    .smetio-accent-text-reverse {
        color: var(--smetio-accent-color-shade-100);
    }

    .smetio-accent-button {
        background-color: var(--smetio-accent-color);
        fill: var(--smetio-accent-color);
        color: var(--smetio-accent-color-shade-100);
    }

    .smetio-accent-button:hover {
        background-color: var(--smetio-accent-color-shade-100);
        fill: var(--smetio-accent-color-shade-100);
        color: var(--smetio-accent-color-tint-100);
    }

    .smetio-accent-button:active {
        background-color: var(--smetio-accent-color-tint-100);
        fill: var(--smetio-accent-color-tint-100);
        color: var(--smetio-accent-color-shade-100);
    }

    .smetio-accent-button[data-smetio-status='active'] {
        background-color: var(--smetio-accent-color-tint-100);
        fill: var(--smetio-accent-color-tint-100);
        color: var(--smetio-accent-color-shade-100);
    }

    .smetio-accent-link {
        color: var(--smetio-accent-color);
    }

    .smetio-accent-link-interactive {
        color: var(--smetio-accent-color);
    }

    .smetio-accent-link-interactive:hover {
        color: var(--smetio-accent-color-shade-100);
    }

    .smetio-accent-link-interactive:active {
        color: var(--smetio-accent-color-shade-100);
    }

    .smetio-accent-icon {
        color: var(--smetio-accent-color);
    }

    .smetio-accent-icon-interactive {
        color: var(--smetio-accent-color);
    }

    .smetio-accent-icon-interactive:hover {
        color: var(--smetio-accent-color-shade-100);
    }

    .smetio-accent-icon-interactive:active {
        color: var(--smetio-accent-color-shade-100);
    }

    .smetio-accent-border {
        border: solid 1px var(--smetio-accent-color);
    }

    .smetio-accent-border-interactive {
        border-color: var(--smetio-accent-color);
        stroke: var(--smetio-accent-color);
    }

    .smetio-accent-border-interactive:hover {
        border-color: var(--smetio-accent-color-shade-100);
        stroke: var(--smetio-accent-color-shade-100);
    }

    .smetio-accent-border-interactive:active {
        border-color: solid 1px var(--smetio-accent-color);
        stroke: solid 1px var(--smetio-accent-color);
    }

    .smetio-accent-border-interactive[data-smetio-status='active'] {
        border-color: solid 1px var(--smetio-accent-color);
        stroke: solid 1px var(--smetio-accent-color);
    }

    /* dominantColor */
    .smetio-dominant-background {
        background-color: var(--smetio-dominant-color);
        fill: var(--smetio-dominant-color);
    }

    .smetio-dominant-background-reverse {
        background-color: var(--smetio-dominant-color-shade-100);
        fill: var(--smetio-dominant-color-shade-100);
    }

    .smetio-dominant-text {
        color: var(--smetio-dominant-color);
    }

    .smetio-dominant-text-reverse {
        color: var(--smetio-dominant-color-shade-100);
    }

    .smetio-dominant-border {
        border: solid 1px var(--smetio-dominant-color);
    }

    .smetio-dominant-border-interactive {
        border-color: var(--smetio-dominant-color);
        stroke: var(--smetio-dominant-color);
    }

    .smetio-dominant-border-interactive:hover {
        border-color: var(--smetio-dominant-color-shade-100);
        stroke: var(--smetio-dominant-color-shade-100);
    }

    .smetio-dominant-border-interactive:active {
        border-color: var(--smetio-dominant-color-tint-100);
        stroke: var(--smetio-dominant-color-tint-100);
    }

    .smetio-dominant-border-interactive[data-smetio-status='active'] {
        border-color: var(--smetio-dominant-color-tint-100);
        stroke: var(--smetio-dominant-color-tint-100);
    }

    /* neutralColor */
    .smetio-neutral-background {
        background-color: var(--smetio-neutral-color);
        fill: var(--smetio-neutral-color);
    }

    .smetio-neutral-background-reverse {
        background-color: var(--smetio-neutral-color-tint-100);
        fill: var(--smetio-neutral-color-tint-100);
    }

    .smetio-neutral-text {
        color: var(--smetio-neutral-color-shade-100);
    }

    .smetio-neutral-text-reverse {
        color: var(--smetio-neutral-color-tint-100);
    }

    .smetio-neutral-border {
        border: solid 1px;
        border-color: var(--smetio-neutral-color-shade-100);
        stroke: var(--smetio-neutral-color-shade-100);
    }

    /* greyColor */
    .smetio-grey-background {
        background-color: var(--smetio-grey-color);
        fill: var(--smetio-grey-color);
    }

    .smetio-grey-background-reverse {
        background-color: var(--smetio-grey-color-tint-100);
        fill: var(--smetio-grey-color-tint-100);
    }

    .smetio-grey-text {
        color: var(--smetio-grey-color-shade-100);
    }

    .smetio-grey-text-reverse {
        color: var(--smetio-grey-color-tint-100);
    }

    .smetio-grey-border {
        border: solid 1px var(--smetio-grey-color);
        border-color: var(--smetio-grey-color-shade-100);
        stroke: var(--smetio-grey-color-shade-100);
    }

    /* warningColor */
    .smetio-warning-background {
        background-color: var(--smetio-warning-color);
        fill: var(--smetio-warning-color);
    }

    .smetio-warning-background-reverse {
        background-color: var(--smetio-warning-color-shade-100);
        fill: var(--smetio-warning-color-shade-100);
    }

    .smetio-warning-text {
        color: var(--smetio-warning-color);
    }

    .smetio-warning-text-reverse {
        color: var(--smetio-warning-color-shade-100);
    }

    .smetio-warning-icon {
        color: var(--smetio-warning-color);
    }

    .smetio-warning-icon-interactive {
        color: var(--smetio-warning-color-shade-100);
    }

    .smetio-warning-icon-interactive:hover {
        color: var(--smetio-warning-color-shade-100);
    }

    .smetio-warning-icon-interactive:active {
        color: var(--smetio-warning-color-tint-100);
    }

    .smetio-warning-border {
        border: solid 1px var(--smetio-warning-color);
    }

    /* successColor */
    .smetio-success-background {
        background-color: var(--smetio-success-color);
        fill: var(--smetio-success-color);
    }

    .smetio-success-background-reverse {
        background-color: var(--smetio-success-color-shade-100);
        fill: var(--smetio-success-color-shade-100);
    }

    .smetio-success-text {
        color: var(--smetio-success-color);
    }

    .smetio-success-text-reverse {
        color: var(--smetio-success-color-shade-100);
    }

    .smetio-success-icon {
        color: var(--smetio-success-color);
    }

    .smetio-success-icon-interactive {
        color: var(--smetio-success-color);
    }

    .smetio-success-icon-interactive:hover {
        color: var(--smetio-success-color-shade-100);
    }

    .smetio-success-icon-interactive:active {
        color: var(--smetio-success-color-tint-100);
    }

    .smetio-success-border {
        border: solid 1px var(--smetio-success-color);
        border-color: var(--smetio-success-color);
        stroke: var(--smetio-success-color);
    }

    /* informationColor */
    .smetio-information-background {
        background-color: var(--smetio-information-color);
        fill: var(--smetio-information-color);
    }

    .smetio-information-background-reverse {
        background-color: var(--smetio-information-color-shade-100);
        fill: var(--smetio-information-color-shade-100);
    }

    .smetio-information-text {
        color: var(--smetio-information-color);
    }

    .smetio-information-text-reverse {
        color: var(--smetio-information-color-shade-100);
    }

    .smetio-information-icon {
        color: var(--smetio-information-color);
    }

    .smetio-information-icon-interactive {
        color: var(--smetio-information-color);
    }

    .smetio-information-icon-interactive:hover {
        color: var(--smetio-information-color-shade-100);
    }

    .smetio-information-icon-interactive:active {
        color: var(--smetio-information-color-tint-100);
    }

    .smetio-information-border {
        border: solid 1px var(--smetio-information-color);
        border-color: var(--smetio-information-color);
        stroke: var(--smetio-information-color);
    }

    /* dangerColor */
    .smetio-danger-background {
        background-color: var(--smetio-danger-color);
        fill: var(--smetio-danger-color);
    }

    .smetio-danger-background-reverse {
        background-color: var(--smetio-danger-color-shade-100);
        fill: var(--smetio-danger-color-shade-100);
    }

    .smetio-danger-text {
        color: var(--smetio-danger-color);
    }

    .smetio-danger-text-reverse {
        color: var(--smetio-danger-color-shade-100);
    }

    .smetio-danger-icon {
        color: var(--smetio-danger-color);
    }

    .smetio-danger-icon-interactive {
        color: var(--smetio-danger-color);
    }

    .smetio-danger-icon-interactive:hover {
        color: var(--smetio-danger-color-shade-100);
    }

    .smetio-danger-icon-interactive:active {
        color: var(--smetio-danger-color-tint-100);
    }

    .smetio-danger-border {
        border: solid 1px var(--smetio-danger-color);
        border-color: var(--smetio-danger-color);
        stroke: var(--smetio-danger-color);
    }
}

html[data-theme="true-brand-dark-100"] {

    /* accentColor */
    .smetio-accent-background {
        background-color: var(--smetio-accent-color-shade-100);
        fill: var(--smetio-accent-color-shade-100);
    }

    .smetio-accent-background-reverse {
        background-color: var(--smetio-accent-color-tint-100);
        fill: var(--smetio-accent-color-tint-100);
    }

    .smetio-accent-text {
        color: var(--smetio-accent-color-shade-100);
    }

    .smetio-accent-text-reverse {
        color: var(--smetio-accent-color-tint-100);
    }

    .smetio-accent-button {
        background-color: var(--smetio-accent-color-shade-100);
        fill: var(--smetio-accent-color-shade-100);
        color: var(--smetio-accent-color-tint-100);
    }

    .smetio-accent-button:hover {
        background-color: var(--smetio-accent-color-tint-100);
        fill: var(--smetio-accent-color-tint-100);
        color: var(--smetio-accent-color-shade-100);
    }

    .smetio-accent-button:active {
        background-color: var(--smetio-accent-color-shade-100);
        fill: var(--smetio-accent-color-shade-100);
        color: var(--smetio-accent-color-tint-100);
    }

    .smetio-accent-button[data-smetio-status='active'] {
        background-color: var(--smetio-accent-color-shade-100);
        fill: var(--smetio-accent-color-shade-100);
        color: var(--smetio-accent-color-tint-100);
    }

    .smetio-accent-link {
        color: var(--smetio-accent-color-shade-100);
    }

    .smetio-accent-link-interactive {
        color: var(--smetio-accent-color-shade-100);
    }

    .smetio-accent-link-interactive:hover {
        color: var(--smetio-accent-color-tint-100);
    }

    .smetio-accent-link-interactive:active {
        color: var(--smetio-accent-color-tint-100);
    }

    .smetio-accent-icon {
        color: var(--smetio-accent-color-shade-100);
    }

    .smetio-accent-icon-interactive {
        color: var(--smetio-accent-color-shade-100);
    }

    .smetio-accent-icon-interactive:hover {
        color: var(--smetio-accent-color-tint-100);
    }

    .smetio-accent-icon-interactive:active {
        color: var(--smetio-accent-color-tint-100);
    }

    .smetio-accent-border {
        border: solid 1px var(--smetio-accent-color);
    }

    .smetio-accent-border-interactive {
        border-color: var(--smetio-accent-color);
        stroke: var(--smetio-accent-color);
    }

    .smetio-accent-border-interactive:hover {
        border-color: var(--smetio-accent-color-tint-100);
        stroke: var(--smetio-accent-color-tint-100);
    }

    .smetio-accent-border-interactive:active {
        border-color: solid 1px var(--smetio-accent-color);
        stroke: solid 1px var(--smetio-accent-color);
    }

    .smetio-accent-border-interactive[data-smetio-status='active'] {
        border-color: solid 1px var(--smetio-accent-color);
        stroke: solid 1px var(--smetio-accent-color);
    }

    /* dominantColor */
    .smetio-dominant-background {
        background-color: var(--smetio-dominant-color-shade-100);
        fill: var(--smetio-dominant-color-shade-100);
    }

    .smetio-dominant-background-reverse {
        background-color: var(--smetio-dominant-color-tint-100);
        fill: var(--smetio-dominant-color-tint-100);
    }

    .smetio-dominant-text {
        color: var(--smetio-dominant-color-shade-100);
    }

    .smetio-dominant-text-reverse {
        color: var(--smetio-dominant-color-tint-100);
    }

    .smetio-dominant-border {
        border: solid 1px var(--smetio-dominant-color);
    }

    .smetio-dominant-border-interactive {
        border-color: var(--smetio-dominant-color-shade-100);
        stroke: var(--smetio-dominant-color-shade-100);
    }

    .smetio-dominant-border-interactive:hover {
        border-color: var(--smetio-dominant-color-tint-100);
        stroke: var(--smetio-dominant-color-tint-100);
    }

    .smetio-dominant-border-interactive:active {
        border-color: var(--smetio-dominant-color-shade-100);
        stroke: var(--smetio-dominant-color-shade-100);
    }

    .smetio-dominant-border-interactive[data-smetio-status='active'] {
        border-color: var(--smetio-dominant-color-shade-100);
        stroke: var(--smetio-dominant-color-shade-100);
    }

    /* neutralColor */
    .smetio-neutral-background {
        background-color: var(--smetio-neutral-color-shade-100);
        fill: var(--smetio-neutral-color-shade-100);
    }

    .smetio-neutral-background-reverse {
        background-color: var(--smetio-neutral-color-shade-100);
        fill: var(--smetio-neutral-color-shade-100);
    }

    .smetio-neutral-text {
        color: var(--smetio-neutral-color-tint-100);
    }

    .smetio-neutral-text-reverse {
        color: var(--smetio-neutral-color-shade-100);
    }

    .smetio-neutral-border {
        border: solid 1px;
        border-color: var(--smetio-neutral-color-tint-100);
        stroke: var(--smetio-neutral-color-tint-100);
    }

    /* greyColor */
    .smetio-grey-background {
        background-color: var(--smetio-grey-color);
        fill: var(--smetio-grey-color);
    }

    .smetio-grey-background-reverse {
        background-color: var(--smetio-grey-color-shade-100);
        fill: var(--smetio-grey-color-shade-100);
    }

    .smetio-grey-text {
        color: var(--smetio-grey-color-tint-100);
    }

    .smetio-grey-text-reverse {
        color: var(--smetio-grey-color-shade-100);
    }

    .smetio-grey-border {
        border: solid 1px var(--smetio-grey-color);
        border-color: var(--smetio-grey-color-tint-100);
        stroke: var(--smetio-grey-color-tint-100);
    }

    /* warningColor */
    .smetio-warning-background {
        background-color: var(--smetio-warning-color-shade-100);
        fill: var(--smetio-warning-color-shade-100);
    }

    .smetio-warning-background-reverse {
        background-color: var(--smetio-warning-color-tint-100);
        fill: var(--smetio-warning-color-tint-100);
    }

    .smetio-warning-text {
        color: var(--smetio-warning-color-tint-100);
    }

    .smetio-warning-text-reverse {
        color: var(--smetio-warning-color);
    }

    .smetio-warning-icon {
        color: var(--smetio-warning-color-shade-100);
    }

    .smetio-warning-icon-interactive {
        color: var(--smetio-warning-color-tint-100);
    }

    .smetio-warning-icon-interactive:hover {
        color: var(--smetio-warning-color-tint-100);
    }

    .smetio-warning-icon-interactive:active {
        color: var(--smetio-warning-color-shade-100);
    }

    .smetio-warning-border {
        border: solid 1px var(--smetio-warning-color);
    }

    /* successColor */
    .smetio-success-background {
        background-color: var(--smetio-success-color-shade-100);
        fill: var(--smetio-success-color-shade-100);
    }

    .smetio-success-background-reverse {
        background-color: var(--smetio-success-color-tint-100);
        fill: var(--smetio-success-color-tint-100);
    }

    .smetio-success-text {
        color: var(--smetio-success-color-tint-100);
    }

    .smetio-success-text-reverse {
        color: var(--smetio-success-color);
    }

    .smetio-success-icon {
        color: var(--smetio-success-color-tint-100);
    }

    .smetio-success-icon-interactive {
        color: var(--smetio-success-color-tint-100);
    }

    .smetio-success-icon-interactive:hover {
        color: var(--smetio-success-color-tint-100);
    }

    .smetio-success-icon-interactive:active {
        color: var(--smetio-success-color-shade-100);
    }

    .smetio-success-border {
        border: solid 1px var(--smetio-success-color);
        border-color: var(--smetio-success-color-tint-100);
        stroke: var(--smetio-success-color-tint-100);
    }

    /* informationColor */
    .smetio-information-background {
        background-color: var(--smetio-information-color-shade-100);
        fill: var(--smetio-information-color-shade-100);
    }

    .smetio-information-background-reverse {
        background-color: var(--smetio-information-color-tint-100);
        fill: var(--smetio-information-color-tint-100);
    }

    .smetio-information-text {
        color: var(--smetio-information-color-tint-100);
    }

    .smetio-information-text-reverse {
        color: var(--smetio-information-color);
    }

    .smetio-information-icon {
        color: var(--smetio-information-color-tint-100);
    }

    .smetio-information-icon-interactive {
        color: var(--smetio-information-color-tint-100);
    }

    .smetio-information-icon-interactive:hover {
        color: var(--smetio-information-color-tint-100);
    }

    .smetio-information-icon-interactive:active {
        color: var(--smetio-information-color-shade-100);
    }

    .smetio-information-border {
        border: solid 1px var(--smetio-information-color);
        border-color: var(--smetio-information-color-tint-100);
        stroke: var(--smetio-information-color-tint-100);
    }

    /* dangerColor */
    .smetio-danger-background {
        background-color: var(--smetio-danger-color-shade-100);
        fill: var(--smetio-danger-color-shade-100);
    }

    .smetio-danger-background-reverse {
        background-color: var(--smetio-danger-color-tint-100);
        fill: var(--smetio-danger-color-tint-100);
    }

    .smetio-danger-text {
        color: var(--smetio-danger-color-tint-100);
    }

    .smetio-danger-text-reverse {
        color: var(--smetio-danger-color);
    }

    .smetio-danger-icon {
        color: var(--smetio-danger-color-tint-100);
    }

    .smetio-danger-icon-interactive {
        color: var(--smetio-danger-color-tint-100);
    }

    .smetio-danger-icon-interactive:hover {
        color: var(--smetio-danger-color-tint-100);
    }

    .smetio-danger-icon-interactive:active {
        color: var(--smetio-danger-color-shade-100);
    }

    .smetio-danger-border {
        border: solid 1px var(--smetio-danger-color);
        border-color: var(--smetio-danger-color-tint-100);
        stroke: var(--smetio-danger-color-tint-100);
    }
}

html[data-theme="trueBrand-light-100"] {

    /* accentColor */
    .smetio-accent-background {
        background-color: var(--smetio-accent-color-shade-100);
        fill: var(--smetio-accent-color-shade-100);
    }

    .smetio-accent-background-reverse {
        background-color: var(--smetio-accent-color-tint-100);
        fill: var(--smetio-accent-color-tint-100);
    }

    .smetio-accent-text {
        color: var(--smetio-accent-color-tint-100);
    }

    .smetio-accent-text-reverse {
        color: var(--smetio-accent-color-shade-100);
    }

    .smetio-accent-button {
        background-color: var(--smetio-accent-color-shade-100);
        fill: var(--smetio-accent-color-shade-100);
        color: var(--smetio-dominant-color-tint-100);
    }

    .smetio-accent-button:hover {
        background-color: var(--smetio-accent-color-tint-100);
        fill: var(--smetio-accent-color-tint-100);
        color: var(--smetio-dominant-color-shade-100);
    }

    .smetio-accent-button:active {
        background-color: var(--smetio-accent-color-shade-100);
        fill: var(--smetio-accent-color-shade-100);
        color: var(--smetio-dominant-color-tint-100);
    }

    .smetio-accent-button[data-smetio-status='active'] {
        background-color: var(--smetio-dominant-color-tint-100);
        fill: var(--smetio-dominant-color-tint-100);
        color: var(--smetio-accent-color-shade-100);
    }

    .smetio-accent-link {
        color: var(--smetio-accent-color-shade-100);
    }

    .smetio-accent-link-interactive {
        color: var(--smetio-accent-color-tint-100);
    }

    .smetio-accent-link-interactive:hover {
        color: var(--smetio-accent-color-shade-100);
    }

    .smetio-accent-link-interactive:active {
        color: var(--smetio-accent-color);
    }

    .smetio-accent-icon {
        color: var(--smetio-accent-color-shade-100);
    }

    .smetio-accent-icon-interactive {
        color: var(--smetio-accent-color-tint-100);
    }

    .smetio-accent-icon-interactive:hover {
        color: var(--smetio-accent-color-shade-100);
    }

    .smetio-accent-icon-interactive:active {
        color: var(--smetio-accent-color);
    }

    .smetio-accent-border {
        border: solid 1px var(--smetio-accent-color-shade-100);
    }

    .smetio-accent-border-interactive {
        border-color: var(--smetio-accent-color-tint-100);
        stroke: var(--smetio-accent-color-tint-100);
    }

    .smetio-accent-border-interactive:hover {
        border-color: solid 1px var(--smetio-accent-color-shade-100);
        stroke: solid 1px var(--smetio-accent-color-shade-100);
    }

    .smetio-accent-border-interactive:active {
        border-color: solid 1px var(--smetio-accent-color);
        stroke: solid 1px var(--smetio-accent-color);
    }

    .smetio-accent-border-interactive[data-smetio-status='active'] {
        border-color: solid 1px var(--smetio-accent-color);
        stroke: solid 1px var(--smetio-accent-color);
    }

    /* dominantColor */
    .smetio-dominant-background {
        background-color: var(--smetio-dominant-color-tint-100);
        fill: var(--smetio-dominant-color-tint-100);
    }

    .smetio-dominant-background-reverse {
        background-color: var(--smetio-dominant-color-shade-100);
        fill: var(--smetio-dominant-color-shade-100);
    }

    .smetio-dominant-text {
        color: var(--smetio-dominant-color-shade-100);
    }

    .smetio-dominant-text-reverse {
        color: var(--smetio-dominant-color-tint-100);
    }

    .smetio-dominant-border {
        border: solid 1px var(--smetio-dominant-color);
    }

    .smetio-dominant-border-interactive {
        border-color: var(--smetio-dominant-color);
        stroke: var(--smetio-dominant-color);
    }

    .smetio-dominant-border-interactive:hover {
        border-color: solid 1px var(--smetio-dominant-color-shade-100);
        stroke: solid 1px var(--smetio-dominant-color-shade-100);
    }

    .smetio-dominant-border-interactive:active {
        border-color: solid 1px var(--smetio-dominant-color);
        stroke: solid 1px var(--smetio-dominant-color);
    }

    .smetio-dominant-border-interactive[data-smetio-status='active'] {
        border-color: solid 1px var(--smetio-dominant-color);
        stroke: solid 1px var(--smetio-dominant-color);
    }

    /* neutralColor */
    .smetio-neutral-background {
        background-color: var(--smetio-neutral-color-tint-100);
        fill: var(--smetio-neutral-color-tint-100);
    }

    .smetio-neutral-background-reverse {
        background-color: var(--smetio-neutral-color-shade-100);
        fill: var(--smetio-neutral-color-shade-100);
    }

    .smetio-neutral-text {
        color: var(--smetio-neutral-color-shade-100);
    }

    .smetio-neutral-text-reverse {
        color: var(--smetio-neutral-color-tint-100);
    }

    .smetio-neutral-border {
        border: solid 1px var(--smetio-neutral-color);
        border-color: var(--smetio-neutral-color-shade-100);
        stroke: var(--smetio-neutral-color-shade-100);
    }

    /* greyColor */
    .smetio-grey-background {
        background-color: var(--smetio-grey-color);
        fill: var(--smetio-grey-color);
    }

    .smetio-grey-background-reverse {
        background-color: var(--smetio-grey-color-tint-100);
        fill: var(--smetio-grey-color-tint-100);
    }

    .smetio-grey-text {
        color: var(--smetio-grey-color-shade-100);
    }

    .smetio-grey-text-reverse {
        color: var(--smetio-grey-color-tint-100);
    }

    .smetio-grey-border {
        border: solid 1px var(--smetio-grey-color);
        border-color: var(--smetio-grey-color-shade-100);
        stroke: var(--smetio-grey-color-shade-100);
    }

    /* warningColor */
    .smetio-warning-background {
        background-color: var(--smetio-warning-color-tint-100);
        fill: var(--smetio-warning-color-tint-100);
    }

    .smetio-warning-background-reverse {
        background-color: var(--smetio-warning-color-shade-100);
        fill: var(--smetio-warning-color-shade-100);
    }

    .smetio-warning-text {
        color: var(--smetio-warning-color-shade-100);
    }

    .smetio-warning-text-reverse {
        color: var(--smetio-warning-color-tint-100);
    }

    .smetio-warning-icon {
        color: var(--smetio-warning-color-shade-100);
    }

    .smetio-warning-icon-interactive {
        color: var(--smetio-warning-color-shade-100);
    }

    .smetio-warning-icon-interactive:hover {
        color: var(--smetio-warning-color-tint-100);
    }

    .smetio-warning-icon-interactive:active {
        color: var(--smetio-warning-color);
    }

    .smetio-warning-border {
        border: solid 1px var(--smetio-warning-color-shade-100);
    }

    /* successColor */
    .smetio-success-background {
        background-color: var(--smetio-success-color-tint-100);
        fill: var(--smetio-success-color-tint-100);
    }

    .smetio-success-background-reverse {
        background-color: var(--smetio-success-color-shade-100);
        fill: var(--smetio-success-color-shade-100);
    }

    .smetio-success-text {
        color: var(--smetio-success-color-shade-100);
    }

    .smetio-success-text-reverse {
        color: var(--smetio-success-color-tint-100);
    }

    .smetio-success-icon {
        color: var(--smetio-success-color-shade-100);
    }

    .smetio-success-icon-interactive {
        color: var(--smetio-success-color-shade-100);
    }

    .smetio-success-icon-interactive:hover {
        color: var(--smetio-success-color-tint-100);
    }

    .smetio-success-icon-interactive:active {
        color: var(--smetio-success-color);
    }

    .smetio-success-border {
        border: solid 1px var(--smetio-success-color-shade-100);
    }

    /* informationColor */
    .smetio-information-background {
        background-color: var(--smetio-information-color-tint-100);
        fill: var(--smetio-information-color-tint-100);
    }

    .smetio-information-background-reverse {
        background-color: var(--smetio-information-color-shade-100);
        fill: var(--smetio-information-color-shade-100);
    }

    .smetio-information-text {
        color: var(--smetio-information-color-shade-100);
    }

    .smetio-information-text-reverse {
        color: var(--smetio-information-color-tint-100);
    }

    .smetio-information-icon {
        color: var(--smetio-information-color-shade-100);
    }

    .smetio-information-icon-interactive {
        color: var(--smetio-information-color-shade-100);
    }

    .smetio-information-icon-interactive:hover {
        color: var(--smetio-information-color-tint-100);
    }

    .smetio-information-icon-interactive:active {
        color: var(--smetio-information-color);
    }

    .smetio-information-border {
        border: solid 1px var(--smetio-information-color-shade-100);
    }

    /* dangerColor */
    .smetio-danger-background {
        background-color: var(--smetio-danger-color-tint-100);
        fill: var(--smetio-danger-color-tint-100);
    }

    .smetio-danger-background-reverse {
        background-color: var(--smetio-danger-color-shade-100);
        fill: var(--smetio-danger-color-shade-100);
    }

    .smetio-danger-text {
        color: var(--smetio-danger-color-shade-100);
    }

    .smetio-danger-text-reverse {
        color: var(--smetio-danger-color-tint-100);
    }

    .smetio-danger-icon {
        color: var(--smetio-danger-color-shade-100);
    }

    .smetio-danger-icon-interactive {
        color: var(--smetio-danger-color-shade-100);
    }

    .smetio-danger-icon-interactive:hover {
        color: var(--smetio-danger-color-tint-100);
    }

    .smetio-danger-icon-interactive:active {
        color: var(--smetio-danger-color);
    }

    .smetio-danger-border {
        border: solid 1px var(--smetio-danger-color-shade-100);
    }
}

html[data-theme="trueBrand-dark-100"] {

    /* accentColor */
    .smetio-accent-background {
        background-color: var(--smetio-accent-color-tint-100);
        fill: var(--smetio-accent-color-tint-100);
    }

    .smetio-accent-background-reverse {
        background-color: var(--smetio-accent-color-shade-100);
        fill: var(--smetio-accent-color-shade-100);
    }

    .smetio-accent-text {
        color: var(--smetio-accent-color-shade-100);
    }

    .smetio-accent-text-reverse {
        color: var(--smetio-accent-color-tint-100);
    }

    .smetio-accent-button {
        background-color: var(--smetio-accent-color-tint-100);
        fill: var(--smetio-accent-color-tint-100);
        color: var(--smetio-dominant-color-shade-100);
    }

    .smetio-accent-button:hover {
        background-color: var(--smetio-accent-color-shade-100);
        fill: var(--smetio-accent-color-shade-100);
        color: var(--smetio-dominant-color-tint-100);
    }

    .smetio-accent-button:active {
        background-color: var(--smetio-accent-color-tint-100);
        fill: var(--smetio-accent-color-tint-100);
        color: var(--smetio-dominant-color-shade-100);
    }

    .smetio-accent-button[data-smetio-status='active'] {
        background-color: var(--smetio-dominant-color-shade-100);
        fill: var(--smetio-dominant-color-shade-100);
        color: var(--smetio-accent-color-tint-100);
    }

    .smetio-accent-link {
        color: var(--smetio-accent-color-tint-100);
    }

    .smetio-accent-link-interactive {
        color: var(--smetio-accent-color-shade-100);
    }

    .smetio-accent-link-interactive:hover {
        color: var(--smetio-accent-color-tint-100);
    }

    .smetio-accent-link-interactive:active {
        color: var(--smetio-accent-color);
    }

    .smetio-accent-icon {
        color: var(--smetio-accent-color-tint-100);
    }

    .smetio-accent-icon-interactive {
        color: var(--smetio-accent-color-shade-100);
    }

    .smetio-accent-icon-interactive:hover {
        color: var(--smetio-accent-color-tint-100);
    }

    .smetio-accent-icon-interactive:active {
        color: var(--smetio-accent-color);
    }

    .smetio-accent-border {
        border: solid 1px var(--smetio-accent-color-tint-100);
    }

    .smetio-accent-border-interactive {
        border-color: var(--smetio-accent-color-shade-100);
        stroke: var(--smetio-accent-color-shade-100);
    }

    .smetio-accent-border-interactive:hover {
        border-color: solid 1px var(--smetio-accent-color-tint-100);
        stroke: solid 1px var(--smetio-accent-color-tint-100);
    }

    .smetio-accent-border-interactive:active {
        border-color: solid 1px var(--smetio-accent-color);
        stroke: solid 1px var(--smetio-accent-color);
    }

    .smetio-accent-border-interactive[data-smetio-status='active'] {
        border-color: solid 1px var(--smetio-accent-color);
        stroke: solid 1px var(--smetio-accent-color);
    }

    /* dominantColor */
    .smetio-dominant-background {
        background-color: var(--smetio-dominant-color-shade-100);
        fill: var(--smetio-dominant-color-shade-100);
    }

    .smetio-dominant-background-reverse {
        background-color: var(--smetio-dominant-color-tint-100);
        fill: var(--smetio-dominant-color-tint-100);
    }

    .smetio-dominant-text {
        color: var(--smetio-dominant-color-tint-100);
    }

    .smetio-dominant-text-reverse {
        color: var(--smetio-dominant-color-shade-100);
    }

    .smetio-dominant-border {
        border: solid 1px var(--smetio-dominant-color);
    }

    .smetio-dominant-border-interactive {
        border-color: var(--smetio-dominant-color);
        stroke: var(--smetio-dominant-color);
    }

    .smetio-dominant-border-interactive:hover {
        border-color: solid 1px var(--smetio-dominant-color-tint-100);
        stroke: solid 1px var(--smetio-dominant-color-tint-100);
    }

    .smetio-dominant-border-interactive:active {
        border-color: solid 1px var(--smetio-dominant-color);
        stroke: solid 1px var(--smetio-dominant-color);
    }

    .smetio-dominant-border-interactive[data-smetio-status='active'] {
        border-color: solid 1px var(--smetio-dominant-color);
        stroke: solid 1px var(--smetio-dominant-color);
    }

    /* neutralColor */
    .smetio-neutral-background {
        background-color: var(--smetio-neutral-color-shade-100);
        fill: var(--smetio-neutral-color-shade-100);
    }

    .smetio-neutral-background-reverse {
        background-color: var(--smetio-neutral-color-tint-100);
        fill: var(--smetio-neutral-color-tint-100);
    }

    .smetio-neutral-text {
        color: var(--smetio-neutral-color-tint-100);
    }

    .smetio-neutral-text-reverse {
        color: var(--smetio-neutral-color-shade-100);
    }

    .smetio-neutral-border {
        border: solid 1px var(--smetio-neutral-color);
        border-color: var(--smetio-neutral-color-tint-100);
        stroke: var(--smetio-neutral-color-tint-100);
    }

    /* greyColor */
    .smetio-grey-background {
        background-color: var(--smetio-grey-color);
        fill: var(--smetio-grey-color);
    }

    .smetio-grey-background-reverse {
        background-color: var(--smetio-grey-color-shade-100);
        fill: var(--smetio-grey-color-shade-100);
    }

    .smetio-grey-text {
        color: var(--smetio-grey-color-tint-100);
    }

    .smetio-grey-text-reverse {
        color: var(--smetio-grey-color-shade-100);
    }

    .smetio-grey-border {
        border: solid 1px var(--smetio-grey-color);
        border-color: var(--smetio-grey-color-tint-100);
        stroke: var(--smetio-grey-color-tint-100);
    }

    /* warningColor */
    .smetio-warning-background {
        background-color: var(--smetio-warning-color-shade-100);
        fill: var(--smetio-warning-color-shade-100);
    }

    .smetio-warning-background-reverse {
        background-color: var(--smetio-warning-color-tint-100);
        fill: var(--smetio-warning-color-tint-100);
    }

    .smetio-warning-text {
        color: var(--smetio-warning-color-tint-100);
    }

    .smetio-warning-text-reverse {
        color: var(--smetio-warning-color-shade-100);
    }

    .smetio-warning-icon {
        color: var(--smetio-warning-color-tint-100);
    }

    .smetio-warning-icon-interactive {
        color: var(--smetio-warning-color-tint-100);
    }

    .smetio-warning-icon-interactive:hover {
        color: var(--smetio-warning-color-shade-100);
    }

    .smetio-warning-icon-interactive:active {
        color: var(--smetio-warning-color);
    }

    .smetio-warning-border {
        border: solid 1px var(--smetio-warning-color-tint-100);
    }

    /* successColor */
    .smetio-success-background {
        background-color: var(--smetio-success-color-shade-100);
        fill: var(--smetio-success-color-shade-100);
    }

    .smetio-success-background-reverse {
        background-color: var(--smetio-success-color-tint-100);
        fill: var(--smetio-success-color-tint-100);
    }

    .smetio-success-text {
        color: var(--smetio-success-color-tint-100);
    }

    .smetio-success-text-reverse {
        color: var(--smetio-success-color-shade-100);
    }

    .smetio-success-icon {
        color: var(--smetio-success-color-tint-100);
    }

    .smetio-success-icon-interactive {
        color: var(--smetio-success-color-tint-100);
    }

    .smetio-success-icon-interactive:hover {
        color: var(--smetio-success-color-shade-100);
    }

    .smetio-success-icon-interactive:active {
        color: var(--smetio-success-color);
    }

    .smetio-success-border {
        border: solid 1px var(--smetio-success-color-tint-100);
    }

    /* informationColor */
    .smetio-information-background {
        background-color: var(--smetio-information-color-shade-100);
        fill: var(--smetio-information-color-shade-100);
    }

    .smetio-information-background-reverse {
        background-color: var(--smetio-information-color-tint-100);
        fill: var(--smetio-information-color-tint-100);
    }

    .smetio-information-text {
        color: var(--smetio-information-color-tint-100);
    }

    .smetio-information-text-reverse {
        color: var(--smetio-information-color-shade-100);
    }

    .smetio-information-icon {
        color: var(--smetio-information-color-tint-100);
    }

    .smetio-information-icon-interactive {
        color: var(--smetio-information-color-tint-100);
    }

    .smetio-information-icon-interactive:hover {
        color: var(--smetio-information-color-shade-100);
    }

    .smetio-information-icon-interactive:active {
        color: var(--smetio-information-color);
    }

    .smetio-information-border {
        border: solid 1px var(--smetio-information-color-tint-100);
    }

    /* dangerColor */
    .smetio-danger-background {
        background-color: var(--smetio-danger-color-shade-100);
        fill: var(--smetio-danger-color-shade-100);
    }

    .smetio-danger-background-reverse {
        background-color: var(--smetio-danger-color-tint-100);
        fill: var(--smetio-danger-color-tint-100);
    }

    .smetio-danger-text {
        color: var(--smetio-danger-color-tint-100);
    }

    .smetio-danger-text-reverse {
        color: var(--smetio-danger-color-shade-100);
    }

    .smetio-danger-icon {
        color: var(--smetio-danger-color-tint-100);
    }

    .smetio-danger-icon-interactive {
        color: var(--smetio-danger-color-tint-100);
    }

    .smetio-danger-icon-interactive:hover {
        color: var(--smetio-danger-color-shade-100);
    }

    .smetio-danger-icon-interactive:active {
        color: var(--smetio-danger-color);
    }

    .smetio-danger-border {
        border: solid 1px var(--smetio-danger-color-tint-100);
    }
}