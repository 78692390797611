
@import "../../../../../css/variables/devices/_device_sizes";

@mixin mobileScreen{

    /* @media only screen and (min-width:$mobile_min) and (max-width:$mobile_max){ */
    
    /**** COMBINE SMALL MOBILE AND MOBILE BY SETTING ONLY MAX-WIDTH ****/
    
    @media only screen and (min-width:$mobile_min) and (max-width:$mobile_max){

        --smetio-customer-website-treatments-items-max-width:100%;
        --smetio-customer-website-treatments-items-justify-content:center;

        .smetio-photo-and-description { 

            width: 100% !important;

            &[data-smetio-max-width="250px"]{ 

                max-width: 100% !important;
                width: 100% !important;
                
            }  

            .smetio-photo-and-description-photo { 

                &[data-smetio-max-height="170px"]{ 

                    max-height: 250px !important;
                    height: 250px !important;
                    
                } 
            }
        }

        @media (orientation: landscape){

            
        }
        

        @media (orientation: portrait){

            
        }
        
        
    }

}    