
@import "../../../../../../css/variables/devices/device_sizes";

   
@mixin mobileScreen{

    /* @media only screen and (min-width:$mobile_min) and (max-width:$mobile_max){ */
    
    /**** COMBINE SMALL MOBILE AND MOBILE BY SETTING ONLY MAX-WIDTH ****/
    
    @media only screen and (min-width:$mobile_min) and (max-width:$mobile_max){

        /* MODALS */

        .modal-dialog{ width:95%;}
        
        --smetio-app-ui-region-and-languages-content-sort-select-diplay:none;
    
        --smetio-ui-language-options-width:95%;
        
        
        /* HEAD */
        

        --smetio-app-ui-head-height:40px;

        
             /* HEAD SECTION 1 */
            
            --smetio-app-ui-head-section-1-width:var(--smetio-app-ui-menu-bar-width); 
        
                        
             
             /* HEAD SECTION 2 */
             

            --smetio-app-ui-head-section-2-width:calc( 100% - var(--smetio-app-ui-menu-bar-width) );

        
        
        /* BODY */
        
        
             /* BODY SECTION 1 */
             
             --smetio-app-ui-body-section-1-fully-open-width:100%;
             
             --smetio-app-ui-menu-text-font-weight:200;
             
             
             /* BODY SECTION 2 */
             
        
            --smetio-app-ui-body-section-2-fully-closed-width:0px;
   

            --smetio-content-interactable-card-style-card-item-width:90%;

            @media (orientation: landscape){

                --smetio-content-interactable-card-style-card-item-width:45%;
            }
            
    
            @media (orientation: portrait){
    
                
            }            
  
        
    } 

}
