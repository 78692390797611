
@import "../../../../../css/variables/devices/_device_sizes";

@mixin tabletScreen{

    @media only screen and (min-width:$tablet_min) and (max-width:$tablet_max){

        --smetio-customer-website-teamMembers-items-max-width:100%;
        // --smetio-customer-website-teamMembers-item-width:45%;
        --smetio-customer-website-teamMembers-items-justify-content:center;
        --smetio-photo-and-description-photo-width:100%;

        .smetio-photo-and-description {
            max-width: 45% !important;
            width: 45% !important;
        }

        /**** THE CSS FOR THE TABLET LOOK OF THE WIDGET SHOULD BE PLACED BELOW ****/


        @media (orientation: landscape){

            
        }
        

        @media (orientation: portrait){

            
        }
        
        
    } 

}
