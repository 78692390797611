
@import "../../../../../css/variables/devices/device_sizes";

@mixin desktopScreen{

    @media only screen and (min-width:$desktop_min) and (max-width:$desktop_max){

        // THE CSS COMES HERE

        --smetio-3ack-blogs-page-controller-item-width:20%;
        --smetio-3ack-blogs-page-controller-items-holder-width:90%;

        @media (orientation: landscape){

            
        }
        

        @media (orientation: portrait){

            
        }
                
        
    } 

}
