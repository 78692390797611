
@use "small_mobile";
@use "mobile";
@use "tablet";
@use "desktop";
@use "large_desktop";



@use "../../../../../css/mixins/displays/displays" as display;
@use "../../../../../css/mixins/dimensions/dimensions" as dimension;
@use "../../../../../css/mixins/position/positions" as position;
@use "../../../../../css/mixins/shapes/shapes" as shapes;
@use "../../../../../css/mixins/looks/looks" as looks;
@use "../../../../../css/mixins/effects/effects" as effects;
// @use "../../../../../css/mixins/general_smetio_classes/general_smetio_classes" as classes;

// @import "../../../../../css/latestSmetioStyleSheet";

// @import "../../../../../css/variables/devices/device_sizes";
// @import "../../../../../css/mainClasses";



.smetio-input-div {

    @include display.flex-wrap(column);
    @include display.flex-perfect-center; 
    
    width: 100%;
    height: auto;
    margin-bottom: var(--smetio-app-ui-icon-default-margin) !important;
    //margin-top: calc(var(--smetio-app-ui-icon-default-margin)/2); 

    &[data-smetio-is-visible=false]{
        display: none;
    }


    * {
        width: 100%;
        text-align: left;
    }

    .smetio-horizontalInputDiv {
        @include display.flex-wrap(row);
        //@include display.flex-perfect-center;              
        //background-color: green;  
        //border: solid 1px brown; 


        flex-wrap: unset;
        flex-wrap: nowrap !important;
        

        div {
            flex: 1;
            width: auto;
        }
        .smetio-labelOrInputDiv {

            //max-width: 20px;
            //width: 20px;
            //overflow: hidden;
            // justify-items: flex-start !important;
            // background-color: pink;

            &[data-smetio-content="input"]{
              
                flex: 1;
                
            }

            &[data-smetio-content="label"]{
                             
                flex: unset;
                //width: auto;
                // justify-items: flex-start !important;

                // background-color: green;
                // @extend.smetio-dominant-background;
                @extend.smetio-neutral-background;
                
            }

            // .forSmetioOnOffSwitch{
            //     max-width: 35px;
            //     width: 35px;
            //     margin-right: var(--smetio-app-ui-icon-default-margin);
            //     margin-top: -15px !important;
            // }

            &[data-smetio-element="smetioOnOffSwitch" ]{
               
                max-width: 35px;
                width: 35px;
                margin-right: var(--smetio-app-ui-icon-default-margin);
                margin-top: -15px !important;
                
            }
            &[data-smetio-element="smetioCustomCheckbox"]{
                max-width: 35px;
                width: 35px;
                margin-right: var(--smetio-app-ui-icon-default-margin);
                margin-top: -15px !important;
            }
            

        }
    }

    .smetio-instruction, .smetio-input-instruction {

        // @extend .smetio-blue-text;
        @extend.smetio-information-text;
        align-items: flex-start !important;
        font-size: 100%;
        font-weight: 100;
        display: none;

    }



    .smetio-input-label {

        width: auto !important;
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: center;


        margin-bottom: -20px;
        font-size: 110%;
        font-weight: 300;
        @extend.smetio-grey-text;


        &[data-smetio-read-only=true]{
            font-weight: 600;
        }
        h6, span{
            width: auto !important;            
            display: flex;
            flex-direction: row;
            align-items: center;
            align-content: center;

            &[data-smetio-read-only=true]{
                display: none;
            }
        }
        h6 {
            margin-bottom: 0 !important;
        }

    }

    input, textarea, select, .smetio-input {

        padding: calc(var(--smetio-app-ui-icon-default-margin)/4); 
        border-radius: calc(var(--smetio-app-ui-icon-default-margin)/4); 


        // background-color:var(--smetio-layout-main-bg-color);
        // @extend.smetio-dominant-background;
        @extend.smetio-neutral-background;
        @extend.smetio-grey-text;

        outline: solid 0.5px transparent;


        // @extend .smetio-default-border-with-hover-active;
        // //@extend.smetio-neutral-border-trans-30;
        // @extend.smetio-dominant-border;
        // @extend.smetio-neutral-border;
        @extend.smetio-grey-border;

        &:hover{
            // //@extend.smetio-neutral-border-trans-70;
            // @extend.smetio-accent-border;
            @extend.smetio-dominant-border;
        }
        &:active{
            // //@extend.smetio-neutral-border-trans-90;
            // @extend.smetio-dominant-border;
            @extend.smetio-accent-border;
        }        

        &[data-smetio-error="yes"]{            
            @extend .smetio-danger-border;            
        }

        &[data-smetio-read-only=true]{
            // @extend.smetio-dominant-background;
            @extend.smetio-neutral-background;
            border-color: transparent !important;
            // font-size: 120%;
            // font-weight: bold;
            pointer-events: none;
            padding-top: 0px;
            padding-bottom: 0px;
            padding-left: 7px;
        }
    }
    textarea {        
        min-height: 200px;
        max-height: 200px;
        height: auto;
        @extend.smetio-data-heights;
        @extend.smetio-data-min-heights;
        @extend.smetio-data-max-heights;
        text-align: left;
        @extend .smetio-hiddenScrollBar;
        &[data-smetio-read-only=true]{
            height: auto;
            resize: none;
            @extend .smetio-hiddenScrollBar;
        }
    }

    datalist {
        width: 100% !important;
    }

    datalist option {
        width: 100% !important;
        outline: solid 1px transparent !important;
    }

    select option {
        width: 100% !important;
        outline: solid 1px transparent !important;
    }



    .smetio-error, .smetio-input-error {

        // @extend .smetio-error-text;
        
        @extend.smetio-danger-text;

        align-items: flex-start !important;
        font-size: 95%;
        font-weight: 100;
        display: none;

    }


    @include small_mobile.smallMobileScreen;
    @include mobile.mobileScreen;
    @include tablet.tabletScreen;
    @include desktop.desktopScreen;
    @include large_desktop.largeDesktopScreen;  

}/* .smetio-multimediaPlayer ENDS HERE */





