
@import "../../../../../css/variables/devices/_device_sizes";

@mixin tabletScreen{

    @media only screen and (min-width:$tablet_min) and (max-width:$tablet_max){

        --smetio-multimediaPlayer-multimediaThumbnail-width: 70%;

        /**** THE CSS FOR THE TABLET LOOK OF THE WIDGET SHOULD BE PLACED BELOW ****/


        @media (orientation: landscape){

            
        }
        

        @media (orientation: portrait){

            
        }
        
        
    } 

}
