@use "small_mobile";
@use "mobile";
@use "tablet";
@use "desktop";
@use "large_desktop";



@use "../../../../../css/mixins/displays/displays" as display;
@use "../../../../../css/mixins/dimensions/dimensions" as dimension;
@use "../../../../../css/mixins/position/positions" as position;
@use "../../../../../css/mixins/shapes/shapes" as shapes;
@use "../../../../../css/mixins/looks/looks" as looks;
@use "../../../../../css/mixins/effects/effects" as effects;
@use "../../../../../css/mixins/general_smetio_classes/general_smetio_classes" as classes;



// @import "../../../../../css/variables/devices/device_sizes";
// @import "../../../../../css/mainClasses";

// @import "../../../../../css/latestSmetioStyleSheet";

.smetio-file-uploader {

    max-width: 100% !important;

    width: 100%;
    overflow: hidden !important;
    min-height: 100%;
    height: auto;
    @extend.smetio-neutral-background;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    .smetio-file-uploader-drag-zone-holder {
        @include display.flex-wrap(column);
        @include display.flex-perfect-center;
        position: fixed;
        top: 0;
        left: 0;
        @extend.smetio-neutral-background;
        width: 100%;
        min-height: 200px;
        height: auto;
        // background-color: green;
        z-index: 3;
        margin: 0 !important;
        padding: 0 !important;
        padding-bottom: 20px;

        .smetio-file-uploader-drag-zone {

            @include display.flex-wrap(column);
            @include display.flex-perfect-center;
            @extend.smetio-grey-background-trans-30;
            @extend.smetio-grey-border;
            @extend.smetio-dominant-text;
            // background-color: green;
            border-radius: 5px;
            border-style: dashed !important;
            margin-top: 50px;
            width: 95%;
            min-height: 150px;
            height: auto;
            // position: fixed;


            &[data-smetio-file-is-over-drag-zone=true] {
                @extend.smetio-dominant-background;
                @extend.smetio-neutral-text;
            }

            div,
            button {
                @include display.flex-wrap(column);
                @include display.flex-perfect-center;
                text-align: center;
                margin: 2.5px;
            }

            input {
                // visibility: hidden;
                display: none;
            }

            .smetio-file-uploader-drag-zone-icon {
                margin-top: 20px;
                @extend.smetio-grey-text;

                i {
                    font-size: 200%;
                }
            }

            .smetio-file-uploader-drag-zone-txt {
                font-size: 150%;
            }

            .smetio-file-uploader-drag-zone-btn {
                @extend.smetio-dominant-background;
                @extend.smetio-neutral-text;
                padding: 2.5px;
                padding-left: 5px;
                padding-right: 5px;
                margin-top: 20px !important;
                margin-bottom: 20px !important;
                ;
                border-radius: 5px;
                border: solid 0.5px transparent;
                border: outline 0.5px transparent;

                &:hover {
                    cursor: pointer;
                    // @extend.smetio-dominant-text;
                    @extend.smetio-accent-background-reverse;
                }
            }

        }

        /* .smetio-file-uploader-drag-zone ENDS HERE */

        .smetio-file-uploader-error-text {
            @extend.smetio-error-text;
            font-size: 100%;
            margin-top: 10px;
            margin-bottom: 10px;
            text-align: left !important;
            display: none;

        }

        .smetio-file-uploader-upload-all {
            @include display.flex-wrap(row);
            @include display.flex-perfect-center;
            width: auto;
            text-align: right;
            font-size: 100%;
            align-self: flex-end;
            justify-self: flex-end;
            @extend.smetio-dominant-text;
            margin-right: 20px;
            margin-top: 10px;
            margin-bottom: 10px;
            i{
                font-size: 120%;
                margin-right: 7.5px;
            }

            &:hover {
                @extend.smetio-dominant-background;
                @extend.smetio-neutral-text;
                padding: 5px;
                cursor: pointer;
            }

            &:active {
                @extend.smetio-dominant-text;
            }



        }
    }

    .smetio-file-uploader-file-list-holder {
        @extend.smetio-neutral-background;
        @extend.smetio-dominant-text;
        flex: 1;
        max-width: 100% !important;        
        width: 100%;
        height: auto;
        overflow: hidden !important;
        // max-height: 100%;
        // overflow-y:scroll;
        // overflow-x:hidden;
        margin-top: 210px;
        z-index: 1;


        &::-webkit-scrollbar {

            display: none;

        }

        .smetio-file-uploader-file-list {

            @include display.flex-wrap(row);
            @include display.flex-perfect-center;

            // flex-wrap: nowrap !important;
            max-width: 100% !important;
            width: 100%;
            overflow: hidden !important;
            height: auto;
            margin: 0;
            padding: 0;
            @extend.smetio-grey-border;
            border-left-color: transparent !important;
            border-right-color: transparent !important;
            border-top-color: transparent !important;
            border-radius: 0;
            height: 50px;
            // position: relative;

            .smetio-file-uploader-file-list-thumbnail {
                @include display.flex-wrap(column);
                @include display.flex-perfect-center;
                justify-self: flex-start;
                width: 40px;
                height: 40px;
                border-radius: 5px;
                margin-top: 5px;
                margin-left: 5px;
                margin-right: 10px;
                overflow: hidden;
                @extend.smetio-grey-background-trans-30;

                img {
                    width: 40px;
                    height: 40px;
                }

            }/* .smetio-file-uploader-file-list-thumbnail ENDS HERE */

            .smetio-file-uploader-file-list-name-and-more {
                @include display.flex-wrap(column);
                // @include display.flex-perfect-center;
                align-items: center !important;
                justify-content: flex-start !important;
                // width: 100%;
                flex: 1;
                height: auto;
                @extend.smetio-neutral-text;
                // background-color: pink;

                .smetio-file-uploader-file-list-name-size-actions {
                    @include display.flex-wrap(row);
                    justify-content: flex-start !important;
                    align-items: center !important;
                    // background-color: rgb(202, 123, 20);
                    max-width: 100% !important;
                    width: 100%;
                    height: auto;
                    overflow: hidden !important;


                    .smetio-file-uploader-file-list-name {
                        flex: 1;
                        // max-width: 10%;
                        // width: 10%;
                        overflow: hidden;
                        text-align: left;
                        margin-right: 10px;
                        // justify-self: left !important;
                        // align-self:flex-start !important;
                        font-size: 100%;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
    
                    .smetio-file-uploader-file-list-size {
                        // flex: 1;
                        font-size: 100%;
                        text-align: center;
                        margin-right: 10px;
                        width: auto;
                    }
    
                    .smetio-file-uploader-file-list-action{
                        @include display.flex-wrap(row);
                        @include display.flex-perfect-center;

                        justify-self: flex-end;
    
                        div {
                            margin: 5px;
                            i {
                                font-size: 120%;
                            }

                            &:hover {
                                @extend.smetio-dominant-text;
                                cursor: pointer;
                            }
                            &:active {
                                @extend.smetio-accent-text;                
                            }
                        }

                        .smetio-file-uploader-file-list-action-cancel {
                            display: none;   
                            @extend.smetio-danger-text;                   
                        }

                        .smetio-file-uploader-file-list-action-done {
                            display: none;
                            // @extend.smetio-success-text;
                            @extend.smetio-success-text-reverse;
                            i {
                                // font-size: 200%;
                            }
                            &:hover {
                                cursor: none !important;
                            }
                        }
                    }
                }
                .smetio-file-uploader-file-list-progress {
                    // position: absolute;
                    right: 0;
                    bottom: 0;
                    width: 50%;
                    width: 0;
                    min-height: 5px;
                    height: 5px;
                    justify-self: flex-start !important;
                    font-size: 100%;
                    text-align: center;
                    @extend.smetio-success-background;
                    @extend.smetio-neutral-text;
    
                }

            }/*.smetio-file-uploader-file-list-name-and-more ENDS HERE  */


        }/* .smetio-file-uploader-file-list ENDS HERE */

    }/* .smetio-file-uploader-file-list-holder ENDS HERE */




    @include small_mobile.smallMobileScreen;
    @include mobile.mobileScreen;
    @include tablet.tabletScreen;
    @include desktop.desktopScreen;
    @include large_desktop.largeDesktopScreen;

}

/* .smetio-multimediaPlayer ENDS HERE */