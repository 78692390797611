/* 

    NO CSS CODE SHOULD BE WRITTEN ON THIS FILE
    
    CSS CODE SHOW BE WRITTEN IN .scss FILE WITH A NAME STARTING WITH _ AND THE THAT FILE SHOULD BE 
    IMPORTED HERE 

    eg: @import "custom_bootstap_variables.scss";
*/

//@import "variables/frameworks/custom_bootstap_variables";

//@import "~bootstrap/scss/bootstrap";

// @use "./mixins/general_smetio_classes/general_smetio_classes" as classes;

@import "bootstrap";

// @import "bootstrap/dist/css/bootstrap.min.css";

@import "animate.css";



//@import "smetioIcons/css/smetioIcons.css";



/**** IMPORT SMETIO SPECIFIC CSS ****/




// @import "smetio_widgets/widgets";

// @import "apps_and_websites/smetio_signup_or_login_page/_smetio_signup_or_login_page";

// @import "./latestSmetioStyleSheet";

@import "./latestSmetioStyleSheet";

@import "./smetio_widgets/smetioIcons/scss/smetioIcons";

// THE ICONS USED WITHIN DATA PRESENTATION
@import "./appIconsStyleSheet.scss";



// @import "./../js/functions/stripe/_confirmDeleteCreditCard";

@import "./mainClasses";
@import "./../js/classes/smetioLanguageOptions/v1.0.0/styles/main";
@import "./../js/classes/smetioApps/smetioAppUserInterface/v1.0.0/styles/_main.scss";

@import "./../js/classes/3ack_apps/admin3ack/v1.0.0/styles/_main.scss";


@import "./../js/classes/smetioCodeVerificationPage/v1.0.0/styles/_main.scss";

@import "./../js/classes/smetioObjectToInput/v1.0.0/styles/_main.scss";

@import "./../js/classes/smetioPreloader/styles/_main.scss";

@import "./../js/classes/smetioSmartbox/v1.0.0/styles/_main.scss";

@import "./../js/classes/smetioPage/v1.0.0/styles/_main.scss";

@import "./../js/classes/smetioOnOffSwitch/styles/_main.scss";

@import "./../js/classes/smetioSlideShow/styles/_main.scss";

@import "./../js/classes/smetioVisitor/styles/_main.scss";

@import "./../js/classes/smetioFileInput/v1.0.0/styles/_main.scss";

@import "./../js/classes/smetioDataGroup/v1.0.0/styles/_main.scss";

@import "./../js/classes/smetioDataPresentation/v1.0.0/styles/_main.scss";

@import "./../js/classes/smetioColorInput/v1.0.0/styles/_main.scss";

@import "./../js/classes/smetioExplanation/v1.0.0/styles/_main.scss";

@import "./../js/classes/smetioActionBar/v1.0.0/styles/_main.scss";

@import "./../js/classes/smetioApps/smetioAuth/v1.0.0/menus/smetioAppMenuAuthenticate/v1.0.0/styles/_main.scss";

@import "./../js/classes/smetioAppUiAppsAndUtilitiesHandler/v1.0.0/styles/_main.scss";

@import "./../js/classes/smetioAppUiNotificationHandler/v1.0.0/styles/_main.scss";

@import "./../js/classes/smetioTaskBar/v1.0.0/styles/_main.scss";

@import "./../js/classes/smetioSideMenu/v1.0.0/styles/_main.scss";

@import "./../js/classes/smetioSideBar/v1.0.0/styles/_main.scss";

@import "./../js/classes/smetioQrCodeScanner/v1.0.0/styles/_main.scss";

@import "./../js/classes/smetioNotification/v1.0.0/styles/_main.scss";

@import "./../js/classes/smetioMenuBar/v1.0.0/styles/_main.scss";

@import "./../js/classes/smetioLoginPage/v1.0.0/styles/_main.scss";

@import "./../js/classes/smetioIcons/v1.0.0/styles/_main.scss";

@import "./../js/classes/smetioFileUploader/v1.0.0/styles/_main.scss";

@import "./../js/classes/smetioCustomCheckbox/v1.0.0/styles/_main.scss";

@import "./../js/classes/smetioCreditCardDetailsCollector/v1.0.0/styles/_main.scss";

@import "./../js/classes/smetioComingSoon/v1.0.0/styles/_main.scss";

@import "./../js/classes/smetioColorSchemeMaker/v1.0.0/styles/_main.scss";

@import "./../js/classes/smetioBusinessHour/v1.0.0/styles/_main.scss";

@import "./../js/classes/smetioAppUiWikiHandler/v1.0.0/styles/_main.scss";

@import "./../js/classes/smetioAppUiSideMenuItemContent/v1.0.0/styles/_main.scss";

@import "./../js/classes/smetioAppUiSettingsHandler/v1.0.0/styles/_main.scss";

@import "./../js/classes/3ack_apps/admin3ack/v1.0.0/methods/uIconfirmAddNewBusinessLocation";


@import "./../js/classes/smetioPhotoAndDescription/v1.0.0/styles/_main.scss";

@import "./../js/classes/smetioPhotoCheckbox/v1.0.0/styles/_main.scss";

@import "./../js/classes/smetioLegalPoliciesPage/v1.0.0/styles/_main.scss";

@import "./../js/classes/smetioCustomerWebsiteBusinessLocations/v1.0.0/styles/_main.scss";

@import "./../js/classes/smetioCustomerWebsiteTeamMembers/v1.0.0/styles/_main.scss";

@import "./../js/classes/smetioCustomerWebsiteTreatments/v1.0.0/styles/_main.scss";

@import "./../js/classes/smetioTextEditor/v1.0.0/styles/_main.scss";

@import "./../js/classes/smetio3ackBlogsPageController/v1.0.0/styles/_main.scss";





@import "./../js/functions/apps/accountSetup/_main.scss";

// @import "quill/dist/quill.core.css";
// @import "quill/dist/quill.snow.css";


// @import "./../js/functions/stripe/_confirmDeleteCreditCard";

// @import "./../js/classes/universal_methods/_newVersionOfConvertModelToInputs";


// @import "./variables/devices/device_sizes";

// @import "./variables/smetio_brands/smetio/colors/colors";

// @import "./variables/smetio_brands/smetio/themes/dark_theme";

// @import "./variables/smetio_brands/smetio/themes/light_theme";


// @import "apps_and_websites/smetio_icons/_smetio_icons";

//@import "apps_and_websites/wakandaCoin_website/wakandaCoin_website";

// @import "apps_and_websites/smetio_admin_app/smetio_admin_app";

// @import "apps_and_websites/smetio_ide/smetio_ide";









