
@import "../../../../../css/variables/devices/_device_sizes";

@mixin tabletScreen{

    @media only screen and (min-width:$tablet_min) and (max-width:$tablet_max){

        --smetio-page-contents-left-display: none;

        --smetio-page-contents-logo-div-img-position:absolute;    
        --smetio-page-contents-logo-div-img-max-width: 100px;
        --smetio-page-contents-logo-div-img-max-height: 75px;
        --smetio-page-contents-logo-div-img-min-width: 50px;

        /**** THE CSS FOR THE TABLET LOOK OF THE WIDGET SHOULD BE PLACED BELOW ****/


        @media (orientation: landscape){

            
        }
        

        @media (orientation: portrait){

            
        }
        
        
    } 

}
