
@use "small_mobile";
@use "mobile";
@use "tablet";
@use "desktop";
@use "large_desktop";



@use "../../../../../../../../../css/mixins/displays/displays" as display;
@use "../../../../../../../../../css/mixins/dimensions/dimensions" as dimension;
@use "../../../../../../../../../css/mixins/position/positions" as position;
@use "../../../../../../../../../css/mixins/shapes/shapes" as shapes;
@use "../../../../../../../../../css/mixins/looks/looks" as looks;
@use "../../../../../../../../../css/mixins/effects/effects" as effects;
@use "../../../../../../../../../css/mixins/general_smetio_classes/general_smetio_classes" as classes;


// @import "../../../../../../../../../css/variables/devices/device_sizes";
// @import "../../../../../../../../../css/mainClasses";


.smetio-app-menu-authenticate-error {

    width: 100%;
    @include display.flex-wrap(column);
    @include display.flex-perfect-center;  

    p {
        @extend .smetio-error-text;
    }
}

/*

.smetio-multimediaPlayer-middleDivContent {

    @include small_mobile.smallMobileScreen;
    @include mobile.mobileScreen;
    @include tablet.tabletScreen;
    @include desktop.desktopScreen;
    @include large_desktop.largeDesktopScreen;  

} .smetio-multimediaPlayer ENDS HERE */





