
@use "small_mobile";
@use "mobile";
@use "tablet";
@use "desktop";
@use "large_desktop";



@use "../../../../css/mixins/displays/displays" as display;
@use "../../../../css/mixins/dimensions/dimensions" as dimension;
@use "../../../../css/mixins/position/positions" as position;
@use "../../../../css/mixins/shapes/shapes" as shapes;
@use "../../../../css/mixins/looks/looks" as looks;
@use "../../../../css/mixins/effects/effects" as effects;
@use "../../../../css/mixins/general_smetio_classes/general_smetio_classes" as classes;


// @import "../../../../css/variables/devices/device_sizes";
// @import "../../../../css/mainClasses";


.smetio-slideShow-container2 {
    
    min-width:100%;
    width:100%;
    flex-wrap: nowrap;     
    margin: 0;
    padding: 0;
    overflow: hidden;
    position: relative;

    &[data-smetio-show-controllerBySlide="true"]{
          
        div.smetio-slideShow-controller-left {
            display: none;
        }
        
    }     

    > div.smetio-slideShow-controller{

        position: absolute;
        z-index: 1;
        width: auto;
        font-size: 200%;
        

        i {

            color: inherit;

        }

        &[data-smetio-visible="no"]{

            display: none;
            
        }        

        &[data-smetio-placement="top"]{
              
            top:0;
            
        }

        &[data-smetio-placement="middle"]{
              
            top:50%;

        } 

        &[data-smetio-placement="bottom"]{
              
            bottom:0;
            
        }

        &:hover{
            cursor: pointer;
        }

    }

    > div.smetio-slideShow-indicator-holder{

        @include display.flex-wrap(row);
        @include display.flex-perfect-center;
        margin: 0;
        padding: 0;
        transition: opacity 2s ease;
        &[data-smetio-visible="no"]{
            //visibility: hidden;        
            display: none;
        } 

        &[data-smetio-placement="top"]{
              
            //position: absolute;              
            //top:0;
            
        }          

        > div.smetio-slideShow-indicator {

            margin: 0;
            padding: 0;
            margin-left: calc(var(--smetio-app-ui-icon-default-margin)/4);
            margin-right: calc(var(--smetio-app-ui-icon-default-margin)/4);
            opacity: 0.4;
            &[data-smetio-active="yes"]{
                opacity: 1;
            }
            //background-color: yellow;
            &:hover{
                cursor: pointer;
            }

        }

    }

    > div.smetio-slideShow-indicator-holder-top {

        margin-top: unset;
        margin-bottom: unset;
        margin-bottom: calc(var(--smetio-app-ui-icon-default-margin)/4);

        &[data-smetio-placement="bottom"]{
              
            display: none;
            
        } 

        &[data-smetio-placement="top"]{
              
            display: flex;
            
        } 


    }

    > div.smetio-slideShow-indicator-holder-bottom {

        margin-top: calc(var(--smetio-app-ui-icon-default-margin)/4);

        &[data-smetio-placement="top"]{
              
            display: none;
            
        }         
    }


    > div.smetio-slideShow-controller-right{

        right:5px;
    }

    > div.smetio-slideShow-controller-left{

        left:5px;
    } 


    > div.smetio-slideShow-items-holder {

        @include display.flex-wrap(row);
        // @include display.flex-perfect-center;
        align-items: flex-start !important;
        justify-content: center !important;
        flex-wrap: nowrap; 
        left: 0;
        right: 0;
        margin: 0;
        margin-bottom: var(--smetio-app-ui-icon-default-margin);
        margin-top: var(--smetio-app-ui-icon-default-margin);
        padding: 0;  
        transition: margin ease;

        min-height: 100%;
        height: 100%;
        //width: 100%;;

        //overflow-y: scroll;
        //background-color: pink;

        &[data-smetio-speed="0.5"]{
            transition-duration: 0.5s;        
        } 

        &[data-smetio-speed="1"]{
            transition-duration: 1s;        
        } 
        &[data-smetio-speed="1.5"]{
            transition-duration: 1.5s;        
        } 
        &[data-smetio-speed="2"]{
            transition-duration: 2s;        
        } 

        > div.smetio-slideShow-item {

            //background-color: gray; 
            left: 0;
            right: 0;
            margin: 0;
            padding: 0;  
            //border-left: solid 1px green;  
            //width:50%;

            min-height: 100%;
            height: 100%;

            //overflow-y: scroll;
            //background-color: brown;


        }/* .smetio-slideShow-item ENDS HERE */


    }/* .smetio-slideShow-items-holder ENDS HERE */
    

    @for $i from 1 through 50 {

        &[data-smetio-items="#{$i}"]{
            > div.smetio-slideShow-items-holder {
                width:calc(100% * #{$i});
                > div.smetio-slideShow-item {
                    width:calc(100% / #{$i});
                }
            }               
        }

    }
                                                                         


    @include small_mobile.smallMobileScreen;
    @include mobile.mobileScreen;
    @include tablet.tabletScreen;
    @include desktop.desktopScreen;
    @include large_desktop.largeDesktopScreen;




}/* .smetio-slideShow-container ENDS HERE */




