.smetio-ui-for-business-account-setup {

    display: flex;
    flex-direction: column;
    width: 100%;

    h3 {
        @extend.smetio-information-text;
    }

    .smetio-ui-for-business-account-setup-button {
        
        @extend.smetio-dominant-button;
        width: auto;
        align-self: center;
        justify-self: flex-end;
        margin-top: 30px;

    }

}