@use "small_mobile";
@use "mobile";
@use "tablet";
@use "desktop";
@use "large_desktop";



@use "../../../../css/mixins/displays/displays" as display;
@use "../../../../css/mixins/dimensions/dimensions" as dimension;
@use "../../../../css/mixins/position/positions" as position;
@use "../../../../css/mixins/shapes/shapes" as shapes;
@use "../../../../css/mixins/looks/looks" as looks;
@use "../../../../css/mixins/effects/effects" as effects;
@use "../../../../css/mixins/general_smetio_classes/general_smetio_classes" as classes;


// @import "../../../../css/latestSmetioStyleSheet";

// @import "../../../../css/variables/devices/device_sizes";
// @import "../../../../css/mainClasses";


.smetio-ui-on-off-switch {

    height: auto;
    width: auto;

    .smetio-switch {
        position: relative;
        display: inline-block;
        // width: 30px;
        // height: 17px;
        width: 35px;
        height: 20px;
        margin-top: 20px;
    }

    .smetio-switch input {
        opacity: 0;
        width: 0;
        height: 0;

        &[data-smetio-read-only=true]{
            pointer-events: none;
        }        
    }
    .smetio-switch-slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        &[data-smetio-read-only=true]{
            pointer-events: none;
        } 
        // background-color: #ccc;
        // @extend.smetio-neutral-background-trans-30;
        @extend.smetio-dominant-border;

        &:hover{
            // @extend.smetio-neutral-background-trans-70;
            @extend.smetio-dominant-background;
        }
        -webkit-transition: .4s;
        transition: .4s;

    }

    .smetio-switch-slider:before {
        position: absolute;
        content: "";
        // height: 13px;
        // width: 13px;
        height: 24px;
        width: 20px;
        left: -1px !important; 
        // right:15px !important;
        bottom: -3px !important;
        // top: 2.5px !important;
        // bottom: 1px;
        // background-color: white;
        // @extend.smetio-dominant-background;
        // @extend.smetio-neutral-background;
        // @extend.smetio-dominant-border;

        @extend.smetio-dominant-background-reverse;

        -webkit-transition: .4s;
        transition: .4s;
    }

    input:checked+.smetio-switch-slider {
        // background-color: #2196F3;
        @extend.smetio-accent-background;
        &:hover{
            // @extend.smetio-accent-background-reverse;
            @extend.smetio-neutral-background;
            @extend.smetio-dominant-border;
        }
    }

    input:focus+.smetio-switch-slider {
        // box-shadow: 0 0 1px #2196F3;
        // @extend.smetio-neutral-shadow-around-trans-30;
    }

    input:checked+.smetio-switch-slider:before {
        // -webkit-transform: translateX(13px);
        // -ms-transform: translateX(13px);
        // transform: translateX(13px);

        -webkit-transform: translateX(15px);
        -ms-transform: translateX(15px);
        transform: translateX(15px);
    }

    /* Rounded sliders */
    .smetio-switch-slider.round {
        // border-radius: 17px;
        border-radius: 2px;
    }

    .smetio-switch-slider.round:before {
        // border-radius: 50%;
        border-radius: 2px;
    }


    @include small_mobile.smallMobileScreen;
    @include mobile.mobileScreen;
    @include tablet.tabletScreen;
    @include desktop.desktopScreen;
    @include large_desktop.largeDesktopScreen;

}

/* .smetio-signup-page ENDS HERE */