$widthAndHeight: 20px !default;


@mixin rounded($widthAndHeight){

    /**** 
    
        THIS WILL CREATE A ROUND SHAPE 
        IT MAKE AN IMAGE LOOK ROUNDED eg: PROFILE PICTURES
        
        THE WIDTH AND HEIGHT HAS TO BE OF THE SAME VALUE
    ****/
    
    height:$widthAndHeight;
    width:$widthAndHeight;
    border-radius:50%;    


}
