
@use "small_mobile";
@use "mobile";
@use "tablet";
@use "desktop";
@use "large_desktop";



@use "../../../../../css/mixins/displays/displays" as display;
@use "../../../../../css/mixins/dimensions/dimensions" as dimension;
@use "../../../../../css/mixins/position/positions" as position;
@use "../../../../../css/mixins/shapes/shapes" as shapes;
@use "../../../../../css/mixins/looks/looks" as looks;
@use "../../../../../css/mixins/effects/effects" as effects;
@use "../../../../../css/mixins/general_smetio_classes/general_smetio_classes" as classes;


// @import "../../../../../css/variables/devices/device_sizes";
// @import "../../../../../css/mainClasses";

.smetio-color-scheme-container {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
}

.smetio-color-scheme-color-type {
    // @extend.smetio-color-scheme-container;
    // margin-top: 20px;
    // margin-bottom: 20px;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    .smetio-color-scheme-color-type-colors {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        // margin-top: 50px;

        .smetio-color-scheme-color-type-tints-or-shades {

            // width: auto;
            flex: 1;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            

            .smetio-color-scheme-box{
                // min-width:9%; 
                // max-width:9%; 
                // width:9%; 
                flex: 1;
                overflow: hidden;
                min-height:40px; 
                border-radius: 1px !important;
                height:40px;
                // margin: 10px;
                display: flex;
                flex-direction: row;

                &:hover{
                    cursor: pointer;
                    min-width:100px !important; 
                    max-width:100px !important; 
                    width:100px !important; 
                    min-height:50px !important; 
                    height:50px !important; 
                }
            
                .smetio-color-scheme-box-color {
                    // @extend.smetio-color-scheme-box;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                }
                .smetio-color-scheme-box-text {
                    font-size: 80%;
                    display: none;
                }
            
            }
        }

        .smetio-color-scheme-color-type-main-color {
            flex: unset !important;
            min-width:100px !important; 
            max-width:100px !important; 
            width:100px !important; 
            // overflow: hidden;
            min-height:50px !important; 
            height:50px !important; 
            // background-color: green;
            .smetio-color-scheme-box {
                min-width:100% !important; 
                max-width:100% !important; 
                width:100% !important; 
                // overflow: hidden;
                min-height:50px !important; 
                height:50px !important; 
            }
        }
        .smetio-color-scheme-color-type-shades {
            // @extend.smetio-color-scheme-color-type-colors;
        }
        
        .smetio-color-scheme-color-type-tints {
            // @extend.smetio-color-scheme-color-type-colors;
            flex-direction: row-reverse !important;
        }

    }
    

}












