@use "small_mobile";
@use "mobile";
@use "tablet";
@use "desktop";
@use "large_desktop";



// @use "../../../../../css/mixins/displays/displays" as display;
// @use "../../../../../css/mixins/dimensions/dimensions" as dimension;
// @use "../../../../../css/mixins/position/positions" as position;
// @use "../../../../../css/mixins/shapes/shapes" as shapes;
// @use "../../../../../css/mixins/looks/looks" as looks;
// @use "../../../../../css/mixins/effects/effects" as effects;
// @use "../../../../../css/mixins/general_smetio_classes/general_smetio_classes" as classes;



// @import "../../../../../css/variables/devices/device_sizes";
// @import "../../../../../css/mainClasses";

// @import "../../../../../css/latestSmetioStyleSheet";

.smetio-customer-website-description {
    // background-color: green;
    // width: 500px !important;
    // min-width: 500px !important;

    flex: 1 !important;
    min-width: 100% !important;
    max-width: 100% !important;
    width: 100% !important;
    min-height: 100% !important;
    height: 100% !important;
    display: flex;
    flex-direction: column !important;
    flex-wrap: wrap;
    // position: relative;
    // background-color: aquamarine;

    h4 {
        overflow-wrap: break-word;
        // word-break: normal;
        word-break: keep-all;
        min-width: 100% !important;
        max-width: 100% !important;
        width: 100% !important;
        margin: 0 !important;
        margin-bottom: 20px !important;
        @extend.smetio-dominant-text;
    }

    .smetio-customer-website-description-address {
        // all: unset !important;
        min-height: 0 !important;
        height: auto !important;
        width: 100%;
        margin: 0 !important;
        display: flex;
        flex-direction: row !important;
        flex-wrap: wrap !important;
        align-items: flex-start !important;
        justify-content: flex-start !important;
        // background-color: antiquewhite;
        text-align: left !important;
        opacity: .7;
        // margin-top: 30px !important;

        .smetio-customer-website-description-address-icon {
            i {
                font-size: 110%;
                margin-right: 5px;
            }
        }

        .smetio-customer-website-description-address-text {
            flex: 1;
        }

    }

    .smetio-customer-website-open-or-not {
        margin: 0 !important;
        padding: 0 !important;
        width: auto !important;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-items: flex-end;
        flex-wrap: nowrap;
        position: absolute;
        right: 10px;
        bottom: 10px;
        text-align: right;
        font-size: 110%;
        font-weight: 400;

        // background-color: aquamarine;
        // .smetio-customer-website-open-or-not-closed, .smetio-customer-website-open-or-not-open {
        //     // width: 100%;
        //     // text-align: right !important;
        //     // display: flex;
        //     // flex-direction: row;
        //     // align-items: flex-end !important;
        //     // justify-items: flex-end !important; 
        // }
        .smetio-customer-website-open-or-not-closed {           
            @extend.smetio-danger-text;
        }
        .smetio-customer-website-open-or-not-open {
            @extend.smetio-success-text;
        }
    }
}

:root {
    --smetio-customer-website-locations-and-map-div-item-width: 45%;
    --smetio-customer-website-map-display: flex;
    --smetio-photo-and-description-width: 30%;
    --smetio-customer-website-locations-items-justify-content:flex-start;
    --smetio-customer-website-map-instruction-display:none;
    --smetio-customer-website-view-btn-display:none;
    --smetio-customer-website-map-display:flex;
    --smetio-customer-website-search-view-btn-margin-right:35px;
    --smetio-photo-and-description-item-total-1:90%;
    --smetio-photo-and-description-item-total-2:45%;
}

.smetio-customer-website-business-locations {

    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding-bottom:100px;

    .smetio-customer-website-locations-and-map-div {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        flex-wrap: wrap;

        .smetio-customer-website-locations,
        .smetio-customer-website-map {
            flex-direction: column !important;
            justify-content: flex-start !important;
            width: var(--smetio-customer-website-locations-and-map-div-item-width);
            max-width: var(--smetio-customer-website-locations-and-map-div-item-width);
            min-height: 450px;
            max-height: 850px;
            height: auto;
            overflow-y: scroll;
            overflow-x: hidden;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: flex-start;

            &[data-smetio-is-visible=false] {
                display: none;
            }
            &[data-smetio-is-visible=true] {
                display: flex;
            }
            // flex-direction: column;
            // flex-wrap: wrap;
            flex-wrap: nowrap !important;
            @extend.smetio-hiddenScrollBar;


            .smetio-customer-website-btn {
                width: auto;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                // align-self: flex-end;
                // justify-self: flex-end;
                font-size: 100%;
                margin-left: 5px;
                margin-right: 5px;
                // position: absolute;
                // right: 5px;
                // top: 5px;
                // padding: 7.5px;
                border-radius: 5px;
                @extend.smetio-dominant-text;
                // align-self: flex-end;
                justify-self: flex-end;
                // @extend.smetio-dominant-background;
                // @extend.smetio-accent-text;
                i {
                    font-size: 110%;
                    margin-right: 5px;
                }

                &:hover {
                    cursor: pointer;
                    @extend.smetio-dominant-text;
                    @extend.smetio-accent-background;
                    padding: 7.5px;
                }
            }

            .smetio-customer-website-view-btn {
                display: var(--smetio-customer-website-view-btn-display) !important;
            }

            .smetio-customer-website-locations-map-instruction {
                width: 100%;
                display: flex;
                flex-direction: row;        
                // justify-content: center;
                align-items: center;  
                margin-bottom: 20px;      
                h6 {
                    flex: 1;
                    // width: 100%;
                    font-size: 120%;
                    text-align: center;
                    // margin-right: 50px;
                    @extend.smetio-information-text;
                    
                }
            }

        }


        .smetio-customer-website-locations {

            // flex-direction: column !important;
            // justify-content: flex-start !important;
            // background-color:burlywood !important;
            .smetio-customer-website-search-view-btn {
                margin-right: var(--smetio-customer-website-search-view-btn-margin-right) !important;
            }

            .smetio-customer-website-locations-items {
                min-width: 100%;
                width: 100%;
                // width: auto !important;
                height: auto;
                // background-color: antiquewhite !important;
                // overflow-y: scroll;
                // overflow-x: hidden;
                // position: relative;
                display: flex;
                // flex-direction: column;
                flex-wrap: wrap;
                background-color: inherit;
                flex-direction: row !important;
                justify-content: var(--smetio-customer-website-locations-items-justify-content) !important;
                // justify-content: space-around;
                align-items: flex-start !important;
                // background-color: antiquewhite;

                .smetio-photo-and-description {
                    // width: 30% !important;
                    width: var(--smetio-photo-and-description-width);
                    
                }

                &[data-smetio-total-items="1"] {
                    .smetio-photo-and-description { width: var(--smetio-photo-and-description-item-total-1); }
                    justify-content:center !important;
                }
                &[data-smetio-total-items="2"] {
                    .smetio-photo-and-description { width: var(--smetio-photo-and-description-item-total-2); }
                }
            }

        }

        .smetio-customer-website-map {
            // width: 50%;
            // background-color:inherit;
            // flex: 1 !important;
            // min-height: 100% !important;
            // height: 100% !important;
            // margin-top: 30px;
            // align-self: flex-end !important;
            display: var(--smetio-customer-website-map-display);


            h6 {
                visibility: hidden;
            }

            .smetio-customer-website-locations-map-instruction {
                display: var(--smetio-customer-website-map-instruction-display) !important;
            }

            .smetio-customer-website-map-item {
                width: 100%;
                min-height: inherit;
                height: inherit;
                align-self: flex-end !important;
                // display: var(--smetio-customer-website-map-display);
                // background-color: antiquewhite;
                // @extend.smetio-dominant-background;

                iframe {
                    min-width: 100%;
                    width: 100%;
                    min-height: 100%;
                    height: 100%;
                    border: solid .5px transparent;
                    outline: solid .5px transparent;
                    // opacity: .7;
                }
    
            }

        }
    }

    @include small_mobile.smallMobileScreen;
    @include mobile.mobileScreen;
    @include tablet.tabletScreen;
    @include desktop.desktopScreen;
    @include large_desktop.largeDesktopScreen;

}

/* .smetio-multimediaPlayer ENDS HERE */