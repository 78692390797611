
@use "small_mobile";
@use "mobile";
@use "tablet";
@use "desktop";
@use "large_desktop";



@use "../../../../../css/mixins/displays/displays" as display;
@use "../../../../../css/mixins/dimensions/dimensions" as dimension;
@use "../../../../../css/mixins/position/positions" as position;
@use "../../../../../css/mixins/shapes/shapes" as shapes;
@use "../../../../../css/mixins/looks/looks" as looks;
@use "../../../../../css/mixins/effects/effects" as effects;
@use "../../../../../css/mixins/general_smetio_classes/general_smetio_classes" as classes;


// @import "../../../../../css/variables/devices/device_sizes";
// @import "../../../../../css/mainClasses";



.smetio-app-ui-coming-soon {

    z-index: 1000;

    @include display.flex-wrap(column);
    @include display.flex-perfect-center;

    width: 100%;
    height: 100%;
    background-color: inherit;

    h2 {
        font-size: 150%;
        font-weight: 400;
        margin-bottom: var(--smetio-app-ui-icon-default-margin);
        @extend.smetio-dominant-text;
    } 

    h5 {
        font-size: 100%;  
        font-weight: 200;  
        @extend.smetio-grey-text;            
    }

   

    @include small_mobile.smallMobileScreen;
    @include mobile.mobileScreen;
    @include tablet.tabletScreen;
    @include desktop.desktopScreen;
    @include large_desktop.largeDesktopScreen;      

}/* #smetio-app-ui-action-bar ENDS HERE */