@use "./mixins/displays/displays" as display;
@use "./mixins/dimensions/dimensions" as dimension;
@use "./mixins/position/positions" as position;
@use "./mixins/shapes/shapes" as shapes;
@use "./mixins/looks/looks" as looks;
@use "./mixins/effects/effects" as effects;
@use "./mixins/general_smetio_classes/general_smetio_classes" as classes;


@import "./variables/devices/device_sizes";
@import "./latestSmetioStyleSheet";

:root {

    --small-mobile-min: {
      $small_mobile_min
    };

    --small-mobile-max: {
      $small_mobile_max
    };

    --mobile-min: {
      $mobile_min
    };

    --mobile-max: {
      $mobile_max
    };

    --tablet-min: {
      $tablet_min
    };

    --tablet-max: {
      $tablet_max
    };

    --desktop-min: {
      $desktop_min
    };

    --desktop-max: {
      $desktop_max
    };

    --large-desktop-min: {
      $large_desktop_min
    };

    --large-desktop-max: {
      $large_desktop_max
    };


    /* MODALS */

    --smetio-app-ui-region-and-languages-content-sort-select-diplay:block;

    --smetio-app-ui-icon-default-margin:15px;

    --smetio-ui-language-options-width:25%;

    /* HEAD */

    --smetio-app-ui-head-height:35px;
    --smetio-app-ui-head-width:100vw;

    /* HEAD SECTION 1 */

    --smetio-app-ui-head-section-1-width:20%;
    --smetio-app-ui-head-section-1-height:100%;

    --smetio-app-ui-menu-bar-width:60px;
    --smetio-app-ui-menu-bar-icon-size:140%;


    /* HEAD SECTION 2 */

    --smetio-app-ui-head-section-2-height:100%;

    --smetio-app-ui-logo-height:20px;
    --smetio-app-ui-profile-height:30px;

    --smetio-app-ui-head-section-2-width:80%;


    /* BODY */


    /* BODY SECTION 1 */

    --smetio-app-ui-body-section-1-fully-open-width:var(--smetio-app-ui-head-section-1-width);

    --smetio-app-ui-menu-text-font-weight:100;


    /* BODY SECTION 2 */

    --smetio-app-ui-body-section-2-fully-open-width:100%;

    --smetio-app-ui-body-section-2-fully-closed-width:80%;

    --smetio-app-ui-task-bar-fully-closed-position:fixed;

    --smetio-app-ui-task-bar-justify-content:center;

    --smetio-app-ui-loaded-contents-width-nr:95;

    --smetio-app-ui-loaded-contents-width:100%;

    --smetio-app-ui-task-bar-icons-spacing:40px;

    --smetio-app-ui-side-bar-icons-spacing:20px;


    /**** SMETIO SMARTBOX ****/

    --smetio-smartBox-min-width:150px;


  }

.smetio-hiddenScrollBar {

    &::-webkit-scrollbar {

        display: none;

    }

    &{

		-ms-overflow-style: none;  /* IE and Edge */
		scrollbar-width: 0 ;  /* Firefox */
    }

}


body {

    //min-height: 100vh;
    //min-width: 100vw;
    //height: 100vh;
    //width: 100vw;

    @extend .smetio-hiddenScrollBar;

}


.smetio-white-text {
    color:#f8f8ff;
}

.smetio-bg-blue {

	// background-color: var(--smetio-button-color);
	// color: var(--smetio-text-secondary-color);
    @extend.smetio-accent-background;
    @extend.smetio-dominant-text;
}

.smetio-basic-anchor {

    text-decoration: none;
    display: block;
}

.smetio-cursor-pointer {

    cursor: pointer;
}

.smetio-bg-blue-on-hover {

	&:hover {

		// background-color: var(--smetio-button-color-hover);
        // color: var(--smetio-text-secondary-color);
        @extend.smetio-accent-background-reverse;
        @extend.smetio-dominant-text-reverse;
		@extend .smetio-cursor-pointer;

	}

	&:active {

		// background-color: var(--smetio-button-color-active);
        @extend.smetio-accent-background;
        @extend.smetio-dominant-text;

	}

    &[data-smetio-active="yes"]{

        // background-color: var(--smetio-button-color-active);
        @extend.smetio-accent-background-reverse;
        @extend.smetio-dominant-text-reverse;
    } 

}

.smetio-bg-blue-with-hover-active {

	@extend .smetio-bg-blue;

	@extend .smetio-bg-blue-on-hover;		

}


.smetio-blue-text {

    // color: var(--smetio-button-color);
    @extend.smetio-accent-text;

}

.smetio-blue-text-with-hover-active {

	@extend .smetio-blue-text;

	&:hover {

		color: var(--smetio-button-color-hover);
		@extend .smetio-cursor-pointer;

	}

	&:active {

		color: var(--smetio-button-color-active);

	}

    &[data-smetio-active="yes"]{

        color: var(--smetio-button-color-active);

    } 		

}

.smetio-green-text {

    // color: var(--smetio-success-color);
    @extend.smetio-success-text;

}

.smetio-success-text {

    @extend .smetio-green-text;

}


.smetio-default-border {

	// border: solid 0.5px var(--smetio-border-color);
    //@extend.smetio-neutral-border-trans-30;
}

.smetio-default-border-with-hover-active {

	@extend .smetio-default-border;

	&:hover {

		// border-color: var(--smetio-border-color-hover);
        //@extend.smetio-neutral-border-trans-70;

	}

	&:active {

		// border-color: var(--smetio-border-color-active);
        //@extend.smetio-neutral-border-trans-90;

	}	

    &[data-smetio-active="yes"]{

        // border-color: var(--smetio-border-color-active);
        //@extend.smetio-neutral-border-trans-70;

    } 		
}

.smetio-error-border {

    // @extend .smetio-default-border;

    // border-color: var(--smetio-error-color);
    @extend.smetio-danger-border;

}

.smetio-error-bg-on-hover{

    &:hover {

        // background-color: var( --smetio-error-color);
        @extend.smetio-danger-background;

        @extend .smetio-cursor-pointer;    
        // @extend .smetio-default-secondary-text-color;

        @extend.smetio-danger-text;

    }                     

    &:active {

        // background-color: var(--smetio-error-color);
        @extend.smetio-danger-background;

    }    
}

.smetio-error-bg-with-hover-active {

    // background-color: var( --smetio-error-color);
    @extend.smetio-danger-background;

    @extend .smetio-default-secondary-text-color;
    @extend .smetio-error-bg-on-hover;

}

.smetio-show-border-on-hover{

    border-style: solid;
    border-width: 0.5px;
    border-color:transparent;

	&:hover {

		// border-color: var(--smetio-border-color-hover);
        //@extend.smetio-neutral-border-trans-30;

	}

	&:active {

		// border-color: var(--smetio-border-color-active);
        //@extend.smetio-neutral-border-trans-70;

	}	    
}



.smetio-default-icon-tetiary {

	// color: var(--smetio-icon-tetiary-color);
    //@extend.smetio-neutral-text-trans-30;

}

.smetio-default-icon-tetiary-with-hover-active  {

	@extend .smetio-default-icon-tetiary;

    &:hover {

        // color: var(--smetio-icon-tetiary-color-hover); 
        //@extend.smetio-neutral-text-trans-70;

        @extend .smetio-cursor-pointer;    
    }                     

    &:active {

        // color: var(--smetio-icon-tetiary-color-active);  
        //@extend.smetio-neutral-text-trans-90; 
    }

    &[data-smetio-active="yes"]{

        // color: var(--smetio-icon-tetiary-color-active); 
        //@extend.smetio-neutral-text-trans-70;

    } 

}

.smetio-default-ui-bg {

	// background-color: var(--smetio-layout-main-bg-color);
    @extend.smetio-dominant-background;
}

.smetio-default-grey1-bg {

	// background-color: var(--smetio-layout-grey-color-1);
    @extend.smetio-neutral-background-trans-10;
}

.smetio-default-grey1-bg-with-hover-active  {

	@extend .smetio-default-grey1-bg;

    &:hover {

        // background-color: var(--smetio-layout-grey-color-2); 
        @extend.smetio-neutral-background-trans-30;
        @extend .smetio-cursor-pointer;    
    }                     

    &:active {

        // background-color: var(--smetio-layout-grey-color-3); 
        @extend.smetio-neutral-background-trans-50; 
    }

    &[data-smetio-active="yes"]{

        // background-color: var(--smetio-layout-grey-color-3);  
        @extend.smetio-neutral-background-trans-30;

    } 
}


.smetio-default-grey2-bg {

	background-color: var(--smetio-layout-grey-color-2);
}

.smetio-default-grey2-bg-with-hover-active  {

	@extend .smetio-default-grey2-bg;

    &:hover {

        background-color: var(--smetio-layout-grey-color-3); 
        @extend .smetio-cursor-pointer;    
    }                     

    &:active {

        background-color: rgba(var(--smetio-layout-grey-color-3-rgb), 0.8);  
    }

    &[data-smetio-active="yes"]{

        background-color: var(--smetio-layout-grey-color-3);   

    } 

}

.smetio-default-grey3-bg {

	background-color: var(--smetio-layout-grey-color-3);
}

.smetio-default-grey3-bg-with-hover-active  {

	@extend .smetio-default-grey3-bg;

    &:hover {

        background-color: var(--smetio-layout-grey-color-2); 
        @extend .smetio-cursor-pointer;    
    }                     

    &:active {

        background-color: var(--smetio-layout-grey-color-1) 
    }

    &[data-smetio-active="yes"]{

        background-color: var(--smetio-layout-grey-color-1);  

    } 
}

.smetio-default-primary-text-color {

	// color: var(--smetio-text-primary-color);
    @extend.smetio-dominant-text;
}

.smetio-default-primary-text-color-with-hover-active {

	@extend .smetio-default-primary-text-color;

    &:hover {

        color: var(--smetio-text-secondary-color);
        @extend .smetio-cursor-pointer;    
    }                     

    &:active {

         color: rgba(var(--smetio-text-secondary-color-rgb), 0.5);  
    }

    &[data-smetio-active="yes"]{

         color: rgba(var(--smetio-text-secondary-color-rgb), 0.5);  

    } 
}

.smetio-default-secondary-text-color {

	color: var(--smetio-text-secondary-color);
}

.smetio-default-secondary-text-color-with-hover-active {

	@extend .smetio-default-secondary-text-color;

    &:hover {

        color: var(--smetio-text-primary-color);
        @extend .smetio-cursor-pointer;    
    }                     

    &:active {

         color: rgba(var(--smetio-text-primary-color-rgb), 0.5); 
    }

    &[data-smetio-active="yes"]{

         color: rgba(var(--smetio-text-primary-color-rgb), 0.5);  

    } 
}



.smetio-toggleDisplay {

    &[data-smetio-isVisible="yes"]{

        display: flex;

    } 

    &[data-smetio-isVisible="no"]{

        display: none;

    } 

}

.smetio-border-box {

    box-sizing: border-box;

    *{
        box-sizing: border-box;        
    }

}

.smetio-border-box-no-padding-margin {

    @extend .smetio-border-box;

    padding: 0;
    margin: 0;

    *{
        padding: 0;
        margin: 0;        
    }

}


.smetio-disabled-bg {

    &[data-smetio-isDisabled="yes"]{

        cursor: not-allowed;
        pointer-events: none;
        // background-color: var(--smetio-disable-color); 
        // color: var(--smetio-text-primary-color); 

        @extend.smetio-neutral-background-trans-10;
        @extend.smetio-dominant-text;

    }   

}

.smetio-disabled-text {

    &[data-smetio-isDisabled="yes"]{

        cursor: not-allowed;
        pointer-events: none;
        // color: var(--smetio-disable-color); 
        //@extend.smetio-neutral-text-trans-30;

    }   

}

* {

    &:disabled {

        cursor: not-allowed;
        pointer-events: none;
        // background-color: var(--smetio-layout-grey-color-1) !important; 
        // color: var(--smetio-text-primary-color) !important; 

        @extend.smetio-neutral-background-trans-70;
        @extend.smetio-dominant-text;

        &[data-smetio-disabled-style="dark"]{

            // background-color: var(--smetio-disable-color) !important;  
            @extend.smetio-neutral-background-trans-70;

        } 

    }

}


.smetio-default-lightbox-bg {

	background-color:rgba(var(--smetio-layout-grey-color-3-rgb), 0.5);

    &[data-smetio-lightbox-style="light"]{

        // background-color: rgba(var(--smetio-layout-grey-color-1-rgb), 0.5) !important;  
        @extend.smetio-neutral-background-trans-50;

    }     

    &[data-smetio-lightbox-style="transparent"]{

        background-color: transparent !important;  

    }

}

.smetio-default-box-shadow {

	box-shadow: 2px 2px 10px 2px rgba(var(--smetio-shadow-color-rgb),0.4);	

}

.smetio-accent-text {

    color: var(--smetio-accent-color);

}

.smetio-accent-text-with-hover-active  {

    @extend .smetio-accent-text;

    &:hover {

        color: var(--smetio-accent-color-hover);
        @extend .smetio-cursor-pointer;    
    }                     

    &:active {

         color: var(--smetio-accent-color-active); 
    }

    &[data-smetio-active="yes"]{

         color: var(--smetio-accent-color-active);   

    }     
    
}

.smetio-accent-text-with-hover-active-reverse  {

    //@extend .smetio-accent-text;
    color: var(--smetio-accent-color-active); 

    &:hover {

        color: var(--smetio-accent-color-hover);
        @extend .smetio-cursor-pointer;    
    }                     

    &:active {

         //color: var(--smetio-accent-color-active); 
         @extend .smetio-accent-text;
    }

    &[data-smetio-active="yes"]{

         //color: var(--smetio-accent-color-active);  
         @extend .smetio-accent-text; 

    }     
    
}


.smetio-accent-bg {

    background-color: var(--smetio-accent-color);

}

.smetio-accent-bg-with-hover-active  {

    @extend .smetio-accent-bg;

    &:hover {

        background-color: var(--smetio-accent-color-hover);
        @extend .smetio-cursor-pointer;    
    }                     

    &:active {

         background-color: var(--smetio-accent-color-active); 
    }

    &[data-smetio-active="yes"]{

         background-color: var(--smetio-accent-color-active);   

    }     
    
}

.smetio-link-text {

    color: var(--smetio-link-color);

}

.smetio-link-text-with-hover-active  {

    @extend .smetio-link-text;

    &:hover {

        color: var(--smetio-link-color-hover);
        @extend .smetio-cursor-pointer;    

    }                     

    &:active {

        color: var(--smetio-link-color-active);

    }

    &[data-smetio-active="yes"]{

        color: var(--smetio-link-color-active); 

    }     
    
}

.smetio-error-text {

    // color: var( --smetio-error-color);
    @extend.smetio-danger-text;

}

.smetio-primary-to-error-text {

    @extend .smetio-default-primary-text-color;

    &:hover {

        // color: var( --smetio-error-color);
        @extend.smetio-danger-text;
        @extend .smetio-cursor-pointer;    

    }                     

    &:active {

        // color: var(--smetio-error-color);
        @extend.smetio-danger-text;

    }

    &[data-smetio-active="yes"]{

        // color: var(--smetio-error-color); 
        @extend.smetio-danger-text;

    }     
}


.smetio-typical-sideNav {

    min-height: 100vh;
    height: 100vh;
    position: fixed;
    top: 0;
    @extend.smetio-dominant-background;
    //transition: width 0.5s;
    border-left: solid 0.5px var(--smetio-border-color);
    z-index: 5;

    &[data-smetio-side="left"]{
          
        border-right: solid 0.5px var(--smetio-border-color);
        left: 0;

    }

    &[data-smetio-side="right"]{
          
        border-left: solid 0.5px var(--smetio-border-color);
        right: 0;

    }        

    &[data-smetio-status="open"]{
          
        min-width: 40%;
        width: 40%;

    }

    &[data-smetio-status="close"]{
          
        min-width: 0;
        width: 0;
        
    }       

    .smetio-sideNav-close {

        @extend .smetio-primary-to-error-text;
        padding: 5px;
        width: 30px;
        height: auto;
        i {
            font-size: 120%;
        }
    } 

    .smetio-sideNav-menu {

        display: flex;
        flex: 1;
        flex-direction: column;
        width: 100%;
        height: auto;
        margin: var(--smetio-app-ui-icon-default-margin);
    }
         
} 

.smetio-no-border-or-outline {

    border: solid 0.5px transparent;
    outline: solid 0.5px transparent;
}


@-webkit-keyframes spin {
    0%  {-webkit-transform: rotate(0deg);}
    100% {-webkit-transform: rotate(360deg);}   
}

.smetio-spin {

    animation: spin 3s infinite linear;
    -webkit-animation: spin 3s infinite linear;
}

.smetio-button {

    @include display.flex-wrap(column);
    @include display.flex-perfect-center; 
    width: 80% !important;
    @extend .smetio-no-border-or-outline;
    padding: calc(var(--smetio-app-ui-icon-default-margin)/4);
    border-radius: calc(var(--smetio-app-ui-icon-default-margin)/2);
    margin-top: var(--smetio-app-ui-icon-default-margin);
    margin-bottom: calc(var(--smetio-app-ui-icon-default-margin) * 2);
    font-weight: 400;
    font-size: 130%;

}


.smetio-blue-button {

    @extend .smetio-button;
    @extend .smetio-bg-blue-with-hover-active;
    @extend .smetio-default-secondary-text-color;
    
}


.smetio-signup-login-page {

    @include display.flex-wrap(column);
    justify-content: flex-start;
    width: 100%;    
    font-size: 100%;
    position: relative;
    flex-wrap: wrap !important;
    min-height: 100% !important;
    height: auto !important;

    h1, h2 {

        @extend .smetio-blue-text;
        font-size: 150%;
        font-weight: 400;
        margin-top: calc(var(--smetio-app-ui-icon-default-margin) * 2 );

    }

    h5 {

        font-size: 100%;
        font-weight: 100;
        font-style: italic;
        // color: rgba(var(--smetio-text-secondary-color-rgb), 0.9);
        @extend.smetio-dominant-text;
        margin-bottom: var(--smetio-app-ui-icon-default-margin);

    }

}


.smetio-codeVerification-holder {

    @include display.flex-wrap(column);
    width: 100%;
    min-height: 200px;
    height: 100%;    
    position: relative;

}


.smetio-signup-login-congratulation {

    @extend .smetio-codeVerification-holder;
    @include display.flex-perfect-center; 
    
    > div{

        //margin: var(--smetio-app-ui-icon-default-margin);
        margin-top: var(--smetio-app-ui-icon-default-margin);
        margin-bottom: var(--smetio-app-ui-icon-default-margin);
        
        width: 100% !important;

        @include display.flex-wrap(column);
        @include display.flex-perfect-center; 
    }

    h1{

        @extend .smetio-success-text;
        margin-top: calc(var(--smetio-app-ui-icon-default-margin) * 2) !important;
        margin-bottom: var(--smetio-app-ui-icon-default-margin) !important;
        text-align: center;

    }

    p, h5 {

        margin-top: calc(var(--smetio-app-ui-icon-default-margin) * 2) !important;
        margin-bottom: calc(var(--smetio-app-ui-icon-default-margin) * 2) !important; 
        text-align: center;

        a {
            text-decoration: none;
            display: block !important; 
            @extend .smetio-blue-text-with-hover-active;
            text-align: center;
        }    

    }

}

.online_status_colors {

    &[data-smetio-online-status="online"] {
        background-color: var(--smetio-success-color);
    }

    &[data-smetio-online-status="offline"] {
        background-color: var(--smetio-disable-color);
    }

    &[data-smetio-online-status="busy"] {
        background-color: var(--smetio-accent-color);
    }

    &[data-smetio-online-status="working"] {
        @extend .smetio-bg-blue;
    }        

    &[data-smetio-online-status="do_not_disturb"] {
        background-color: var(--smetio-link-color);
    }

}

.defaultPadding {
    padding-top: calc(var(--smetio-app-ui-icon-default-margin)/4) !important;
    padding-bottom: calc(var(--smetio-app-ui-icon-default-margin)/4) !important;
    padding-left: calc(var(--smetio-app-ui-icon-default-margin)/4) !important;
    padding-right: calc(var(--smetio-app-ui-icon-default-margin)/4) !important;
}

/*
.smetio-input-div {

    @include display.flex-wrap(column);
    @include display.flex-perfect-center; 
    //width: 100%;
    width: 90%;
    height: auto;
    margin-bottom: calc(var(--smetio-app-ui-icon-default-margin)/2); 
    margin-top: calc(var(--smetio-app-ui-icon-default-margin)/2); 

    * {
        width: 100%;
        text-align: left;
    }

    .smetio-instruction {

        @extend .smetio-blue-text;
        align-items: flex-start !important;
        font-size: 100%;
        font-weight: 100;
        display: none;

    }

    label {

        margin-bottom: -20px;
        font-size: 110%;
        font-weight: 300;

    }

    input {

        padding: calc(var(--smetio-app-ui-icon-default-margin)/4); 
        border-radius: calc(var(--smetio-app-ui-icon-default-margin)/4); 
        @extend.smetio-dominant-background;
        outline: solid 0.5px transparent;
        @extend .smetio-default-border-with-hover-active;

        &[data-smetio-error="yes"]{
              
            @extend .smetio-error-border;
            
        }


    }

    .smetio-error {

        @extend .smetio-error-text;
        align-items: flex-start !important;
        font-size: 95%;
        font-weight: 100;
        display: none;

    }



} .smetio-input-div ENDS HERE */


.smetio-signup-page-gobackBtn {

    flex: unset;
    max-width: 50px;
    width: auto;
    height: auto;
    @extend .smetio-default-icon-tetiary-with-hover-active;
    margin-left: calc(var(--smetio-app-ui-icon-default-margin) * 2);
    font-size: 100%;

    i {

        font-size: 150%;
        margin-left: var(--smetio-app-ui-icon-default-margin);

    }

}

.smetio-question-icon {

    font-size: 70%;
    @extend .smetio-blue-text-with-hover-active;

    i {
        font-size: 100%;
    }
}

.smetio-wiki-article {

    .smetio-wiki-article-tile {

        margin-top: calc(var(--smetio-app-ui-icon-default-margin) * 2);
        margin-bottom: calc(var(--smetio-app-ui-icon-default-margin) * 2);
        font-weight: bold;
        font-size: 120%;

    }

    .smetio-wiki-article-paragraphs {

        p {

            font-size: 100%;
            margin-top: var(--smetio-app-ui-icon-default-margin);

        }

        p:first-child {

            margin-top:0 !important;

        }

        p:last-child {

            margin-bottom: calc(var(--smetio-app-ui-icon-default-margin) * 2) !important;

        }

    }
}

.smetio-majorNotification {

    min-width: 100%;
    min-height: 50px;
    width: 100%;
    height: auto;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1000;
    font-size: 100%;
    
    // @extend.smetio-dominant-background;
    @extend.smetio-dominant-background;
    
    @include display.flex-wrap(column);
    @include display.flex-perfect-center; 

}

.smetio-fullW-fullH {

    min-width: 100vw;
    min-height: 100vh;
    width: 100vw;
    height: 100vh;
  
}
.smetio-app-splashScreen {

    @extend .smetio-fullW-fullH;
    @extend.smetio-dominant-background;
    @include display.flex-wrap(column);
    @include display.flex-perfect-center; 
    position: fixed;
    z-index: 3;
    
    img {
        width: 50%;
        max-width: 300px;
        height: auto;
        margin-bottom: var(--smetio-app-ui-icon-default-margin) !important;
    }

    h1 {
        font-size: 120%;
        @extend .smetio-default-primary-text-color;
        margin-bottom: calc(var(--smetio-app-ui-icon-default-margin) * 2);
        letter-spacing: 3px;

        //--animate-delay:2s;
    }

    h3 {

        font-size: 90%;
        @extend .smetio-default-secondary-text-color;
        @include display.flex-wrap(column);
        @include display.flex-perfect-center; 
        i{
            font-size: 150%;
        }
    }

}


/* Heading 1 */
.smetio-h1-text-size {
    font-size: 48px;
    line-height: 1.3;
}

/* Heading 2 */
.smetio-h2-text-size {
    font-size: 36px;
    line-height: 1.3;
}

/* Heading 3 */
.smetio-h3-text-size {
    font-size: 30px;
    line-height: 1.4;
}

/* Heading 4 */
.smetio-h4-text-size {
    font-size: 24px;
    line-height: 1.4;
}

/* Heading 5 */
.smetio-h5-text-size {
    font-size: 20px;
    line-height: 1.5;
}

/* Heading 6 */
.smetio-h6-text-size {
    font-size: 16px;
    line-height: 1.6;
}

/* Paragraph text */
.smetio-p-text-size {
    font-size: 18px;
    line-height: 1.6;
}

/* Small text */
.smetio-small-text-size {
    font-size: 14px;
    line-height: 1.5;
}

/* List items */
.smetio-li-text-size {
    font-size: 18px;
    line-height: 1.6;
}

/* Caption text */
.smetio-caption-text-size {
    font-size: 14px;
    line-height: 1.5;
}

/* Link text */
.smetio-a-text-size {
    font-size: 18px;
    line-height: 1.6;
}

/* Button text */
.smetio-button-text-size {
    font-size: 18px;
    line-height: 1.6;
}

/* Input text */
.smetio-input-text-size {
    font-size: 16px;
    line-height: 1.5;
}

.smetio-basic-button-style {
    width: auto;
    padding:10px;
    border-radius: 5px;
    // font-size: 26px;
    @extend.smetio-button-text-size;
    border: solid 0.5px transparent;
    outline: solid 0.5px transparent;
}

.smetio-dominant-button {
    @extend.smetio-3ack-main-website-basic-btn;
    @extend.smetio-dominant-background;
    @extend.smetio-accent-text;

    &:hover {
        cursor: pointer;
        @extend.smetio-accent-background;
        @extend.smetio-dominant-text;
    }
}
.smetio-accent-button {
    @extend.smetio-3ack-main-website-basic-btn;
    @extend.smetio-accent-background;
    @extend.smetio-dominant-text;

    &:hover {
        cursor: pointer;
        @extend.smetio-dominant-background;
        @extend.smetio-accent-text;
    }
}

.smetio-3ack-main-website-basic-btn {
    @extend.smetio-basic-button-style;
}
.smetio-3ack-main-website-dominant-btn {

    @extend.smetio-dominant-button;
}

.smetio-3ack-main-website-accent-btn {

    @extend.smetio-accent-button;
}


.smetio-h1-heading {
    // font-size: 67px !important;
    @extend.smetio-h1-text-size;
}

.smetio-h2-heading {
    // font-size: 51px !important;
    @extend.smetio-h2-text-size;
}

.smetio-h3-heading {
    // font-size: 38px !important;
    @extend.smetio-h3-text-size;
}

.smetio-h4-heading {
    // font-size: 28px !important;
    @extend.smetio-h4-text-size;
}
.smetio-h5-heading {
    // font-size: 21px !important;
    @extend.smetio-h5-text-size;
}

.smetio-h6-heading {
    // font-size: 18px !important;
    @extend.smetio-h6-text-size;
}

.smetio-p-text {
    // font-size: 16px !important;
    @extend.smetio-p-text-size;
}

.smetio-smart-box-button {
    // color: unset;

    @extend.smetio-dominant-button;
    margin-top: 20px !important;
    max-width: 200px;
    width: 50%;
    height: auto;
    padding: 10px !important;
    border-radius: 5px;
    border: solid 1px transparent;
    outline: solid 1px transparent;
    font-size: 100%;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}



.smetio-align-center{
    align-items: center;
}
.smetio-align-start{
    align-items: flex-start;
}
.smetio-align-end{
    align-items: flex-end;
}

.smetio-justify-center{
    justify-content: center;
}

.smetio-justify-start{
    justify-content: flex-start;
}
.smetio-justify-end{
    justify-content: flex-end;
}


.smetio-flex-row {
    display: flex;
    flex-direction: row;
}

.smetio-flex-column{
    display: flex;
    flex-direction: column;
}

.smetio-margin-left-5 {
    margin-left: 5px !important;
}

.smetio-margin-left-10 {
    margin-left: 10px !important;
}

.smetio-margin-left-20 {
    margin-left: 20px !important;
}

.smetio-margin-left-25 {
    margin-left: 25px !important;
}

.smetio-margin-left-30 {
    margin-left: 35px !important;
}

.smetio-margin-left-40 {
    margin-left: 40px !important;
}

.smetio-margin-left-50 {
    margin-left: 50px !important;
}

.smetio-margin-right-5 {
    margin-right: 5px !important;
}

.smetio-margin-right-10 {
    margin-right: 10px !important;
}

.smetio-margin-right-20 {
    margin-right: 20px !important;
}

.smetio-margin-right-25 {
    margin-right: 25px !important;
}

.smetio-margin-right-30 {
    margin-right: 35px !important;
}

.smetio-margin-right-40 {
    margin-right: 40px !important;
}

.smetio-margin-right-50 {
    margin-right: 50px !important;
}


.smetio-margin-top-5 {
    margin-top: 5px !important;
}

.smetio-margin-top-10 {
    margin-top: 10px !important;
}
.smetio-margin-top-15 {
    margin-top: 15px !important;
}

.smetio-margin-top-20 {
    margin-top: 20px !important;
}

.smetio-margin-top-25 {
    margin-top: 25px !important;
}

.smetio-margin-top-30 {
    margin-top: 35px !important;
}

.smetio-margin-top-40 {
    margin-top: 40px !important;
}

.smetio-margin-top-50 {
    margin-top: 50px !important;
}

.smetio-margin-top-60 {
    margin-top: 60px !important;
}

.smetio-margin-bottom-5 {
    margin-bottom: 5px !important;
}

.smetio-margin-bottom-10 {
    margin-bottom: 10px !important;
}

.smetio-margin-bottom-15 {
    margin-bottom: 15px !important;
}

.smetio-margin-bottom-20 {
    margin-bottom: 20px !important;
}

.smetio-margin-bottom-25 {
    margin-bottom: 25px !important;
}

.smetio-margin-bottom-30 {
    margin-bottom: 35px !important;
}

.smetio-margin-bottom-40 {
    margin-bottom: 40px !important;
}

.smetio-margin-bottom-50 {
    margin-bottom: 50px !important;
}
.smetio-margin-bottom-60 {
    margin-bottom: 60px !important;
}

.smetio-padding-left-5 {
    padding-left: 5px !important;
}

.smetio-padding-left-10 {
    padding-left: 10px !important;
}

.smetio-padding-left-20 {
    padding-left: 20px !important;
}

.smetio-padding-left-25 {
    padding-left: 25px !important;
}

.smetio-padding-left-30 {
    padding-left: 35px !important;
}

.smetio-padding-left-40 {
    padding-left: 40px !important;
}

.smetio-padding-left-50 {
    padding-left: 50px !important;
}

.smetio-padding-right-5 {
    padding-right: 5px !important;
}

.smetio-padding-right-10 {
    padding-right: 10px !important;
}

.smetio-padding-right-20 {
    padding-right: 20px !important;
}

.smetio-padding-right-25 {
    padding-right: 25px !important;
}

.smetio-padding-right-30 {
    padding-right: 35px !important;
}

.smetio-padding-right-40 {
    padding-right: 40px !important;
}

.smetio-padding-right-50 {
    padding-right: 50px !important;
}


.smetio-padding-top-5 {
    padding-top: 5px !important;
}

.smetio-padding-top-10 {
    padding-top: 10px !important;
}

.smetio-padding-top-20 {
    padding-top: 20px !important;
}

.smetio-padding-top-25 {
    padding-top: 25px !important;
}

.smetio-padding-top-30 {
    padding-top: 35px !important;
}

.smetio-padding-top-40 {
    padding-top: 40px !important;
}

.smetio-padding-top-50 {
    padding-top: 50px !important;
}


.smetio-padding-bottom-5 {
    padding-bottom: 5px !important;
}

.smetio-padding-bottom-10 {
    padding-bottom: 10px !important;
}

.smetio-padding-bottom-20 {
    padding-bottom: 20px !important;
}

.smetio-padding-bottom-25 {
    padding-bottom: 25px !important;
}

.smetio-padding-bottom-30 {
    padding-bottom: 35px !important;
}

.smetio-padding-bottom-40 {
    padding-bottom: 40px !important;
}

.smetio-padding-bottom-50 {
    padding-bottom: 50px !important;
}


.smetio-text-center {
    text-align: center;
}
.smetio-text-left {
    text-align: left;
}
.smetio-text-right {
    text-align: right;
}

.smetio-anchor-text{
    text-decoration: none;
    display: block;
    // color:inherit;
    @extend.smetio-dominant-text;

    &:hover{
        text-decoration: none;
        @extend.smetio-warning-text;
    }
    &:active{
        text-decoration: none;
        @extend.smetio-information-text;
    }
}

.smetio-flex-column-perfect-center{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.smetio-flex-column-vertical-center{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.smetio-flex-column-horizontal-center{
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
}

.smetio-flex-row-perfect-center{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}


/////
.smetio-data-widths {

    &[data-smetio-width="auto"]{ 
        width: auto;
    }

    &[data-smetio-width="100%"]{ 
        width: 100%;
    }

    &[data-smetio-width="50%"]{ 
        width: 50%;
    }

    &[data-smetio-width="45%"]{ 
        width: 45%;
    }

    &[data-smetio-width="30%"]{ 
        width: 30%;
    }

    &[data-smetio-width="20%"]{ 
        width: 20%;
    }

    &[data-smetio-width="10px"]{ 
        width: 10px;
    }

    &[data-smetio-width="15px"]{ 
        width: 15px;
    }

    &[data-smetio-width="20px"]{ 
        width: 20px;
    }

    &[data-smetio-width="25px"]{ 
        width: 25px;
    }
    &[data-smetio-width="50px"]{ 
        width: 50px;
    }
    &[data-smetio-width="75px"]{ 
        width: 75px;
    }    
    &[data-smetio-width="100px"]{ 
        width: 100px;
    }
    &[data-smetio-width="125px"]{ 
        width: 125px;
    }
    &[data-smetio-width="150px"]{ 
        width: 150px;
    }
    &[data-smetio-width="200px"]{ 
        width: 200px;
    }
    &[data-smetio-width="250px"]{ 
        width: 250px;
    }    
    &[data-smetio-width="300px"]{ 
        width: 300px;
    }
    &[data-smetio-width="350px"]{ 
        width: 350px;
    }    
    &[data-smetio-width="400px"]{ 
        width: 400px;
    }
}

.smetio-data-max-widths {

    &[data-smetio-max-width="100%"]{ 
        max-width: 100%;
    }

    &[data-smetio-max-width="50%"]{ 
        max-width: 50%;
    }

    &[data-smetio-max-width="45%"]{ 
        max-width: 45%;
    }

    &[data-smetio-max-width="30%"]{ 
        max-width: 30%;
    }

    &[data-smetio-max-width="20%"]{ 
        max-width: 20%;
    }

    &[data-smetio-max-width="10px"]{ 
        max-width: 10px;
    }

    &[data-smetio-max-width="15px"]{ 
        max-width: 15px;
    }

    &[data-smetio-max-width="20px"]{ 
        max-width: 20px;
    }

    &[data-smetio-max-width="25px"]{ 
        max-width: 25px;
    }
    &[data-smetio-max-width="50px"]{ 
        max-width: 50px;
    }
    &[data-smetio-max-width="75px"]{ 
        max-width: 75px;
    }    
    &[data-smetio-max-width="100px"]{ 
        max-width: 100px;
    }
    &[data-smetio-max-width="125px"]{ 
        max-width: 125px;
    }
    &[data-smetio-max-width="150px"]{ 
        max-width: 150px;
    }
    &[data-smetio-max-width="200px"]{ 
        max-width: 200px;
    }
    &[data-smetio-max-width="250px"]{ 
        max-width: 250px;
    }    
    &[data-smetio-max-width="300px"]{ 
        max-width: 300px;
    }
    &[data-smetio-max-width="350px"]{ 
        max-width: 350px;
    }    
    &[data-smetio-max-width="400px"]{ 
        max-width: 400px;
    }
    &[data-smetio-max-width="450px"]{ 
        max-width: 450px;
    }   
    &[data-smetio-max-width="500px"]{ 
        max-width: 500px;
    } 
    &[data-smetio-max-width="550px"]{ 
        max-width: 550px;
    }  
    &[data-smetio-max-width="600px"]{ 
        max-width: 600px;
    } 
    &[data-smetio-max-width="650px"]{ 
        max-width: 650px;
    }   

    &[data-smetio-max-width="700px"]{ 
        max-width: 700px;
    }   
    &[data-smetio-max-width="750px"]{ 
        max-width: 750px;
    }   
    &[data-smetio-max-width="800px"]{ 
        max-width: 800px;
    }   
    &[data-smetio-max-width="850px"]{ 
        max-width: 850px;
    }   
    &[data-smetio-max-width="900px"]{ 
        max-width: 900px;
    }   
    &[data-smetio-max-width="950px"]{ 
        max-width: 950px;
    }   
    &[data-smetio-max-width="1000px"]{ 
        max-width: 1000px;
    }  

}

.smetio-data-min-widths {

    &[data-smetio-min-width="100%"]{ 
        min-width: 100%;
    }

    &[data-smetio-min-width="50%"]{ 
        min-width: 50%;
    }

    &[data-smetio-min-width="45%"]{ 
        min-width: 45%;
    }

    &[data-smetio-min-width="30%"]{ 
        min-width: 30%;
    }

    &[data-smetio-min-width="20%"]{ 
        min-width: 20%;
    }

    &[data-smetio-min-width="10px"]{ 
        min-width: 10px;
    }

    &[data-smetio-min-width="15px"]{ 
        min-width: 15px;
    }

    &[data-smetio-min-width="20px"]{ 
        min-width: 20px;
    }

    &[data-smetio-min-width="25px"]{ 
        min-width: 25px;
    }
    &[data-smetio-min-width="50px"]{ 
        min-width: 50px;
    }
    &[data-smetio-min-width="75px"]{ 
        min-width: 75px;
    }    
    &[data-smetio-min-width="100px"]{ 
        min-width: 100px;
    }
    &[data-smetio-min-width="125px"]{ 
        min-width: 125px;
    }
    &[data-smetio-min-width="150px"]{ 
        min-width: 150px;
    }
    &[data-smetio-min-width="200px"]{ 
        min-width: 200px;
    }
    &[data-smetio-min-width="250px"]{ 
        min-width: 250px;
    }    
    &[data-smetio-min-width="300px"]{ 
        min-width: 300px;
    }
    &[data-smetio-min-width="350px"]{ 
        min-width: 350px;
    }    
    &[data-smetio-min-width="400px"]{ 
        min-width: 400px;
    }
}

.smetio-data-min-heights {

    &[data-smetio-min-height="100%"]{ 
        min-height: 100%;
    }

    &[data-smetio-min-height="50%"]{ 
        min-height: 50%;
    }

    &[data-smetio-min-height="45%"]{ 
        min-height: 45%;
    }

    &[data-smetio-min-height="30%"]{ 
        min-height: 30%;
    }

    &[data-smetio-min-height="20%"]{ 
        min-height: 20%;
    }

    &[data-smetio-min-height="10px"]{ 
        min-height: 10px;
    }

    &[data-smetio-min-height="15px"]{ 
        min-height: 15px;
    }

    &[data-smetio-min-height="20px"]{ 
        min-height: 20px;
    }

    &[data-smetio-min-height="25px"]{ 
        min-height: 25px;
    }
    &[data-smetio-min-height="50px"]{ 
        min-height: 50px;
    }
    &[data-smetio-min-height="75px"]{ 
        min-height: 75px;
    }    
    &[data-smetio-min-height="100px"]{ 
        min-height: 100px;
    }
    &[data-smetio-min-height="125px"]{ 
        min-height: 125px;
    }
    &[data-smetio-min-height="150px"]{ 
        min-height: 150px;
    }
    &[data-smetio-min-height="200px"]{ 
        min-height: 200px;
    }
    &[data-smetio-min-height="250px"]{ 
        min-height: 250px;
    }    
    &[data-smetio-min-height="300px"]{ 
        min-height: 300px;
    }
    &[data-smetio-min-height="350px"]{ 
        min-height: 350px;
    }    
    &[data-smetio-min-height="400px"]{ 
        min-height: 400px;
    }
    &[data-smetio-min-height="450px"]{ 
        min-height: 450px;
    }
    
    &[data-smetio-min-height="500px"]{ 
        min-height: 500px;
    }
    
    &[data-smetio-min-height="550px"]{ 
        min-height: 550px;
    }
    
    &[data-smetio-min-height="600px"]{ 
        min-height: 600px;
    }
    
    &[data-smetio-min-height="650px"]{ 
        min-height: 650px;
    }

    &[data-smetio-min-height="700px"]{ 
        min-height: 700px;
    }
    
    &[data-smetio-min-height="850px"]{ 
        min-height: 850px;
    }
    

}

.smetio-data-max-heights {

    &[data-smetio-max-height="100%"]{ 
        max-height: 100%;
    }

    &[data-smetio-max-height="50%"]{ 
        max-height: 50%;
    }

    &[data-smetio-max-height="45%"]{ 
        max-height: 45%;
    }

    &[data-smetio-max-height="30%"]{ 
        max-height: 30%;
    }

    &[data-smetio-max-height="20%"]{ 
        max-height: 20%;
    }

    &[data-smetio-max-height="10px"]{ 
        max-height: 10px;
    }

    &[data-smetio-max-height="15px"]{ 
        max-height: 15px;
    }

    &[data-smetio-max-height="20px"]{ 
        max-height: 20px;
    }

    &[data-smetio-max-height="25px"]{ 
        max-height: 25px;
    }
    &[data-smetio-max-height="50px"]{ 
        max-height: 50px;
    }
    &[data-smetio-max-height="75px"]{ 
        max-height: 75px;
    }    
    &[data-smetio-max-height="100px"]{ 
        max-height: 100px;
    }
    &[data-smetio-max-height="125px"]{ 
        max-height: 125px;
    }
    &[data-smetio-max-height="150px"]{ 
        max-height: 150px;
    }
    &[data-smetio-max-height="160px"]{ 
        max-height: 160px;
    }  
    &[data-smetio-max-height="170px"]{ 
        max-height: 170px;
    } 
    &[data-smetio-max-height="180px"]{ 
        max-height: 180px;
    }         
    &[data-smetio-max-height="200px"]{ 
        max-height: 200px;
    }
    &[data-smetio-max-height="250px"]{ 
        max-height: 250px;
    }    
    &[data-smetio-max-height="300px"]{ 
        max-height: 300px;
    }
    &[data-smetio-max-height="350px"]{ 
        max-height: 350px;
    }    
    &[data-smetio-max-height="400px"]{ 
        max-height: 400px;
    }
    &[data-smetio-max-height="450px"]{ 
        max-height: 450px;
    }

    &[data-smetio-max-height="500px"]{ 
        max-height: 500px;
    }   
    
    &[data-smetio-max-height="550px"]{ 
        max-height: 550px;
    }    
    
    &[data-smetio-max-height="600px"]{ 
        max-height: 600px;
    } 

    &[data-smetio-max-height="650px"]{ 
        max-height: 650px;
    }
    &[data-smetio-max-height="700px"]{ 
        max-height: 700px;
    }

    &[data-smetio-max-height="850px"]{ 
        max-height: 850px;
    }
    
    &[data-smetio-max-height="900px"]{ 
        max-height: 900px;
    }
    
    &[data-smetio-max-height="950px"]{ 
        max-height: 950px;
    }
    
    &[data-smetio-max-height="1000px"]{ 
        max-height: 1000px;
    }
    
    &[data-smetio-max-height="1050px"]{ 
        max-height: 1050px;
    }
    
    &[data-smetio-max-height="1100px"]{ 
        max-height: 1100px;
    }
    
    &[data-smetio-max-height="1150px"]{ 
        max-height: 1150px;
    }
    
    &[data-smetio-max-height="1200px"]{ 
        max-height: 1200px;
    }
    
    &[data-smetio-max-height="1250px"]{ 
        max-height: 1250px;
    }
    
    &[data-smetio-max-height="1300px"]{ 
        max-height: 1300px;
    }
    
    &[data-smetio-max-height="1350px"]{ 
        max-height: 1350px;
    }
    
    &[data-smetio-max-height="1400px"]{ 
        max-height: 1400px;
    }
    
    &[data-smetio-max-height="1450px"]{ 
        max-height: 1450px;
    }
    
    &[data-smetio-max-height="1500px"]{ 
        max-height: 1500px;
    }
    
    &[data-smetio-max-height="1550px"]{ 
        max-height: 1550px;
    }
    
    &[data-smetio-max-height="1600px"]{ 
        max-height: 1600px;
    }
    
    &[data-smetio-max-height="1650px"]{ 
        max-height: 1650px;
    }
    
    &[data-smetio-max-height="1700px"]{ 
        max-height: 1700px;
    }
    
    &[data-smetio-max-height="1750px"]{ 
        max-height: 1750px;
    }
    
    &[data-smetio-max-height="1800px"]{ 
        max-height: 1800px;
    }
    
    &[data-smetio-max-height="1850px"]{ 
        max-height: 1850px;
    }
    
    &[data-smetio-max-height="1900px"]{ 
        max-height: 1900px;
    }
    
    &[data-smetio-max-height="1950px"]{ 
        max-height: 1950px;
    }
    
    &[data-smetio-max-height="2000px"]{ 
        max-height: 2000px;
    }
    

}

.smetio-data-heights {

    &[data-smetio-height="auto"]{ 
        height: auto;
    }

    &[data-smetio-height="100%"]{ 
        height: 100%;
    }

    &[data-smetio-height="50%"]{ 
        height: 50%;
    }

    &[data-smetio-height="45%"]{ 
        height: 45%;
    }

    &[data-smetio-height="30%"]{ 
        height: 30%;
    }

    &[data-smetio-height="20%"]{ 
        height: 20%;
    }

    &[data-smetio-height="10px"]{ 
        height: 10px;
    }

    &[data-smetio-height="15px"]{ 
        height: 15px;
    }

    &[data-smetio-height="20px"]{ 
        height: 20px;
    }

    &[data-smetio-height="25px"]{ 
        height: 25px;
    }
    &[data-smetio-height="50px"]{ 
        height: 50px;
    }
    &[data-smetio-height="75px"]{ 
        height: 75px;
    }    
    &[data-smetio-height="100px"]{ 
        height: 100px;
    }
    &[data-smetio-height="125px"]{ 
        height: 125px;
    }
    &[data-smetio-height="150px"]{ 
        height: 150px;
    }
    &[data-smetio-height="160px"]{ 
        height: 160px;
    }   
    &[data-smetio-height="170px"]{ 
        height: 170px;
    }     
    &[data-smetio-height="200px"]{ 
        height: 200px;
    }
    &[data-smetio-height="250px"]{ 
        height: 250px;
    }    
    &[data-smetio-height="300px"]{ 
        height: 300px;
    }
    &[data-smetio-height="350px"]{ 
        height: 350px;
    }    
    &[data-smetio-height="400px"]{ 
        height: 400px;
    }

    &[data-smetio-height="450px"]{ 
        height: 450px;
    }

    &[data-smetio-height="650px"]{ 
        height: 650px;
    }
    &[data-smetio-height="700px"]{ 
        height: 700px;
    }    

    &[data-smetio-height="850px"]{ 
        height: 850px;
    }
}


.smetio-data-margins {
    &[data-smetio-margin="0px"]{ 
        margin: 0px;
    }
    &[data-smetio-margin="2px"]{ 
        margin: 2px;
    }
    &[data-smetio-margin="2.5px"]{ 
        margin: 2.5px;
    }
    &[data-smetio-margin="5px"]{ 
        margin: 5px;
    }
    &[data-smetio-margin="10px"]{ 
        margin: 10px;
    }
    &[data-smetio-margin="20px"]{ 
        margin: 20px;
    }
}

.smetio-data-paddings {
    &[data-smetio-padding="0px"]{ 
        padding: 0px;
    }
    &[data-smetio-padding="2px"]{ 
        padding: 2px;
    }
    &[data-smetio-padding="2.5px"]{ 
        padding: 2.5px;
    }
    &[data-smetio-padding="5px"]{ 
        padding: 5px;
    }
    &[data-smetio-padding="10px"]{ 
        padding: 10px;
    }
    &[data-smetio-padding="20px"]{ 
        padding: 20px;
    }
}

.smetio-flex-v-positions {
    &[data-smetio-v-position="center"]{
        justify-content: center !important;
        // justify-self: center !important;
        align-self: center !important;
    }

    &[data-smetio-v-position="top"]{
        justify-content: flex-start !important;
        // justify-self: flex-start !important;
        align-self: flex-start !important;
    }

    &[data-smetio-v-position="bottom"]{
        justify-content: flex-end !important;
        // justify-self: flex-end !important;
        align-self: flex-end !important;
    }

}

.smetio-accent-dominant-hover{
    &:hover {
        cursor: pointer;
        @extend.smetio-accent-background;
        @extend.smetio-dominant-text;
    }
}

// /////


.smetio-ui-confirm-add-new-business-location {

    width: 100% !important;
    text-align: center !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start !important;

    h4 {
       
        // @extend.smetio-information-text;
        width: 100% !important;
        margin-bottom: 20px !important;
        text-align: center !important;
        // @extend.smetio-h4-heading;
        
    }
    
    div {
        margin-bottom: 20px !important;
        text-align: center !important;
        h6 {
            margin-bottom: 15px !important;    
            // @extend.smetio-h6-heading;        
        }
        // p {
        //     // @extend.smetio-information-text;
        //     // @extend.smetio-p-text;
        // }
    }

    p {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px !important;
        // @extend.smetio-p-text;

        span {
            flex:1;
            text-align: left;
        }

        strong {
            width: 40% !important;
            text-align: right !important;
        }
    }

    h6 {
        margin-top: 20px !important;    
        margin-bottom: 10px !important;    
        // @extend.smetio-h6-heading;        
    }

    .smetio-ui-confirm-add-new-business-location-button {
       @extend.smetio-smart-box-button;
    }

}

//////
.smetio-presentation-confirm-delete-credit-card {

    min-width: 100%;
    min-height: 100%;
    width: 100%;
    height: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  
    // @extend.smetio-neutral-background;

    h2 {
        @extend.smetio-warning-text;
        margin-bottom: 30px;
    }

    h4 {
        margin-bottom: 15px;
    }

    p {
        margin-bottom: 30px;
    }

    .smetio-presentation-confirm-delete-credit-card-btn {        
            
        margin-top: 30px;
        max-width: 200px;
        width: 50%;
        height: auto;
        padding: 7px;
        border-radius: 5px;
        border: solid 1px transparent;
        outline: solid 1px transparent;
        font-size: 120%;
        font-weight: 600;
        text-align: center;
        // display: none;
        @extend.smetio-dominant-background;
        @extend.smetio-grey-text-reverse;

        &:hover {
            cursor: pointer;
            @extend.smetio-accent-background;
            @extend.smetio-grey-text-reverse;
        }            
        // @extend.smetio-accent-button;
        // @extend.smetio-accent-text;
        // @extend.smetio-dominant-background;    
        // @include display.flex-wrap(column);
        // @include display.flex-perfect-center; 

        // &[data-smetio-is-visible=false] {
        //     display: none;
        // }
    }


    // @include small_mobile.smallMobileScreen;
    // @include mobile.mobileScreen;
    // @include tablet.tabletScreen;
    // @include desktop.desktopScreen;
    // @include large_desktop.largeDesktopScreen;  

}/* .smetio-presentation-confirm-delete-credit-card ENDS HERE */


/////
.smetio-whole-input-form-container {

    width: 100%;
    height: auto;

    .smetio-input-instruction-title {
        width: 100%;
        margin-top: 20px !important;
        margin-bottom: 10px !important;
        text-align: center;
        @extend.smetio-h4-heading;
        
    }

    .smetio-input-instruction-subtitle {
        width: 100%;
        text-align: center;
        margin-bottom: 20px !important;
        @extend.smetio-information-text;
        @extend.smetio-p-text;
    }

    .smetio-input-section {
        // background-color: green;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-bottom: 20px;
        height: auto;

        .smetio-input-section-title {
            padding-top: 10px;
            padding-bottom: 10px;
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin-bottom: 10px;
            border-radius: 5px;
            // @extend.smetio-grey-background;
            @extend.smetio-neutral-background-trans-60;
            @extend.smetio-grey-text;
            

            &:hover {
                cursor: pointer;
                @extend.smetio-neutral-background-trans-90;
                // @extend.smetio-grey-text-reverse;
            }


            h5 {
                flex: 1;
                padding-left: 10px;
                // @extend.smetio-h6-heading;
                @extend.smetio-h5-text-size;
                @extend.smetio-grey-text;

            }

            div {
                width: auto;
                font-size: 100%;
                padding-right: 10px;

                i {
                    font-size: 150%;
                    @extend.smetio-grey-text;
                }
            }
        }

        .smetio-input-section-content {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            &[data-smetio-is-visible="no"] {
                display: none;
            }
        }
    }

    .smetio-input-submit-btn-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: auto;
        margin-top: 10px !important;

        .smetio-input-submit-btn {

            @extend.smetio-smart-box-button;
            
        }
    }

}