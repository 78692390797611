@use "small_mobile";
@use "mobile";
@use "tablet";
@use "desktop";
@use "large_desktop";



@use "../../../../../css/mixins/displays/displays" as display;
@use "../../../../../css/mixins/dimensions/dimensions" as dimension;
@use "../../../../../css/mixins/position/positions" as position;
@use "../../../../../css/mixins/shapes/shapes" as shapes;
@use "../../../../../css/mixins/looks/looks" as looks;
@use "../../../../../css/mixins/effects/effects" as effects;
@use "../../../../../css/mixins/general_smetio_classes/general_smetio_classes" as classes;

// @import "../../../../../css/latestSmetioStyleSheet";

// @import "../../../../../css/variables/devices/device_sizes";
// @import "../../../../../css/mainClasses";


.smetio-custom-checkbox {

    // min-height: 30px;
    // min-width: 350px;
    // max-width: 35px;
    // height: 30px;
    // width: 35px;
    // position: relative;
    // margin-top: 20px;
    // background-color: green;
    width: auto;
    height: auto;

    @include display.flex-wrap(column);
    @include display.flex-perfect-center;

    /* The container */
    .smetio-custom-checkbox-container {
        display: block;
        position: relative;
        // padding-left: 35px;
        // margin-bottom: 12px;
        cursor: pointer;
        // font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        margin-bottom: -45px;
        
    }

    /* Hide the browser's default checkbox */
    .smetio-custom-checkbox-container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;     
        
        &[data-smetio-read-only=true]{
            pointer-events: none;
        }  
    }

    /* Create a custom checkbox */
    .smetio-custom-checkbox-checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        &[data-smetio-read-only=true]{
            pointer-events: none;
        }  
        @extend.smetio-dominant-border;
        // //@extend.smetio-neutral-border-trans-30;
        @extend.smetio-neutral-background;
     
    }

    /* On mouse-over, add a grey background color */
    .smetio-custom-checkbox-container:hover input~.smetio-custom-checkbox-checkmark {

        // @extend.smetio-neutral-background-trans-70;
        @extend.smetio-dominant-background;

    }

    /* When the checkbox is checked, add a blue background */
    .smetio-custom-checkbox-container input:checked~.smetio-custom-checkbox-checkmark {

        @extend.smetio-accent-background;
        &:hover{
            @extend.smetio-accent-background-reverse;
        }
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .smetio-custom-checkbox-checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */
    .smetio-custom-checkbox-container input:checked~.smetio-custom-checkbox-checkmark:after {
        display: block;
    }

    /* Style the checkmark/indicator */
    .smetio-custom-checkbox-container .smetio-custom-checkbox-checkmark:after {
        // left: 7.5px;
        // top: 2.5px;
        // width: 5px;
        // height: 10px;

        left: 4px;
        top: 4px;
        width: 10px;
        height: 10px;
        border-radius: 2px;
        // @extend.smetio-neutral-border-plain;
        @extend.smetio-dominant-background-reverse;
        border-width: 0 3px 3px 0;
        // -webkit-transform: rotate(45deg);
        // -ms-transform: rotate(45deg);
        // transform: rotate(45deg);
    }

    @include small_mobile.smallMobileScreen;
    @include mobile.mobileScreen;
    @include tablet.tabletScreen;
    @include desktop.desktopScreen;
    @include large_desktop.largeDesktopScreen;

}

/* .smetio-multimediaPlayer ENDS HERE */