
@import "../../../../../css/variables/devices/device_sizes";

@mixin desktopScreen{

    @media only screen and (min-width:$desktop_min) and (max-width:$desktop_max){

        // THE CSS COMES HERE

        --smetio-customer-website-locations-and-map-div-item-width: 45%;
        --smetio-customer-website-map-display: flex;
        --smetio-photo-and-description-width: 30%;

        @media (orientation: landscape){

            
        }
        

        @media (orientation: portrait){

            
        }
                
        
    } 

}
