@use "small_mobile";
@use "mobile";
@use "tablet";
@use "desktop";
@use "large_desktop";



// @use "../../../../../css/mixins/displays/displays" as display;
// @use "../../../../../css/mixins/dimensions/dimensions" as dimension;
// @use "../../../../../css/mixins/position/positions" as position;
// @use "../../../../../css/mixins/shapes/shapes" as shapes;
// @use "../../../../../css/mixins/looks/looks" as looks;
// @use "../../../../../css/mixins/effects/effects" as effects;
// @use "../../../../../css/mixins/general_smetio_classes/general_smetio_classes" as classes;



// @import "../../../../../css/variables/devices/device_sizes";
// @import "../../../../../css/mainClasses";

// @import "../../../../../css/latestSmetioStyleSheet";

:root{
    --smetio-page-contents-left-display:flex;
    --smetio-page-contents-logo-div-img-position:static;    
    --smetio-page-contents-logo-div-img-max-width: 200px;
    --smetio-page-contents-logo-div-img-max-height: 150px;
    --smetio-page-contents-logo-div-img-min-width: 100px;
    --smetio-page-contents-logo-div-img-width: auto;
    --smetio-page-contents-logo-div-img-height: auto;

    --smetio-page-contents-logo-div-img-top: 10px;
    --smetio-page-contents-logo-div-img-left: 10px;
}
.smetio-page {

    min-width: 100vw;
    min-height: 100vh;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    // background-image: url("https://cdn.3ack.com/production/img/placeholders/webiste_barber_home_page_chair.jpg");



    .smetio-page-blur {
        min-width: 102%;
        min-height: 102%;
        width: 102%;
        height: 102%;
        position: fixed;
        z-index: 1;
        // top: 0;
        // left: 0;
        background-image: url("https://cdn.3ack.com/production/img/placeholders/webiste_barber_home_page_chair.jpg");

        /* Add the blur effect */
        filter: blur(8px);
        -webkit-filter: blur(20px);

        /* Add the blur effect */
        filter: blur(8px);
        -webkit-filter: blur(20px);

        /* Center and scale the image nicely */
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .smetio-page-contents {
        min-width: 90% !important;
        min-height: 90% !important;
        width: 90% !important;
        height: 80% !important;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        z-index: 3;
        // @extend.smetio-neutral-background;
        // @extend.smetio-default-box-shadow;

        .smetio-page-contents-left {
            width: 60%;
            height: 100%;
            max-height: 100%;
            overflow: hidden;
            background-image: url("https://cdn.3ack.com/production/img/placeholders/webiste_barber_home_page_chair.jpg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            background-color: transparent;

            display: var(--smetio-page-contents-left-display);

            
            // @extend.smetio-dominant-text;

            // filter: none !important;
            // -webkit-filter: none !important;
        }

        .smetio-page-contents-right {
            flex: 1;
            // width: 100%;
            height: 100%;
            max-height: 100%;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            position: relative;
            // @extend.smetio-neutral-background;
            // @extend.smetio-dominant-text;
            // @extend.smetio-hiddenScrollBar;

            // div{
            //     width: 100%;
            //     height: auto;
            //     display: flex;
            //     flex-direction: column;
            //     justify-content: center;
            //     align-items: center;
            // }

            .smetio-page-contents-logo-div {
            
                width: 100%;
                min-height:40px;
                height: auto;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                img {
                    // width: 50%;
                    // height: auto;
                    max-width: var( --smetio-page-contents-logo-div-img-max-width);
                    max-height: var( --smetio-page-contents-logo-div-img-max-height);
                    min-width: var( --smetio-page-contents-logo-div-img-min-width);
                    width: var( --smetio-page-contents-logo-div-img-width);
                    position: var(--smetio-page-contents-logo-div-img-position);
                    top: var(--smetio-page-contents-logo-div-img-top);
                    left: var(--smetio-page-contents-logo-div-img-left);

                }
            }
            .smetio-page-contents-unique-content {
                flex: 1 !important;
                width: 90%;
                // height: auto;
                // max-height: 100%;
                overflow: scroll;
                display: flex;
                flex-direction: column;
                justify-content: flex-start !important;
                align-items: center !important;
                @extend.smetio-hiddenScrollBar;
                // background-color: green;

                div {
                    width: 100% !important;
                }

                .smetio-page-links-div {
                    height: auto;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end !important;
                    align-items: flex-end !important;
                }

            }

            .smetio-page-contents-right-language-switcher {
                min-width: 50px !important;
                width: auto !important;
                flex-direction: row !important;
                justify-content: center !important;
                align-items: flex-end !important;
                position: absolute;
                top: 10px;
                right: 10px;
                z-index: 3;
            }
        }
    }


    @include small_mobile.smallMobileScreen;
    @include mobile.mobileScreen;
    @include tablet.tabletScreen;
    @include desktop.desktopScreen;
    @include large_desktop.largeDesktopScreen;

}

/* .smetio-multimediaPlayer ENDS HERE */