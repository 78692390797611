
@use "small_mobile";
@use "mobile";
@use "tablet";
@use "desktop";
@use "large_desktop";



@use "../../../../../css/mixins/displays/displays" as display;
@use "../../../../../css/mixins/dimensions/dimensions" as dimension;
@use "../../../../../css/mixins/position/positions" as position;
@use "../../../../../css/mixins/shapes/shapes" as shapes;
@use "../../../../../css/mixins/looks/looks" as looks;
@use "../../../../../css/mixins/effects/effects" as effects;
@use "../../../../../css/mixins/general_smetio_classes/general_smetio_classes" as classes;

// @import "../../../../../css/latestSmetioStyleSheet";

// @import "../../../../../css/variables/devices/device_sizes";
// @import "../../../../../css/mainClasses";




.smetio-app-ui-menu-bar-container {

    // background-color: var(--smetio-layout-brand-color-shade-60);
    // @extend.smetio-accent-background;
    @extend.smetio-dominant-background;
    // @extend.smetio-accent-text-reverse;  
    @extend.smetio-white-text;

    @include display.flex-wrap(row);

    width: var(--smetio-app-ui-head-section-1-width);
    height: var(--smetio-app-ui-head-section-1-height);

    div {

        height: var(--smetio-app-ui-head-section-1-height);

    }

    .smetio-app-ui-menu-bar {

        width: var(--smetio-app-ui-menu-bar-width);
        // color: var(--smetio-layout-grey-color-1);
        // @extend.smetio-accent-button;
        @extend.smetio-dominant-background;
        @extend.smetio-white-text;
        // @extend.smetio-accent-text-reverse;     

        &:hover {

            @extend.smetio-accent-background;
            // @extend.smetio-dominant-text-reverse;
            @extend.smetio-dominant-text;

            @extend .smetio-cursor-pointer;        
        }
    
        &:active {    
            @extend.smetio-neutral-background;
            @extend.smetio-neutral-text;

        }

        @include display.flex-perfect-center;

        // @extend .smetio-accent-bg-with-hover-active;

    }


    @include small_mobile.smallMobileScreen;
    @include mobile.mobileScreen;
    @include tablet.tabletScreen;
    @include desktop.desktopScreen;
    @include large_desktop.largeDesktopScreen;    


}/* #smetio-app-ui-head-section-1 ENDS HERE */

