@use "small_mobile";
@use "mobile";
@use "tablet";
@use "desktop";
@use "large_desktop";



@use "../../../../../../css/mixins/displays/displays" as display;
@use "../../../../../../css/mixins/dimensions/dimensions" as dimension;
@use "../../../../../../css/mixins/position/positions" as position;
@use "../../../../../../css/mixins/shapes/shapes" as shapes;
@use "../../../../../../css/mixins/looks/looks" as looks;
@use "../../../../../../css/mixins/effects/effects" as effects;
@use "../../../../../../css/mixins/general_smetio_classes/general_smetio_classes" as classes;

// @import "../../../../../../css/latestSmetioStyleSheet";

// @import "../../../../../../css/variables/devices/device_sizes";
// @import "../../../../../../css/mainClasses";




body[data-smetio-page-type="smetio_app"] {

    // background-color: var(--smetio-layout-main-bg-color);
    @extend.smetio-dominant-background;
    // color: var(--smetio-text-primary-color);
    @extend.smetio-dominant-text;
    overflow: hidden;

    @include classes.general_smetio_classes;


    .default_bg_border_bottom {

        background-color: var(--smetio-layout-brand-color);
        border-bottom: solid 1px rgba(var(--smetio-text-primary-color-rgb), 0.2);

    }

    .smetio_button_hover_and_active {

        &:hover {
            background-color: var(--smetio-button-color-hover);

            color: var(--smetio-text-secondary-color);
            cursor: pointer;
        }

        &:active {
            background-color: var(--smetio-button-color-active);

        }

    }

    // .smetio-app-ui-splash-screen {
    //     min-width: 100vw;
    //     min-height: 100vh;
    //     width: 100vw;
    //     height: 100vh;
    //     z-index: 100;
    //     position: fixed;
    //     top: 0;
    //     left: 0;
    //     background-color: var(--smetio-layout-main-bg-color);;        
    //     @include display.flex-wrap(column);
    //     @include display.flex-perfect-center();
    //     @extend.smetio-neutral-background;


    //     img {
    //         width: 200px;
    //         height: auto;
    //         margin-bottom: 30px;
    //     }

    //     h4{
    //         font-size: 100%;

    //     }
    
    // };

    .smetio-app-ui {

        @include display.flex-wrap(column);
        @include dimension.full-vh-and-vw;

        /**** HEAD STYLE ****/

        //@include head.smetio_admin_app_head();
        .smetio-app-ui-head {

            @include display.flex-wrap(row);
            @include position.fixed(0, "", "", "");

            width: var(--smetio-app-ui-head-width);
            height: var(--smetio-app-ui-head-height);

            // background-color: var(--smetio-layout-main-bg-color);              
            @extend.smetio-neutral-background;
            @extend.smetio-dominant-border;
            border-left: none !important;
            border-right: none !important;
            border-top: none !important;
            
            z-index: 3;

            overflow: hidden;
            transition: height 250ms;

            &[data-smetio-mode="hidden"] {
                height: 0;                
            } 

            

            @extend .smetio-toggleDisplay;

            /**** HEAD SECTION 2 STYLE ****/

            //@include sec2.section_2;

            .smetio-app-ui-head-section-2 {

                width: var(--smetio-app-ui-head-section-2-width);
                height: var(--smetio-app-ui-head-section-2-height);

                // background-color: var(--smetio-layout-main-bg-color); 
                // @extend.smetio-dominant-background;
                @extend.smetio-neutral-background;



                @include display.flex-wrap(row);

                div {

                    @include display.flex-perfect-center;

                }

                #smetio-app-ui-logo {

                    margin-right:var(--smetio-app-ui-icon-default-margin);
                    font-size: 100%;

                    @include display.flex-wrap(row);
                    @include display.flex-perfect-center;

                    // @extend.smetio-dominant-text;
                    // @extend.smetio-accent-text;
                    @extend.smetio-grey-text;


                    img {
                        height: var(--smetio-app-ui-logo-height);
                        width: auto;
                        margin-left: var(--smetio-app-ui-icon-default-margin);
                    }

                    // h1, h2 {
                    //     margin-left: calc(var(--smetio-app-ui-icon-default-margin)/2);
                    //     vertical-align:middle;
                    //     min-height: 100%;
                    //     height: 100%;   
                    // }
                    h1 {
                        margin-left: calc(var(--smetio-app-ui-icon-default-margin)/2);
                        vertical-align:middle;
                        min-height: 100%;
                        height: 100%;   
                        font-size: 150% !important;                    
                    }
                    h2 {
                        font-size: 100% !important;
                        // margin-top: unset !important;
                        // margin-bottom: unset !important;
                        margin-left: calc(var(--smetio-app-ui-icon-default-margin)/2);
                    }

                }

                .smetio-app-ui-top-control {

                    @include display.flex-wrap(row);
                    @include display.flex-1-end();
                    // color: var(--smetio-icon-primary-color);
                    @extend.smetio-dominant-text;

                    div {

                        margin-left:var(--smetio-app-ui-icon-default-margin);

                    }

                    .smetio-app-ui-top-control-language-info {
                        width: auto;

                    }

                    .smetio-app-ui-profile {

                        margin-right:var(--smetio-app-ui-icon-default-margin);
                        position: relative;
                        // @extend.smetio-dominant-background-reverse;

                        img {

                            @include shapes.rounded(var(--smetio-app-ui-profile-height));

                            // background-color: var(--smetio-icon-primary-color);
                            @extend.smetio-dominant-background-reverse;
                        }

                        .smetio-app-ui-online-status {

                            position: absolute;
                            @include shapes.rounded(12px);
                            bottom: 0;
                            right: -20%;

                            @extend .online_status_colors;

                        }

                    }/* .smetio-app-ui-profile ENDS HERE */




                }/* .smetio-app-ui-top-control ENDS HERE*/


            } /* #smetio-app-ui-head-section-2 ENDS HERE*/

        }/* #smetio-app-ui-head ENDS HERE*/



        /**** BODY STYLE ****/
        //@include body.smetio_admin_app_body();

        .smetio-app-ui-body {

            @include display.flex-wrap(row);
            @include dimension.full-vh-and-vw;
            @include display.flex-1-end();

            margin-top: var(--smetio-app-ui-head-height);

            // background-color: var(--smetio-layout-main-bg-color);
            // @extend.smetio-dominant-background;
            @extend.smetio-neutral-background;


            z-index: 1;
            overflow-x: hidden;
            overflow-y: hidden;

            transition: margin-top 250ms;

            &[data-smetio-mode="full"] {
                margin-top: 0;                
            }            

            /**** SECTION 2 ****/

            //@include sec2.section_2;
            .smetio-app-ui-body-section-2 {

                // background-color: var(--smetio-layout-main-bg-color);
                // @extend.smetio-dominant-background;

                @include display.flex-wrap(column);
                @include display.flex-perfect-center;
                position: relative;
                z-index: 1;

                &[data-open-status="close"] {

                    width: var(--smetio-app-ui-body-section-2-fully-open-width);
                    
                }

                &[data-open-status="fully_open"] {
                    
                    width: var(--smetio-app-ui-body-section-2-fully-closed-width);
                }

                &[data-open-status="half_open"] {

                    width: calc(100% - var(--smetio-app-ui-menu-bar-width));

                }

                transition: width 0.4s;
                transition-timing-function:linear;

                .smetio-app-ui-loaded-contents {

                    width: var(--smetio-app-ui-loaded-contents-width);

                    // background-color: inherit !important;                   
                    min-height: 100vh;
                    @include display.flex-wrap(column);
                    //justify-content: center !important;
                    
                    max-width: 100%;
                    width: 100%;
                    flex: 1;

                    &[data-smetio-scroll="yes"] {

                        max-height: 100vh;
                        overflow: scroll;

                    }

                    .smetio-app-ui-loaded-contents-content {

                        margin-top: var(--smetio-app-ui-head-height);
                        max-width: 100%;
                        width: 100%;                        
                        min-height: 100vh;
                        // background-color: inherit !important;
                        @include display.flex-wrap(row);
                        justify-content: center !important;
                       
                        .smetio-content {

                            width: 95% !important;
                            //width: 100%;
                            min-height: 100vh;
                            padding-bottom: 400px;
                            // background-color: inherit !important;
                        }

                    }/* #smetio-app-ui-loaded-contents-content ENDS HERE */

                }/* #smetio-app-ui-loaded-contents ENDS HERE */


            }/* #smetio-app-ui-body-section-2 ENDS HERE */


            /**** NOTIFICATION ****/

            //@include notice.notification;
            .smetio-app-ui-body-notification{
        
                z-index:2;

                // background-color:var(--smetio-layout-main-bg-color);
                @extend.smetio-dominant-background;

                border-left:solid 1px rgba(var(--smetio-disable-color-rgb), 0.2);    
                            
            } /* .smetio-app-ui-body-notification ENDS HERE */             


        }/* #smetio-app-ui-body ENDS HERE */



    }/* #smetio-app-ui ENDS HERE */

    @include small_mobile.smallMobileScreen;
    @include mobile.mobileScreen;
    @include tablet.tabletScreen;
    @include desktop.desktopScreen;
    @include large_desktop.largeDesktopScreen;


}

