
@import "../../../../../css/variables/devices/_device_sizes";

@mixin smallMobileScreen{

    @media only screen and (min-width:$small_mobile_min) and (max-width:$small_mobile_max){


        --smetio-customer-website-locations-and-map-div-item-width: 95%;
        --smetio-customer-website-map-display: none;
        --smetio-photo-and-description-width: 100%;
        --smetio-photo-and-description-item-total-1:100%;
        --smetio-photo-and-description-item-total-2:100%;
        --smetio-customer-website-locations-items-justify-content:center;
        --smetio-customer-website-map-instruction-display:flex;
        --smetio-customer-website-view-btn-display:flex;
        --smetio-customer-website-search-view-btn-margin-right:5px;

        /**** THE CSS FOR THE SMALL MOBILE LOOK OF THE WIDGET SHOULD BE PLACED BELOW ****/

        @media (orientation: landscape){

            
        }
        

        @media (orientation: portrait){

            
        }
              
        
    } 

}
