@use "small_mobile";
@use "mobile";
@use "tablet";
@use "desktop";
@use "large_desktop";



@use "../../../../css/mixins/displays/displays" as display;
@use "../../../../css/mixins/dimensions/dimensions" as dimension;
@use "../../../../css/mixins/position/positions" as position;
@use "../../../../css/mixins/shapes/shapes" as shapes;
@use "../../../../css/mixins/looks/looks" as looks;
@use "../../../../css/mixins/effects/effects" as effects;
@use "../../../../css/mixins/general_smetio_classes/general_smetio_classes" as classes;

// @import "../../../../css/latestSmetioStyleSheet";

// @import "../../../../css/variables/devices/device_sizes";
// @import "../../../../css/mainClasses";


.smetio-preloader {

    min-width: 100%;
    width: 100%;
    min-height: 100%;
    // height: 100vh;
    height: 100%;

    font-size: 250%;
    @include display.flex-wrap(row);
    @include display.flex-perfect-center;
    // color: rgba(var(--smetio-disable-color-rgb), 0.1);
    @extend.smetio-neutral-background-trans-30;
    // background-color: green;
    @extend.smetio-information-text;

    position: fixed;
    top: 0;
    left: 0;
    transition: visibility 0s, opacity 0.5s linear;
    z-index: 10;
    
    &[data-smetio-is-visible=false] {
        display: none;
    }
    

    .smetio-preloader-spinner-and-text {

        @include display.flex-wrap(column);
        @include display.flex-perfect-center;
        width: 100%;
        height: 100%;
        position: fixed;
        font-size: 100%;
        // bottom: 50%;

        div {
            width: 100%;
            height: auto;
            @include display.flex-wrap(column);
            @include display.flex-perfect-center;
            color: inherit;
            // font-size: 80%;
            text-align: center;

            i{
                font-size: 250% !important;
            }
        }

    }

    @include small_mobile.smallMobileScreen;
    @include mobile.mobileScreen;
    @include tablet.tabletScreen;
    @include desktop.desktopScreen;
    @include large_desktop.largeDesktopScreen;

}

/* .smetio-preloader ENDS HERE */