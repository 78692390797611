
@import "../../../../../css/variables/devices/_device_sizes";

@mixin mobileScreen{

    /* @media only screen and (min-width:$mobile_min) and (max-width:$mobile_max){ */
    
    /**** COMBINE SMALL MOBILE AND MOBILE BY SETTING ONLY MAX-WIDTH ****/
    
    @media only screen and (min-width:$mobile_min) and (max-width:$mobile_max){

        --smetio-customer-website-locations-and-map-div-item-width: 95%;
        --smetio-customer-website-map-display: none;
        --smetio-photo-and-description-width: 100%;
        --smetio-photo-and-description-item-total-1:100%;
        --smetio-photo-and-description-item-total-2:100%;
        --smetio-customer-website-locations-items-justify-content:center;
        --smetio-customer-website-map-instruction-display:flex;
        --smetio-customer-website-view-btn-display:flex;
        --smetio-customer-website-search-view-btn-margin-right:5px;

        @media (orientation: landscape){

            
        }
        

        @media (orientation: portrait){

            
        }
        
        
    }

}    