
@import "../../../../../../css/variables/devices/device_sizes";


@mixin tabletScreen{

    @media only screen and (min-width:$tablet_min) and (max-width:$tablet_max){

        /**** THE CSS FOR THE TABLET LOOK OF THE WIDGET SHOULD BE PLACED BELOW ****/

        --smetio-content-interactable-card-style-card-item-width:30%;

        @media (orientation: landscape){

            
        }
        

        @media (orientation: portrait){

            
        }
                
        
    } 


}
