
@import "../../../../../../css/variables/devices/device_sizes";

@mixin desktopScreen{

    @media only screen and (min-width:$desktop_min) and (max-width:$desktop_max){

        // THE CSS COMES HERE

       --smetio-content-interactable-card-style-card-item-width:20%; 
        
       @media (orientation: landscape){

            
       }
       

       @media (orientation: portrait){

           
       }

    } 

}
