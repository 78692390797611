
@use "small_mobile";
@use "mobile";
@use "tablet";
@use "desktop";
@use "large_desktop";



@use "../../../../../css/mixins/displays/displays" as display;
@use "../../../../../css/mixins/dimensions/dimensions" as dimension;
@use "../../../../../css/mixins/position/positions" as position;
@use "../../../../../css/mixins/shapes/shapes" as shapes;
@use "../../../../../css/mixins/looks/looks" as looks;
@use "../../../../../css/mixins/effects/effects" as effects;
@use "../../../../../css/mixins/general_smetio_classes/general_smetio_classes" as classes;

// @import "../../../../../css/latestSmetioStyleSheet";

// @import "../../../../../css/variables/devices/device_sizes";
// @import "../../../../../css/mainClasses";


.smetio-multi-input-div {

    width: 100%;
    height: auto;
    @include display.flex-wrap(row);
    align-items: center;
    justify-content: flex-start;
    // @extend.smetio-grey-border;
    // @include display.flex-perfect-center; 

    div {
        // flex: 1;
        width: auto;
        @include display.flex-wrap(row);
        @include display.flex-perfect-center; 

        margin: 0 !important;
        padding: 0 !important;
    }

    .smetio-multi-input-on-off-btn-and-label-div {

        margin-right: 15px !important; 

        &[data-smetio-read-only=true]{
            margin-right: 5px !important;
            display: none;
        } 

        .smetio-multi-input-on-off-btn-holder {
            margin-right: 5px !important;
            margin-top: -17px !important;

            &[data-smetio-read-only=true]{
                display: none;
            } 
        }
    }


    .smetio-multi-input-inputs-div {
        flex: 1;
        justify-content: flex-start;



        div {
            flex: 1;
            @include display.flex-wrap(row);
            @include display.flex-perfect-center; 
            text-align: center;

            &[data-smetio-read-only=true]{
                display: none;
            }

            input {
                width: 100% !important;
                border: solid 0px transparent;
                outline: solid 0px transparent;

                @extend.smetio-grey-border;

                &:hover {
                    @extend.smetio-dominant-border;
                }

                &:disabled {
                    @extend .smetio-grey-background-trans-30;
                }

                &[data-smetio-read-only=true]{
                    border: solid 0px transparent;
                    outline: solid 0px transparent;
                    @extend.smetio-neutral-background;
                    // max-width: 50px;

                    &::-webkit-calendar-picker-indicator {
                        background: none;
                        display: none;
                    }
                } 
            }
            
        }

        .smetio-multi-input-read-only-mode-div {
            display: none;
            width: 100%;
            text-align: left;
            &[data-smetio-read-only=true]{
                display: block !important;
            }
        }

        .smetio-multi-input-starts-input-div {
            // @include display.flex-wrap(row);
            margin-left: 0 !important;
            justify-self: flex-start !important;
            width: 46% !important;
            height: 100%;
        }

        .smetio-multi-input-ends-input-div {
            // @include display.flex-wrap(row);
            margin-right: 0 !important;
            justify-content: right;
            justify-self: flex-end !important;
            width: 46% !important;
            height: 100%;

        }

        // flex: 1;
        // @include display.flex-wrap(row);
        // @include display.flex-perfect-center; 
    }


    @include small_mobile.smallMobileScreen;
    @include mobile.mobileScreen;
    @include tablet.tabletScreen;
    @include desktop.desktopScreen;
    @include large_desktop.largeDesktopScreen;  

}/* .smetio-multimediaPlayer ENDS HERE */





