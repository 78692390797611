
@use "small_mobile";
@use "mobile";
@use "tablet";
@use "desktop";
@use "large_desktop";



@use "../../../../../css/mixins/displays/displays" as display;
@use "../../../../../css/mixins/dimensions/dimensions" as dimension;
@use "../../../../../css/mixins/position/positions" as position;
@use "../../../../../css/mixins/shapes/shapes" as shapes;
@use "../../../../../css/mixins/looks/looks" as looks;
@use "../../../../../css/mixins/effects/effects" as effects;
@use "../../../../../css/mixins/general_smetio_classes/general_smetio_classes" as classes;


// @import "../../../../../css/latestSmetioStyleSheet.scss";

// @import "../../../../../css/variables/devices/device_sizes";
// @import "../../../../../css/mainClasses";


.smetio-app-ui-task-bar {

    z-index: 1000;

    @include display.flex-wrap(row);
    @include display.flex-perfect-center;

    justify-content: var(--smetio-app-ui-task-bar-justify-content);

    height: var(--smetio-app-ui-head-height);
    border-top: solid 1px rgba(var(--smetio-shadow-color-rgb), 0.4);
    position: fixed;
    bottom: 0;
    // background-color: var(--smetio-layout-main-bg-color);
    // @extend.smetio-neutral-background-trans-20;
    // @extend.smetio-dominant-background;
    @extend.smetio-neutral-background;
    // @extend.smetio-dominant-text-reverse;
    @extend.smetio-grey-text;
    // //@extend.smetio-neutral-text-trans-70;
    // @extend.smetio-neutral-text;

    width: inherit;

    overflow: hidden;
    transition: height 250ms;

    &[data-smetio-mode="hidden"] {

        height: 0;

    }    

    &[data-open-status="fully_closed"] {

        position: var(--smetio-app-ui-task-bar-fully-closed-position);

    }

    div {

        margin-left: var(--smetio-app-ui-task-bar-icons-spacing);
        margin-right: var(--smetio-app-ui-task-bar-icons-spacing);
    }
    .smetio-app-ui-task-bar-icon{
        &:hover{
            // @extend.smetio-neutral-text-trans-40;
            // @extend.smetio-dominant-text;
            // @extend.smetio-neutral-text;
            @extend.smetio-dominant-text;
            cursor: pointer;
        }

        &:active{
            // @extend.smetio-neutral-text-trans-50;
            @extend.smetio-dominant-text-reverse;
        }
    }

    @include small_mobile.smallMobileScreen;
    @include mobile.mobileScreen;
    @include tablet.tabletScreen;
    @include desktop.desktopScreen;
    @include large_desktop.largeDesktopScreen;  


}/* #smetio-app-ui-task-bar ENDS HERE */




