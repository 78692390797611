//$txtElem: div !default;


@mixin setTextOverflowWithElipsis($lineClamp:3, $multipleLines:false){

    /**** 
    
        $txtElement IS THE ELEMENT THAT ACTUALLY HOLDS THE TEXTS 

    ****/

    position: relative;
    min-width: 0 !important;
    width: 100%;
    max-width: 100%;                
    max-height: 25px;
    height: 25px;

    @if $multipleLines {

        > .smetio-elipsedText {

            white-space: normal;
            display: -webkit-box;
            -webkit-line-clamp:$lineClamp;
            -webkit-box-orient:vertical;
            
        }

    }@else {

        > .smetio-elipsedText {

            min-width: 0 !important;
            width: 100%;
            max-width: 100%;
            max-height: inherit;
            height: inherit;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis; 
            position: absolute;
            top: 0;
            left: 0;
            right: var(--smetio-app-ui-icon-default-margin);

        } 

    }





}