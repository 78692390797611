
@import "../../../../../css/variables/devices/_device_sizes";

@mixin mobileScreen{

    /* @media only screen and (min-width:$mobile_min) and (max-width:$mobile_max){ */
    
    /**** COMBINE SMALL MOBILE AND MOBILE BY SETTING ONLY MAX-WIDTH ****/
    
    @media only screen and (min-width:$mobile_min) and (max-width:$mobile_max){

    
        --smetio-photo-and-description-horizontal-direction:column;
        --smetio-photo-and-description-horizontal-justify-content:flex-start;
        --smetio-photo-and-description-flex-wrap:wrap;
        // --smetio-photo-and-description-horizontal-photo-width:100%;

        // .smetio-photo-and-description {

        //     // &[data-smetio-horizontal-responsive=true]{ 

        //     //     min-height: 2000px !important;
        //     //     height: auto !important;
        //     // }


        //     .smetio-photo-and-description-photo {
        
        //         &[data-smetio-orientation="horizontal"]{
        
        //             &[data-smetio-horizontal-responsive=true]{ 
        //                 width:100% !important;
        //             }
        //         }
        //     }

        // }

        .smetio-photo-and-description-image {
            width: 100% !important;
        }
        
        @media (orientation: landscape){

            
        }
        

        @media (orientation: portrait){

            
        }
        
        
    }

}    