
@import "../../../../../css/variables/devices/_device_sizes";

@mixin smallMobileScreen{

    @media only screen and (min-width:$small_mobile_min) and (max-width:$small_mobile_max){


        --smetio-data-presentation-width: 95%;
        --smetio-data-presentation-menu-right: 5px;

        /**** THE CSS FOR THE SMALL MOBILE LOOK OF THE WIDGET SHOULD BE PLACED BELOW ****/

        @media (orientation: landscape){

            
        }
        

        @media (orientation: portrait){

            
        }
              
        
    } 

}
