@use "small_mobile";
@use "mobile";
@use "tablet";
@use "desktop";
@use "large_desktop";



@use "../../../../../css/mixins/displays/displays" as display;
@use "../../../../../css/mixins/dimensions/dimensions" as dimension;
@use "../../../../../css/mixins/position/positions" as position;
@use "../../../../../css/mixins/shapes/shapes" as shapes;
@use "../../../../../css/mixins/looks/looks" as looks;
@use "../../../../../css/mixins/effects/effects" as effects;
@use "../../../../../css/mixins/general_smetio_classes/general_smetio_classes" as classes;

// @import "../../../../../css/latestSmetioStyleSheet";

// @import "../../../../../css/variables/devices/device_sizes";
// @import "../../../../../css/mainClasses";


.smetio-photo-checkbox {
    width:100%; 
    max-width:100%;  
    display:flex; 
    flex-direction:column !important;
    flex-wrap: wrap;

    &[data-smetio-read-only=true]{
        touch-action: none;
        pointer-events: none;

        *{
            touch-action: none;
            pointer-events: none;
        }
    }
    
    &[data-smetio-is-visible=false]{
        display: none;
    }


    .smetio-photo-checkbox-options{
        width:100%; 
        max-width:100%; 
        // background-color:green; 
        display:flex; 
        flex-direction:row !important; 
        flex-wrap:wrap; 
        justify-content:space-between; 
        justify-items:flex-start !important; 
        align-items:flex-start !important; 
        align-content:flex-start !important;
    }

    .smetio-photo-checkbox-search-and-select-all {
        width:100%; 
        display:flex; 
        flex-direction:row !important; 
        flex-wrap: wrap;
        margin-bottom: 20px;
        // background-color: green;

        .smetio-photo-checkbox-search {
            flex: 1;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;;
            padding: 2.5px !important;
            padding-left: 5px !important;
            @extend.smetio-grey-border;
            @extend.smetio-neutral-background;

            input {
                width: 100%;                                
                font-size: 100%;
                outline: solid 0.5px transparent;
                border: solid 0.5px transparent;
                @extend.smetio-neutral-background;
                &:focus{
                    outline: solid 0.5px transparent;
                    border: solid 0.5px transparent;
                }
                
            }
        }

        .smetio-photo-checkbox-select-and-deselect {
            width: auto;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            margin-left: 10px;
            // background-color: blue;

            .smetio-photo-checkbox-select-and-deselect-btn {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 5px;
                font-size: 100%;
                &:hover{
                    cursor: pointer;
                    @extend.smetio-accent-background;
                    @extend.smetio-dominant-text;
                }

                i {
                    font-size: 120%;
                }
            }
        }
    }

    .smetio-photo-checkbox-error-text {
        @extend.smetio-error-text;
    }

    .smetio-photo-checkbox-instruction  {
        // all: unset !important;
        font-style: italic !important;
        @extend.smetio-information-text;
    }
 
    @include small_mobile.smallMobileScreen;
    @include mobile.mobileScreen;
    @include tablet.tabletScreen;
    @include desktop.desktopScreen;
    @include large_desktop.largeDesktopScreen;

}

