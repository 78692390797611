@use "small_mobile";
@use "mobile";
@use "tablet";
@use "desktop";
@use "large_desktop";





:root {
    --smetio-customer-website-treatments-items-max-width:1620px;
    --smetio-customer-website-treatments-items-justify-content:flex-start;
}
.smetio-customer-website-treatment-book-appointment {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    h4 {
        margin-top:20px;
        margin-bottom: 20px;
        font-weight: bold;
        font-size: 120%;
    }

    .smetio-template-1-social-media-icons {
        margin-top:5px;
        margin-bottom: 5px;

        i {
            font-size: 110%;
            margin-right: 10px;
        }
    }
}

.smetio-customer-website-treatments {

    width: 100%;
    min-height: 400px !important;
    height: auto;
    display: flex;
    flex-direction: column;
    // justify-content: center !important;
    align-items: center !important;
    padding-bottom: 150px;

    h6 {
        font-size: 120%;
        text-align: center;
        @extend.smetio-information-text;
    }

    .smetio-customer-website-treatments-search-filter{
        width: var(--smetio-customer-website-treatments-items-max-width);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;

        .smetio-customer-website-btn{
            margin-left: 10px;
            @extend.smetio-dominant-text;

            &:hover{
                cursor: pointer;
                border-radius: 2.5px;
                padding: 7.5px;
                @extend.smetio-accent-background;
            }
        } 
    }

    .smetio-customer-website-treatments-items {

        width: 90%;
        max-width: var( --smetio-customer-website-treatments-items-max-width);
        height: auto;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: var(--smetio-customer-website-treatments-items-justify-content);
        flex-wrap: wrap;

        .smetio-customer-website-treatment-description {

            min-width: 100% !important;
            max-width: 100% !important;
            width: 100% !important;
            display: flex;
            flex-direction: column !important;
            flex-wrap: wrap;
            // background-color: aquamarine;
            background-color: inherit;
            height: auto !important;
            flex: 1;

            .smetio-customer-website-treatment-description-text {

                display: flex;
                flex-direction: column;
                flex: 1;

                h4 {
                    @extend.smetio-dominant-text;
                }

                .smetio-customer-website-treatment-description-text-body-part {
                    opacity: .7;
                }
                .smetio-customer-website-treatment-description-text-with-icon {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    margin-top: 5px;
                    margin-bottom: 5px;
                    opacity: .7;

                    i {
                        font-size: 110%;
                        margin-right: 5px;
                    }

                    strong {
                        margin-left: 5px;
                    }
                }

            }

            .smetio-customer-website-description-book-with-me {

                width: auto !important;
                display: flex;
                flex-direction: column;
                justify-content: center !important;
                align-items: center !important;
                align-self: flex-end !important;
                justify-self: flex-end !important;
                text-align: center !important;
                margin: 0 !important;
                margin-top: 20px !important;
                padding: 0 !important;
                // position: absolute;
                // right: 0;
                // bottom: 0;
                padding: 5px !important;
                border-radius: 2px !important;
                border: solid .5px transparent;
                outline: solid .5px transparent;
                @extend.smetio-dominant-background;
                @extend.smetio-accent-text;

                &:hover {
                    padding: 7.5px !important;
                    cursor: pointer;
                    @extend.smetio-accent-background;
                    @extend.smetio-dominant-text;
                }

                a {
                    text-decoration: none !important;
                    display: block;
                    color: inherit;
                }
            }


        }
    }


    @include small_mobile.smallMobileScreen;
    @include mobile.mobileScreen;
    @include tablet.tabletScreen;
    @include desktop.desktopScreen;
    @include large_desktop.largeDesktopScreen;

}

/* .smetio-multimediaPlayer ENDS HERE */