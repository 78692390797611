
@use "small_mobile";
@use "mobile";
@use "tablet";
@use "desktop";
@use "large_desktop";



@use "../../../../../css/mixins/displays/displays" as display;
@use "../../../../../css/mixins/dimensions/dimensions" as dimension;
@use "../../../../../css/mixins/position/positions" as position;
@use "../../../../../css/mixins/shapes/shapes" as shapes;
@use "../../../../../css/mixins/looks/looks" as looks;
@use "../../../../../css/mixins/effects/effects" as effects;
@use "../../../../../css/mixins/general_smetio_classes/general_smetio_classes" as classes;



// @import "../../../../../css/variables/devices/device_sizes";
// @import "../../../../../css/mainClasses";

// @import "../../../../../css/latestSmetioStyleSheet";

.smetio-file-input {

    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    @extend.smetio-grey-text;
    @extend.smetio-neutral-background;

    &[data-smetio-read-only=true]{
        // @extend.smetio-neutral-background;
        // @extend.smetio-neutral-text;
        color: transparent !important;
    }

    &:hover{
        cursor:text;
    }

    .smetio-file-input-display {
        width: 100%;
        // width: 200px;
        height: 30px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        position: relative;

        .smetio-file-input-display-thumbnail {
            max-width: 30px;
            max-height: 30px;
            min-width: 30px;
            min-height: 30px;
            width: 30px;
            height: 30px;
            overflow: hidden;
            border-radius: 5px;
            margin-right: 10px;
            @extend.smetio-neutral-background;

            img {
                width: 100%;
                height: auto;
                object-fit: cover;
            }

            &::-webkit-scrollbar {

                display: none;
    
            }

            &:hover{
                cursor:pointer;
                max-width: 200px;
                max-height: 1000px;
                min-width: 200px;
                min-height: 200px;
                width: 200px;
                height: auto;
                overflow: scroll;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 3;
                
            }

        }

        .smetio-file-input-display-txt {
            flex: 1;
            min-width: 100px;
            max-width: 80%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            text-align: left;
            @extend.smetio-grey-text;
        }

        .smetio-file-input-display-remove{
            width: auto;
            height: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-left: 10px;
            justify-self: flex-end;
            justify-content: flex-end;
            text-align: right;

            &:hover{
                cursor:pointer;
                @extend.smetio-danger-text;
            }

            i {
                font-size: 120%;
            }

        }
   
    }

    @include small_mobile.smallMobileScreen;
    @include mobile.mobileScreen;
    @include tablet.tabletScreen;
    @include desktop.desktopScreen;
    @include large_desktop.largeDesktopScreen;  

}/* .smetio-multimediaPlayer ENDS HERE */





