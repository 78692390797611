
@import "../../../../../css/variables/devices/_device_sizes";

@mixin smallMobileScreen{

    @media only screen and (min-width:$small_mobile_min) and (max-width:$small_mobile_max){


        --smetio-customer-website-teamMembers-items-max-width:100%;
        // --smetio-customer-website-teamMembers-item-width:95%;
        --smetio-customer-website-teamMembers-items-justify-content:center;
        --smetio-photo-and-description-photo-width:100%;

        .smetio-photo-and-description {
            max-width: 95% !important;
            width: 95% !important;
        }

        /**** THE CSS FOR THE SMALL MOBILE LOOK OF THE WIDGET SHOULD BE PLACED BELOW ****/

        @media (orientation: landscape){

            
        }
        

        @media (orientation: portrait){

            
        }
              
        
    } 

}
