
@import "../../../../../css/variables/devices/_device_sizes";

@mixin mobileScreen{

    /* @media only screen and (min-width:$mobile_min) and (max-width:$mobile_max){ */
    
    /**** COMBINE SMALL MOBILE AND MOBILE BY SETTING ONLY MAX-WIDTH ****/
    
    @media only screen and (min-width:$mobile_min) and (max-width:$mobile_max){

        --smetio-page-contents-left-display: none;

        --smetio-page-contents-logo-div-img-position:absolute;    
        --smetio-page-contents-logo-div-img-max-width: 100px;
        --smetio-page-contents-logo-div-img-max-height: 75px;
        --smetio-page-contents-logo-div-img-min-width: 50px;
        // --smetio-page-contents-logo-div-img-width: auto;
        // --smetio-page-contents-logo-div-img-height: auto;
    
        // --smetio-page-contents-logo-div-img-top: 10px;
        // --smetio-page-contents-logo-div-img-left: 10px;

        @media (orientation: landscape){

            
        }
        

        @media (orientation: portrait){

            
        }
        
        
    }

}    