
@use "small_mobile";
@use "mobile";
@use "tablet";
@use "desktop";
@use "large_desktop";

:root {
    --smetio-3ack-blogs-page-controller-item-width:20%;
    --smetio-3ack-blogs-page-controller-items-holder-width:90%;
}

.smetio-3ack-blogs-page-controller {

    // @extend.smetio-dominant-background;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction:column;
    flex-wrap: wrap;
    align-items: center !important;
    justify-content: center !important;
    margin-top: 30px !important;
    margin-bottom: 30px !important;

    .smetio-3ack-blogs-page-controller-items-holder {

        // max-width: 1700px !important;
        max-width: var(--smetio-3ack-blogs-page-controller-items-holder-width) !important;
        width: auto;
        height: auto;
        display: flex;
        flex-direction:row;
        flex-wrap: wrap;
        align-items: stretch !important;
        justify-content: flex-start;   
        // @extend.smetio-warning-background;  

        
        .smetio-3ack-blogs-page-controller-item {
            text-decoration: none;
            display: block;
            box-sizing: border-box !important;
            // max-width: 250px;
            padding:15px;
            min-width: 300px;
            max-width: var(--smetio-3ack-blogs-page-controller-item-width) !important;
            width: var(--smetio-3ack-blogs-page-controller-item-width) !important;
            // margin: 15px;
            display: flex;
            flex-direction:column;
            flex-wrap:nowrap;
            justify-content: center;
            // align-items: stretch !important;
            // background-color: green;
            

            .smetio-photo-and-description {
                padding: 0px !important;
                margin: 0px !important;   
                flex: 1 !important;  
                .smetio-3ack-blogs-page-controller-item-description {
                    // @extend.smetio-dominant-background;
                    flex: 1 !important;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start !important;
                    justify-content: flex-start !important;
                    padding: 0px !important;
                    margin: 15px !important;
                    h4, h6, p {
                        padding: 0px !important;
                        margin: 0px !important;
                    }
                    h4 { @extend.smetio-dominant-text; }

                    p { @extend.smetio-neutral-text; }

                    h4{
                        // @extend.smetio-dominant-background;
                        padding-top:1px !important ;
                        margin-bottom: 15px !important
                    }

                    .smetio-3ack-blogs-page-controller-item-description-authorship {
                        @extend.smetio-information-text;
                        flex:1 !important;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                        justify-content: flex-end;
                        align-self: flex-end;
                        justify-self: flex-end;
                        margin-top: 15px !important;
                        
                        p {
                            padding: 0px !important;
                            margin: 0px !important;
                            font-size: 90% !important;
                            // @extend.smetio-information-text;
                        }

                    }
                }      
            }



        }

    }

    .smetio-3ack-blogs-page-controller-load-more-btn {
        margin: 30px;
        @extend.smetio-dominant-button;
    }
    

    @include small_mobile.smallMobileScreen;
    @include mobile.mobileScreen;
    @include tablet.tabletScreen;
    @include desktop.desktopScreen;
    @include large_desktop.largeDesktopScreen;  

}/* .smetio-multimediaPlayer ENDS HERE */





