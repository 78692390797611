
@import "../../../../../../css/variables/devices/device_sizes";

@mixin largeDesktopScreen{

    @media only screen and (min-width:$large_desktop_min) and (max-width:$large_desktop_max){

       /**** THE CSS FOR THE LARGE DESKTOP LOOK OF THE WIDGET SHOULD BE PLACED BELOW ****/

        --smetio-content-interactable-card-style-card-item-width: 15%;


        @media (orientation: landscape){

            
        }
        

        @media (orientation: portrait){

            
        }        
       
   } 

}
