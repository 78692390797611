
@use "small_mobile";
@use "mobile";
@use "tablet";
@use "desktop";
@use "large_desktop";



@use "../../../../../css/mixins/displays/displays" as display;
@use "../../../../../css/mixins/dimensions/dimensions" as dimension;
@use "../../../../../css/mixins/position/positions" as position;
@use "../../../../../css/mixins/shapes/shapes" as shapes;
@use "../../../../../css/mixins/looks/looks" as looks;
@use "../../../../../css/mixins/effects/effects" as effects;
@use "../../../../../css/mixins/general_smetio_classes/general_smetio_classes" as classes;


// @import "../../../../../css/variables/devices/device_sizes";
// @import "../../../../../css/mainClasses";


.smetio-app-ui-menu-item-content{

    min-width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    width: 100%;
    height: 100vh;                                
    overflow-y: scroll;
    overflow-x: hidden;        
    padding-bottom: 10px !important;
    margin-bottom: 10px !important;                        
    // padding-bottom: 1500px !important;
    // margin-bottom: 1500px !important;
    // background-color: var(--smetio-layout-main-bg-color);

    // @extend.smetio-dominant-background;
    @extend.smetio-neutral-background;

    @extend .smetio-hiddenScrollBar;

    //@extend .smetio-toggleDisplay;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    justify-content: flex-start !important;
    align-content: flex-start !important;

    // &[data-smetio-view-style ="cardView"] {

    //     display: flex;
    //     flex-direction: row;
    //     flex-wrap: wrap;

    //     // justify-content: flex-start;
    //     // align-content: flex-start;

    // }   
    
    // &[data-smetio-view-style ="listView"] {

    //     // @include display.flex-wrap(column);
    //     // display: flex;
    //     // flex-direction: column;
    //     // flex-wrap: nowrap;

    // }      

    &[data-smetio-is-active="no"] {

        display: none;

    }

    .smetio-slideShow-item {

        min-height:calc(100vh - 170px) !important;
        max-height: calc(100vh - 170px) !important;
        height: calc(100vh - 170px) !important;
        overflow-y: scroll;
        @extend .smetio-hiddenScrollBar;
        
        &[data-smetio-view-style ="cardView"] {

            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
    
            justify-content: flex-start;
            align-content: flex-start;
    
        }   
        
        &[data-smetio-view-style ="listView"] {
    
            // @include display.flex-wrap(column);
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            // height: unset !important;
            // height: 100% !important;

            // justify-content: unset !important;
            // align-content: unset !important;

            // justify-content: flex-start !important;
            // align-content: flex-start !important;
    
        }        
    }

    @include small_mobile.smallMobileScreen;
    @include mobile.mobileScreen;
    @include tablet.tabletScreen;
    @include desktop.desktopScreen;
    @include large_desktop.largeDesktopScreen;        
    
}
                          
