
//@import "../../../../css/variables/devices/_device_sizes";
@import "../../../../../css/variables/devices/device_sizes";

@mixin tabletScreen{

    @media only screen and (min-width:$tablet_min) and (max-width:$tablet_max){

        /**** THE CSS FOR THE TABLET LOOK OF THE WIDGET SHOULD BE PLACED BELOW ****/
        --smetio-smartbox-head-control-size:100%;
        --smetio-smartbox-head-control-margin:7.5px;

        @media (orientation: landscape){

            
        }
        

        @media (orientation: portrait){

            
        }
        
        
    } 

}
