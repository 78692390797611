
@use "small_mobile";
@use "mobile";
@use "tablet";
@use "desktop";
@use "large_desktop";



@use "../../../../../css/mixins/displays/displays" as display;
@use "../../../../../css/mixins/dimensions/dimensions" as dimension;
@use "../../../../../css/mixins/position/positions" as position;
@use "../../../../../css/mixins/shapes/shapes" as shapes;
@use "../../../../../css/mixins/looks/looks" as looks;
@use "../../../../../css/mixins/effects/effects" as effects;
@use "../../../../../css/mixins/general_smetio_classes/general_smetio_classes" as classes;

// @import "../../../../../css/latestSmetioStyleSheet";

// @import "../../../../../css/variables/devices/device_sizes";

// @import "../../../../../css/mainClasses";


.smetio-explanation-article{

    .smetio-explanation-article-title {

        font-weight: bold;
        font-size: 120%;
        margin-bottom: var(--smetio-app-ui-icon-default-margin);

    }

    .smetio-explanation-article-paragraphs {
        
        font-size: 100%;
        margin-bottom: calc(var(--smetio-app-ui-icon-default-margin) * 2);

        p {

            margin-bottom: calc(var(--smetio-app-ui-icon-default-margin) / 2);

        }
    }

}

.smetio-explannation-icon-holder {

    font-size: 80%;
    margin-top: -10px;


    // @extend .smetio-blue-text-with-hover-active;
    @extend.smetio-accent-icon-interactive;

    i {
        font-size: 100%;
        // @extend.smetio-accent-icon-interactive;
        @extend.smetio-accent-icon-interactive;
        margin-left: calc(var(--smetio-app-ui-icon-default-margin) / 4);
        
        &:hover{
            cursor: pointer;
        }
    }
}

.smetio-explanation {

    // @extend .smetio-blue-text-with-hover-active;
    @extend.smetio-accent-icon-interactive;


    font-size: 80%;

    @include small_mobile.smallMobileScreen;
    @include mobile.mobileScreen;
    @include tablet.tabletScreen;
    @include desktop.desktopScreen;
    @include large_desktop.largeDesktopScreen;  

}/* .smetio-multimediaPlayer ENDS HERE */





