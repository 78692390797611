
@import "../../../../../../css/variables/devices/device_sizes";

@mixin smallMobileScreen{

    @media only screen and (min-width:$small_mobile_min) and (max-width:$small_mobile_max){

        /**** THE CSS FOR THE SMALL MOBILE LOOK OF THE WIDGET SHOULD BE PLACED BELOW ****/

      
        --smetio-content-interactable-card-style-card-item-width:100%;

        @media (orientation: landscape){

            
        }        

        @media (orientation: portrait){

            
        }

    } 

}
