
@import "../../../../../css/variables/devices/_device_sizes";

@mixin largeDesktopScreen{

     @media only screen and (min-width:$large_desktop_min) and (max-width:$large_desktop_max){

        /**** THE CSS FOR THE LARGE DESKTOP LOOK OF THE WIDGET SHOULD BE PLACED BELOW ****/

        --smetio-customer-website-locations-and-map-div-item-width: 45%;
        --smetio-customer-website-map-display: flex;
        --smetio-photo-and-description-width: 30%;

        @media (orientation: landscape){

            
        }
        

        @media (orientation: portrait){

            
        }
              
        
    } 

}
