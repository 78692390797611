
@import "../../../../../css/variables/devices/_device_sizes";

@mixin tabletScreen{

    @media only screen and (min-width:$tablet_min) and (max-width:$tablet_max){

        --smetio-customer-website-locations-and-map-div-item-width: 95%;
        --smetio-customer-website-map-display: none;
        --smetio-photo-and-description-width: 45%;
        --smetio-photo-and-description-item-total-1:90%;
        --smetio-photo-and-description-item-total-2:45%;
        --smetio-customer-website-locations-items-justify-content:center;
        --smetio-customer-website-map-instruction-display:flex;
        --smetio-customer-website-view-btn-display:flex;
        --smetio-customer-website-search-view-btn-margin-right:5px;

        /**** THE CSS FOR THE TABLET LOOK OF THE WIDGET SHOULD BE PLACED BELOW ****/


        @media (orientation: landscape){

            
        }
        

        @media (orientation: portrait){

            
        }
        
        
    } 

}
