$auto: auto !default;

@mixin fixed($top:$auto, $bottom:$auto, $right:$auto, $left:$auto ){
    
        position:fixed;
        top:$top;
        bottom:$bottom;         
        right:$right;
        left:$left;      


}
