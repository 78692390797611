
@import "../../../../../css/variables/devices/_device_sizes";

@mixin tabletScreen{

    @media only screen and (min-width:$tablet_min) and (max-width:$tablet_max){

        --smetio-data-presentation-width: 30%;
        --smetio-data-presentation-menu-right: 5px;

        /**** THE CSS FOR THE TABLET LOOK OF THE WIDGET SHOULD BE PLACED BELOW ****/


        @media (orientation: landscape){

            
        }
        

        @media (orientation: portrait){

            
        }
        
        
    } 

}
