
@use "small_mobile";
@use "mobile";
@use "tablet";
@use "desktop";
@use "large_desktop";



@use "../../../../../css/mixins/displays/displays" as display;
@use "../../../../../css/mixins/dimensions/dimensions" as dimension;
@use "../../../../../css/mixins/position/positions" as position;
@use "../../../../../css/mixins/shapes/shapes" as shapes;
@use "../../../../../css/mixins/looks/looks" as looks;
@use "../../../../../css/mixins/effects/effects" as effects;
@use "../../../../../css/mixins/general_smetio_classes/general_smetio_classes" as classes;


// @import "../../../../../css/variables/devices/device_sizes";
// @import "../../../../../css/mainClasses";


.smetio-notification-colorCode {


    &[data-smetio-noticeType="danger"]{

        // @extend .smetio-error-text;
        @extend.smetio-danger-background;
        // @extend.smetio-danger-text;
        @extend.smetio-danger-text-reverse;

    }

    &[data-smetio-noticeType="warning"]{

        // @extend .smetio-warning-text;
        @extend.smetio-warning-background;
        @extend.smetio-warning-text-reverse;

    }

    &[data-smetio-noticeType="info"]{

        // @extend .smetio-info-text;
        @extend.smetio-information-background;
        @extend.smetio-information-text-reverse;

    } 

    &[data-smetio-noticeType="friendly"]{

        // @extend .smetio-blue-text;
        // @extend.smetio-information-text;
        @extend.smetio-information-text-reverse;

    }          

    &[data-smetio-noticeType="success"]{

        // @extend .smetio-success-text;
        @extend.smetio-success-background;
        // @extend.smetio-success-text;
        @extend.smetio-success-text-reverse;


    }

}

.smetio-notification {

    @extend .smetio-majorNotification;
    bottom: unset;
    top: 0 !important;
    // @extend .smetio-default-primary-text-color;
    @extend.smetio-neutral-background;
    border-bottom: solid 0.5px var(--smetio-border-color);

    @extend.smetio-notification-colorCode;

    .smetio-notification-closeBtn {

        @extend .smetio-primary-to-error-text;
        margin: calc(var(--smetio-app-ui-icon-default-margin)/2);
        width: auto;
        position: fixed;
        right: 0;
        top: 0;

        i {
            font-size: 150%;
        }
    }

    h2 {

        font-size: 120%;
        margin-top: var(--smetio-app-ui-icon-default-margin);
        margin-bottom: calc(var(--smetio-app-ui-icon-default-margin)/2);    
        color: inherit;

        // @extend .smetio-notification-colorCode;
        
    }

    .smetio-notification-icon {

        margin: calc(var(--smetio-app-ui-icon-default-margin)/2);
        width: 20px;
        height: 20px;
        position: fixed;
        left: 0;
        top: 0;
        border-radius:50%;

    }

    .smetio-notification-body {

        width: 100%;
        margin: calc(var(--smetio-app-ui-icon-default-margin)/2);
        margin-bottom: var(--smetio-app-ui-icon-default-margin) !important;
        @include display.flex-wrap(column);
        @include display.flex-perfect-center; 

        .smetio-notification-affectedField {

            @extend .smetio-notification-colorCode;
            
        }
    }


    @include small_mobile.smallMobileScreen;
    @include mobile.mobileScreen;
    @include tablet.tabletScreen;
    @include desktop.desktopScreen;
    @include large_desktop.largeDesktopScreen;  

}/* .smetio-multimediaPlayer ENDS HERE */





