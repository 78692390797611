
@use "small_mobile";
@use "mobile";
@use "tablet";
@use "desktop";
@use "large_desktop";



// @use "../../../../../css/mixins/displays/displays" as display;
// @use "../../../../../css/mixins/dimensions/dimensions" as dimension;
// @use "../../../../../css/mixins/position/positions" as position;
// @use "../../../../../css/mixins/shapes/shapes" as shapes;
// @use "../../../../../css/mixins/looks/looks" as looks;
// @use "../../../../../css/mixins/effects/effects" as effects;
// @use "../../../../../css/mixins/general_smetio_classes/general_smetio_classes" as classes;



// @import "../../../../../css/variables/devices/device_sizes";
// @import "../../../../../css/mainClasses";

// @import "../../../../../css/latestSmetioStyleSheet";



:root {
    --smetio-photo-and-description-horizontal-direction:row;
    --smetio-photo-and-description-horizontal-justify-content: center;
    --smetio-photo-and-description-flex-wrap:nowrap;
    --smetio-photo-and-description-horizontal-photo-width:initial;
}

.smetio-photo-and-description {
    
    position: relative;
    height: auto;
    display: flex;
    // flex-wrap: wrap;
    flex-wrap: nowrap;
    width: 100%;
    // margin: 10px !important;
    // padding: 10px !important;
    @extend.smetio-data-heights; 
    @extend.smetio-data-min-heights; 
    @extend.smetio-data-max-heights; 

    @extend.smetio-data-widths;
    @extend.smetio-data-min-widths;
    @extend.smetio-data-max-widths;
    @extend.smetio-data-margins;
    @extend.smetio-data-paddings;
    @extend.smetio-grey-border;

    overflow: hidden;

    &:hover{
        cursor: pointer;
        @extend.smetio-grey-shadow-around-trans-50;
    }
 
    // data-smetio-is-hidden
    &[data-smetio-is-hidden=true]{
        display: none;
    }

    &[data-smetio-is-hidden=false]{
        display: flex;
    }

    &[data-smetio-orientation="horizontal"]{
        flex-direction: row !important;
        justify-content: center;
        align-items: flex-start;        
        // min-height: 100px;

        &[data-smetio-horizontal-responsive=true]{
            // flex-wrap: var(--smetio-photo-and-description-flex-wrap) !important;
            flex-direction: var(--smetio-photo-and-description-horizontal-direction) !important;
            justify-content: var(--smetio-photo-and-description-horizontal-justify-content) !important;
        }
    }

    &[data-smetio-orientation="vertical"]{
        flex-direction: column !important;
        justify-content: flex-start;
        align-items: flex-start;  
   
    }  

    .smetio-photo-and-description-photo {

        // background-color: blue;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        font-size: 100%;

        @extend.smetio-flex-v-positions;
        @extend.smetio-data-max-heights; 
        @extend.smetio-data-min-heights; 
        // @extend.smetio-data-heights; 
        overflow: hidden;


        &[data-smetio-orientation="horizontal"]{
            height: auto;
            @extend.smetio-data-widths;

            // &[data-smetio-horizontal-responsive=true]{ 
            //     width:var(--smetio-photo-and-description-horizontal-photo-width) !important;
            // }
        }

        &[data-smetio-orientation="vertical"]{
            width: 100%;
            height: auto;
            // @extend.smetio-data-heights;  
        }

        .smetio-photo-and-description-image {
            // width: 100%;
            // height: 100%;
            // object-fit: cover;

            // @extend.smetio-data-heights; 
            // @extend.smetio-data-widths;
    
            &[data-smetio-auto-side="height"]{
                width: 100%;
                height: auto;
            }
    
            &[data-smetio-auto-side="width"]{
                width: auto;
                height: 100%;
            }
            
        }
        
        i {
            // width:100%;
            // height: auto;
            font-size: 250% !important;
        }

    }

    .smetio-photo-and-description-description {
        flex: 1;
        max-width: 100%;
        height: auto;
        display: flex;
        flex-direction: column !important;
        align-items: flex-start;
        justify-content: flex-start;
        // flex-wrap: wrap;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: wrap;
        // word-break: break-all;      
        // word-wrap: break-word;
        word-break: keep-all;
        word-wrap: break-word;
        white-space: pre-wrap;
        hyphens: auto;       
        
        @extend.smetio-flex-v-positions;
        @extend.smetio-grey-text;

        &[data-smetio-orientation="horizontal"]{
            // justify-content: flex-start;
            padding-left: 10px;
            padding-right: 10px;   

            min-height: 100% !important;
            height: 100% !important;
            
        }
    
        &[data-smetio-orientation="vertical"]{            
            // justify-content: center;  
            min-width: 100% !important;
            width: 100% !important;
            
            padding-top: 10px;
        }  

    }

    .smetio-photo-and-description-select-status{
        position: absolute;
        display: none;
        align-items: center;
        justify-content: center;;
        top: 5px;
        left: 5px;
        &[data-smetio-is-selected=true]{
            display: flex;
        }
        @extend.smetio-data-heights;  
        @extend.smetio-data-widths;
        // @extend.smetio-data-margins;
        // width: 30px;
        // height: 30px;
        // @extend.smetio-neutral-background;
        @extend.smetio-accent-background;
        @extend.smetio-dominant-text;
        i{
            font-size: 150%;
        }
    }


    @include small_mobile.smallMobileScreen;
    @include mobile.mobileScreen;
    @include tablet.tabletScreen;
    @include desktop.desktopScreen;
    @include large_desktop.largeDesktopScreen;  

}/* .smetio-multimediaPlayer ENDS HERE */





