
@import "../../../../../css/variables/devices/device_sizes";
//@import "../../../../css/variables/devices/_device_sizes";

@mixin largeDesktopScreen{

     @media only screen and (min-width:$large_desktop_min) and (max-width:$large_desktop_max){

        /**** THE CSS FOR THE LARGE DESKTOP LOOK OF THE WIDGET SHOULD BE PLACED BELOW ****/

        --smetio-multimediaPlayer-multimediaThumbnail-width: 50%;

        @media (orientation: landscape){

            
        }
        

        @media (orientation: portrait){

            
        }
              
        
    } 

}
