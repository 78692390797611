
@use "small_mobile";
@use "mobile";
@use "tablet";
@use "desktop";
@use "large_desktop";



@use "../../../../../css/mixins/displays/displays" as display;
@use "../../../../../css/mixins/dimensions/dimensions" as dimension;
@use "../../../../../css/mixins/position/positions" as position;
@use "../../../../../css/mixins/shapes/shapes" as shapes;
@use "../../../../../css/mixins/looks/looks" as looks;
@use "../../../../../css/mixins/effects/effects" as effects;
@use "../../../../../css/mixins/general_smetio_classes/general_smetio_classes" as classes;


// @import "../../../../../css/variables/devices/device_sizes";
// @import "../../../../../css/mainClasses";


body[data-app="smetio_icons"]{

    background-color:var(--smetio-layout-main-bg-color);
    color:var(--smetio-text-primary-color);

    *{

        box-sizing: border-box;
        background-color:var(--smetio-layout-main-bg-color);

    }

    .smetio-content {

        background-color:var(--smetio-layout-main-bg-color);
    }

    .smetio-icons-head {

        width: 100%;
        height: auto;
        position: fixed;
        top: 0;
        left: 0;
        padding-bottom: calc(var(--smetio-app-ui-icon-default-margin)/2);
        background-color:var(--smetio-layout-main-bg-color);
        z-index: 5;
        border-bottom: solid 1px rgba(var(--smetio-layout-grey-color-2-rgb), 0.1);

        h1, p {

            text-align: center;
            margin: calc(var(--smetio-app-ui-icon-default-margin)/4);
            font-weight: 300;

        }

        p {

            font-size: 100%;
        }

        .searchDiv {

            width: 100%;
            @include display.flex-wrap(row);
            @include display.flex-perfect-center; 
            margin-top: calc(var(--smetio-app-ui-icon-default-margin)/4);
            margin-bottom: calc(var(--smetio-app-ui-icon-default-margin)/4);
            background-color:var(--smetio-layout-main-bg-color);

            select {

                height: 35px;
                min-width: 100px;
                border-radius: 2.5px;
                flex: 1;
                border: solid 1px rgba(var(--smetio-layout-grey-color-2-rgb), 0.2);
                outline: solid 1px transparent;
                margin:calc(var(--smetio-app-ui-icon-default-margin)/4);
                background-color:var(--smetio-layout-main-bg-color);

            } 

            input {

                height: 35px;
                min-width: 100px;
                border-radius: 2.5px;
                flex: 1;
                border: solid 1px rgba(var(--smetio-layout-grey-color-2-rgb), 0.2);
                outline: solid 1px transparent;
                margin:calc(var(--smetio-app-ui-icon-default-margin)/4);
                background-color:var(--smetio-layout-main-bg-color);
                padding: calc(var(--smetio-app-ui-icon-default-margin)/2);
            }        

        }    
            
    }



    .content-icons {

        width: 100%;
        font-size: 100%;
        z-index: 2;
        @include display.flex-wrap(row);
        @include display.flex-perfect-center;     
        margin-top: 140px;     
        background-color:var(--smetio-layout-main-bg-color);

        .smetio-iconHolder {

            width: 100px;
            height: 100px;
            margin: calc(var(--smetio-app-ui-icon-default-margin)/2);
            padding: calc(var(--smetio-app-ui-icon-default-margin)/2);
            @include display.flex-wrap(column);
            @include display.flex-perfect-center; 
            //flex-wrap: nowrap;
            border: solid 0.5px rgba(var(--smetio-layout-grey-color-2-rgb), 0.2);
            font-size: 100%;
            border-radius: 2.5px;
            //overflow: hidden;

            &:hover {

                cursor: pointer;    
            }                     

            .smetio-iconTag{

                font-size: 100%;
                background-color: inherit;
                color: inherit;

                 i {

                    font-size: 200%;
                    background-color: inherit;
                    color: inherit;                    

                }                
            }
   
            .smetio-iconName {

                width: 100%;
                font-size: 100%;
                @include looks.setTextOverflowWithElipsis();
                background-color: inherit;
                color: inherit;                

                p {

                    font-size: 100%;
                    text-align: center;
                    margin-top: var(--smetio-app-ui-icon-default-margin);
                    background-color: inherit;
                    color: inherit;                    

                }                 

            }

        

        }

    }

    
    @include small_mobile.smallMobileScreen;
    @include mobile.mobileScreen;
    @include tablet.tabletScreen;
    @include desktop.desktopScreen;
    @include large_desktop.largeDesktopScreen;
  

}






