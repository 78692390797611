@use "small_mobile";
@use "mobile";
@use "tablet";
@use "desktop";
@use "large_desktop";



@use "../../../../../css/mixins/displays/displays" as display;
@use "../../../../../css/mixins/dimensions/dimensions" as dimension;
@use "../../../../../css/mixins/position/positions" as position;
@use "../../../../../css/mixins/shapes/shapes" as shapes;
@use "../../../../../css/mixins/looks/looks" as looks;
@use "../../../../../css/mixins/effects/effects" as effects;
@use "../../../../../css/mixins/general_smetio_classes/general_smetio_classes" as classes;


// @import "../../../../../css/variables/devices/device_sizes";
// @import "../../../../../css/mainClasses";



.smetio-app-qrcode-scanner {

    @include display.flex-wrap(column);
    @include display.flex-perfect-center;

    width: 100%;
    height: 70%;

    align-self: flex-start;

    .smetio-app-qrcode-scanner-videoContainer {

        width: 100%;
        height: calc(100vh - 300px);
       // height: 100%;
        z-index: 1;
    }

    .smetio-app-qrcode-scanner-overlay {
        @include display.flex-wrap(column);
        @include display.flex-perfect-center;
        @extend .smetio-app-qrcode-scanner-videoContainer;
        @extend .smetio-default-lightbox-bg;
        background-color: rgba(0, 0, 0, .8);
        mix-blend-mode: hard-light;
        @extend .smetio-toggleDisplay;
        height: unset;
        height: 100% !important;
        position: absolute;
        z-index: 2 !important;


        div {
            width: 250px;
            height: 250px;
            background-color: grey;

        }


    }

    button {
        z-index: 3 !important;
        position: absolute;
        //z-index: 3100 !important;
        @extend .smetio-blue-button;
        @extend .smetio-toggleDisplay;
    }

    .smetio-app-qrcode-scanner-cancelBtn {

        margin-top: 450px;

    }

    .smetio-app-qrcode-scanner-output {

        @include display.flex-wrap(column);
        @include display.flex-perfect-center;
        font-size: 100%;

        div {
            @include display.flex-wrap(column);
            @include display.flex-perfect-center;
            margin-bottom: var(--smetio-app-ui-icon-default-margin);

            h2 {
                font-size: 130%;
                margin-bottom: var(--smetio-app-ui-icon-default-margin);
                @extend .smetio-blue-text;
            }
        }

        h4 {

            font-size: 100%;
            margin-bottom: var(--smetio-app-ui-icon-default-margin);
            display: none;
        }

        button{
            position: unset;
            position: static !important;
            @extend .smetio-blue-button;
            @extend .smetio-toggleDisplay;
            margin-bottom: var(--smetio-app-ui-icon-default-margin);
        }
    }

    @include small_mobile.smallMobileScreen;
    @include mobile.mobileScreen;
    @include tablet.tabletScreen;
    @include desktop.desktopScreen;
    @include large_desktop.largeDesktopScreen;

}

/* #smetio-app-ui-action-bar ENDS HERE */