@use "small_mobile";
@use "mobile";
@use "tablet";
@use "desktop";
@use "large_desktop";

/*
developement/js/classes/smetioSmartbox/v1.0.0/styles/_main.scss
developement../../../../../css/variables/devices/_device_sizes.scss

developement/css/variables/devices/_device_sizes.scss
*/

@use "../../../../../css/mixins/displays/displays" as display;
@use "../../../../../css/mixins/dimensions/dimensions" as dimension;
@use "../../../../../css/mixins/position/positions" as position;
@use "../../../../../css/mixins/shapes/shapes" as shapes;
@use "../../../../../css/mixins/looks/looks" as looks;
@use "../../../../../css/mixins/effects/effects" as effects;
@use "../../../../../css/mixins/general_smetio_classes/general_smetio_classes" as classes;

// @import "../../../../../css/latestSmetioStyleSheet";

// @import "../../../../../css/variables/devices/device_sizes";
// @import "../../../../../css/mainClasses";



:root {
    --smetio-smartbox-head-control-size:100%;
    --smetio-smartbox-head-control-margin:5px;
}


.smetio-smartbox2 {

    touch-action: none;
    user-select: none;

    &[data-smetio-do-not-respond=true] {
        touch-action: none;
        user-select: none;
        pointer-events: none;
    }

    z-index: 1000;
    // background-color:var(--smetio-layout-grey-color-1); 
    // @extend.smetio-dominant-background;
    @extend.smetio-neutral-background;
    // @extend.smetio-dominant-border;
    // @extend.smetio-neutral-border;
    @extend.smetio-grey-border;

    @include display.flex-wrap(column);

    // border: solid 1px var(--smetio-layout-main-bg-color);
    // @extend.smetio-neutral-border-trans-50;


    &:hover {
        cursor: move;
        @extend.smetio-neutral-shadow-around-trans-50;
        // @extend.smetio-accent-border;
        // @extend.smetio-dominant-border;
        @extend.smetio-neutral-border;
    }

    border-radius: calc(var(--smetio-app-ui-icon-default-margin)/4);
    // box-shadow: 0px 0px 5px 0px rgba(var(--smetio-layout-grey-color-2-rgb), 0.7);
    
    &[data-smetio-position="fixed"] {
        position: fixed;
    }
    position: absolute;
    width: 350px;
    // height: 500px;
    height: 100vh;

    top: 0;
    // bottom: 100px;
    bottom: 0;
    right: 0;
    // right: calc(var(--smetio-app-ui-head-height)+25px);
    //left: 50px;
    min-width: var(--smetio-smartBox-min-width);
    // max-height: calc(100vh - 55px);
    max-height: 100vh;
    min-height: var(--smetio-app-ui-head-height);
    overflow: hidden;

    //transition: width 1s, height 1s;
    &[data-smetio-transition="yes"] {
        transition: width .5s, height .5s;
    }

    * {

        // color: var(--smetio-layout-grey-color-2);
        // @extend.smetio-dominant-text;
        // @extend.smetio-grey-text;

        font-size: 100%;
        border-radius: calc(var(--smetio-app-ui-icon-default-margin)/4);
    }

    >div.smetio-smartbox-head {

        // @include display.flex-wrap(row);
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        // @include display.flex-perfect-center;

        min-height: var(--smetio-app-ui-head-height);
        min-width: 150px;
        width: 100%;
        height: auto !important;                
        overflow: visible;
        z-index: 2;

        @extend.smetio-neutral-background;
        position: relative;
        border-top-left-radius: calc(var(--smetio-app-ui-icon-default-margin)/4);
        border-top-right-radius: calc(var(--smetio-app-ui-icon-default-margin)/4);
        border-bottom-left-radius: unset;
        border-bottom-right-radius: unset;

        @extend.smetio-grey-border;


        border-top-color: transparent !important;
        border-left-color: transparent !important;
        border-right-color: transparent !important;



        >div.smetio-smartbox-head-title {

            // position: absolute;
            margin-left: calc(var(--smetio-app-ui-icon-default-margin)/4);
            // background-color: aqua;
            flex: 1;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center !important;
            white-space: nowrap;         /* Prevents the text from wrapping */
            overflow: hidden;            /* Ensures the overflow is hidden */
            text-overflow: ellipsis; 

            h3 {
                //font-size: 120%;
                max-width: 100%;
                font-weight: bold;
                white-space: nowrap;         /* Prevents the text from wrapping */
                overflow: hidden;            /* Ensures the overflow is hidden */
                text-overflow: ellipsis;                 
            }
        }

        >div.smetio-smartbox-head-controls {
            @include display.flex-wrap(row);
            @include display.flex-perfect-center;
            // position: absolute;
            // background-color: antiquewhite;
            // right: calc(var(--smetio-app-ui-icon-default-margin)/4);
            min-width: 75px;
            width: auto;
            padding: calc(var(--smetio-app-ui-icon-default-margin)/4);
            @extend.smetio-neutral-background;
            height: auto;

            // background-color: var(--smetio-layout-grey-color-1);  
            // @extend.smetio-neutral-border-trans-50;           

            * {
                font-size: 100%;
            }

            >div.smetio-smartbox-head-control {
                height: auto;
                // margin-left: calc(var(--smetio-app-ui-icon-default-margin)/4);
                // margin-right: calc(var(--smetio-app-ui-icon-default-margin)/4);
                //  @include looks.setSmetioDefaultHoverAndActiveLooks;     
                margin-left: var(--smetio-smartbox-head-control-margin);
                margin-right: var(--smetio-smartbox-head-control-margin);
                i{
                    font-size: var(--smetio-smartbox-head-control-size);
                    // //@extend.smetio-neutral-text-trans-70;
                    // @extend.smetio-neutral-text-reverse;
                    // @extend.smetio-dominant-text-reverse;
                    @extend.smetio-grey-text;

                    &:hover{
                        // @extend.smetio-neutral-text-trans-20;
                        // @extend.smetio-neutral-text-trans-50;
                        @extend.smetio-dominant-text;
                        cursor: pointer;
                    }
            
                    &:active{
                        // @extend.smetio-neutral-text-trans-10;
                        @extend.smetio-dominant-text-reverse;
                    }
                }                         
            }

            


        }

    }

    /* .smetio-smartbox-head ENDS HERE */


     >div.smetio-smartbox-body::-webkit-scrollbar {

        display: none;

    } 

    >div.smetio-smartbox-body {

        flex: 1;
        min-width: 150px;
        min-height: var(--smetio-app-ui-head-height);
        max-height: calc(100vh - 55px);
        //overflow: hidden;
        overflow: scroll;
        overflow-x: hidden;
        margin: calc(var(--smetio-app-ui-icon-default-margin)/4);
        z-index: 1;
        color: inherit;
        @extend .smetio-hiddenScrollBar;

        .smetio-smartbox-body-content-holder {

            @extend.smetio-neutral-background;

            min-width: 100%;
            width: 100%;
            min-height: 100%;
            height: auto;
            // max-height: calc(100vh - 55px);
            max-height: 20000px;
            //overflow: scroll;
            overflow: hidden;
            box-sizing: border-box;
            padding: var(--smetio-app-ui-icon-default-margin);
            padding-bottom: 200px;

            // * {

            //     font-size: 100%;
            //     color: inherit;
            // }

            .smetio-form-submitter {

                width: 60%;
                margin-left: 20%;
                font-size: 120%;
                margin-top: var(--smetio-app-ui-icon-default-margin);
                margin-bottom: var(--smetio-app-ui-icon-default-margin);
                padding: calc(var(--smetio-app-ui-icon-default-margin)/4);
                border-radius: calc(var(--smetio-app-ui-icon-default-margin)/4);

                outline: solid 1px transparent;

            }

            .smetio-input-explannation-box {

                flex: 1;
                width: 100%;


                >div.smetio-input-explannation-box-section {

                    margin-top: var(--smetio-app-ui-icon-default-margin);
                    margin-bottom: var(--smetio-app-ui-icon-default-margin);
                    font-size: 100%;

                    h2 {
                        font-weight: 400;
                        margin-bottom: calc(var(--smetio-app-ui-icon-default-margin)/4);
                    }
                }


            }

            /* .smetio-input-explannation-box ENDS HERE */

            >div.smetio-readOnlyDataView {

                margin-bottom: var(--smetio-app-ui-icon-default-margin);

                h2 {
                    font-weight: 400;
                    margin-bottom: calc(var(--smetio-app-ui-icon-default-margin)/4);
                    padding: calc(var(--smetio-app-ui-icon-default-margin)/4);

                    @extend.smetio-neutral-background;
                    @extend.smetio-dominant-text;

                }

                p {

                    padding: calc(var(--smetio-app-ui-icon-default-margin)/4);

                }

            }

            /* > div.smetio-readOnlyDataView ENDS HERE */


        }

        /* .smetio-smartbox-body-content-holder ENDS HERE */

    }

    /* .smetio-smartbox-body ENDS HERE */


    @include small_mobile.smallMobileScreen;
    @include mobile.mobileScreen;
    @include tablet.tabletScreen;
    @include desktop.desktopScreen;
    @include large_desktop.largeDesktopScreen;


}

/* .smetio-smartbox ENDS HERE */