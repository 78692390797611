
@use "small_mobile";
@use "mobile";
@use "tablet";
@use "desktop";
@use "large_desktop";



@use "../../../../../css/mixins/displays/displays" as display;
@use "../../../../../css/mixins/dimensions/dimensions" as dimension;
@use "../../../../../css/mixins/position/positions" as position;
@use "../../../../../css/mixins/shapes/shapes" as shapes;
@use "../../../../../css/mixins/looks/looks" as looks;
@use "../../../../../css/mixins/effects/effects" as effects;
@use "../../../../../css/mixins/general_smetio_classes/general_smetio_classes" as classes;

// @import "../../../../../css/latestSmetioStyleSheet";

// @import "../../../../../css/variables/devices/device_sizes";
// @import "../../../../../css/mainClasses";



.smetio-app-ui-action-bar {

    z-index: 1000;

    @include display.flex-wrap(row);
    @include display.flex-perfect-center;

    width: inherit;
    position: fixed;
    background-color: unset !important;
    // //@extend.smetio-neutral-border-trans-30;
    justify-content: flex-start;
    height: var(--smetio-app-ui-head-height);
    border-left-color: transparent;

    div {

        margin-left: var(--smetio-app-ui-icon-default-margin);
        @extend .smetio-toggleDisplay;

        // //@extend.smetio-neutral-text-trans-70;
        // @extend.smetio-neutral-text;
        // @extend.smetio-dominant-text-reverse;
        @extend.smetio-grey-text;

    }
    .smetio-app-ui-action-bar-btn {
        &:hover{
            // //@extend.smetio-neutral-text-trans-70;
            @extend.smetio-dominant-text;
            cursor: pointer;
        }

        &:active{
            // @extend.smetio-neutral-text-trans-20;
            // @extend.smetio-neutral-text;
            @extend.smetio-dominant-text-reverse;
        }
    }

    .smetio-app-ui-action-bar-search-div {
        
        padding: unset !important;
        flex: 1;
        max-width: 300px;
        height: 100%;
        background-color: inherit;
        border-radius: 5px;

        .smetio-app-ui-action-bar-search-div-input-element {
        
            width: 100%;
            height: 100%;
            border-radius: 5px;
            margin-left: -10px;
            background-color: inherit;
            input {
                width: 100%;
                height: 100%;
                background-color: inherit;
                // @extend.smetio-neutral-text;
                // @extend.smetio-dominant-text;
                @extend.smetio-grey-text;
                // //@extend.smetio-neutral-border-trans-30;

                border-color: transparent;
                outline: solid 1px transparent;
                padding-left:5px; 
                border-radius: 5px;
            }
        }
    }


    @include small_mobile.smallMobileScreen;
    @include mobile.mobileScreen;
    @include tablet.tabletScreen;
    @include desktop.desktopScreen;
    @include large_desktop.largeDesktopScreen;      

}/* #smetio-app-ui-action-bar ENDS HERE */