
@use "small_mobile";
@use "mobile";
@use "tablet";
@use "desktop";
@use "large_desktop";



@use "../../../../../css/mixins/displays/displays" as display;
@use "../../../../../css/mixins/dimensions/dimensions" as dimension;
@use "../../../../../css/mixins/position/positions" as position;
@use "../../../../../css/mixins/shapes/shapes" as shapes;
@use "../../../../../css/mixins/looks/looks" as looks;
@use "../../../../../css/mixins/effects/effects" as effects;
@use "../../../../../css/mixins/general_smetio_classes/general_smetio_classes" as classes;


// @import "../../../../../css/variables/devices/device_sizes";
// @import "../../../../../css/mainClasses";


.smetio-app-ui-notification-icon {

    position: relative;

    .smetio-app-ui-notification-numbers {

        background-color: var(--smetio-link-color);
        color: var(--smetio-layout-grey-color-1);
        @include display.flex-perfect-center;
        border-radius: 25%;
        position: absolute;
        top: -25%;
        right: -25%;

        padding: 2px;

        h1 {

            font-size: 50%;
            text-align: center;

        }

    }

    @include small_mobile.smallMobileScreen;
    @include mobile.mobileScreen;
    @include tablet.tabletScreen;
    @include desktop.desktopScreen;
    @include large_desktop.largeDesktopScreen;        

}/* .smetio-app-ui-notification-icon ENDS HERE */

.smetio-app-ui-notification-comingSoon {

    h5 {
        font-size: 100%;
        margin-bottom: var(--smetio-app-ui-icon-default-margin);
    }

    h2 {
        font-size: 120%;
        font-weight: 400;

    }

}
