
@use "small_mobile";
@use "mobile";
@use "tablet";
@use "desktop";
@use "large_desktop";



@use "../../../../css/mixins/displays/displays" as display;
@use "../../../../css/mixins/dimensions/dimensions" as dimension;
@use "../../../../css/mixins/position/positions" as position;
@use "../../../../css/mixins/shapes/shapes" as shapes;
@use "../../../../css/mixins/looks/looks" as looks;
@use "../../../../css/mixins/effects/effects" as effects;
@use "../../../../css/mixins/general_smetio_classes/general_smetio_classes" as classes;


// @import "../../../../css/variables/devices/device_sizes";
// @import "../../../../css/mainClasses";


.smetio-offlineNotification {

    @extend .smetio-majorNotification;
    bottom: unset;
    top: 0 !important;    

    h5 {

        margin-bottom: var(--smetio-app-ui-icon-default-margin);
    }

    i {
        font-size: 200%;
        @extend .smetio-error-text;
        margin-top: var(--smetio-app-ui-icon-default-margin);
        margin-bottom: calc(var(--smetio-app-ui-icon-default-margin)/2);
    }
    
}

.smetio-legalPolicyNotification {

    @extend .smetio-majorNotification;
    border-top: solid 0.5px var(--smetio-border-color);

    h2 {

        @extend .smetio-blue-text;
        margin-top: var(--smetio-app-ui-icon-default-margin);
        margin-bottom: var(--smetio-app-ui-icon-default-margin);
        margin: calc(var(--smetio-app-ui-icon-default-margin)/2);

    }

    h5 {

        @extend .smetio-default-primary-text-color;
        margin: calc(var(--smetio-app-ui-icon-default-margin)/2);
    
    }

    a {

        text-decoration: none;
        @extend .smetio-blue-text;

    }

    button {

        @include display.flex-wrap(column);
        @include display.flex-perfect-center; 
        width: 40% !important;
        @extend .smetio-accent-bg-with-hover-active;
        //@extend .smetio-default-primary-text-color-with-hover-active;
        @extend .smetio-default-primary-text-color;
        @extend .smetio-no-border-or-outline;
        padding: calc(var(--smetio-app-ui-icon-default-margin)/4);
        border-radius: calc(var(--smetio-app-ui-icon-default-margin)/2);
        margin-top: var(--smetio-app-ui-icon-default-margin);
        margin-bottom: var(--smetio-app-ui-icon-default-margin);
        //margin-bottom: calc(var(--smetio-app-ui-icon-default-margin) * 2);

        font-weight: 400;
        font-size: 130%;

    }

    @include small_mobile.smallMobileScreen;
    @include mobile.mobileScreen;
    @include tablet.tabletScreen;
    @include desktop.desktopScreen;
    @include large_desktop.largeDesktopScreen;  

}/* .smetio-multimediaPlayer ENDS HERE */





