
@import "../../../../../css/variables/devices/device_sizes";

@mixin desktopScreen{

    @media only screen and (min-width:$desktop_min) and (max-width:$desktop_max){

        // THE CSS COMES HERE

        --smetio-page-contents-left-display: flex;

        @media (orientation: landscape){

            
        }
        

        @media (orientation: portrait){

            
        }
                
        
    } 

}
