@use "small_mobile";
@use "mobile";
@use "tablet";
@use "desktop";
@use "large_desktop";



// @use "../../../../../css/mixins/displays/displays" as display;
// @use "../../../../../css/mixins/dimensions/dimensions" as dimension;
// @use "../../../../../css/mixins/position/positions" as position;
// @use "../../../../../css/mixins/shapes/shapes" as shapes;
// @use "../../../../../css/mixins/looks/looks" as looks;
// @use "../../../../../css/mixins/effects/effects" as effects;
// @use "../../../../../css/mixins/general_smetio_classes/general_smetio_classes" as classes;



// @import "../../../../../css/variables/devices/device_sizes";
// @import "../../../../../css/mainClasses";

// @import "../../../../../css/latestSmetioStyleSheet";



// .smetio-customer-website-description {
//     // background-color: green;
//     // width: 500px !important;
//     // min-width: 500px !important;

//     // flex: 1;

//     min-width: 100% !important;
//     max-width: 100% !important;
//     width: 100% !important;
//     display: flex;
//     flex-direction: column !important;
//     flex-wrap: wrap;
//     @extend.smetio-neutral-background;
//     // position: relative;

//     h4 {
//         overflow-wrap: break-word;
//         // word-break: normal;
//         word-break: keep-all;
//         min-width: 100% !important;
//         max-width: 100% !important;
//         width: 100% !important;
//         margin: 0 !important;
//         margin-bottom: 20px !important;
//         @extend.smetio-dominant-text;
//     }

//     .smetio-customer-website-description-address {
//         // all: unset !important;
//         min-height: 0 !important;
//         height: auto !important;
//         width: 100%;
//         margin: 0 !important;
//         display: flex;
//         flex-direction: row !important;
//         flex-wrap: wrap !important;
//         align-items: flex-start !important;
//         justify-content: flex-start !important;
//         // background-color: antiquewhite;
//         text-align: left !important;
//         opacity: .7;
//         // margin-top: 30px !important;

//     }

// }

:root {
    --smetio-customer-website-teamMembers-items-max-width:1350px;
    --smetio-customer-website-teamMembers-item-width:250px;
    --smetio-customer-website-teamMembers-items-justify-content:flex-start;

    --smetio-photo-and-description-photo-width:50%;
}

.smetio-customer-website-team-members {

    width: 100%;
    min-height: 400px !important;
    height: auto;
    display: flex;
    flex-direction: column;
    // justify-content: center !important;
    align-items: center !important;
    padding-bottom: 150px;
    @extend.smetio-dominant-background;

    h2{
        @extend.smetio-accent-text;
    }

    .smetio-customer-website-teamMembers-items{

        // width: 80%;
        // max-width: 80% !important;
        max-width: var(--smetio-customer-website-teamMembers-items-max-width) !important;
        width: auto !important;
        display: flex;
        flex-direction: row;
        // justify-content: center;
        justify-content: var(--smetio-customer-website-teamMembers-items-justify-content);
        
        
        // justify-self: center !important;
        // align-self: center !important;
        flex-wrap: wrap;

        // background-color: antiquewhite;

        .smetio-photo-and-description {

            // .smetio-photo-and-description-photo {

            //     &[data-smetio-horizontal-responsive=true]{ 
            //         min-width: var(--smetio-photo-and-description-photo-width) !important;
            //         width: var(--smetio-photo-and-description-photo-width) !important;
            //     }

            //  }


            // width: var(--smetio-customer-website-teamMembers-item-width);
            .smetio-customer-website-team-member-description {

                // flex: 1 !important;
                min-width: 100% !important;
                max-width: 100% !important;
                width: 100% !important;
                // min-height: 100% !important;
                // height: 100% !important;
                display: flex;
                flex-direction: column !important;
                flex-wrap: wrap;

                // // display: flex !important;
                // flex: 1 !important;
                // // background-color: bisque;
                // display: flex !important;
                // flex-direction: column !important;
                // flex-wrap: wrap;
                // align-items: flex-start;
                // justify-content: flex-start;
                // background-color: aquamarine;
                // min-height: 200px !important;
                // height: 100% !important;
                height: auto !important;
                // position: relative;

                .smetio-customer-website-team-member-description-text {

                    display: flex;
                    flex-direction: column;
                    flex: 1;

                    h4 {
                        @extend.smetio-dominant-text;
                    }

                    .smetio-customer-website-description-title {
                        margin-bottom: 0 !important;
                        opacity: .7 !important;
                    }
                    .smetio-customer-website-description-experience {
                        margin-top: 0 !important;
                        opacity: .7 !important;
                    }
    
                    .smetio-customer-website-description-bio {
                        flex: 1;
                        font-size: 20px;
                        @extend.smetio-information-text;
                    }
                }

                .smetio-customer-website-description-book-with-me {
    
                    width: auto !important;
                    display: flex;
                    flex-direction: column;
                    justify-content: center !important;
                    align-items: center !important;
                    align-self: flex-end !important;
                    justify-self: flex-end !important; 
                    text-align: center !important;
                    margin: 0 !important;
                    margin-top: 20px !important;
                    padding: 0 !important;
                    // position: absolute;
                    // right: 0;
                    // bottom: 0;
                    padding:5px !important;
                    border-radius: 2px !important;
                    border: solid .5px transparent;
                    outline: solid .5px transparent;
                    @extend.smetio-dominant-background;
                    @extend.smetio-accent-text;
    
                    &:hover {
                        padding:7.5px !important;
                        cursor: pointer;
                        @extend.smetio-accent-background;
                        @extend.smetio-dominant-text;
                    }
    
                    a {
                        text-decoration: none !important;
                        display: block;
                        color: inherit;
                    }
                }     
                

            }


            @extend.smetio-neutral-background;


        }
    }



    @include small_mobile.smallMobileScreen;
    @include mobile.mobileScreen;
    @include tablet.tabletScreen;
    @include desktop.desktopScreen;
    @include large_desktop.largeDesktopScreen;

}

/* .smetio-multimediaPlayer ENDS HERE */