
@use "small_mobile";
@use "mobile";
@use "tablet";
@use "desktop";
@use "large_desktop";



@use "../../../../../css/mixins/displays/displays" as display;
@use "../../../../../css/mixins/dimensions/dimensions" as dimension;
@use "../../../../../css/mixins/position/positions" as position;
@use "../../../../../css/mixins/shapes/shapes" as shapes;
@use "../../../../../css/mixins/looks/looks" as looks;
@use "../../../../../css/mixins/effects/effects" as effects;
@use "../../../../../css/mixins/general_smetio_classes/general_smetio_classes" as classes;



// @import "../../../../../css/variables/devices/device_sizes";
// @import "../../../../../css/mainClasses";

// @import "../../../../../css/latestSmetioStyleSheet";

.smetio-language-options {

    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row !important;
    justify-content: center;
    align-items: center;

    div {
        flex: 1;
        height: auto;
        display: flex;
        flex-direction: row !important;
        justify-content: center;
        align-items: center;

        img {
            width:30px !important;
            height: auto !important;
        }

        i {
            font-size: 100%;
        }
    }
    // .smetio-language-options-current-language-text {
    //     min-width: 70%;
    //     max-width: 70%;
    //     width: 70%;
    //     justify-content: flex-start !important;
    //     align-items: center;
    //     text-align: left;

    //     // overflow: hidden;
    //     // text-wrap: nowrap !important;
    //     // text-overflow: ellipsis !important;

    //     white-space: nowrap; /* Prevent text from wrapping */
    //     overflow: hidden; /* Hide overflowing text */
    //     text-overflow: ellipsis; /* Display ellipsis for overflowed text */
    // }

    &:hover{
        cursor: pointer;
        padding:5px;
        border-radius: 5px;
        @extend.smetio-dominant-background;
        @extend.smetio-neutral-text-reverse;
    }

    @include small_mobile.smallMobileScreen;
    @include mobile.mobileScreen;
    @include tablet.tabletScreen;
    @include desktop.desktopScreen;
    @include large_desktop.largeDesktopScreen;  

}/* .smetio-multimediaPlayer ENDS HERE */

.smetio-language-options-language-item-holder {
    width: 100%;
    height: auto;    

    .smetio-language-options-language-item {
        width: 100%;
        height: auto;        
        display: flex;
        flex-direction: column !important;
        justify-content: flex-start !important;
        align-items: flex-start;   
        margin-bottom: 15px !important;
        @extend.smetio-neutral-text;   


        &:hover{
            cursor: pointer;
            padding-top:7px;
            padding-bottom:7px; 
            @extend.smetio-dominant-background;
            @extend.smetio-neutral-text-reverse;

        }

        a {
            text-decoration: none;
            display: block;
            color: inherit;
            padding-left:5px;
            padding-right: 5px;
            padding-top: 2.5px !important;
            padding-bottom: 2.5px !important;
            width: 100%;
            height: auto;        
            display: flex;
            flex-direction: column !important;
            justify-content: flex-start !important;
            align-items: flex-start;   

            .smetio-language-options-language-link-flag-and-text {
                width: 100%;
                height: 100%;        
                display: flex;
                flex-direction: row !important;
                justify-content: flex-start !important;
                align-items: center;

                // background-color: green;

                div {
                    display: flex;
                    flex-direction: row !important;
                    // justify-content: center;
                    align-items: center;  
                    // height: auto;
                }

                .smetio-language-options-language-item-flag {
                    width:40px !important;
                    height: 25px !important;
                    max-width:40px !important;
                    max-height: 25px !important;
                    overflow: hidden;
                    justify-content: center !important;
                    margin-right: 10px;
                    img{
                        width: 100%;
                        height: auto;
                    }                    
                }
                .smetio-language-options-language-item-text{
                    flex: 1;
                    height: auto;
                    justify-content: flex-start !important;
                    align-items: center;
                    // background-color: green;
                }
            }
        }

    }

}




