@use "small_mobile";
@use "mobile";
@use "tablet";
@use "desktop";
@use "large_desktop";



@use "../../../../../css/mixins/displays/displays" as display;
@use "../../../../../css/mixins/dimensions/dimensions" as dimension;
@use "../../../../../css/mixins/position/positions" as position;
@use "../../../../../css/mixins/shapes/shapes" as shapes;
@use "../../../../../css/mixins/looks/looks" as looks;
@use "../../../../../css/mixins/effects/effects" as effects;
@use "../../../../../css/mixins/general_smetio_classes/general_smetio_classes" as classes;


// @import "../../../../../css/variables/devices/device_sizes";
// @import "../../../../../css/mainClasses";

// @import "../../../../../css/latestSmetioStyleSheet";


.smetio-data-group {
    min-width: 100%;
    width: 100%;
    min-height: 100%;
    height: 100%;
}

.smetio-data-group-menu {
    min-width: 100%;
    min-height: 100%;
    width: 100%;
    height: 100%;
    // //@extend.smetio-neutral-border-trans-30;
    @extend.smetio-neutral-background;
    // @extend.smetio-neutral-shadow-around-trans-10;
    // position: absolute;
    // bottom: -240px;
    // z-index: 3;
    // right: var(--smetio-data-group-menu-right);
    // display: none;
    // border-radius: 5px;

    // &[data-smetio-view-style="listView"] {
    //     right: 5px;
    // }

    // &[data-smetio-active=true] {
    //     display: flex;
    //     flex-direction: column;
    // }

    .smetio-data-group-menu-item {

        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 7.5px;
        @extend.smetio-neutral-background;
        // @extend.smetio-dominant-text;
        @extend.smetio-grey-text;
        cursor: pointer;

        &:hover {
            // @extend.smetio-dominant-background-reverse;
            // @extend.smetio-dominant-text-reverse;
            // background-color: unset !important;
            // background-color: green !important;
            // @extend.smetio-neutral-background-trans-30;
            // @extend.smetio-neutral-border-trans-20;
            @extend.smetio-dominant-border;
            // @extend.smetio-dominant-text-reverse;
            border-right-color: transparent !important;
            border-left-color: transparent !important;
        }

        &:active {
            // //@extend.smetio-neutral-border-trans-70;
            @extend.smetio-dominant-text;
        }

        .smetio-data-group-menu-item-icon {
            margin-right: 10px;

            i {
                font-size: 120%;
            }
        }


    }

    @include small_mobile.smallMobileScreen;
    @include mobile.mobileScreen;
    @include tablet.tabletScreen;
    @include desktop.desktopScreen;
    @include large_desktop.largeDesktopScreen;
}