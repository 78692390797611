
@use "small_mobile";
@use "mobile";
@use "tablet";
@use "desktop";
@use "large_desktop";



@use "../../../../../css/mixins/displays/displays" as display;
@use "../../../../../css/mixins/dimensions/dimensions" as dimension;
@use "../../../../../css/mixins/position/positions" as position;
@use "../../../../../css/mixins/shapes/shapes" as shapes;
@use "../../../../../css/mixins/looks/looks" as looks;
@use "../../../../../css/mixins/effects/effects" as effects;
@use "../../../../../css/mixins/general_smetio_classes/general_smetio_classes" as classes;



// @import "../../../../../css/variables/devices/device_sizes";
// @import "../../../../../css/mainClasses";

// @import "../../../../../css/latestSmetioStyleSheet";

.smetio-credit-card-details-collector {

    min-width: 100%;
    min-height: 100%;
    width: 100%;
    height: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  

    @extend.smetio-neutral-background;

    *{
        font-size: 100% !important;
    }

    #address-element {
        margin-bottom: 30px;
    }


    button {        
            
        margin-top: 30px;
        max-width: 200px;
        width: 50%;
        height: auto;
        padding: 7px;
        border-radius: 5px;
        border: solid 1px transparent;
        outline: solid 1px transparent;
        font-size: 120%;
        font-weight: 600;
        display: none;
        @extend.smetio-dominant-background;
        @extend.smetio-grey-text-reverse;
        &:hover {
            cursor: pointer;
            @extend.smetio-accent-background;
            @extend.smetio-grey-text-reverse;
        }            
        // @extend.smetio-accent-button;
        // @extend.smetio-accent-text;
        // @extend.smetio-dominant-background;    
        // @include display.flex-wrap(column);
        // @include display.flex-perfect-center; 

        &[data-smetio-is-visible=false] {
            display: none;
        }
    }

    .smetio-credit-card-details-collector-error {
        // @extend.smetio-danger-background;
        margin-top: 20px;
        margin-bottom: 20px;
        @extend.smetio-danger-text;
    }

    @include small_mobile.smallMobileScreen;
    @include mobile.mobileScreen;
    @include tablet.tabletScreen;
    @include desktop.desktopScreen;
    @include large_desktop.largeDesktopScreen;  

}/* .smetio-multimediaPlayer ENDS HERE */


.smetio-credit-card-details-collector-status-message {

    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // margin-top: 20px;


    &[data-smetio-status="succeeded"] {
        @extend.smetio-success-background;
        @extend.smetio-success-text-reverse;
    }

    &[data-smetio-status="requires_payment_method"] {
        @extend.smetio-danger-background;
        @extend.smetio-danger-text-reverse;
    }

    &[data-smetio-status="processing"] {
        @extend.smetio-information-background;
        @extend.smetio-information-text-reverse;
    }

    h1, p, i {
        color: inherit !important;
        text-align: center;

        // &[data-smetio-status="succeeded"] {
        //     @extend.smetio-success-background;
        //     @extend.smetio-success-text;
        // }

        // &[data-smetio-status="requires_payment_method"] {
        //     @extend.smetio-danger-background;
        //     @extend.smetio-danger-text;
        // }

        // &[data-smetio-status="processing"] {
        //     @extend.smetio-information-background;
        //     @extend.smetio-information-text;
        // }            
    }

    i {
        font-size: 120%;
        margin-right: 10px;
    }


}


