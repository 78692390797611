
@use "small_mobile";
@use "mobile";
@use "tablet";
@use "desktop";
@use "large_desktop";



@use "../../../../../css/mixins/displays/displays" as display;
@use "../../../../../css/mixins/dimensions/dimensions" as dimension;
@use "../../../../../css/mixins/position/positions" as position;
@use "../../../../../css/mixins/shapes/shapes" as shapes;
@use "../../../../../css/mixins/looks/looks" as looks;
@use "../../../../../css/mixins/effects/effects" as effects;
@use "../../../../../css/mixins/general_smetio_classes/general_smetio_classes" as classes;



// @import "../../../../../css/variables/devices/device_sizes";
// @import "../../../../../css/mainClasses";

// @import "../../../../../css/latestSmetioStyleSheet";

.smetio-legal-policies-page {

    width: 100%;
    height: auto;
    @extend.smetio-neutral-background;

    h1 {
        font-size: 150%;
        text-align: center;
        @extend.smetio-dominant-text;
    }

    .smetio-legal-policies-page-policy-link-holder {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: center;
        margin-top: 30px;

        .smetio-legal-policies-page-policy-link {
            width: 100%;
            margin: 10px;
            a {
                display: block;
                text-decoration: none;
                padding:10px;
                font-size: 120%;
                text-align: left;
                @extend.smetio-dominant-background;
                @extend.smetio-accent-text;

                &:hover {
                    cursor: pointer;
                    @extend.smetio-accent-background;
                    @extend.smetio-dominant-text;
                }
            }
        }

    }

    @include small_mobile.smallMobileScreen;
    @include mobile.mobileScreen;
    @include tablet.tabletScreen;
    @include desktop.desktopScreen;
    @include large_desktop.largeDesktopScreen;  

}/* .smetio-multimediaPlayer ENDS HERE */





