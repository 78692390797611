
@use "small_mobile";
@use "mobile";
@use "tablet";
@use "desktop";
@use "large_desktop";



@use "../../../../../css/mixins/displays/displays" as display;
@use "../../../../../css/mixins/dimensions/dimensions" as dimension;
@use "../../../../../css/mixins/position/positions" as position;
@use "../../../../../css/mixins/shapes/shapes" as shapes;
@use "../../../../../css/mixins/looks/looks" as looks;
@use "../../../../../css/mixins/effects/effects" as effects;
@use "../../../../../css/mixins/general_smetio_classes/general_smetio_classes" as classes;



// @import "../../../../../css/variables/devices/device_sizes";
// @import "../../../../../css/mainClasses";

// @import "../../../../../css/latestSmetioStyleSheet";

.smetio-color-input {

    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: inherit;

    div {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-right: 5px;
        min-height: 100%;
        height: 100%;
        background-color: inherit;


        input, select {
            width: 100%;
            height: 100%;
            border: solid 0.1px transparent !important;
            outline: solid 0.1px transparent !important;
            min-width: 20px !important;
            height: 100% !important;
            // @extend.smetio-grey-border;
            // border: solid 0.1px green !important;
            margin-left: 2.5px;
        }
    }

    .smetio-color-input-color-picker-and-code-div {

        width: 100% !important;
        @extend.smetio-grey-border;

        &[data-smetio-read-only=true]{

            border-color: transparent !important;
        }

        &:hover {
            @extend.smetio-dominant-border;
        }

        .smetio-color-input-color-picker-input-div {
            min-height: 35px !important;
            height: 35px !important;
            min-width: 20px !important;
            width: 20px !important;
            // border: solid 0.1px green !important;
            // background-color: rgb(241, 107, 230) !important;
            padding:0 !important;
            margin:0 !important;
    
            .smetio-color-input-color-picker-input {
                min-width: 100% !important;
                width: 100% !important;
                // padding: none !important;
                // height:50px;
                // width:60px;
                padding:0 !important;
                margin:0 !important;
                margin-left: 0 !important;
                border:solid 0.1px transparent !important;
                outline:solid 0.1px transparent !important;
                // visibility: hidden;
            }
        }
    
        .smetio-color-input-color-code-input-div {

            flex: 1;
            .smetio-color-input-color-code-input {
                // min-width: 40% !important;
                // width: 40% !important;
            }
        }

        .smetio-color-input-icons-div {
            width: auto !important;

            &[data-smetio-read-only=true]{

                display: none;
            }

            div {
                width: auto !important;
                padding-left: 2.5px !important;
                padding-right: 2.5px !important;

                &:hover {
                    cursor: pointer;
                    @extend.smetio-dominant-background;
                }

                &:active {
                    @extend.smetio-dominant-background;
                }

                i{
                    font-size: 120%;
                }
            }
            
            .smetio-color-input-advanced-settings-preview-btn {
                display: none;
            }
        }
        
    }

    .smetio-color-input-advanced-settings-div {

        transition: all 0.5s ease-in-out;
        // flex-wrap: nowrap !important;

        &[data-smetio-read-only=true]{

            display: none;
        }
        
        &[data-smetio-is-visible=false] {
            display: none;
            min-height: 0 !important;
            height: 0 !important;
        }

        div{
            flex: 1;
            width: auto !important;
            margin-left: 0 !important;
            margin-right: 0 !important;
            padding-left: 0 !important;
            padding-right: 0 !important;
            min-height: 35px !important;
            height: 35px !important;
            input, label, i {
                // @extend.smetio-grey-border;
                margin: 0 !important;
                padding: 0 !important;
                margin-left: 0 !important;
                // background-color: green;

            }
            label, i {
                width: auto;
            }
            input {
                border:solid 0.1px transparent !important;
                @extend.smetio-neutral-background-trans-30;
                text-align: center;
            }
            select{
                min-width: 50px !important;
                width: auto !important;
                border:solid 0.1px transparent !important;
                text-align: center;
            }
        }

        .smetio-color-input-tint-gap-input-div {
            // width: auto !important;
            // margin-left: 0 !important;
            // margin-right: 0 !important;
            // padding-left: 0 !important;
            // padding-right: 0 !important;
            // input, label {
            //     @extend.smetio-grey-border;
            //     margin: 0 !important;
            //     padding: 0 !important;
            //     margin-left: 0 !important;
            //     background-color: green;
            // }
            // label {
            //     width: auto;
            // }
        }
        .smetio-color-input-match-brand-input-div {
            // all: unset;
            min-width: 20px !important;
            max-width: 20px !important;
            width: auto !important;
            justify-self: flex-end !important;
            display: flex !important;
            flex-direction: row !important;
            justify-content: flex-end !important;
            align-items: center;
            // background-color: green;
            div {
                margin-top: -20px !important;
            }

        }
    }


    @include small_mobile.smallMobileScreen;
    @include mobile.mobileScreen;
    @include tablet.tabletScreen;
    @include desktop.desktopScreen;
    @include large_desktop.largeDesktopScreen;  

}/* .smetio-multimediaPlayer ENDS HERE */


.smetio-color-input-pre-defined-palettes-and-color-scheme-holder {

    all: unset !important;

    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    // padding-bottom: 600px;
    // background-color: green;
    .smetio-color-input-color-scheme-title {
        text-align: center;
        font-size: 120%;
        font-weight: bold;
        @extend.smetio-dominant-text;  
        margin-bottom: 10px;      
    }

    .smetio-color-input-color-scheme-instruction {
        text-align: center;
        margin-top: 10px;   
        margin-bottom: 10px;   
    }

    .smetio-color-input-pre-defined-palettes-holder {

        width: 100% !important;
        height: auto !important;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        // background-color: pink;
        flex-wrap: wrap;

        @extend.smetio-grey-background-trans-20;
    
        padding-bottom: 10px;
    
        .smetio-color-input-pre-defined-palette-div {
            // width: 40% !important;
            flex: 1;
            min-width: 40% !important;
            height: auto !important;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            margin: 10px !important;
            margin-top: 20px !important;
            // margin-bottom: 30px;
            max-height: 130px !important;
            min-height: 130px !important;
            height: auto !important;
            overflow: hidden;

            position: relative;
            
            &:hover {
                cursor: pointer;
            }

            &:has(.smetio-color-input-pre-defined-palette-cover-picture-div:hover){
                cursor: pointer;
                position: absolute;
                z-index: 1000;
                width: 100% !important;
                height: auto !important;
                max-height:1000px !important
            }
        
            .smetio-color-input-pre-defined-palette-cover-picture-div {
                width: 100% !important;
                height: auto !important;
                img {
                    width: 100% !important;
                    height: auto !important;
                }


            }
        
            .smetio-color-input-pre-defined-palette-colors-div {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                // flex-wrap: wrap;
                min-height: 50px !important;
                height: 50px !important;

                // transition: all 0.3s ease-in-out;
          

                &:hover {
                    position: absolute !important;
                    left: 0 !important;
                    top: 0 !important;
                    z-index: 3;
                    min-height: 130px  !important;
                    height: 130px  !important;
                    max-height: 130px  !important;
              
                    // background-color: red;
                }

                div {
                    flex: 1;
                    min-height: 100%!important;
                    height: 100% !important;
                    -webkit-border-radius: 0 !important;
                    -moz-border-radius: 0 !important;
                    border-radius: 0 !important;
                }
            }
        
        
        }
    }

    .smetio-color-input-color-scheme-advance-preview-btn {
        width: 100% !important;
        height: auto !important;
        padding: 5px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        margin-top: 10px;
        margin-bottom: 10px;

        @extend.smetio-grey-background-trans-20;
        @extend.smetio-dominant-text;

        &:hover {
            cursor: pointer;
            @extend.smetio-dominant-background;
            @extend.smetio-neutral-text-reverse;
        }

        .smetio-color-input-color-scheme-advance-preview-btn-txt {
            flex: 1;            
        }
        .smetio-color-input-color-scheme-advance-preview-btn-icon {
            width: 20px !important;
            text-align: center;
            i {
                font-size: 120% !important;
            }
        }


    }
    
    .smetio-advance-color-scheme {
        display: none;
    }

}







