
@use "small_mobile";
@use "mobile";
@use "tablet";
@use "desktop";
@use "large_desktop";



@use "../../../../../css/mixins/displays/displays" as display;
@use "../../../../../css/mixins/dimensions/dimensions" as dimension;
@use "../../../../../css/mixins/position/positions" as position;
@use "../../../../../css/mixins/shapes/shapes" as shapes;
@use "../../../../../css/mixins/looks/looks" as looks;
@use "../../../../../css/mixins/effects/effects" as effects;
@use "../../../../../css/mixins/general_smetio_classes/general_smetio_classes" as classes;

// @import "../../../../../css/latestSmetioStyleSheet";

// @import "../../../../../css/variables/devices/device_sizes";
// @import "../../../../../css/mainClasses";


.smetio-app-ui-side-bar {

    position: fixed;
    right: var(--smetio-app-ui-icon-default-margin);
    //bottom: calc(var(--smetio-app-ui-head-height)+550px);
    top: 300px;


    div {

        margin-top: var(--smetio-app-ui-side-bar-icons-spacing);
        margin-bottom: var(--smetio-app-ui-side-bar-icons-spacing);
        text-align: right;
        // @extend.smetio-accent-icon-interactive;
        
        @extend.smetio-dominant-text;
        i{
            &:hover{
                @extend.smetio-dominant-text-reverse;
                cursor: pointer;
            }
    
            &:active{
                @extend.smetio-dominant-text;
            }
        }
    }

    .smetio-app-ui-side-bar-activeMainMenu {

        font-size: 90%;
        margin-top: calc((var(--smetio-app-ui-side-bar-icons-spacing)/2) - var(--smetio-app-ui-side-bar-icons-spacing));
        margin-bottom: 0;

        p {
            text-align: right;
            color: rgba(var(--smetio-disable-color-rgb), 0.1);
        }

    }/* .activeSideMenu ENDS HERE */

    @include small_mobile.smallMobileScreen;
    @include mobile.mobileScreen;
    @include tablet.tabletScreen;
    @include desktop.desktopScreen;
    @include large_desktop.largeDesktopScreen;  


}/* .smetio-app-ui-side-bar ENDS HERE */


