@use "small_mobile";
@use "mobile";
@use "tablet";
@use "desktop";
@use "large_desktop";



@use "../../../../../css/mixins/displays/displays" as display;
@use "../../../../../css/mixins/dimensions/dimensions" as dimension;
@use "../../../../../css/mixins/position/positions" as position;
@use "../../../../../css/mixins/shapes/shapes" as shapes;
@use "../../../../../css/mixins/looks/looks" as looks;
@use "../../../../../css/mixins/effects/effects" as effects;
@use "../../../../../css/mixins/general_smetio_classes/general_smetio_classes" as classes;

// @import "../../../../../css/latestSmetioStyleSheet";

// @import "../../../../../css/variables/devices/device_sizes";
// @import "../../../../../css/mainClasses";


:root {
    --smetio-data-presentation-width: 15%;
    --smetio-data-presentation-height: 300px;

    --smetio-data-presentation-menu-width: 200px;
    --smetio-data-presentation-menu-height: auto;
    --smetio-data-presentation-menu-min-height:250px;
    --smetio-data-presentation-menu-right: -100px;
}

.smetio-in-smart-box-button {

    margin-top: 30px;
    max-width: 200px;
    width: 50%;
    height: auto;
    padding: 7px;
    border-radius: 5px;
    border: solid 1px transparent;
    outline: solid 1px transparent;
    font-size: 120%;
    font-weight: 600;
    @extend.smetio-dominant-background;
    @extend.smetio-grey-text-reverse;
    // @extend.smetio-neutral-text-reverse;
    &:hover {
        cursor: pointer;
        @extend.smetio-accent-background;
        @extend.smetio-grey-text-reverse;
        // @extend.smetio-neutral-text;
    }
    // @extend.smetio-accent-button;
    // @extend.smetio-accent-text;
    // @extend.smetio-dominant-background;    
    @include display.flex-wrap(column);
    @include display.flex-perfect-center; 

    &[data-smetio-is-visible=false] {
        display: none;
    }
}

.smetio-data-presentation {

    flex: var(--smetio-data-presentation-width);
    height: 150px;
    // //@extend.smetio-neutral-border-trans-30;
    // @extend.smetio-neutral-background-trans-10;
    // @extend.smetio-neutral-background;
    @extend.smetio-grey-background-trans-10;
    @extend.smetio-grey-border;
    @extend.smetio-grey-text;
    // @extend.smetio-dominant-border;

    position: relative;
    z-index: 1;
    border-radius: 5px;
    display: flex;
    &[data-smetio-is-visible=false] {
        display: none;
    }

    &[data-smetio-view-style="cardView"] {
        max-width: var(--smetio-data-presentation-width);
        min-width: var(--smetio-data-presentation-width);
        flex: var(--smetio-data-presentation-width) !important;

        min-height: var(--smetio-data-presentation-height) !important;
        max-height: var(--smetio-data-presentation-height) !important;
        height: var(--smetio-data-presentation-height) !important;

        margin: 15px;

        flex-direction: column;

    }

    &[data-smetio-view-style="listView"] {
        // min-width: 95%;
        flex: 100%;
        min-height: 60px;
        max-height: 60px !important;
        height: 60px;
        border-top-color: transparent !important;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        flex-direction: row;
        align-items: center;
        // padding-top: 7px;
        // padding-bottom: 7px;
    }

    &[data-smetio-active=true] {
        // @extend.smetio-accent-border;
        @extend.smetio-dominant-border;
    }

    .smetio-data-presentation-checkbox {
        z-index: 3;
        display: none;
      

        &[data-smetio-active=true] {
            display: block;
            
        }
        
        &[data-smetio-view-style="cardView"] {
            position: absolute;
            left: 5px;
            top: -15px;
        }
        
        &[data-smetio-view-style="listView"] {
            z-index: 1;
            position: relative;
            // margin-top: -40px;
            margin-bottom: 70px;
            
            // align-self: center;
            // justify-self: flex-start;
            margin-left:7.5px;
            margin-right: 10px;
        }
    }

    .smetio-data-presentation-alert-icon {
        display: none;
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 3;
        @extend.smetio-danger-text;

        
        &[data-smetio-is-visible=true] {
            display: block;            
        }

        &:hover{
            cursor: pointer;
            font-size: 200%;
        }

        i {
            font-size: 120%;
            color: inherit;
            // @extend.smetio-danger-text;
        }
    }

    &:hover {
        cursor: pointer;
        @extend.smetio-grey-shadow-around-trans-50;
        // @extend.smetio-accent-border;
        @extend.smetio-dominant-border;
        z-index: 5;

        >.smetio-data-presentation-checkbox{
            display: block;
        }
    }

    
    .smetio-data-presentation-images {
        z-index: 2;
        width: 100%;
        height: calc(var(--smetio-data-presentation-height)/2);
        position: relative;
        overflow: hidden;
        border-radius: 5px;
        // @extend.smetio-dominant-background;
        // @extend.smetio-neutral-background;
        background-color: inherit;


        &[data-smetio-view-style="listView"] {
            width: 30px;
            height: 30px;
            margin-left: 15px;
            @include display.flex-wrap(column);
            @include display.flex-perfect-center;

            &:hover {
                top: 0;
                position: absolute;
                width: 150px;
                height: 150px;

                .smetio-data-presentation-logo {
                    width: 50px;
                    height: 50px;
                }
            }
        }


        .smetio-data-presentation-cover-image {
            max-height: calc(var(--smetio-data-presentation-height)/2);
            width: 100%;
            height: 100%;
            overflow: hidden;
            border-radius: 5px;
            background-color: inherit;
            @include display.flex-wrap(column);
            @include display.flex-perfect-center;

            img {
                min-width: 100%;
                width: auto;
                height: 100%;
                border-radius: 5px;
            }
        }

        .smetio-data-presentation-logo {

            @include display.flex-wrap(column);
            @include display.flex-perfect-center;
            border-radius: 5px;
            width: 50px;
            height: 50px;
            max-width: 50px;
            max-height: 50px;
            overflow: hidden;
            position: absolute;
            left: 5px;
            // bottom: -25px;
            bottom: 5px;
            // background-color: inherit;
            @extend.smetio-neutral-background;


            img {
                width: 100%;
                height: auto;
            }

            &[data-smetio-view-style="listView"] {
                width: 10px;
                height: 10px;
            }
        }
    }

    .smetio-data-presentation-text {
        width: 100%;
        margin-top: 15px;
        margin-bottom: 15px;
        border-radius: 5px;

        &:hover {
            cursor: wait;
        }


        h2,
        h4,
        p {
            margin: 5px;
        }

        h2 {
            font-size: 120%;
            font-weight: 600;
        }

        h4 {
            font-weight: 400;
            font-size: 105%;
        }

        p {
            font-size: 100%;
            font-weight: 200;
        }

        &[data-smetio-view-style="listView"] {
            @include display.flex-wrap(row);
            align-items: center;
            height: 100%;
            margin: 0;
            margin-left: 15px;
            overflow: hidden;
            padding-top: 5px;
            padding-bottom: 5px;

            h2,
            h4,
            p {
                margin: 0;
                margin-left: 7px;
            }
        }
    }

    .smetio-data-presentation-menu {
        min-width: var(--smetio-data-presentation-menu-width);
        min-height: var(--smetio-data-presentation-menu-min-height);
        width: var(--smetio-data-presentation-menu-width);
        height: var(--smetio-data-presentation-menu-height);
        // //@extend.smetio-neutral-border-trans-30;
        // @extend.smetio-dominant-background;
        @extend.smetio-neutral-background;
        // @extend.smetio-dominant-border;
        // @extend.smetio-neutral-shadow-around-trans-50;
        // @extend.smetio-grey-background-trans-10;
        @extend.smetio-grey-border;
        position: absolute;
        bottom: -240px;
        z-index: 3;
        right: var(--smetio-data-presentation-menu-right);
        display: none;
        border-radius: 5px;

        &[data-smetio-view-style="listView"] {
            right: 5px;
        }

        &[data-smetio-active=true] {
            display: flex;
            flex-direction: column;
        }

        .smetio-data-presentation-menu-item {

            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            padding: 7.5px;
            border-radius: 5px;
            // @extend.smetio-dominant-background;
            @extend.smetio-neutral-background;
            // @extend.smetio-dominant-text;
            @extend.smetio-grey-text;
            
            cursor: pointer;

            &[data-smetio-is-visible=false] {
                display: none;
            }

            &:hover {
                // @extend.smetio-dominant-background-reverse;
                // @extend.smetio-dominant-text-reverse;
                // background-color: unset !important;
                // background-color: green !important;
                // @extend.smetio-neutral-background-trans-30;
                // @extend.smetio-neutral-border-trans-20;
                @extend.smetio-dominant-border;
                border-top: solid 1px transparent !important;
                border-right: solid 1px transparent !important;
                border-left:solid 1px transparent !important;
                // @extend.smetio-dominant-text-reverse;
                // @extend.smetio-dominant-text;
            }

            &:active{
                //@extend.smetio-neutral-border-trans-70;
            }

            .smetio-data-presentation-menu-item-icon {
                margin-right: 10px;
                i{
                    font-size: 120%;
                }
            }


        }


    }

    .smetio-data-presentation-menu-btn {
        position: absolute;
        bottom: 2px;
        right: 2px;
        @extend.smetio-dominant-text;
        // @extend.smetio-accent-icon-interactive;
        &:hover{
            cursor: pointer;
        }

        i {
            font-size: 120%;
            margin-right: -3px;
        }
    }

    .smetio-data-presentation-info-on-hover{
        min-width: 100%;
        min-height: 100%;
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 100;
        border-radius: 5px;
        @extend.smetio-neutral-background;
        @extend.smetio-hiddenScrollBar;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 10px;
        opacity: 1;
        transition: opacity 0.5s;


        &[data-smetio-is-visible=false] {
            display: none;
            opacity: 0;
        }

        .smetio-data-presentation-info-on-hover-preloader {
            min-width: 100%;
            min-height: 100%;
            max-width: 100%;
            max-height: 100%;
            width: 100%;
            height: 100%;
            flex: 1;
            @include display.flex-wrap(column);
            @include display.flex-perfect-center; 
        }
    }

    @include small_mobile.smallMobileScreen;
    @include mobile.mobileScreen;
    @include tablet.tabletScreen;
    @include desktop.desktopScreen;
    @include large_desktop.largeDesktopScreen;

}

.smetio-data-presentation-big-edit-btn, .smetio-data-presentation-big-cancel-btn {
    position: absolute;
    bottom: 5px;
    right: 5px;
    z-index: 5;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    @include display.flex-wrap(column);
    @include display.flex-perfect-center; 
    // @extend.smetio-dominant-background;
    // @extend.smetio-dominant-text;
    // //@extend.smetio-neutral-border-trans-30;

    @extend.smetio-neutral-background;
    // @extend.smetio-dominant-text;
    @extend.smetio-grey-text;
    // @extend.smetio-dominant-border;
    @extend.smetio-grey-border;

    &[data-smetio-is-visible=false] {
        display: none;
    }

    &:hover {
        cursor: pointer;
        // @extend.smetio-dominant-background-reverse;
        // @extend.smetio-dominant-text-reverse;
        @extend.smetio-dominant-border;
        @extend.smetio-dominant-text;
    }

    i{
        font-size: 300%;
    }
}

.smetio-data-presentation-submit-btn-container {
    width: 100%;
    height: auto;
    @include display.flex-wrap(column);
    @include display.flex-perfect-center; 
    margin-top: 70px;

    .smetio-data-presentation-submit-btn{

        @extend.smetio-in-smart-box-button;

        // // all: unset !important;

        // margin-top: 30px;
        // max-width: 200px;
        // width: 50%;
        // height: auto;
        // padding: 7px;
        // border-radius: 5px;
        // border: solid 1px transparent;
        // outline: solid 1px transparent;
        // font-size: 120%;
        // font-weight: 600;
        // @extend.smetio-dominant-background;
        // @extend.smetio-grey-text-reverse;
        // &:hover {
        //     cursor: pointer;
        //     @extend.smetio-accent-background;
        //     @extend.smetio-grey-text-reverse;
        // }
        // // @extend.smetio-accent-button;
        // // @extend.smetio-accent-text;
        // // @extend.smetio-dominant-background;    
        // @include display.flex-wrap(column);
        // @include display.flex-perfect-center; 

        // &[data-smetio-is-visible=false] {
        //     display: none;
        // }

        // &:hover{
        //     cursor: pointer;
        // }
    }
}

.smetio-data-presentation-extra-content {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    align-items: flex-start;
    // margin-top: 20px;

    div {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center !important;
        align-items: center;

        h2 {
            text-align: center !important;
        }
        button {

            @extend.smetio-in-smart-box-button;
        }
    }

}



/* .smetio-data-presentation ENDS HERE */