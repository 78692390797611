
//@import "../../../../css/variables/devices/_device_sizes";
@import "../../../../../css/variables/devices/device_sizes";

@mixin smallMobileScreen{

    @media only screen and (min-width:$small_mobile_min) and (max-width:$small_mobile_max){

        --smetio-smartbox-head-control-size:100%;
        --smetio-smartbox-head-control-margin:7.5px;
        /**** THE CSS FOR THE SMALL MOBILE LOOK OF THE WIDGET SHOULD BE PLACED BELOW ****/

        @media (orientation: landscape){

            
        }
        

        @media (orientation: portrait){

            
        }
              
        
    } 

}
