
@import "../../../../../css/variables/devices/_device_sizes";

@mixin smallMobileScreen{

    @media only screen and (min-width:$small_mobile_min) and (max-width:$small_mobile_max){


        --smetio-photo-and-description-horizontal-direction:column;
        --smetio-photo-and-description-horizontal-justify-content:flex-start;
        --smetio-photo-and-description-flex-wrap:wrap;
        // --smetio-photo-and-description-horizontal-photo-width:100%;


        // .smetio-photo-and-description {
            
        //     // &[data-smetio-horizontal-responsive=true]{ 

        //     //     min-height: 2000px !important;
        //     //     height: auto !important;
        //     // }


        //     .smetio-photo-and-description-photo {
        
        //         &[data-smetio-orientation="horizontal"]{
        
        //             &[data-smetio-horizontal-responsive=true]{ 
        //                 width:100% !important;
        //             }
        //         }
        //     }

        // }

        .smetio-photo-and-description-image {
            width: 100% !important;
        }

        /**** THE CSS FOR THE SMALL MOBILE LOOK OF THE WIDGET SHOULD BE PLACED BELOW ****/

        @media (orientation: landscape){

            
        }
        

        @media (orientation: portrait){

            
        }
              
        
    } 

}
