
@import "../../../../../css/variables/devices/_device_sizes";

@mixin mobileScreen{

    /* @media only screen and (min-width:$mobile_min) and (max-width:$mobile_max){ */
    
    /**** COMBINE SMALL MOBILE AND MOBILE BY SETTING ONLY MAX-WIDTH ****/
    
    @media only screen and (min-width:$mobile_min) and (max-width:$mobile_max){


        #greeting {

            width:90%;

            h1,h2{
            
                font-size:150%;
                font-weight:400;
                
            }

            h2{
            
                font-weight:300;
                
            }
            
            h1,h2,p{
            
                padding-left:10px;
                padding-right:10px;
                
            } 
            
            p{
            
                font-size:150%;
                font-weight:200;
                
            }             
                     
        }  


        @media (orientation: landscape){

            
        }
        

        @media (orientation: portrait){

            
        }
        
        
    }

}    