
@use "small_mobile";
@use "mobile";
@use "tablet";
@use "desktop";
@use "large_desktop";



// @use "../../../../../css/mixins/displays/displays" as display;
// @use "../../../../../css/mixins/dimensions/dimensions" as dimension;
// @use "../../../../../css/mixins/position/positions" as position;
// @use "../../../../../css/mixins/shapes/shapes" as shapes;
// @use "../../../../../css/mixins/looks/looks" as looks;
// @use "../../../../../css/mixins/effects/effects" as effects;
// @use "../../../../../css/mixins/general_smetio_classes/general_smetio_classes" as classes;



// @import "../../../../../css/variables/devices/device_sizes";
// @import "../../../../../css/mainClasses";

// @import "../../../../../css/latestSmetioStyleSheet";

// .ql-size-10px { font-size: 10px; }
// .ql-size-12px { font-size: 12px; }
// .ql-size-14px { font-size: 14px; }
// .ql-size-18px { font-size: 18px; }
// .ql-size-24px { font-size: 24px; }

.ql-snow{
    .ql-picker{
        &.ql-size{
            .ql-picker-label,
            .ql-picker-item{
                &::before{
                    content: attr(data-value) !important;
                }
            }
        }
    }
}


.smetio-text-editor {

    width: 100%;
    min-height: 92vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    @extend.smetio-hiddenScrollBar;

    &[data-smetio-read-only=true]{
        // touch-action: none;
        // pointer-events: none;
        // margin: 0px !important;
        // padding: 0px !important;

        // *{
        //     touch-action: none;
        //     pointer-events: none;
        // }

        .smetio-text-editor-ctrl {
            margin: 0px !important;
            padding: 0px !important;            
        }
        .ql-toolbar {
            border-bottom: solid 1px transparent !important;
            display: none;
            max-height: 0px !important;
            height: 0px !important;
            overflow: hidden !important;            
        }

        .ql-editor {
            margin-top: 0px !important;
            margin-left: 0px !important;
            margin-right: 0px !important;
            padding: 0px !important;
            width: 100% !important;
        }
        .smetio-text-editor-container {
            
            margin: 0px !important;
            margin-top:15px !important;
            padding: 0px !important;  
            padding-left:15px !important;
            padding-right:15px !important;
            
        }
    }

    .smetio-text-editor-custom-controls {
        width: auto;
        height: auto;
        display: flex;
        flex-direction: row;

        &[data-smetio-read-only=true]{ 
            display: none;
            max-height: 0px !important;
            height: 0px !important;
            overflow: hidden !important;
        }

        .smetio-text-editor-ctrl{
            width: auto;
            height: auto;
            padding: 2.5px;  
            margin: 5px;     
            @extend.smetio-neutral-text;   
            i {
                font-size: 100%;
            }

            &:hover{
                cursor: pointer;
                @extend.smetio-dominant-text;
            }
        }
    }

    .ql-toolbar {
        border: solid 0.5px transparent !important;
        border-bottom: solid 1px grey !important;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        box-shadow: none;
    }

    .ql-editor {
        border: solid 0.5px transparent !important;
        @extend.smetio-hiddenScrollBar;
        margin-top: 10px !important;
        @extend.smetio-grey-text;
        
    }    

    .smetio-text-editor-container {
        border: solid 0.5px transparent !important;
        @extend.smetio-hiddenScrollBar;
        flex: 1;
        @extend.smetio-grey-text;
    }

    .smetio-text-editor-primary-btn {
        @extend.smetio-smart-box-button;
        align-self: center;
        justify-self: center;
    }


    @include small_mobile.smallMobileScreen;
    @include mobile.mobileScreen;
    @include tablet.tabletScreen;
    @include desktop.desktopScreen;
    @include large_desktop.largeDesktopScreen;  

}/* .smetio-multimediaPlayer ENDS HERE */





