
@use "small_mobile";
@use "mobile";
@use "tablet";
@use "desktop";
@use "large_desktop";



@use "../../../../../css/mixins/displays/displays" as display;
@use "../../../../../css/mixins/dimensions/dimensions" as dimension;
@use "../../../../../css/mixins/position/positions" as position;
@use "../../../../../css/mixins/shapes/shapes" as shapes;
@use "../../../../../css/mixins/looks/looks" as looks;
@use "../../../../../css/mixins/effects/effects" as effects;
@use "../../../../../css/mixins/general_smetio_classes/general_smetio_classes" as classes;


// @import "../../../../../css/variables/devices/device_sizes";
// @import "../../../../../css/mainClasses";

// @import "../../../../../css/latestSmetioStyleSheet";


.smetio-app-ui-sideMenu {

    z-index: 2;
    top: 0;     
    height: 100%;
    transition: width 0.4s;
    transition-timing-function:linear;      

    // background-color:var(--smetio-layout-brand-color-shade-60);
    // @extend.smetio-accent-background;
    @extend.smetio-dominant-background;
    // @extend.smetio-accent-text-reverse;
    @extend.smetio-white-text;
 
    overflow:hidden;
    position:fixed;
    
    @include display.flex-wrap(column);
    
    &[data-open-status="fully_open"]{
    
        width:var(--smetio-app-ui-body-section-1-fully-open-width);                
    }

    &[data-open-status="half_open"]{
    
        width:var(--smetio-app-ui-menu-bar-width); 
                             
    }

    &[data-open-status="close"]{
    
        width:0;   
                                     
    }

    &[data-side ="left"]{
    
        left:0;   
                               
    }

    &[data-side ="right"]{
    
        right:0;   
                               
    }

    &[data-smetio-position ="relative"]{
    
        position:relative;  
                               
    }    

    &[data-smetio-position ="static"]{
    
        position:static;  
                               
    }

    .smetio-app-ui-sideMenu-items-holder {

        width: 100%;
        margin-top: var(--smetio-app-ui-head-height);
        @include display.flex-wrap(column);
        @include display.flex-perfect-center;         
        

        .smetio-app-ui-sideMenu-item {

            width: 100%;
            @include display.flex-wrap(row);
            @include display.flex-perfect-center;  
            padding-top: calc(var(--smetio-app-ui-icon-default-margin)/2);  
            padding-bottom: calc(var(--smetio-app-ui-icon-default-margin)/2);
            font-size: 100%;
            // @extend .smetio-default-secondary-text-color;
            // border-top: solid 0.5px var(--smetio-layout-brand-color-shade-60);
            // border-bottom: solid 0.5px var(--smetio-layout-brand-color-shade-60);
            // @extend.smetio-accent-button;


            // @extend.smetio-accent-background;
            // // @extend.smetio-dominant-text-reverse;
            // @extend.smetio-neutral-text;   
            @extend.smetio-dominant-background;
            @extend.smetio-white-text;
            // @extend.smetio-accent-text-reverse;     

            &:hover {
                // @extend .smetio-bg-blue;
                // @extend.smetio-accent-text;
                // @extend.smetio-dominant-text;
                // @extend.smetio-dominant-background;
            
                @extend.smetio-accent-background;
                @extend.smetio-dominant-text-reverse;

                @extend .smetio-cursor-pointer;        
            }
        
            &:active {    
                // @extend .smetio-bg-blue;   
                // @extend.smetio-dominant-background; 
                @extend.smetio-neutral-background;
                @extend.smetio-dominant-text-reverse;

            }
        
            &[data-smetio-status="active"]{    
                // @extend.smetio-dominant-text;
                // @extend.smetio-dominant-background;  
                // @extend.smetio-neutral-background;
                // @extend.smetio-dominant-text-reverse;   
                @extend.smetio-accent-background;
                @extend.smetio-dominant-text-reverse;
            } 		

            overflow: hidden;
            flex-wrap: unset;
            flex-wrap: nowrap !important;
                      
            .smetio-app-ui-sideMenu-item-icon {
    
                max-width:var(--smetio-app-ui-menu-bar-width); 
                width:var(--smetio-app-ui-menu-bar-width); 
                @include display.flex-wrap(row);
                @include display.flex-perfect-center;

                i {
                    font-size: 150%;
                }                

            }

            .smetio-app-ui-sideMenu-item-text {

                flex: 1;
                @include display.flex-wrap(row);
                justify-items: flex-start !important;
                justify-content: flex-start !important;
                align-items: center;

                flex-wrap: unset;
                flex-wrap: nowrap;
                white-space: nowrap;                
                overflow: hidden;

                p{
                    white-space: nowrap;
                }

            }
            

        }
    }
 

    @include small_mobile.smallMobileScreen;
    @include mobile.mobileScreen;
    @include tablet.tabletScreen;
    @include desktop.desktopScreen;
    @include large_desktop.largeDesktopScreen;      

}/* .smetio-app-ui-sideMenu ENDS HERE */